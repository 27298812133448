/**
 * Consolidated AI Service
 * 
 * This service handles all AI-related API calls and evaluation functions.
 * It replaces:
 * - src/services/openai.js
 * - src/components/interactivelessons/utils/aiService.js
 * - src/components/interactivelessons/utils/aiEvaluation.js
 * - src/components/interactivelessons/utils/evaluation.js (AI-related functions)
 */

import { supabase } from '../auth/supabase';
import React from 'react';
import ReactDOM from 'react-dom';
import PaywallModal from '../components/ui/PaywallModal';

// API URL for OpenAI calls - directly to OpenAI API
const API_URL = 'https://api.openai.com/v1/chat/completions';

// OpenAI API key from environment variables
const OPENAI_API_KEY = process.env.REACT_APP_OPENAI_API_KEY;

// Check if device is mobile
const isMobile = () => {
  const userAgent = window.navigator.userAgent;
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent) || window.innerWidth <= 768;
};

// Check if network is available
const isOnline = () => {
  return navigator.onLine;
};

// Network error handler with mobile-specific messaging
const handleNetworkError = (error) => {
  console.error('Network error in AI service:', error);
  
  if (!isOnline()) {
    return {
      isSuccess: false,
      isNetworkError: true,
      message: "You appear to be offline. Please check your internet connection and try again."
    };
  }
  
  if (isMobile()) {
    return {
      isSuccess: false,
      isMobileError: true,
      message: "There was an issue connecting to the AI service on your mobile device. Try switching to WiFi if you're using mobile data."
    };
  }
  
  return {
    isSuccess: false,
    message: "There was an error connecting to the AI service. Please try again later."
  };
};

/**
 * Creates a temporary DOM element and renders a paywall modal
 * @param {string} feature - The premium feature being accessed
 */
const showPaywallModal = (feature = 'AI Assistant') => {
  // Create a temporary container for the modal
  const container = document.createElement('div');
  container.id = 'ai-paywall-modal-container';
  document.body.appendChild(container);
  
  // Handle modal close
  const handleClose = () => {
    // Unmount the component
    ReactDOM.unmountComponentAtNode(container);
    // Remove the container
    if (document.body.contains(container)) {
      document.body.removeChild(container);
    }
  };
  
  // Handle upgrade click
  const handleUpgrade = () => {
    window.location.href = '/dashboard/settings';
  };
  
  // Render the paywall modal
  ReactDOM.render(
    <PaywallModal 
      isOpen={true} 
      onClose={handleClose} 
      feature={feature}
      onUpgrade={handleUpgrade}
    />,
    container
  );
};

/**
 * Checks if the user has premium access
 * @param {string} feature - The premium feature being accessed
 * @returns {Promise<Object>} - Result with isPremium flag and error info
 */
const checkPremiumAccess = async (feature = 'AI Assistant') => {
  try {
    // Check if user is logged in
    const { data: { user } } = await supabase.auth.getUser();
    
    if (!user) {
      return {
        isPremium: false,
        requiresAuth: true,
        message: "Please sign in to use AI features."
      };
    }
    
    // Check premium status from the database
    const { data: userData, error: userError } = await supabase
      .from('users')
      .select('is_premium')
      .eq('id', user.id)
      .single();
    
    if (userError) {
      console.error('Error fetching premium status:', userError);
      return {
        isPremium: false,
        message: "Error checking your subscription status. Please try again later."
      };
    }
    
    // If not premium, show the paywall modal
    if (!userData.is_premium) {
      showPaywallModal(feature);
      return {
        isPremium: false,
        isPremiumFeature: true,
        message: "This is a premium feature. Please upgrade to access AI assistance."
      };
    }
    
    // User has premium access
    return { isPremium: true };
  } catch (error) {
    console.error('Error checking premium status:', error);
    return {
      isPremium: false,
      message: "Error checking premium status. Please try again later."
    };
  }
};

/**
 * Evaluates a user's answer to a question using OpenAI
 * 
 * @param {string} question - The question that was asked
 * @param {string} userAnswer - The user's answer to evaluate
 * @param {string} questionDescription - Optional additional context for the question
 * @returns {Promise<Object>} - The evaluation result with feedback
 */
export const evaluateAnswer = async (question, userAnswer, questionDescription = '') => {
  try {
    // Check network status first
    if (!isOnline()) {
      console.log('Evaluation failed: User is offline');
      return {
        isCorrect: false,
        feedback: "You appear to be offline. Please check your internet connection and try again.",
        expectedAnswer: "Unable to evaluate while offline.",
        isNetworkError: true
      };
    }

    // Check premium access
    const premiumCheck = await checkPremiumAccess('AI Evaluation');
    if (!premiumCheck.isPremium) {
      return {
        isCorrect: false,
        feedback: premiumCheck.message,
        expectedAnswer: "Upgrade to premium for AI evaluation.",
        isPremiumFeature: premiumCheck.isPremiumFeature,
        requiresAuth: premiumCheck.requiresAuth
      };
    }

    console.log('Sending evaluation request to OpenAI API');

    // Check if API key is available
    if (!OPENAI_API_KEY) {
      console.error('OpenAI API key is missing');
      return {
        isCorrect: false,
        feedback: "API key is not configured. Please check your environment variables.",
        expectedAnswer: "Unable to evaluate without API key."
      };
    }

    // Create the system message to instruct the AI how to evaluate answers
    const systemMessage = `
      You are an educational assistant that evaluates student answers to coding questions.
      Evaluate the user's answer and provide helpful feedback that is concise and to the point.
      
      Your response must be a JSON object with the following structure:
      {
        "isCorrect": boolean,     // true if the answer is generally correct, false if it needs improvement
        "feedback": string,       // IMPORTANT: Provide very concise and short feedback (1-3 sentences maximum)
        "expectedAnswer": string  // A brief model answer or key points that should have been included
      }
      
      Guidelines for feedback:
      - Keep feedback extremely concise and direct
      - Maximum 1-3 sentences of feedback
      - Focus only on the most important points
      - No lengthy explanations
      - No markdown formatting in your response, just return a valid JSON object
    `;

    // Create the user message with the question and answer
    const userMessage = `
      Question: ${question}
      ${questionDescription ? `Additional context: ${questionDescription}` : ''}
      
      User's Answer:
      ${userAnswer}
      
      Please evaluate this answer with concise feedback.
    `;

    // Reduce the max tokens for mobile to improve performance
    const maxTokens = isMobile() ? 750 : 1500;

    // Make the API request directly to OpenAI
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 15000); // 15-second timeout
    
    const response = await fetch(API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${OPENAI_API_KEY}`
      },
      body: JSON.stringify({
        model: 'gpt-4-turbo-preview',
        messages: [
          { role: 'system', content: systemMessage },
          { role: 'user', content: userMessage }
        ],
        temperature: 0.3, // Lower temperature for more consistent evaluations
        max_tokens: maxTokens
      }),
      signal: controller.signal
    });
    
    clearTimeout(timeoutId);

    // Check if the request was successful
    if (!response.ok) {
      const errorData = await response.json();
      console.error('OpenAI API error:', errorData);
      throw new Error(`API error: ${response.status}`);
    }

    // Parse the response
    const data = await response.json();
    const aiResponse = data.choices[0].message.content;
    
    try {
      // Parse the JSON response from the AI
      const feedbackObject = JSON.parse(aiResponse);
      
      // Validate that we have the expected structure
      if (
        typeof feedbackObject.isCorrect !== 'boolean' ||
        typeof feedbackObject.feedback !== 'string' ||
        typeof feedbackObject.expectedAnswer !== 'string'
      ) {
        throw new Error('Invalid response format from AI');
      }
      
      return feedbackObject;
    } catch (parseError) {
      console.error('Failed to parse AI response as JSON:', parseError);
      console.log('AI Response:', aiResponse);
      
      // If parsing fails, provide a fallback response
      return {
        isCorrect: false,
        feedback: "I couldn't properly evaluate your answer. Please try again.",
        expectedAnswer: "Unable to generate expected answer at this time."
      };
    }
  } catch (error) {
    console.error('Error evaluating answer:', error);
    
    // Handle timeout errors
    if (error.name === 'AbortError') {
      return {
        isCorrect: false,
        feedback: "The request took too long to process. Please try again with a shorter answer.",
        expectedAnswer: "Unable to generate expected answer at this time.",
        isTimeoutError: true
      };
    }
    
    // Handle network errors with device-specific feedback
    if (error.message.includes('NetworkError') || error.message.includes('Failed to fetch')) {
      return handleNetworkError(error);
    }
    
    // Return a generic error response
    return {
      isCorrect: false,
      feedback: "There was an error evaluating your answer. Please try again later.",
      expectedAnswer: "Unable to generate expected answer at this time."
    };
  }
};

/**
 * Evaluates code based on the exercise requirements
 * @param {string} code - The user's code to evaluate
 * @param {Object} exercise - The exercise object with requirements
 * @returns {Promise<Object>} - Evaluation result with feedback
 */
export const evaluateCode = async (code, exercise) => {
  try {
    // Check network status first
    if (!isOnline()) {
      return {
        isCorrect: false,
        feedback: "You appear to be offline. Please check your internet connection and try again.",
        expectedCode: "Unable to evaluate while offline.",
        isNetworkError: true
      };
    }
    
    // Check premium access
    const premiumCheck = await checkPremiumAccess('Code Evaluation');
    if (!premiumCheck.isPremium) {
      return {
        isCorrect: false,
        feedback: premiumCheck.message,
        expectedCode: "Upgrade to premium for AI code evaluation.",
        isPremiumFeature: premiumCheck.isPremiumFeature,
        requiresAuth: premiumCheck.requiresAuth
      };
    }
    
    // Check if API key is available
    if (!OPENAI_API_KEY) {
      console.error('OpenAI API key is missing');
      return {
        isCorrect: false,
        feedback: "API key is not configured. Please check your environment variables.",
        expectedCode: "Unable to evaluate without API key."
      };
    }
    
    // Reduce complexity for mobile
    const maxTokens = isMobile() ? 600 : 1024;
    
    // Add timeout for mobile
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 15000); // 15-second timeout
    
    const response = await fetch(API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${OPENAI_API_KEY}`
      },
      body: JSON.stringify({
        model: 'gpt-4-turbo-preview',
        max_tokens: maxTokens,
        messages: [
          {
            role: 'system',
            content: 'You are an educational assistant evaluating a student\'s code for a programming exercise. When providing code examples or feedback, format them using markdown code blocks with the appropriate language syntax highlighting. For example: ```javascript\n// Your code here\n```'
          },
          {
            role: 'user',
            content: `
Exercise: ${exercise.exercise || 'Code exercise'}
Instructions: ${exercise.codeInstructions || ''}
Language: ${exercise.language || 'javascript'}
Expected Output: ${exercise.expectedOutput || ''}
Sample Solution: ${exercise.sampleSolution || ''}

Student's Code:
\`\`\`
${code}
\`\`\`

Evaluate the student's code and provide:
1. Whether the code is correct or needs improvement
2. Constructive feedback on the code
3. Example of expected code that would solve the problem correctly

Return your response in the following JSON format exactly:
{
  "isCorrect": true or false,
  "feedback": "Your constructive feedback here",
  "expectedCode": "Your example of expected code here with proper formatting using markdown code blocks"
}

Your evaluation should be educational and encouraging. If the code is completely wrong, provide guidance on how to improve it.`
          }
        ],
        temperature: 0.7
      }),
      signal: controller.signal
    });
    
    clearTimeout(timeoutId);

    if (!response.ok) {
      throw new Error(`API error: ${response.status}`);
    }

    const data = await response.json();
    const aiResponseText = data.choices[0].message.content;
    
    try {
      // Parse JSON response
      const aiResponse = JSON.parse(aiResponseText);
      return aiResponse;
    } catch (err) {
      console.error('Error parsing AI response:', err);
      return {
        isCorrect: false,
        feedback: "Sorry, I couldn't evaluate your code due to a technical issue.",
        expectedCode: "Unable to generate expected code at this time."
      };
    }
  } catch (error) {
    console.error('Error calling AI API for code evaluation:', error);
    
    // Handle timeout errors
    if (error.name === 'AbortError') {
      return {
        isCorrect: false,
        feedback: "The request took too long to process. Please try again with simpler code.",
        expectedCode: "Unable to generate expected code at this time.",
        isTimeoutError: true
      };
    }
    
    // Handle network errors with device-specific feedback
    if (error.message.includes('NetworkError') || error.message.includes('Failed to fetch')) {
      return {
        ...handleNetworkError(error),
        isCorrect: false,
        expectedCode: "Unable to evaluate code at this time due to network issues."
      };
    }
    
    // Fallback evaluation with simple checks
    return {
      isCorrect: code.length > 50,
      feedback: "There was an error evaluating your code. Please try again later.",
      expectedCode: "Unable to generate expected code at this time."
    };
  }
};

/**
 * Ask a question about selected text using AI
 * @param {string} selectedText - The text selection to ask about
 * @param {string} question - The user's question about the text
 * @param {string} slideContext - Additional context from the slide (optional)
 * @returns {Promise<Object>} - AI response with answer to the question
 */
export const askQuestionAboutText = async (selectedText, question, slideContext = '') => {
  try {
    // Check network status first
    if (!isOnline()) {
      return {
        isSuccess: false,
        answer: "You appear to be offline. Please check your internet connection and try again.",
        isNetworkError: true
      };
    }
    
    // Check premium access
    const premiumCheck = await checkPremiumAccess('AI Assistant');
    if (!premiumCheck.isPremium) {
      return {
        isSuccess: false,
        answer: premiumCheck.message,
        isPremiumFeature: premiumCheck.isPremiumFeature,
        requiresAuth: premiumCheck.requiresAuth
      };
    }
    
    // Check if API key is available
    if (!OPENAI_API_KEY) {
      console.error('OpenAI API key is missing');
      return {
        isSuccess: false,
        answer: "API key is not configured. Please check your environment variables."
      };
    }
    
    // Reduce complexity for mobile
    const maxTokens = isMobile() ? 750 : 1500;
    
    // Add timeout for mobile
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 20000); // 20-second timeout
    
    const response = await fetch(API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${OPENAI_API_KEY}`
      },
      body: JSON.stringify({
        model: 'gpt-4-turbo-preview',
        max_tokens: maxTokens,
        messages: [
          {
            role: 'system',
            content: `You are an AI tutor that helps students understand text they've selected. 
                     When answering questions:
                     1. Use simple, easy-to-understand language
                     2. Be extremely concise and direct - keep answers short and focused
                     3. Explain complex concepts in plain terms a beginner would understand
                     4. Include only the most relevant information
                     5. Use examples when they help clarify a concept
                     Remember that your goal is to make learning accessible and understandable.`
          },
          {
            role: 'user',
            content: `I've selected the following text from an educational course: 
                    
                    "${selectedText}"
                    
                    ${slideContext ? `Additional context from the lesson: "${slideContext}"` : ''}
                    
                    My question is: ${question}
                    
                    Please explain this in simple terms and help me understand.`
          }
        ],
        temperature: 0.7
      }),
      signal: controller.signal
    });
    
    clearTimeout(timeoutId);

    if (!response.ok) {
      throw new Error(`API error: ${response.status}`);
    }

    const data = await response.json();
    return {
      isSuccess: true,
      answer: data.choices[0].message.content
    };
  } catch (error) {
    console.error('Error asking AI about text:', error);
    
    // Handle timeout errors
    if (error.name === 'AbortError') {
      return {
        isSuccess: false,
        answer: "The request took too long to process. Please try asking a simpler question or selecting less text.",
        isTimeoutError: true
      };
    }
    
    // Handle network errors with device-specific feedback
    if (error.message.includes('NetworkError') || error.message.includes('Failed to fetch')) {
      return handleNetworkError(error);
    }
    
    return {
      isSuccess: false,
      answer: "I'm sorry, I couldn't process your question at this time. Please try again later."
    };
  }
};

/**
 * Evaluates an answer based on keyword matching (non-AI fallback)
 * 
 * @param {string} userAnswer - The user's answer text
 * @param {string[]} expectedKeywords - Array of keywords to look for
 * @param {number} highThreshold - Percentage threshold for "correct" (default: 70%)
 * @param {number} lowThreshold - Percentage threshold for "partial" (default: 40%)
 * @returns {Object} Evaluation result with feedback text and correctness
 */
export const evaluateKeywordMatch = (
  userAnswer,
  expectedKeywords,
  highThreshold = 70,
  lowThreshold = 40
) => {
  if (!userAnswer || !expectedKeywords || !expectedKeywords.length) {
    return {
      isCorrect: false,
      feedback: "Please provide an answer that addresses the question."
    };
  }

  // Count how many expected keywords are in the answer
  const keywordCount = expectedKeywords.filter(keyword => 
    userAnswer.toLowerCase().includes(keyword.toLowerCase())
  ).length;
  
  // Calculate match percentage
  const percentageMatch = (keywordCount / expectedKeywords.length) * 100;
  
  // Determine result based on thresholds
  if (percentageMatch >= highThreshold) {
    return {
      isCorrect: true,
      feedback: "Great job! Your answer covers the key points.",
      percentageMatch,
      matchedKeywords: keywordCount,
      totalKeywords: expectedKeywords.length
    };
  } else if (percentageMatch >= lowThreshold) {
    // Get missing keywords for feedback
    const missingKeywords = expectedKeywords
      .filter(kw => !userAnswer.toLowerCase().includes(kw.toLowerCase()))
      .slice(0, 3)
      .join(', ');
    
    return {
      isCorrect: false,
      feedback: `Good attempt! Consider adding more detail about: ${missingKeywords}`,
      percentageMatch,
      matchedKeywords: keywordCount,
      totalKeywords: expectedKeywords.length,
      partialCredit: true
    };
  } else {
    return {
      isCorrect: false,
      feedback: "Your answer is missing some key concepts. Try reviewing the material again.",
      percentageMatch,
      matchedKeywords: keywordCount,
      totalKeywords: expectedKeywords.length,
      partialCredit: false
    };
  }
}; 