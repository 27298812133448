/**
 * Slide Components
 * 
 * This file exports all the available components that can be used within slides.
 * Each component is designed to be composable and can be used in any combination
 * within a slide.
 */

import TextComponent from './TextComponent';
import ImageComponent from './ImageComponent';
import VideoComponent from './VideoComponent';
import CodingComponent from './CodingComponent';
import QuizComponent from './QuizComponent';
import InputComponent from './InputComponent';

// Export individual components
export { 
  TextComponent,
  ImageComponent,
  VideoComponent,
  CodingComponent,
  QuizComponent,
  InputComponent
};

/**
 * Component registry for slide components
 * 
 * This maps component types to their React component implementations.
 * When adding a new component type, register it here to make it available
 * to the SlideRenderer.
 */
export const components = {
  text: TextComponent,
  image: ImageComponent,
  video: VideoComponent,
  code: CodingComponent,
  quiz: QuizComponent,
  input: InputComponent,
};

/**
 * Component type constants
 * 
 * These constants are used to reference component types throughout the application.
 */
export const COMPONENT_TYPES = {
  TEXT: 'text',
  IMAGE: 'image',
  VIDEO: 'video',
  CODING: 'code',
  QUIZ: 'quiz',
  INPUT: 'input',
};

/**
 * Creates a unique ID for a component
 * 
 * @param {string} type - The component type
 * @param {string} [prefix=''] - Optional prefix for the ID
 * @returns {string} A unique component ID
 */
export const createComponentId = (type, prefix = '') => {
  const timestamp = Date.now();
  const random = Math.random().toString(36).substring(2, 9);
  return `${prefix}${prefix ? '-' : ''}${type}-${timestamp}-${random}`;
};

/**
 * Register a custom component in the registry
 * 
 * @param {string} type - The component type
 * @param {React.Component} component - The React component implementation
 */
export const registerComponent = (type, component) => {
  if (components[type]) {
    console.warn(`Component type "${type}" is already registered. Overwriting.`);
  }
  
  components[type] = component;
};

/**
 * Get a component from the registry by type
 * 
 * @param {string} type - The component type
 * @returns {React.Component|null} The component or null if not found
 */
export const getComponent = (type) => {
  return components[type] || null;
};

/**
 * Export the ComponentMap for backward compatibility
 * @deprecated Use `components` directly instead
 */
export const ComponentMap = components;

// Legacy type mapping for backward compatibility
export const LEGACY_TYPE_MAPPING = {
  'context': COMPONENT_TYPES.TEXT,
  'learn': COMPONENT_TYPES.TEXT,
  'recap': COMPONENT_TYPES.TEXT,
  'text': COMPONENT_TYPES.TEXT,
  'user_input': COMPONENT_TYPES.INPUT,
  'input': COMPONENT_TYPES.INPUT,
  'test': COMPONENT_TYPES.INPUT,
  'coding': COMPONENT_TYPES.CODING,
  'selection_quiz': COMPONENT_TYPES.QUIZ,
  'quiz': COMPONENT_TYPES.QUIZ,
  'video': COMPONENT_TYPES.VIDEO,
  'custom': COMPONENT_TYPES.TEXT
};

// Helper function to convert legacy slide types
export const convertLegacySlideType = (slide) => {
  if (!slide || !slide.type) return slide;
  
  // If the slide type is already one of our current types, return as-is
  if (Object.values(COMPONENT_TYPES).includes(slide.type)) {
    return slide;
  }
  
  // Otherwise map the legacy type to the new format
  const newType = LEGACY_TYPE_MAPPING[slide.type] || COMPONENT_TYPES.TEXT;
  
  return {
    ...slide,
    type: newType
  };
};

// Default export for easier importing
export default {
  TextComponent,
  ImageComponent,
  VideoComponent,
  CodingComponent,
  QuizComponent,
  InputComponent,
  COMPONENT_TYPES,
  ComponentMap,
  convertLegacySlideType,
  LEGACY_TYPE_MAPPING
}; 