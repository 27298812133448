import React, { useEffect, useRef, useState } from 'react';
import { useTextSelection } from '../../context/TextSelectionContext';
import { SlideContainer } from '../../ui/CommonUI';

/**
 * Slide - A base container component for all lesson content
 * 
 * This component serves as the container for all slide content components.
 * It provides the basic layout, navigation controls, and context management
 * for any components that are placed inside it.
 */
const Slide = ({ 
  id,
  title,
  className = '',
  onNext, 
  onPrevious, 
  isFirst, 
  isLast,
  children,
  onOverflow,
  debug = false,
  maxHeightPercentage = 75 // Default to 75% of viewport height
}) => {
  // Use the text selection context to update the current slide title
  const { updateSlideContext } = useTextSelection();
  const slideRef = useRef(null);
  const contentRef = useRef(null);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [overflowComponentIds, setOverflowComponentIds] = useState([]);
  const [debugInfo, setDebugInfo] = useState({});
  const checkTimeoutRef = useRef(null);
  // Track when we've finished the initial overflow check
  const [initialCheckComplete, setInitialCheckComplete] = useState(false);
  
  // Update the slide context when the slide mounts or changes
  useEffect(() => {
    if (title) {
      updateSlideContext(title);
    }
  }, [title, updateSlideContext]);
  
  // Debug log function
  const logDebug = (message, data = {}) => {
    if (debug) {
      console.log(`[Slide:${id}] ${message}`, data);
      setDebugInfo(prev => ({
        ...prev,
        lastMessage: message,
        lastData: data,
        time: new Date().toISOString()
      }));
    }
  };
  
  // Reset state when slide changes
  useEffect(() => {
    // Reset states on slide change
    setIsOverflowing(false);
    setOverflowComponentIds([]);
    setInitialCheckComplete(false);
    
    // Run immediate check for overflow on new slide
    if (contentRef.current && slideRef.current) {
      // Schedule a quick check
      setTimeout(() => {
        checkOverflow(true);
      }, 50);
    }
    
    return () => {
      if (checkTimeoutRef.current) {
        clearTimeout(checkTimeoutRef.current);
      }
    };
  }, [id]);
  
  // Check for content overflow and handle it
  useEffect(() => {
    if (!contentRef.current || !slideRef.current) return;
    
    // Use ResizeObserver to check for overflow when content or window size changes
    const resizeObserver = new ResizeObserver(() => {
      // Clear any existing timeout to prevent multiple rapid checks
      if (checkTimeoutRef.current) {
        clearTimeout(checkTimeoutRef.current);
      }
      
      // Set a timeout to debounce the check
      checkTimeoutRef.current = setTimeout(() => {
        checkOverflow();
        checkTimeoutRef.current = null;
      }, 300);
    });
    
    resizeObserver.observe(contentRef.current);
    resizeObserver.observe(slideRef.current);
    
    // Also check once on mount after a delay to ensure content is rendered
    checkTimeoutRef.current = setTimeout(() => {
      checkOverflow(true);
      checkTimeoutRef.current = null;
    }, 100);
    
    return () => {
      resizeObserver.disconnect();
      if (checkTimeoutRef.current) {
        clearTimeout(checkTimeoutRef.current);
      }
    };
  }, [children, onOverflow]);
  
  // Function to check for content overflow
  const checkOverflow = (isInitialCheck = false) => {
    if (!contentRef.current || !slideRef.current) return;
    
    // Calculate the maximum height based on viewport height
    const viewportHeight = window.innerHeight;
    const maxHeight = (viewportHeight * maxHeightPercentage) / 100;
    
    // Get the content container's measurements
    const containerRect = slideRef.current.getBoundingClientRect();
    const contentHeight = contentRef.current.scrollHeight;
    
    // Calculate available height (accounting for title and padding)
    const titleHeight = slideRef.current.querySelector('.slide-title')?.offsetHeight || 0;
    const padding = 32; // Account for padding
    const availableHeight = maxHeight - titleHeight - padding;
    
    // Check if content is overflowing
    const hasOverflow = contentHeight > availableHeight;
    
    logDebug("Checking overflow", { 
      contentHeight, 
      availableHeight, 
      hasOverflow,
      viewportHeight,
      maxHeight,
      titleHeight,
      isInitialCheck
    });
    
    // If this is the initial check, mark it as complete
    if (isInitialCheck) {
      setInitialCheckComplete(true);
    }
    
    // Only proceed if we have actual overflow and we aren't already handling it
    if (!hasOverflow || !onOverflow) {
      if (isOverflowing !== false) {
        setIsOverflowing(false);
        setOverflowComponentIds([]);
      }
      return;
    }
    
    // Get all direct child components of the content container
    const childNodes = Array.from(contentRef.current.querySelectorAll('.slide-component'));
    
    if (childNodes.length === 0) {
      logDebug("No slide components found", { childNodesLength: 0 });
      return;
    }
    
    logDebug("Found child components", { count: childNodes.length });
    
    let visibleHeight = 0;
    let lastVisibleIndex = -1;
    
    // Find the last fully visible component
    for (let i = 0; i < childNodes.length; i++) {
      const child = childNodes[i];
      const childHeight = child.offsetHeight;
      
      // Check if adding this component would exceed available height
      if (visibleHeight + childHeight <= availableHeight) {
        visibleHeight += childHeight;
        lastVisibleIndex = i;
        logDebug(`Component ${i} fits`, { height: childHeight, runningTotal: visibleHeight });
      } else {
        // This component doesn't fit, we need to put it in overflow
        logDebug(`Component ${i} overflows`, { height: childHeight, runningTotal: visibleHeight + childHeight });
        break;
      }
    }
    
    // If we found the breakpoint, prepare overflow components
    if (lastVisibleIndex >= 0 && lastVisibleIndex < childNodes.length - 1) {
      // Get the IDs of the overflowing components
      const overflowingIds = childNodes
        .slice(lastVisibleIndex + 1)
        .map(node => node.dataset.componentId)
        .filter(Boolean);
      
      logDebug("Identified overflowing components", { 
        lastVisibleIndex, 
        totalComponents: childNodes.length,
        overflowingIds 
      });
      
      // Check if the overflowing components are different from what we already have
      const overflowChanged = 
        overflowingIds.length !== overflowComponentIds.length || 
        overflowingIds.some(id => !overflowComponentIds.includes(id));
      
      // Only update state if there's a change to avoid infinite loops
      if (overflowChanged) {
        setIsOverflowing(true);
        setOverflowComponentIds(overflowingIds);
        
        // Call the onOverflow handler with the overflow component IDs
        if (overflowingIds.length > 0) {
          logDebug("Calling onOverflow handler", { overflowingIds });
          onOverflow(overflowingIds);
        }
      }
    } else {
      logDebug("No overflow breakpoint found", { lastVisibleIndex, childNodes: childNodes.length });
    }
  };
  
  // The class for the slide container
  const slideClasses = `${className} ${!initialCheckComplete ? 'overflow-checking' : ''}`;
  
  return (
    <SlideContainer ref={slideRef} className={slideClasses} style={{ maxHeight: `${maxHeightPercentage}vh` }}>
      <div 
        className="w-full h-full px-4 slide-content overflow-hidden"
        ref={contentRef}
      >
        {/* Title */}
        {title && (
          <h2 className="text-3xl font-bold mb-4 text-blue-400 selectable-text slide-title">
            {title}
          </h2>
        )}
        
        {/* Show loading indicator if initial check is not complete */}
        {!initialCheckComplete ? (
          <div className="slide-loading flex justify-center items-center py-4">
            <div className="text-gray-400 text-center italic">
              Preparing content...
            </div>
          </div>
        ) : (
          /* Content Components - only render once initial check is complete */
          <div className="slide-components space-y-4">
            {React.Children.map(children, child => {
              if (!child) return null;
              
              // Add a wrapper to each component with a data attribute for tracking overflow
              const componentId = child.props?.data?.id || `component-${Math.random().toString(36).substring(2, 9)}`;
              
              // Skip rendering if this component ID is in the overflow list
              if (isOverflowing && overflowComponentIds.includes(componentId)) {
                return null;
              }
              
              return (
                <div className="slide-component" data-component-id={componentId}>
                  {child}
                </div>
              );
            })}
          </div>
        )}
        
        {/* Debug information */}
        {debug && (
          <div className="mt-4 border-t border-gray-700 pt-2 text-xs text-gray-500">
            <div>Slide ID: {id}</div>
            <div>Is Overflowing: {isOverflowing ? 'Yes' : 'No'}</div>
            <div>Overflow Components: {overflowComponentIds.length}</div>
            <div>Initial Check: {initialCheckComplete ? 'Complete' : 'Pending'}</div>
            <div>Last Check: {debugInfo.time}</div>
            {debugInfo.lastMessage && (
              <div>Last Message: {debugInfo.lastMessage}</div>
            )}
          </div>
        )}
      </div>
    </SlideContainer>
  );
};

export default Slide; 