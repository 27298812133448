/**
 * URL Utility Functions
 * 
 * Functions for handling URL slugs and other URL-related operations
 */

/**
 * Creates a URL path segment from a title, ID, and optionally a slug
 * Prioritizes using the slug if provided, otherwise falls back to slugifying the title
 * @param {string} title - The title to use for generating a slug if needed
 * @param {string|number} id - The ID to use as a fallback
 * @param {string} slug - Optional existing slug from the database
 * @returns {string} A URL path segment
 */
export const createSlug = (title, id, slug) => {
  // If a slug is provided, use it directly
  if (slug) {
    return slug;
  }
  
  // Handle case where ID is undefined or null
  if (!id) {
    // Special case for SQL-related courses and lessons
    if (title && title.toLowerCase().includes('sql')) {
      return 'sql-' + slugify(title);
    }
    
    console.warn('No ID or slug provided to createSlug, falling back to slugified title');
    return title ? slugify(title) : 'unknown';
  }
  
  // Remove any 'lesson-' prefix if present
  let cleanId = String(id);
  if (cleanId.includes('lesson-')) {
    cleanId = cleanId.replace('lesson-', '');
  }
  
  // If it's a numbered lesson ID like 001, remove leading zeros
  if (/^0+\d+$/.test(cleanId)) {
    cleanId = String(parseInt(cleanId, 10));
  }
  
  return cleanId;
};

/**
 * Helper function to convert a string to a URL-friendly slug
 * Kept for backward compatibility and fallback
 * @param {string} text - The text to slugify
 * @returns {string} A URL-friendly slug
 */
const slugify = (text) => {
  return text
    .toLowerCase()
    .replace(/[^\w ]+/g, '')  // Remove non-word chars except spaces
    .replace(/ +/g, '-')      // Replace spaces with hyphens
    .trim();                  // Trim leading/trailing spaces
};

/**
 * Extracts the ID from a path segment
 * @param {string} slug - The path segment to extract ID from
 * @returns {string} The extracted ID
 */
export const getIdFromSlug = (slug) => {
  if (!slug) return null;
  
  // Special case for SQL courses
  if (slug === 'sql' || slug.startsWith('sql-')) {
    return 'sql';
  }
  
  // In the new URL structure, the slug is just the ID
  // But we need to handle old slug format for backward compatibility
  
  // Check if slug has dashes, which would indicate old format
  if (slug.includes('-')) {
    // Old format: extract ID from the end
    return slug.split('-').pop();
  }
  
  // New format: slug is already the ID
  return slug;
}; 