import React, { Suspense, lazy, useState, useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './auth/AuthContext';
import { LanguageProvider } from './components/interactivelessons/context/LanguageContext';
import Login from './auth/Login';
import SignUp from './auth/SignUp';
import ForgotPassword from './auth/ForgotPassword';
import ProtectedRoute from './auth/ProtectedRoute';
import LandingPage from './components/LandingPage';
import NetworkStatus from './components/interactivelessons/ui/NetworkStatus';
import { LessonSelector } from './components/interactivelessons/core';

// Error boundary component to catch chunk loading errors
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Error caught by boundary:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="min-h-screen bg-[#1a1e2b] flex items-center justify-center">
          <div className="text-center max-w-md p-6 bg-red-900/20 rounded-lg border border-red-500/30">
            <h2 className="text-xl text-red-300 mb-3">Loading Error</h2>
            <p className="text-gray-300 mb-4">
              We had trouble loading this page. Please try refreshing.
            </p>
            <button 
              className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
              onClick={() => {
                this.setState({ hasError: false });
                window.location.reload();
              }}
            >
              Refresh Page
            </button>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

// Use lazy loading with better chunk names for debugging
const InteractiveLessons = lazy(() => import(/* webpackChunkName: "interactive-lessons" */ './components/interactivelessons'));
const CourseSelector = lazy(() => import(/* webpackChunkName: "course-selector" */ './components/interactivelessons/course/CourseSelector'));
const CoursePlayer = lazy(() => import(/* webpackChunkName: "course-player" */ './components/interactivelessons/course/CoursePlayer'));
const CourseCreationSelector = lazy(() => import(/* webpackChunkName: "course-creation" */ './components/interactivelessons/course/CourseCreationSelector'));
const Dashboard = lazy(() => import(/* webpackChunkName: "dashboard" */ './components/dashboard/Dashboard'));
const MyCourses = lazy(() => import(/* webpackChunkName: "my-courses" */ './components/dashboard/MyCourses'));
const Discover = lazy(() => import(/* webpackChunkName: "discover" */ './components/dashboard/Discover'));
const Settings = lazy(() => import(/* webpackChunkName: "settings" */ './components/dashboard/Settings'));
const PricingPage = lazy(() => import(/* webpackChunkName: "pricing-page" */ './components/ui/PricingPage'));
const PaymentSuccess = lazy(() => import(/* webpackChunkName: "payment-success" */ './components/ui/PaymentSuccess'));
const PaymentCancelled = lazy(() => import(/* webpackChunkName: "payment-cancelled" */ './components/ui/PaymentCancelled'));
const TestCourseViewer = lazy(() => import(/* webpackChunkName: "test-course-viewer" */ './components/interactivelessons/examples/TestCourseViewer'));

// Admin components
const CourseList = lazy(() => import(/* webpackChunkName: "admin-course-list" */ './components/admin/CourseList'));
const CourseEditor = lazy(() => import(/* webpackChunkName: "admin-course-editor" */ './components/admin/CourseEditor'));
const LessonList = lazy(() => import(/* webpackChunkName: "admin-lesson-list" */ './components/admin/LessonList'));
const LessonEditor = lazy(() => import(/* webpackChunkName: "admin-lesson-editor" */ './components/admin/LessonEditor'));
const SlideEditor = lazy(() => import(/* webpackChunkName: "admin-slide-editor" */ './components/admin/SlideEditor'));

// Add webpack chunk names to the Review components
const ReviewCourses = lazy(() => import(/* webpackChunkName: "review-courses" */ './components/dashboard/ReviewCourses'));
const ReviewSession = lazy(() => import(/* webpackChunkName: "review-session" */ './components/dashboard/ReviewSession'));

// Loading fallback
const LoadingFallback = () => (
  <div className="min-h-screen bg-[#1a1e2b] flex items-center justify-center">
    <div className="text-center">
      <div className="w-16 h-16 border-4 border-blue-600 border-t-transparent rounded-full animate-spin mx-auto mb-4"></div>
      <p className="text-gray-400">Loading...</p>
    </div>
  </div>
);

function App() {
  const [appError, setAppError] = useState(null);

  // Reset app error after 10 seconds if one occurs
  useEffect(() => {
    if (appError) {
      const timer = setTimeout(() => {
        setAppError(null);
      }, 10000);
      return () => clearTimeout(timer);
    }
  }, [appError]);

  // Error boundary for any auth/app-wide issues
  if (appError) {
    return (
      <div className="min-h-screen bg-[#1a1e2b] flex items-center justify-center">
        <div className="text-center max-w-md p-6 bg-red-900/20 rounded-lg border border-red-500/30">
          <h2 className="text-xl text-red-300 mb-3">Application Error</h2>
          <p className="text-gray-300 mb-4">{appError.message || "Something went wrong. Please try refreshing the page."}</p>
          <button 
            className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
            onClick={() => window.location.reload()}
          >
            Refresh Page
          </button>
        </div>
      </div>
    );
  }

  return (
    <Router>
      <LanguageProvider>
        <AuthProvider>
          <div className="App">
            <NetworkStatus />
            <ErrorBoundary>
              <Suspense fallback={<LoadingFallback />}>
                <Routes>
                  <Route path="/" element={<LandingPage />} />
                  <Route path="/home" element={<CourseSelector />} />
                  
                  {/* Test Components Route */}
                  <Route path="/test" element={
                    <ErrorBoundary>
                      <Suspense fallback={<LoadingFallback />}>
                        <TestCourseViewer />
                      </Suspense>
                    </ErrorBoundary>
                  } />
                  
                  {/* Redirect old paths to new paths */}
                  <Route path="/courses" element={<Navigate to="/dashboard/courses" replace />} />
                  <Route path="/courses/:courseSlug" element={<Navigate to={({ params }) => `/dashboard/courses/${params.courseSlug}`} replace />} />
                  <Route path="/courses/:courseSlug/lessons/:lessonSlug" element={<Navigate to={({ params }) => `/dashboard/courses/${params.courseSlug}/lesson/${params.lessonSlug}`} replace />} />
                  
                  {/* Auth Routes */}
                  <Route path="/login" element={<Login />} />
                  <Route path="/signup" element={<SignUp />} />
                  <Route path="/forgot-password" element={<ForgotPassword />} />
                  
                  {/* Pricing Page - Public */}
                  <Route path="/pricing" element={
                    <ErrorBoundary>
                      <Suspense fallback={<LoadingFallback />}>
                        <PricingPage />
                      </Suspense>
                    </ErrorBoundary>
                  } />
                  
                  {/* Payment Result Pages */}
                  <Route path="/payment-success" element={
                    <ErrorBoundary>
                      <ProtectedRoute>
                        <Suspense fallback={<LoadingFallback />}>
                          <PaymentSuccess />
                        </Suspense>
                      </ProtectedRoute>
                    </ErrorBoundary>
                  } />
                  
                  <Route path="/payment-cancelled" element={
                    <ErrorBoundary>
                      <Suspense fallback={<LoadingFallback />}>
                        <PaymentCancelled />
                      </Suspense>
                    </ErrorBoundary>
                  } />
                  
                  {/* Dashboard Routes - Each wrapped in its own error boundary */}
                  <Route path="/dashboard" element={
                    <ErrorBoundary>
                      <ProtectedRoute>
                        <Suspense fallback={<LoadingFallback />}>
                          <Dashboard />
                        </Suspense>
                      </ProtectedRoute>
                    </ErrorBoundary>
                  } />
                  
                  <Route path="/dashboard/courses" element={
                    <ErrorBoundary>
                      <ProtectedRoute>
                        <Suspense fallback={<LoadingFallback />}>
                          <MyCourses />
                        </Suspense>
                      </ProtectedRoute>
                    </ErrorBoundary>
                  } />
                  
                  <Route path="/dashboard/discover" element={
                    <ErrorBoundary>
                      <ProtectedRoute>
                        <Suspense fallback={<LoadingFallback />}>
                          <Discover />
                        </Suspense>
                      </ProtectedRoute>
                    </ErrorBoundary>
                  } />
                  
                  <Route path="/dashboard/review" element={
                    <ErrorBoundary>
                      <ProtectedRoute>
                        <Suspense fallback={<LoadingFallback />}>
                          <ReviewCourses />
                        </Suspense>
                      </ProtectedRoute>
                    </ErrorBoundary>
                  } />
                  
                  <Route path="/review-session" element={
                    <ErrorBoundary>
                      <ProtectedRoute>
                        <Suspense fallback={<LoadingFallback />}>
                          <ReviewSession />
                        </Suspense>
                      </ProtectedRoute>
                    </ErrorBoundary>
                  } />
                  
                  <Route path="/dashboard/settings" element={
                    <ErrorBoundary>
                      <ProtectedRoute>
                        <Suspense fallback={<LoadingFallback />}>
                          <Settings />
                        </Suspense>
                      </ProtectedRoute>
                    </ErrorBoundary>
                  } />
                  
                  {/* Course and Lesson Routes - Protected */}
                  <Route path="/dashboard/courses/:subject/lessons" element={<LessonSelector />} />
                  
                  <Route path="/dashboard/courses/:courseSlug" element={
                    <ErrorBoundary>
                      <ProtectedRoute>
                        <Suspense fallback={<LoadingFallback />}>
                          <LessonSelector />
                        </Suspense>
                      </ProtectedRoute>
                    </ErrorBoundary>
                  } />
                  
                  <Route path="/dashboard/courses/:courseSlug/lesson/:lessonSlug" element={
                    <ErrorBoundary>
                      <ProtectedRoute>
                        <Suspense fallback={<LoadingFallback />}>
                          <CoursePlayer />
                        </Suspense>
                      </ProtectedRoute>
                    </ErrorBoundary>
                  } />
                  
                  <Route path="/create-course" element={
                    <ErrorBoundary>
                      <Suspense fallback={<LoadingFallback />}>
                        <CourseCreationSelector />
                      </Suspense>
                    </ErrorBoundary>
                  } />
                  
                  {/* Admin Routes - Protected with admin status check in components */}
                  <Route path="/admin/course-list" element={
                    <ErrorBoundary>
                      <ProtectedRoute>
                        <Suspense fallback={<LoadingFallback />}>
                          <CourseList />
                        </Suspense>
                      </ProtectedRoute>
                    </ErrorBoundary>
                  } />
                  
                  <Route path="/admin/course-editor" element={
                    <ErrorBoundary>
                      <ProtectedRoute>
                        <Suspense fallback={<LoadingFallback />}>
                          <CourseEditor />
                        </Suspense>
                      </ProtectedRoute>
                    </ErrorBoundary>
                  } />
                  
                  <Route path="/admin/course-editor/:courseId" element={
                    <ErrorBoundary>
                      <ProtectedRoute>
                        <Suspense fallback={<LoadingFallback />}>
                          <CourseEditor />
                        </Suspense>
                      </ProtectedRoute>
                    </ErrorBoundary>
                  } />
                  
                  <Route path="/admin/lesson-list/:courseId" element={
                    <ErrorBoundary>
                      <ProtectedRoute>
                        <Suspense fallback={<LoadingFallback />}>
                          <LessonList />
                        </Suspense>
                      </ProtectedRoute>
                    </ErrorBoundary>
                  } />
                  
                  <Route path="/admin/lesson-editor/:courseId" element={
                    <ErrorBoundary>
                      <ProtectedRoute>
                        <Suspense fallback={<LoadingFallback />}>
                          <LessonEditor />
                        </Suspense>
                      </ProtectedRoute>
                    </ErrorBoundary>
                  } />
                  
                  <Route path="/admin/lesson-editor/:courseId/:lessonId" element={
                    <ErrorBoundary>
                      <ProtectedRoute>
                        <Suspense fallback={<LoadingFallback />}>
                          <LessonEditor />
                        </Suspense>
                      </ProtectedRoute>
                    </ErrorBoundary>
                  } />
                  
                  <Route path="/admin/slide-editor/:courseId/:lessonId" element={
                    <ErrorBoundary>
                      <ProtectedRoute>
                        <Suspense fallback={<LoadingFallback />}>
                          <SlideEditor />
                        </Suspense>
                      </ProtectedRoute>
                    </ErrorBoundary>
                  } />
                  
                  <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
              </Suspense>
            </ErrorBoundary>
          </div>
        </AuthProvider>
      </LanguageProvider>
    </Router>
  );
}

export default App;
