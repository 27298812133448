import React, { useState, useEffect } from 'react';
import { CheckCircle, XCircle } from 'lucide-react';

/**
 * QuizComponent - Renders an interactive quiz
 * 
 * This component displays a quiz with multiple choice answers,
 * provides feedback on user selections, and tracks results.
 */
const QuizComponent = ({ data, onSubmit }) => {
  // Extract props from data object
  const question = data?.question || '';
  const options = data?.options || [];
  const correctAnswers = data?.correctAnswers || [];
  const multipleSelect = data?.multipleSelect || false;
  const explanation = data?.explanation || '';
  const onAnswer = onSubmit;
  
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);
  
  // Reset state when the question changes
  useEffect(() => {
    setSelectedOptions([]);
    setHasSubmitted(false);
    setIsCorrect(false);
  }, [question, options, correctAnswers]);
  
  // Handle answer selection
  const handleOptionClick = (optionIndex) => {
    if (hasSubmitted) return; // Prevent changes after submission
    
    let newSelected;
    if (multipleSelect) {
      // Toggle the selection for multiple select
      newSelected = selectedOptions.includes(optionIndex)
        ? selectedOptions.filter(idx => idx !== optionIndex)
        : [...selectedOptions, optionIndex];
    } else {
      // Replace selection for single select
      newSelected = [optionIndex];
    }
    setSelectedOptions(newSelected);
  };
  
  // Handle quiz submission
  const handleSubmit = () => {
    // Cannot submit without selecting an option
    if (selectedOptions.length === 0) return;
    
    // Check if all selected options are correct and if all correct options are selected
    const allSelectedAreCorrect = selectedOptions.every(selectedIdx => 
      correctAnswers.includes(selectedIdx) || correctAnswers.includes(options[selectedIdx])
    );
    
    const allCorrectAreSelected = correctAnswers.every(correctAnswer => {
      // Handle the case where correctAnswers contains indices
      if (typeof correctAnswer === 'number') {
        return selectedOptions.includes(correctAnswer);
      }
      // Handle the case where correctAnswers contains option values
      const correctIndex = options.indexOf(correctAnswer);
      return correctIndex !== -1 && selectedOptions.includes(correctIndex);
    });
    
    const result = allSelectedAreCorrect && allCorrectAreSelected;
    setIsCorrect(result);
    setHasSubmitted(true);
    
    if (onAnswer) {
      onAnswer({ 
        isCorrect: result, 
        selected: selectedOptions.map(idx => options[idx]),
        correct: correctAnswers.map(answer => 
          typeof answer === 'number' ? options[answer] : answer
        )
      });
    }
  };
  
  // Reset the quiz
  const handleReset = () => {
    setSelectedOptions([]);
    setHasSubmitted(false);
    setIsCorrect(false);
  };
  
  // Format the correct answer display text
  const formatCorrectAnswers = () => {
    return correctAnswers
      .map(answer => typeof answer === 'number' ? options[answer] : answer)
      .join(', ');
  };
  
  return (
    <div className="quiz-component w-full my-4">
      {/* Question */}
      <div className="mb-4">
        <h3 className="text-lg font-semibold text-white mb-2">{question}</h3>
        <p className="text-gray-400 text-sm">
          {multipleSelect ? 'Select all that apply' : 'Select one option'}
        </p>
      </div>
      
      {/* Options */}
      <div className="space-y-3 mb-4">
        {options.map((option, index) => {
          const isSelected = selectedOptions.includes(index);
          const isCorrectOption = hasSubmitted && (
            correctAnswers.includes(index) || correctAnswers.includes(option)
          );
          
          let optionClass = "flex items-center p-3 rounded-lg cursor-pointer border transition-all duration-200";
          
          if (!hasSubmitted) {
            // Not submitted yet
            optionClass += isSelected
              ? " bg-blue-900/40 border-blue-500 text-white"
              : " bg-gray-800 border-gray-700 text-gray-300 hover:bg-gray-700 hover:border-gray-600";
          } else {
            // After submission
            if (isCorrectOption) {
              optionClass += " bg-green-900/30 border-green-500 text-white";
            } else if (isSelected) {
              optionClass += " bg-red-900/30 border-red-500 text-white";
            } else {
              optionClass += " bg-gray-800 border-gray-700 text-gray-400";
            }
          }
          
          return (
            <div
              key={index}
              className={optionClass}
              onClick={() => handleOptionClick(index)}
            >
              {/* Selection indicator */}
              <div className="shrink-0 w-6 h-6 flex items-center justify-center mr-3 rounded-full border">
                {hasSubmitted ? (
                  isCorrectOption ? (
                    <CheckCircle size={20} className="text-green-500" />
                  ) : (
                    isSelected && <XCircle size={20} className="text-red-500" />
                  )
                ) : (
                  multipleSelect ? (
                    // Checkbox style
                    <div className={`w-4 h-4 rounded-sm border ${
                      isSelected ? 'bg-blue-500 border-blue-600' : 'border-gray-500'
                    }`}>
                      {isSelected && (
                        <svg viewBox="0 0 24 24" className="w-4 h-4 text-white">
                          <path
                            fill="currentColor"
                            d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"
                          />
                        </svg>
                      )}
                    </div>
                  ) : (
                    // Radio style
                    <div className={`w-4 h-4 rounded-full border ${
                      isSelected ? 'border-4 border-blue-500' : 'border-gray-500'
                    }`} />
                  )
                )}
              </div>
              
              {/* Option text */}
              <span>{option}</span>
            </div>
          );
        })}
      </div>
      
      {/* Feedback after submission */}
      {hasSubmitted && (
        <div className={`mb-4 p-4 rounded-lg ${
          isCorrect ? 'bg-green-900/30 border border-green-600' : 'bg-red-900/30 border border-red-600'
        }`}>
          <div className="font-semibold mb-1 flex items-center">
            {isCorrect ? (
              <>
                <CheckCircle size={18} className="text-green-500 mr-2" />
                <span className="text-green-300">Correct!</span>
              </>
            ) : (
              <>
                <XCircle size={18} className="text-red-500 mr-2" />
                <span className="text-red-300">Incorrect</span>
              </>
            )}
          </div>
          
          {!isCorrect && (
            <p className="text-sm text-gray-300 mb-2">
              The correct {correctAnswers.length > 1 ? 'answers are' : 'answer is'}: {formatCorrectAnswers()}
            </p>
          )}
          
          {explanation && (
            <div className="text-sm text-gray-300 mt-2">
              <p className="font-medium text-gray-300">Explanation:</p>
              <p>{explanation}</p>
            </div>
          )}
        </div>
      )}
      
      {/* Action buttons */}
      <div className="flex justify-end">
        {hasSubmitted ? (
          <button
            className="px-4 py-2 bg-gray-700 hover:bg-gray-600 text-white rounded-md transition-colors"
            onClick={handleReset}
          >
            Try Again
          </button>
        ) : (
          <button
            className={`px-4 py-2 rounded-md transition-colors ${
              selectedOptions.length === 0
                ? 'bg-gray-700 text-gray-400 cursor-not-allowed'
                : 'bg-blue-600 hover:bg-blue-700 text-white'
            }`}
            onClick={handleSubmit}
            disabled={selectedOptions.length === 0}
          >
            Submit
          </button>
        )}
      </div>
    </div>
  );
};

export default QuizComponent; 