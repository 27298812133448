/**
 * Course Content Schema
 * 
 * This file defines the structure of course data and provides validation utilities.
 * Use this as a reference when creating new courses or extending existing ones.
 */

// NOTE: For slide types, use the SLIDE_TYPES exported from src/components/interactivelessons/components/slides/index.js
// Keeping these constants for backward compatibility only
// The consolidated slide types are: text, input, coding, quiz, video

// Legacy Slide Types
export const SLIDE_TYPES = {
  CONTEXT: 'context',   // Now use 'text' type instead
  LEARN: 'learn',       // Now use 'text' type instead
  USER_INPUT: 'test',   // Now use 'input' type instead
  RECAP: 'recap',       // Now use 'text' type instead
  CUSTOM: 'custom',     // Now use 'text' type instead
  CODING: 'coding',     // Now use 'coding' type
  SELECTION_QUIZ: 'selection_quiz', // Now use 'quiz' type
  VIDEO: 'video'        // Still use 'video' type
};

// Phase Types (for the phase labels)
export const PHASE_TYPES = {
  CONTEXT: 'context',
  LEARN: 'learn',
  TEST: 'test',
  RECAP: 'recap',
  PRACTICE: 'practice',
  EXERCISE: 'exercise',
  CODING: 'coding'      // Coding exercises phase
};

/**
 * Base Course Schema
 * This is the expected structure for all courses
 */
export const courseSchema = {
  // Required fields
  title: String,             // Course title
  description: String,       // Brief description
  slides: Array,             // Array of slide objects
  
  // Optional fields
  author: String,            // Course creator
  verifiedStatus: String,    // 'org', 'creator', or undefined
  subject: String,           // Subject area like Math, Programming etc.
  duration: String,          // Estimated completion time
  difficulty: String,        // Easy, Medium, Hard, etc.
  prerequisites: Array,      // Required knowledge/courses
  tags: Array,               // Categorization tags
  targetAudience: String,    // Who the course is for
  version: String,           // Course version
  lastUpdated: Date,         // Last modified date
  
  // Extension point for custom metadata
  metadata: Object
};

/**
 * Slide Schema Definitions
 * These are the expected structures for different slide types
 */

// Context Slide Schema
export const contextSlideSchema = {
  id: Number,                // Unique identifier
  type: SLIDE_TYPES.CONTEXT, // Slide type
  phase: String,             // Phase label
  title: String,             // Slide title
  content: String,           // Main text content
  icon: String,              // Emoji or icon
  media: Object              // Optional images/videos
};

// Learn Slide Schema
export const learnSlideSchema = {
  id: Number,                // Unique identifier
  type: SLIDE_TYPES.LEARN,   // Slide type
  phase: String,             // Phase label
  title: String,             // Slide title
  content: String,           // Main text content
  bullets: Array,            // Bullet points list
  examples: Array,           // Example items
  customSections: Array,     // Additional custom sections
  media: Object              // Optional images/videos
};

// Test Slide Schema
export const testSlideSchema = {
  id: Number,                // Unique identifier
  type: SLIDE_TYPES.TEST,    // Slide type
  phase: String,             // Phase label
  title: String,             // Slide title
  content: String,           // Introduction text
  question: String,          // The main question
  expectedKeywords: Array,   // Keywords for evaluation
  sampleAnswer: String,      // Example of good answer
  possibleFollowUps: Array,  // Follow-up questions
  evaluationMethod: String   // How answers are evaluated
};

// Recap Slide Schema
export const recapSlideSchema = {
  id: Number,                // Unique identifier
  type: SLIDE_TYPES.RECAP,   // Slide type
  phase: String,             // Phase label
  title: String,             // Slide title
  content: String,           // Main text content
  emoji: String,             // Celebratory emoji
  nextSteps: Array,          // Recommendations for next courses
  resources: Array           // Additional learning resources
};

// Custom Slide Schema
export const customSlideSchema = {
  id: Number,                // Unique identifier
  type: SLIDE_TYPES.CUSTOM,  // Slide type
  phase: String,             // Phase label (optional)
  title: String,             // Slide title
  content: String,           // Content (may be ignored if using custom renderer)
  customRenderer: Function,  // Function to render custom content
  customData: Object         // Any custom data needed for the slide
};

// Coding Slide Schema
export const codingSlideSchema = {
  id: Number,                // Unique identifier
  type: SLIDE_TYPES.CODING,  // Slide type
  phase: String,             // Phase label
  title: String,             // Slide title
  content: String,           // Introduction text
  exercise: String,          // The main coding exercise
  codeInstructions: String,  // Detailed instructions
  startingCode: String,      // Initial code for the exercise
  language: String,          // Optional: Explicitly set the coding language (e.g., 'javascript', 'html', 'css')
  expectedOutput: String,    // Expected output or behavior
  testCode: String,          // Code to test the solution
  sampleSolution: String,    // Example of correct solution
  followUpExercises: Array,  // Follow-up coding challenges
  evaluationMethod: String   // How solutions are evaluated
};

// Selection Quiz Slide Schema
export const selectionQuizSlideSchema = {
  id: Number,                      // Unique identifier
  type: SLIDE_TYPES.SELECTION_QUIZ, // Slide type
  phase: String,                   // Phase label
  title: String,                   // Slide title
  content: String,                 // Introduction text
  question: String,                // The main question
  options: Array,                  // Multiple choice options
  correctOption: Number,           // Index of correct option (0-based)
  explanation: String,             // Explanation of the correct answer
  showFeedbackImmediately: Boolean // Whether to show feedback immediately or on submit
};

// Video Slide Schema
export const videoSlideSchema = {
  id: Number,                // Unique identifier
  type: SLIDE_TYPES.VIDEO,   // Slide type
  phase: String,             // Phase label
  title: String,             // Slide title
  content: String,           // Introduction text
  videoUrl: String,          // URL for video (YouTube, Vimeo, etc.)
  videoFile: String,         // Path to uploaded video file (optional)
  autoplay: Boolean,         // Whether to autoplay the video
  showControls: Boolean      // Whether to show video controls
};

/**
 * Validates a course object against the schema
 * @param {Object} course - The course to validate
 * @returns {Object} Validation result with isValid and errors
 */
export const validateCourse = (course) => {
  const errors = [];
  
  // Required fields
  if (!course.title) errors.push('Course title is required');
  if (!course.description) errors.push('Course description is required');
  if (!Array.isArray(course.slides) || course.slides.length === 0) {
    errors.push('Course must have at least one slide');
  }
  
  // Validate slides
  if (Array.isArray(course.slides)) {
    course.slides.forEach((slide, index) => {
      if (!slide.id) errors.push(`Slide ${index + 1} missing id property`);
      if (!slide.type) errors.push(`Slide ${index + 1} missing type property`);
      if (!slide.title) errors.push(`Slide ${index + 1} missing title property`);
      
      // Type-specific validation
      if (slide.type === SLIDE_TYPES.TEST) {
        if (!slide.question) errors.push(`Test slide ${index + 1} missing question property`);
        if (!slide.expectedKeywords || !Array.isArray(slide.expectedKeywords)) 
          errors.push(`Test slide ${index + 1} missing expectedKeywords array`);
        if (!slide.sampleAnswer) errors.push(`Test slide ${index + 1} missing sampleAnswer property`);
      }
    });
  }
  
  return {
    isValid: errors.length === 0,
    errors
  };
};

/**
 * Creates a new course template with basic structure
 * @returns {Object} A new course template
 */
export const createCourseTemplate = () => {
  return {
    title: 'New Course',
    description: 'Course description goes here',
    author: '',
    verifiedStatus: undefined,  // Default to regular creator
    subject: 'General',         // Default subject
    duration: '10 minutes',
    slides: [
      {
        id: 1,
        type: SLIDE_TYPES.CONTEXT,
        phase: PHASE_TYPES.CONTEXT,
        title: 'Introduction',
        content: 'Welcome to this course!',
        icon: '👋'
      },
      {
        id: 2,
        type: SLIDE_TYPES.LEARN,
        phase: PHASE_TYPES.LEARN,
        title: 'First Lesson',
        content: 'This is the first lesson content.',
        bullets: ['Key point 1', 'Key point 2', 'Key point 3']
      },
      {
        id: 3,
        type: SLIDE_TYPES.TEST,
        phase: PHASE_TYPES.TEST,
        title: 'Knowledge Check',
        content: 'Let\'s test what you\'ve learned:',
        question: 'Explain the key concepts covered in this lesson.',
        expectedKeywords: ['concept', 'learn', 'understand'],
        sampleAnswer: 'A good answer would include key concepts from the lesson.',
        possibleFollowUps: ['What did you find most interesting?']
      },
      {
        id: 4,
        type: SLIDE_TYPES.RECAP,
        phase: PHASE_TYPES.RECAP,
        title: 'Congratulations!',
        content: 'You\'ve completed this course!',
        emoji: '🎉'
      }
    ]
  };
};

export default {
  SLIDE_TYPES,
  PHASE_TYPES,
  validateCourse,
  createCourseTemplate
}; 