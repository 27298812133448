// Colors
export const colors = {
  background: {
    primary: '#050508',
    secondary: '#0a0c14',
    tertiary: '#0f1218',
    dark: '#12141c',
    input: '#0a0c14',
    card: '#12141c',
  },
  text: {
    primary: 'white',
    secondary: 'rgba(255, 255, 255, 0.7)',
    muted: 'rgba(255, 255, 255, 0.4)',
    gray: {
      400: 'rgb(156, 163, 175)',
      500: 'rgb(107, 114, 128)',
    }
  },
  border: {
    default: '#1f212a',
    hover: 'rgb(59, 130, 246)',
    gray: {
      600: 'rgb(75, 85, 99)',
      700: 'rgb(55, 65, 81)',
    }
  },
  accent: {
    blue: {
      primary: 'rgb(59, 130, 246)',
      hover: 'rgb(37, 99, 235)',
      light: 'rgba(59, 130, 246, 0.2)',
      300: 'rgb(147, 197, 253)',
      400: 'rgb(96, 165, 250)',
      500: 'rgb(59, 130, 246)',
      600: 'rgb(37, 99, 235)',
    },
    red: {
      primary: 'rgb(239, 68, 68)',
      light: 'rgba(239, 68, 68, 0.1)',
    },
    green: {
      primary: 'rgb(34, 197, 94)',
      light: 'rgba(34, 197, 94, 0.1)',
      500: 'rgb(34, 197, 94)',
    },
    emerald: {
      500: 'rgb(16, 185, 129)',
    }
  },
};

// Typography
export const typography = {
  fontFamily: {
    // Default font family (English)
    sans: 'ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
    // Japanese font family
    japanese: '"Hiragino Sans", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "MS Pゴシック", sans-serif',
  },
  fontSize: {
    xs: '0.75rem',
    sm: '0.875rem',
    base: '1rem',
    lg: '1.125rem',
    xl: '1.25rem',
    '2xl': '1.5rem',
    '3xl': '1.875rem',
  },
  fontWeight: {
    normal: '400',
    medium: '500',
    semibold: '600',
    bold: '700',
  },
};

// Spacing
export const spacing = {
  0: '0',
  1: '0.25rem',
  2: '0.5rem',
  3: '0.75rem',
  4: '1rem',
  6: '1.5rem',
  8: '2rem',
  12: '3rem',
};

// Border Radius
export const borderRadius = {
  sm: '0.375rem',
  DEFAULT: '0.5rem',
  md: '0.75rem',
  lg: '1rem',
  xl: '1.25rem',
  '2xl': '1.5rem',
};

// Shadows
export const shadows = {
  sm: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
  DEFAULT: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1)',
  md: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1)',
  lg: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -4px rgba(0, 0, 0, 0.1)',
  blue: '0 4px 14px rgba(59, 130, 246, 0.2)',
  hover: '0 10px 25px -5px rgba(0, 0, 0, 0.2), 0 10px 10px -5px rgba(0, 0, 0, 0.1)',
};

// Common component styles
export const components = {
  input: {
    base: `
      w-full
      bg-[${colors.background.input}]
      border
      border-[${colors.border.default}]
      rounded-xl
      text-white
      placeholder-gray-500
      focus:border-[${colors.border.hover}]
      focus:ring-0
      transition-all
      outline-none
    `,
    sizes: {
      sm: 'p-2',
      md: 'p-3',
      lg: 'p-4',
    },
  },
  button: {
    base: `
      inline-flex
      items-center
      justify-center
      font-medium
      rounded-xl
      transition-all
      disabled:opacity-50
      disabled:cursor-not-allowed
    `,
    variants: {
      primary: `
        bg-[${colors.accent.blue.primary}]
        text-white
        hover:bg-[${colors.accent.blue.hover}]
        shadow-md
        hover:shadow-[${shadows.blue}]
      `,
      secondary: `
        bg-[${colors.background.card}]
        text-white
        border
        border-[${colors.border.default}]
        hover:border-[${colors.border.hover}]
        hover:bg-[${colors.background.secondary}]
        shadow-md
      `,
      danger: `
        bg-[${colors.accent.red.primary}]
        text-white
        hover:opacity-90
        shadow-md
      `,
    },
    sizes: {
      sm: 'px-3 py-2 text-sm',
      md: 'px-4 py-2',
      lg: 'px-6 py-3',
    },
  },
  card: {
    base: `
      bg-[${colors.background.card}]
      border
      border-[${colors.border.default}]
      rounded-2xl
      overflow-hidden
      shadow-lg
    `,
    variants: {
      hover: `
        hover:border-[${colors.border.hover}]
        hover:shadow-[${shadows.hover}]
        transition-all
        hover:-translate-y-1
      `,
    },
  },
};

// Animations
export const animations = {
  transition: {
    fast: 'all 0.15s ease',
    DEFAULT: 'all 0.3s ease',
    slow: 'all 0.5s ease',
  },
};

// Z-index
export const zIndex = {
  dropdown: 10,
  sticky: 20,
  fixed: 30,
  modal: 40,
  popover: 50,
  tooltip: 60,
};