import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { supabase } from '../../../auth/supabase';
import { useAuth } from '../../../auth/AuthContext';
import { 
  COMPONENT_TYPES,
  convertLegacySlideType 
} from '../core/slideComponents';
import { 
  Layout,
  Type,
  TestTube,
  RefreshCw,
  Code,
  HelpCircle,
  Play,
  Puzzle,
  Plus,
  GripVertical,
  X,
  Check,
  ChevronDown,
  ChevronUp
} from 'lucide-react';
import { PHASE_TYPES } from '../data/courseSchema';
import { COMPONENT_TYPES as SLIDE_TYPES } from '../core/slideComponents';
import { colors, components } from './designSystem';

const SlideTypeOptions = [
  { id: SLIDE_TYPES.TEXT, label: 'Text', icon: Type, description: 'Present text content, information, or context' },
  { id: SLIDE_TYPES.USER_INPUT, label: 'User Input', icon: TestTube, description: 'Ask for text input answers' },
  { id: SLIDE_TYPES.CODING, label: 'Coding', icon: Code, description: 'Interactive coding exercises' },
  { id: SLIDE_TYPES.SELECTION_QUIZ, label: 'Quiz', icon: HelpCircle, description: 'Multiple choice questions' },
  { id: SLIDE_TYPES.VIDEO, label: 'Video', icon: Play, description: 'Embed video content' }
];

const CourseCreator = () => {
  const [courseTitle, setCourseTitle] = useState('');
  const [courseDescription, setCourseDescription] = useState('');
  const [slides, setSlides] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [expandedSlide, setExpandedSlide] = useState(null);

  const handleAddSlide = (type) => {
    const newSlide = {
      id: Date.now(),
      type,
      title: '',
      content: '',
      ...getDefaultFieldsForType(type)
    };
    setSlides([...slides, newSlide]);
    setExpandedSlide(newSlide.id);
  };

  const handleRemoveSlide = (id) => {
    setSlides(slides.filter(slide => slide.id !== id));
    if (expandedSlide === id) {
      setExpandedSlide(null);
    }
  };

  const handleUpdateSlide = (id, updates) => {
    setSlides(slides.map(slide => 
      slide.id === id ? { ...slide, ...updates } : slide
    ));
  };

  const handleMoveSlide = (fromIndex, toIndex) => {
    const newSlides = [...slides];
    const [removed] = newSlides.splice(fromIndex, 1);
    newSlides.splice(toIndex, 0, removed);
    setSlides(newSlides);
  };

  const getDefaultFieldsForType = (type) => {
    switch (type) {
      case 'learn':
        return { bullets: [], keyTakeaways: [] };
      case 'video':
        return { url: '', startTime: 0, endTime: null };
      case 'quiz':
        return { question: '', options: [], correctOption: null };
      case 'coding':
        return { instructions: '', starterCode: '', solution: '', tests: [] };
      default:
        return {};
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!courseTitle.trim()) {
      setError('Course title is required');
      return;
    }
    
    if (slides.length === 0) {
      setError('At least one slide is required');
      return;
    }

    setError('');
    setIsProcessing(true);

    try {
      // Simulated processing
      await new Promise(resolve => setTimeout(resolve, 2000));
      setSuccess('Course created successfully!');
      setIsProcessing(false);
      setCourseTitle('');
      setCourseDescription('');
      setSlides([]);
    } catch (err) {
      setError('Failed to create course. Please try again.');
      setIsProcessing(false);
    }
  };

  const renderSlideFields = (slide) => {
    switch (slide.type) {
      case 'learn':
        return (
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-white/70 mb-2">Key Points</label>
              <div className="space-y-2">
                {slide.bullets.map((bullet, index) => (
                  <div key={index} className="flex gap-2">
                    <input
                      type="text"
                      value={bullet}
                      onChange={(e) => {
                        const newBullets = [...slide.bullets];
                        newBullets[index] = e.target.value;
                        handleUpdateSlide(slide.id, { bullets: newBullets });
                      }}
                      placeholder="Enter a key point"
                      className="flex-1 p-3 bg-[#1a1f2d] border border-gray-800 rounded-xl text-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500/50 focus:border-blue-500/50 transition-all"
                    />
                    <button
                      onClick={() => {
                        const newBullets = slide.bullets.filter((_, i) => i !== index);
                        handleUpdateSlide(slide.id, { bullets: newBullets });
                      }}
                      className="p-2 text-gray-400 hover:text-red-500 transition-colors rounded-lg"
                    >
                      <X className="h-5 w-5" />
                    </button>
                  </div>
                ))}
                <button
                  onClick={() => handleUpdateSlide(slide.id, { bullets: [...slide.bullets, ''] })}
                  className="w-full p-3 border border-dashed border-gray-800 rounded-xl text-gray-400 hover:text-blue-400 hover:border-blue-500/50 transition-all flex items-center justify-center gap-2"
                >
                  <Plus className="h-5 w-5" />
                  Add Key Point
                </button>
              </div>
            </div>
          </div>
        );
      
      case 'video':
        return (
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-white/70 mb-2">Video URL</label>
              <input
                type="url"
                value={slide.url}
                onChange={(e) => handleUpdateSlide(slide.id, { url: e.target.value })}
                placeholder="Enter YouTube or Vimeo URL"
                className="w-full p-3 bg-[#1a1f2d] border border-gray-800 rounded-xl text-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500/50 focus:border-blue-500/50 transition-all"
              />
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-white/70 mb-2">Start Time (seconds)</label>
                <input
                  type="number"
                  value={slide.startTime}
                  onChange={(e) => handleUpdateSlide(slide.id, { startTime: parseInt(e.target.value) || 0 })}
                  min="0"
                  className="w-full p-3 bg-[#1a1f2d] border border-gray-800 rounded-xl text-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500/50 focus:border-blue-500/50 transition-all"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-white/70 mb-2">End Time (seconds)</label>
                <input
                  type="number"
                  value={slide.endTime || ''}
                  onChange={(e) => handleUpdateSlide(slide.id, { endTime: e.target.value ? parseInt(e.target.value) : null })}
                  min={slide.startTime + 1}
                  className="w-full p-3 bg-[#1a1f2d] border border-gray-800 rounded-xl text-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500/50 focus:border-blue-500/50 transition-all"
                />
              </div>
            </div>
          </div>
        );
      
      case 'quiz':
        return (
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-white/70 mb-2">Question</label>
              <input
                type="text"
                value={slide.question}
                onChange={(e) => handleUpdateSlide(slide.id, { question: e.target.value })}
                placeholder="Enter your question"
                className={`${components.input.base} ${components.input.sizes.md}`}
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-white/70 mb-2">Options</label>
              <div className="space-y-2">
                {slide.options.map((option, index) => (
                  <div key={index} className="flex gap-2">
                    <button
                      onClick={() => {
                        const newOptions = slide.options.filter((_, i) => i !== index);
                        handleUpdateSlide(slide.id, { 
                          options: newOptions,
                          correctOption: slide.correctOption === index ? null : 
                            slide.correctOption > index ? slide.correctOption - 1 : slide.correctOption
                        });
                      }}
                      className="p-2 text-gray-400 hover:text-red-500 transition-colors rounded-lg"
                    >
                      <X className="h-5 w-5" />
                    </button>
                    <input
                      type="text"
                      value={option}
                      onChange={(e) => {
                        const newOptions = [...slide.options];
                        newOptions[index] = e.target.value;
                        handleUpdateSlide(slide.id, { options: newOptions });
                      }}
                      placeholder={`Option ${index + 1}`}
                      className={`${components.input.base} ${components.input.sizes.md}`}
                    />
                    <button
                      onClick={() => handleUpdateSlide(slide.id, { correctOption: index })}
                      className={`p-2 rounded-lg transition-colors ${
                        slide.correctOption === index
                          ? 'bg-green-500/20 text-green-400'
                          : 'text-gray-400 hover:text-green-400'
                      }`}
                    >
                      <Check className="h-5 w-5" />
                    </button>
                  </div>
                ))}
                <button
                  onClick={() => handleUpdateSlide(slide.id, { options: [...slide.options, ''] })}
                  className="w-full p-3 border border-dashed border-gray-800 rounded-xl text-gray-400 hover:text-blue-400 hover:border-blue-500/50 transition-all flex items-center justify-center gap-2"
                >
                  <Plus className="h-5 w-5" />
                  Add Option
                </button>
              </div>
            </div>
          </div>
        );
      
      default:
        return (
          <div>
            <label className="block text-sm font-medium text-white/70 mb-2">Content</label>
            <textarea
              value={slide.content}
              onChange={(e) => handleUpdateSlide(slide.id, { content: e.target.value })}
              placeholder="Enter slide content"
              rows={4}
              className="w-full p-3 bg-[#1a1f2d] border border-gray-800 rounded-xl text-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500/50 focus:border-blue-500/50 transition-all resize-none"
            />
          </div>
        );
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-8">
      {error && (
        <div className="mb-6 p-4 bg-red-500/10 backdrop-blur-sm border border-red-500/20 rounded-2xl text-red-500 flex items-center gap-2">
          <X size={20} />
          {error}
        </div>
      )}

      {success && (
        <div className="mb-6 p-4 bg-green-500/10 backdrop-blur-sm border border-green-500/20 rounded-2xl text-green-500 flex items-center gap-2">
          <Check size={20} />
          {success}
        </div>
      )}

      <form onSubmit={handleSubmit} className="space-y-8">
        {/* Course Details */}
        <div className="bg-[#262b38]/50 backdrop-blur-xl rounded-2xl border border-gray-800/50 p-8 space-y-6">
          <h2 className="text-xl font-medium text-white/90">Course Details</h2>
          
          <div className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-white/70 mb-2">
                Course Title<span className="text-blue-400">*</span>
              </label>
              <input
                type="text"
                value={courseTitle}
                onChange={(e) => setCourseTitle(e.target.value)}
                placeholder="Enter course title"
                className="w-full p-4 bg-[#1a1f2d] border border-gray-800 rounded-xl text-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500/50 focus:border-blue-500/50 transition-all"
                required
              />
            </div>
            
            <div>
              <label className="block text-sm font-medium text-white/70 mb-2">
                Course Description
              </label>
              <textarea
                value={courseDescription}
                onChange={(e) => setCourseDescription(e.target.value)}
                placeholder="Enter course description"
                rows={3}
                className="w-full p-4 bg-[#1a1f2d] border border-gray-800 rounded-xl text-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500/50 focus:border-blue-500/50 transition-all resize-none"
              />
            </div>
          </div>
        </div>

        {/* Slides */}
        <div className="bg-[#262b38]/50 backdrop-blur-xl rounded-2xl border border-gray-800/50 p-8 space-y-6">
          <div className="flex items-center justify-between">
            <h2 className="text-xl font-medium text-white/90">Slides</h2>
            <div className="relative group">
              <button
                type="button"
                className="px-4 py-2 bg-blue-500 text-white rounded-xl hover:bg-blue-600 transition-colors flex items-center gap-2"
              >
                <Plus className="h-5 w-5" />
                Add Slide
              </button>
              
              <div className="absolute right-0 mt-2 w-64 py-2 bg-[#262b38] rounded-xl border border-gray-800 shadow-xl opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all z-10">
                {SlideTypeOptions.map(type => (
                  <button
                    key={type.id}
                    type="button"
                    onClick={() => handleAddSlide(type.id)}
                    className="w-full px-4 py-2 flex items-center gap-3 hover:bg-[#1a1f2d] transition-colors text-left"
                  >
                    <type.icon className="h-5 w-5 text-blue-400" />
                    <div>
                      <div className="font-medium text-white">{type.label}</div>
                      <div className="text-sm text-gray-400">{type.description}</div>
                    </div>
                  </button>
                ))}
              </div>
            </div>
          </div>

          <div className="space-y-4">
            {slides.map((slide, index) => {
              const TypeIcon = SlideTypeOptions.find(t => t.id === slide.type)?.icon;
              const isExpanded = expandedSlide === slide.id;
              
              return (
                <div
                  key={slide.id}
                  className="bg-[#1a1f2d] border border-gray-800 rounded-xl overflow-hidden group"
                >
                  <div className="flex items-center p-4">
                    <button
                      type="button"
                      className="p-2 text-gray-600 cursor-move hover:text-gray-400 transition-colors"
                    >
                      <GripVertical className="h-5 w-5" />
                    </button>
                    
                    <div className="flex-1 min-w-0 flex items-center gap-3">
                      <TypeIcon className="h-5 w-5 text-blue-400" />
                      <input
                        type="text"
                        value={slide.title}
                        onChange={(e) => handleUpdateSlide(slide.id, { title: e.target.value })}
                        placeholder={`${SlideTypeOptions.find(t => t.id === slide.type)?.label} Slide`}
                        className="flex-1 bg-transparent border-none focus:outline-none focus:ring-2 focus:ring-blue-500/50 rounded-lg text-white placeholder-gray-500"
                      />
                    </div>

                    <div className="flex items-center gap-2">
                      <button
                        type="button"
                        onClick={() => handleRemoveSlide(slide.id)}
                        className="p-2 text-gray-400 hover:text-red-500 transition-colors rounded-lg opacity-0 group-hover:opacity-100"
                      >
                        <X className="h-5 w-5" />
                      </button>
                      <button
                        type="button"
                        onClick={() => setExpandedSlide(isExpanded ? null : slide.id)}
                        className="p-2 text-gray-400 hover:text-white transition-colors rounded-lg"
                      >
                        {isExpanded ? (
                          <ChevronUp className="h-5 w-5" />
                        ) : (
                          <ChevronDown className="h-5 w-5" />
                        )}
                      </button>
                    </div>
                  </div>

                  {isExpanded && (
                    <div className="p-4 border-t border-gray-800">
                      {renderSlideFields(slide)}
                    </div>
                  )}
                </div>
              );
            })}

            {slides.length === 0 && (
              <div className="text-center py-12">
                <p className="text-gray-400">No slides yet. Click "Add Slide" to get started.</p>
              </div>
            )}
          </div>
        </div>

        {/* Submit Button */}
        <div className="flex justify-end">
          <button
            type="submit"
            disabled={isProcessing}
            className="px-6 py-3 bg-blue-500 text-white rounded-xl hover:bg-blue-600 transition-all disabled:opacity-50 disabled:cursor-not-allowed flex items-center gap-2 font-medium"
          >
            {isProcessing ? (
              <>
                <div className="w-5 h-5 border-2 border-white/20 border-t-white rounded-full animate-spin" />
                Processing...
              </>
            ) : (
              <>
                <Check className="h-5 w-5" />
                Create Course
              </>
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default CourseCreator;