import React, { useRef, useEffect, useState } from 'react';
import { X, Send, Loader2, User, Bot, Info, HelpCircle, Coffee, FileText, Lightbulb, Code, PlayCircle, List } from 'lucide-react';
import { useTextSelection } from '../../context/TextSelectionContext';
import CustomMarkdown from '../CustomMarkdown';
import { logUserActivity } from '../../../../auth/userActivity';
import { supabase } from '../../../../auth/supabase';
import MobileAIErrorMessage from './MobileAIErrorMessage';

/**
 * Typing effect component
 */
const TypingEffect = ({ content, isComplete, setIsComplete }) => {
  const [displayedContent, setDisplayedContent] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  
  useEffect(() => {
    if (!content || isComplete) return;
    
    // Reset animation when content changes
    if (currentIndex === 0) {
      setDisplayedContent('');
    }
    
    // Incrementally reveal text
    const interval = setInterval(() => {
      if (currentIndex < content.length) {
        setDisplayedContent(prev => prev + content[currentIndex]);
        setCurrentIndex(prev => prev + 1);
      } else {
        clearInterval(interval);
        setIsComplete(true);
      }
    }, 20); // Speed of typing effect
    
    return () => clearInterval(interval);
  }, [content, currentIndex, isComplete, setIsComplete]);
  
  return (
    <CustomMarkdown content={displayedContent} />
  );
};

/**
 * Message bubble component for chat interface
 */
const MessageBubble = ({ message, isTyping }) => {
  const [isTypingComplete, setIsTypingComplete] = useState(false);
  
  // Check if message has error flags 
  const hasError = message.isNetworkError || message.isTimeoutError || message.isMobileError;

  // User message
  if (message.type === 'user') {
    return (
      <div className="flex mb-3 justify-end">
        <div className="bg-blue-600 p-3 rounded-lg rounded-tr-none max-w-[80%] text-white">
          <p>{message.content}</p>
        </div>
        <div className="bg-blue-600 p-1 rounded-full ml-2 flex-shrink-0 w-6 h-6 flex items-center justify-center">
          <User size={16} className="text-white" />
        </div>
      </div>
    );
  }
  
  // System message
  if (message.type === 'system') {
    return (
      <div className="flex mb-3 justify-center">
        <div className="bg-gray-800 p-2 rounded-lg text-xs text-gray-300 flex items-center">
          <Info size={14} className="mr-1 text-gray-400" />
          <p>{message.content}</p>
        </div>
      </div>
    );
  }
  
  // Loading message
  if (message.isLoading) {
    return (
      <div className="flex mb-3">
        <div className="bg-gray-700 p-1 rounded-full mr-2 flex-shrink-0 w-6 h-6 flex items-center justify-center">
          <Bot size={16} className="text-blue-300" />
        </div>
        <div className="p-3 rounded-lg rounded-tl-none bg-gray-700 text-white">
          <div className="flex items-center">
            <Loader2 size={16} className="animate-spin mr-2" />
            <p>Thinking...</p>
          </div>
        </div>
      </div>
    );
  }

  // AI message with markdown support
  return (
    <div className={`flex ${message.type === 'user' ? 'justify-end' : 'justify-start'} mb-4`}>
      <div 
        className={`max-w-[85%] px-4 py-3 rounded-lg ${
          message.type === 'user' 
            ? 'bg-blue-600 text-white rounded-br-none' 
            : 'bg-gray-700 text-gray-100 rounded-bl-none'
        }`}
      >
        {hasError ? (
          <MobileAIErrorMessage 
            isNetworkError={message.isNetworkError}
            isTimeoutError={message.isTimeoutError}
            isMobileError={message.isMobileError}
            message={message.content}
            onRetry={() => message.onRetry && message.onRetry()}
          />
        ) : (
          <div className="custom-markdown-content text-sm">
            {isTyping && !isTypingComplete ? (
              <TypingEffect 
                content={message.content} 
                isComplete={isTypingComplete}
                setIsComplete={setIsTypingComplete}
              />
            ) : (
              <CustomMarkdown content={message.content} />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

/**
 * A helper function to format AI prompts with markdown instructions
 * This could be used when submitting questions to the AI
 */
const enrichPromptWithMarkdownContext = (message) => {
  // Only process string messages
  if (typeof message !== 'string') return message;
  
  const markdownInstructions = `
When formatting your response, please use our supported markdown syntax:

- **Bold** for important concepts or keywords
- *Italics* for emphasis or definitions
- \`Code\` for inline code snippets
- ==Highlighted text== for critical information
- Code blocks with language hints: \`\`\`javascript, \`\`\`css, \`\`\`html, \`\`\`python, or \`\`\`sql
- :::info, :::warning, :::error, or :::success for callout blocks
- Tables with | Header | syntax
- > For blockquotes
- # For headings (## and ### for subheadings)
- Numbered lists (1. 2. 3.) for steps
- Bullet points (- or *) for related items

YOUR QUESTION: ${message}
`;

  return markdownInstructions;
};

/**
 * Modal for asking questions about selected text in a chat-like interface
 */
const AIQuestionModal = () => {
  const { 
    isModalOpen, 
    closeQuestionModal, 
    selectedText,
    question,
    handleQuestionChange,
    submitQuestion: originalSubmitQuestion,
    messages,
    isLoading,
    clearConversation,
    currentSlideTitle,
    addMessage
  } = useTextSelection();
  
  // State to track current user
  const [currentUser, setCurrentUser] = useState(null);
  
  // Get the current user when the component mounts
  useEffect(() => {
    const fetchUser = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      setCurrentUser(user);
    };
    
    fetchUser();
  }, []);

  // Network status detector
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  
  // Monitor network status
  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);
    
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);
    
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  // Quick question templates
  const quickQuestions = [
    { id: 'explain', text: 'Explain this', prompt: 'Explain ', icon: <HelpCircle size={14} className="mr-1.5" /> },
    { id: 'simplify', text: 'Simplify this', prompt: 'Simplify ', icon: <Coffee size={14} className="mr-1.5" /> },
    { id: 'example', text: 'Give an example', prompt: 'Give an example of ', icon: <FileText size={14} className="mr-1.5" /> }
  ];

  // Check if device is mobile
  const isMobile = () => {
    const userAgent = window.navigator.userAgent;
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent) || window.innerWidth <= 768;
  };

  // Wrapper for submitQuestion to enhance with markdown context
  const submitQuestion = (customQuestionText) => {
    // Use either custom question or the one from state
    const questionToSubmit = customQuestionText || question;
    
    // Only proceed if there's a question
    if (!questionToSubmit.trim()) return;
    
    // Check if network is offline before submitting
    if (!isOnline) {
      // Add an error message from the assistant
      addMessage({
        type: 'assistant',
        content: "You appear to be offline. Please check your internet connection and try again.",
        isNetworkError: true,
        onRetry: () => submitQuestion(questionToSubmit) // Add retry function
      });
      return;
    }
    
    // For API-based implementations, you might want to add markdown context
    // This depends on how your API is set up to handle prompts
    if (typeof originalSubmitQuestion === 'function') {
      // Call the original submit question function
      originalSubmitQuestion(questionToSubmit);
      
      // Check if the user is authenticated
      const checkAuthAndSave = async () => {
        try {
          // Verify the session is active
          const { data: { session } } = await supabase.auth.getSession();
          
          if (!session || !currentUser?.id) {
            console.warn("Cannot save AI question: User not authenticated");
            return;
          }
          
          // Log the question to user_activity
          logUserActivity(
            currentUser.id,
            'ask_ai_question',
            {
              question: questionToSubmit,
              selected_text: selectedText || null,
              slide_title: currentSlideTitle || null,
              timestamp: new Date().toISOString(),
              device_type: isMobile() ? 'mobile' : 'desktop' // Track device type
            }
          );
          
          // Save to review_data for future review
          saveToReviewData(questionToSubmit);
        } catch (error) {
          console.warn("Error checking auth status:", error);
        }
      };
      
      // Execute auth check and save
      checkAuthAndSave();
      
      // Log markdown instructions to console for debugging
      console.log("Markdown prompt example:", enrichPromptWithMarkdownContext(questionToSubmit));
    }
  };

  // Save to review_data for future review
  const saveToReviewData = async (questionToSubmit) => {
    try {
      // Create a unique ID for this question
      const questionId = `askAI-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
      
      // Try to get the current lesson ID, title and description if available
      let lessonId = null;
      let lessonTitle = null;
      let lessonDescription = null;
      let slideInfo = currentSlideTitle || '';
      
      // Extract lesson slug from URL
      const urlPathParts = window.location.pathname.split('/');
      const lessonIndex = urlPathParts.findIndex(part => part === 'lesson');
      const lessonSlug = lessonIndex >= 0 && lessonIndex < urlPathParts.length - 1 ? urlPathParts[lessonIndex + 1] : null;
      
      if (lessonSlug) {
        // Use the slug to fetch lesson data directly
        try {
          const { data: lessonData, error: lessonError } = await supabase
            .from('lessons')
            .select('id, title, description')
            .eq('slug', lessonSlug)
            .single();
          
          if (!lessonError && lessonData) {
            lessonId = lessonData.id;
            lessonTitle = lessonData.title;
            lessonDescription = lessonData.description;
          }
        } catch (lessonErr) {
          console.warn("Could not fetch lesson data:", lessonErr);
        }
      }
      
      // Format a better question for review
      let formattedQuestion = questionToSubmit;
      
      // Try to improve the question format for review
      if (lessonTitle) {
        // If the question is a simple "explain X", make it more specific
        if (formattedQuestion.toLowerCase().startsWith('explain ')) {
          const topic = formattedQuestion.substring(8).trim();
          formattedQuestion = `Explain what "${topic}" is in the context of ${lessonTitle}`;
        }
        
        // If the question is a simple "what is X", improve it
        else if (formattedQuestion.toLowerCase().startsWith('what is ')) {
          const topic = formattedQuestion.substring(8).trim();
          formattedQuestion = `What is "${topic}" in the context of ${lessonTitle}?`;
        }
        
        // If the question is about examples
        else if (formattedQuestion.toLowerCase().includes('example')) {
          if (!formattedQuestion.includes(lessonTitle)) {
            formattedQuestion = `${formattedQuestion} (in ${lessonTitle})`;
          }
        }
      }
      
      // Prepare the new review item
      const newReviewItem = {
        id: questionId,
        type: 'AskAI',
        timestamp: new Date().toISOString(),
        reviewCount: 0,
        isUnderstood: false, // Start as not understood
        problem: formattedQuestion, // Use the improved question format
        problemContext: selectedText || '', // Don't show context in review card
        userAnswer: '', // No user answer for AskAI
        answer: '', // Will be updated when AI responds
        aiResponse: '', // Will store full AI response
        slide_title: slideInfo,
        slide_summary: currentSlideTitle || '',
        lesson_id: lessonId,
        lesson_title: lessonTitle,
        lesson_description: lessonDescription
      };
      
      // Try to get current user's review_data
      const { data: userData, error: fetchError } = await supabase
        .from('users')
        .select('review_data')
        .eq('id', currentUser.id)
        .single();
      
      if (fetchError) {
        console.warn("Could not fetch user review data. Will create a new review data object.", fetchError);
        // Continue with a new review data object instead of throwing
      }
      
      // Get existing review data or initialize if empty/error
      const reviewData = (userData?.review_data) || { 
        items: [], 
        last_reviewed: null, 
        review_stats: { 
          total_reviews: 0, 
          mastered: 0, 
          review_required: 0 
        } 
      };
      
      // Add to reviewItems with pending flag
      reviewData.items.unshift(newReviewItem);
      
      // Keep only the latest 100 items to prevent the object from becoming too large
      if (reviewData.items.length > 100) {
        reviewData.items = reviewData.items.slice(0, 100);
      }
      
      // Try to update the user's review_data in Supabase
      let retries = 2;
      let updateSuccess = false;
      
      while (retries > 0 && !updateSuccess) {
        try {
          const { error: updateError } = await supabase
            .from('users')
            .update({ review_data: reviewData })
            .eq('id', currentUser.id);
          
          if (!updateError) {
            updateSuccess = true;
            console.log("Successfully saved review data");
          } else {
            console.warn(`Error saving review data, retries left: ${retries}`, updateError);
            retries--;
            await new Promise(resolve => setTimeout(resolve, 1000)); // Wait 1 second before retry
          }
        } catch (updateError) {
          console.warn(`Error saving review data, retries left: ${retries}`, updateError);
          retries--;
          await new Promise(resolve => setTimeout(resolve, 1000)); // Wait 1 second before retry
        }
      }
      
      // When the AI responds, save its answer
      setTimeout(() => {
        const saveAIResponse = async () => {
          try {
            // Make sure messages is defined
            if (!messages || !Array.isArray(messages)) {
              console.warn("Messages array is undefined or not an array");
              return;
            }
            
            // Find the AI's response in the messages
            const aiResponseMessage = messages.find(msg => 
              msg.type === 'assistant' && !msg.isLoading
            );
            
            if (!aiResponseMessage) {
              console.log("No AI response found yet, will try again later");
              return; // No response found yet
            }
            
            // Store the AI response locally if we can't save to Supabase
            const aiResponseContent = aiResponseMessage.content;
            
            // Only continue if we were able to save the initial data
            if (!updateSuccess) {
              console.warn("Skipping saving AI response as initial save failed");
              return;
            }
            
            // Try to get the current review data again
            const { data: updatedUserData, error: fetchError2 } = await supabase
              .from('users')
              .select('review_data')
              .eq('id', currentUser.id)
              .single();
            
            if (fetchError2) {
              console.warn("Could not fetch updated user review data", fetchError2);
              return;
            }
            
            // Update the review item with the AI response
            const updatedReviewData = updatedUserData.review_data;
            const itemIndex = updatedReviewData.items.findIndex(item => item.id === questionId);
            
            if (itemIndex !== -1) {
              updatedReviewData.items[itemIndex].answer = aiResponseContent;
              updatedReviewData.items[itemIndex].aiResponse = aiResponseContent;
              
              // Retry logic for updating with AI response
              let aiRetries = 2;
              let aiUpdateSuccess = false;
              
              while (aiRetries > 0 && !aiUpdateSuccess) {
                try {
                  const { error: updateError2 } = await supabase
                    .from('users')
                    .update({ review_data: updatedReviewData })
                    .eq('id', currentUser.id);
                  
                  if (!updateError2) {
                    aiUpdateSuccess = true;
                    console.log("Successfully saved AI response to review data");
                  } else {
                    console.warn(`Error saving AI response, retries left: ${aiRetries}`, updateError2);
                    aiRetries--;
                    await new Promise(resolve => setTimeout(resolve, 1000)); // Wait 1 second before retry
                  }
                } catch (updateError2) {
                  console.warn(`Error saving AI response, retries left: ${aiRetries}`, updateError2);
                  aiRetries--;
                  await new Promise(resolve => setTimeout(resolve, 1000)); // Wait 1 second before retry
                }
              }
            } else {
              console.warn("Item not found in review data");
            }
          } catch (error) {
            console.error('Error saving AI response to review data:', error);
          }
        };
        
        saveAIResponse();
        // Try up to 3 times with increasing delays if no AI response is found yet
        setTimeout(() => saveAIResponse(), 6000); // Try again after 6 seconds
        setTimeout(() => saveAIResponse(), 15000); // Try one last time after 15 seconds
      }, 3000); // Initial wait for AI to respond
    } catch (error) {
      console.error('Error saving question to review data:', error);
    }
  };

  // Handle quick question selection
  const handleQuickQuestion = (prompt) => {
    if (selectedText) {
      // Create the question text
      const quickQuestion = `${prompt}${selectedText}`;
      
      // Set the question in the input field instead of submitting immediately
      if (typeof handleQuestionChange === 'function') {
        // Create a synthetic event to pass to handleQuestionChange
        const syntheticEvent = {
          target: { value: quickQuestion }
        };
        handleQuestionChange(syntheticEvent);
        
        // Focus the input field after updating
        setTimeout(() => {
          if (inputRef.current) {
            inputRef.current.focus();
          }
        }, 0);
      }
    }
  };

  const [isTypingAnimation, setIsTypingAnimation] = useState(false);
  const [typingMessageIndex, setTypingMessageIndex] = useState(-1);
  const [hasAddedGuide, setHasAddedGuide] = useState(false);

  const inputRef = useRef(null);
  const modalRef = useRef(null);
  const messagesEndRef = useRef(null);
  const chatContainerRef = useRef(null);

  // Focus the input when modal opens
  useEffect(() => {
    if (isModalOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isModalOpen]);

  // Prevent wheel events from propagating outside the modal
  useEffect(() => {
    // Handler to stop wheel event propagation
    const handleWheel = (e) => {
      // Stop the event from bubbling up to parent elements
      e.stopPropagation();
    };

    // Add wheel event listener to chat container and modal
    if (isModalOpen && chatContainerRef.current) {
      chatContainerRef.current.addEventListener('wheel', handleWheel, { passive: false });
    }
    
    if (isModalOpen && modalRef.current) {
      modalRef.current.addEventListener('wheel', handleWheel, { passive: false });
    }
    
    // Clean up event listeners when modal closes
    return () => {
      if (chatContainerRef.current) {
        chatContainerRef.current.removeEventListener('wheel', handleWheel);
      }
      if (modalRef.current) {
        modalRef.current.removeEventListener('wheel', handleWheel);
      }
    };
  }, [isModalOpen]);

  // Add system message about markdown format when conversation starts
  useEffect(() => {
    if (isModalOpen && messages.length === 0 && !hasAddedGuide && addMessage) {
      const markdownGuideMessage = {
        type: 'system',
        content: "This chat supports rich markdown formatting. The AI has been instructed to format responses using our custom markdown syntax."
      };
      
      // Message to the AI (invisible to the user but used in API calls)
      const aiInstructionsMessage = {
        type: 'ai_instructions',
        content: `
Please format your responses using markdown to enhance readability:

1. **Text Formatting:**
   - Use **bold** for important concepts or keywords
   - Use *italics* for emphasis or definitions
   - Use \`inline code\` for variables, functions, or brief code snippets
   - Use ==highlighted text== for critical information

2. **Code Blocks:**
   - Use language-specific code blocks for proper syntax highlighting:
   \`\`\`javascript
   // JavaScript code example
   const greeting = "Hello, world!";
   console.log(greeting);
   \`\`\`
   
   \`\`\`css
   /* CSS code example */
   .container {
     display: flex;
     justify-content: center;
   }
   \`\`\`

3. **Lists:**
   - Use numbered lists for sequential steps or instructions
   - Use bullet points for related but non-sequential items

4. **Callouts:**
   - Use \`:::info\` blocks for additional information
   - Use \`:::warning\` blocks for cautionary notes
   - Use \`:::error\` blocks for common mistakes or errors to avoid
   - Use \`:::success\` blocks for successful outcomes or best practices

5. **Tables:**
   - Use markdown tables for structured data:
   | Header 1 | Header 2 |
   | -------- | -------- |
   | Cell 1   | Cell 2   |
   | Cell 3   | Cell 4   |

6. **Blockquotes:**
   - Use > for blockquotes or important quotes

7. **Headings:**
   - Use # for main headings
   - Use ## for subheadings
   - Use ### for section titles

When explaining code or technical concepts, show examples using code blocks with the appropriate language hint.
Supported languages include: javascript, jsx, css, html, python, and sql.
Be concise but thorough in your explanations.
`
      };
      
      // Add the messages to the conversation
      addMessage(markdownGuideMessage);
      
      // Add a system message showing the selected text if available
      if (selectedText && currentSlideTitle) {
        const selectedTextMessage = {
          type: 'system',
          content: `:::info\n**Selected text from "${currentSlideTitle}"**\n\n===${selectedText}===\n:::`
        };
        addMessage(selectedTextMessage);
      }
      
      // If your API implementation supports sending instructions to the AI
      // that aren't displayed to the user, add this message
      // addAIInstructions(aiInstructionsMessage);
      
      setHasAddedGuide(true);
      
      // Log the AI instructions for debugging
      console.log("AI Markdown Instructions:", aiInstructionsMessage.content);
    }
  }, [isModalOpen, messages.length, hasAddedGuide, addMessage, selectedText, currentSlideTitle]);

  // Reset guide state when modal closes
  useEffect(() => {
    if (!isModalOpen) {
      setHasAddedGuide(false);
    }
  }, [isModalOpen]);

  // Scroll to bottom when messages change
  useEffect(() => {
    if (messagesEndRef.current && chatContainerRef.current) {
      // Wait a small amount of time for the content to render
      // This is especially important for longer messages
      const timeoutId = setTimeout(() => {
        messagesEndRef.current.scrollIntoView({ 
          behavior: 'smooth', 
          block: 'end' 
        });
      }, 100);
      
      return () => clearTimeout(timeoutId);
    }
  }, [messages, isLoading]);

  // Auto-resize textarea based on content
  useEffect(() => {
    if (inputRef.current) {
      // Reset height to auto to get the correct scrollHeight
      inputRef.current.style.height = 'auto';
      // Set the height to scrollHeight + 2px for border
      const newHeight = Math.min(inputRef.current.scrollHeight, 150); // Max height of 150px
      inputRef.current.style.height = `${newHeight}px`;
    }
  }, [question]);

  // Manage typing animation when messages change
  useEffect(() => {
    if (messages.length > 0 && !isLoading) {
      const lastMessage = messages[messages.length - 1];
      if (lastMessage.type === 'assistant' && !lastMessage.isError) {
        setIsTypingAnimation(true);
        setTypingMessageIndex(messages.length - 1);
      }
    } else if (!isLoading) {
      setIsTypingAnimation(false);
      setTypingMessageIndex(-1);
    }
  }, [messages, isLoading]);

  // Handle Enter key press
  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      submitQuestion();
    }
  };

  // Handle click outside to close
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (modalRef.current && !modalRef.current.contains(e.target)) {
        closeQuestionModal();
      }
    };

    if (isModalOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isModalOpen, closeQuestionModal]);

  // Custom scrollbar styles
  const scrollbarStyles = `
    .custom-scrollbar::-webkit-scrollbar {
      width: 6px;
    }
    
    .custom-scrollbar::-webkit-scrollbar-track {
      background: rgba(31, 41, 55, 0.5);
      border-radius: 10px;
    }
    
    .custom-scrollbar::-webkit-scrollbar-thumb {
      background: rgba(75, 85, 99, 0.8);
      border-radius: 10px;
    }
    
    .custom-scrollbar::-webkit-scrollbar-thumb:hover {
      background: rgba(107, 114, 128, 0.8);
    }
    
    .typing-effect-container {
      position: relative;
    }
    
    .typing-cursor {
      display: inline-block;
      width: 2px;
      height: 1.2em;
      background-color: #fff;
      margin-left: 2px;
      vertical-align: middle;
      animation: blink 1s step-end infinite;
    }
    
    @keyframes blink {
      from, to {
        opacity: 1;
      }
      50% {
        opacity: 0;
      }
    }
    
    .custom-markdown-content {
      transition: opacity 0.2s ease-in-out;
    }
    
    .custom-markdown-content mark {
      background-color: rgba(59, 130, 246, 0.2);
      border-radius: 4px;
      padding: 2px 4px;
      font-weight: 500;
      color: #93c5fd;
      border-left: 3px solid #3b82f6;
    }
    
    /* Special styling for selected text highlight with triple equals */
    .custom-markdown-content p:has(mark:first-child:last-child) mark {
      display: block;
      padding: 8px;
      margin: 10px 0;
      background-color: rgba(59, 130, 246, 0.15);
      border: 1px solid rgba(59, 130, 246, 0.3);
      border-left: 4px solid #3b82f6;
    }
    
    .quick-question-btn {
      transition: all 0.2s ease;
      position: relative;
      overflow: hidden;
    }
    
    .quick-question-btn:hover {
      transform: translateY(-1px);
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    }
    
    .quick-question-btn:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.1);
      opacity: 0;
      transition: opacity 0.3s ease;
    }
    
    .quick-question-btn:hover:after {
      opacity: 1;
    }
  `;

  if (!isModalOpen) return null;

  return (
    <div 
      className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4" 
      onWheel={(e) => e.stopPropagation()}
    >
      <style dangerouslySetInnerHTML={{ __html: scrollbarStyles }} />
      <div 
        ref={modalRef}
        className="bg-gray-800 rounded-lg shadow-xl w-full max-w-lg max-h-[80vh] flex flex-col"
      >
        <div className="flex justify-between items-center p-4 border-b border-gray-700">
          <h2 className="text-lg font-semibold text-white">Ask AI</h2>
          <button 
            onClick={closeQuestionModal}
            className="text-gray-400 hover:text-white transition-colors"
            aria-label="Close"
          >
            <X size={20} />
          </button>
        </div>
        
        <div 
          ref={chatContainerRef}
          className="flex-1 overflow-y-auto p-4 space-y-4 min-h-[200px] custom-scrollbar"
          style={{ scrollBehavior: 'smooth' }}
        >
          {messages.length === 0 ? (
            <div className="flex flex-col items-center justify-center h-full text-center text-gray-400 p-4">
              <Bot size={32} className="mb-2 text-blue-400" />
              <p className="mb-1">Ask me anything about the content</p>
              <p className="text-xs">I can explain concepts, provide examples, or help you understand difficult topics</p>
            </div>
          ) : (
            <>
              {messages.map((message, index) => (
                <MessageBubble 
                  key={index} 
                  message={message} 
                  isTyping={isTypingAnimation && index === typingMessageIndex}
                />
              ))}
              {isLoading && (
                <MessageBubble message={{ type: 'assistant', isLoading: true }} />
              )}
              <div ref={messagesEndRef} />
            </>
          )}
        </div>
        
        {/* Quick question buttons - for selected text */}
        {selectedText && !isLoading && (
          <>
            <div className="px-4 pt-3 flex items-center">
              <div className="text-xs text-gray-400 flex items-center mb-1">
                <Lightbulb size={14} className="mr-1.5 text-yellow-500" />
                <span>Quick questions about the selected text</span>
              </div>
            </div>
            <div className="px-4 pb-1 flex flex-wrap gap-2">
              {quickQuestions.map((q) => (
                <button
                  key={q.id}
                  onClick={() => handleQuickQuestion(q.prompt)}
                  className="quick-question-btn text-xs bg-blue-600/30 hover:bg-blue-600/50 text-blue-300 px-3 py-1.5 rounded-full transition-colors flex items-center shadow-sm"
                >
                  {q.icon}
                  {q.text}
                </button>
              ))}
            </div>
          </>
        )}
        
        {/* General quick questions - when no text is selected */}
        {!selectedText && !isLoading && (
          <>
            <div className="px-4 pt-3 flex items-center">
              <div className="text-xs text-gray-400 flex items-center mb-1">
                <Lightbulb size={14} className="mr-1.5 text-yellow-500" />
                <span>Quick questions about this topic</span>
              </div>
            </div>
            <div className="px-4 pb-1 flex flex-wrap gap-2">
              <button
                onClick={() => handleQuickQuestion("Can you explain ")}
                className="quick-question-btn text-xs bg-blue-600/30 hover:bg-blue-600/50 text-blue-300 px-3 py-1.5 rounded-full transition-colors flex items-center shadow-sm"
              >
                <HelpCircle size={14} className="mr-1.5" />
                Explain this topic
              </button>
              <button
                onClick={() => handleQuickQuestion("Give me an example of ")}
                className="quick-question-btn text-xs bg-blue-600/30 hover:bg-blue-600/50 text-blue-300 px-3 py-1.5 rounded-full transition-colors flex items-center shadow-sm"
              >
                <FileText size={14} className="mr-1.5" />
                Give an example
              </button>
              <button
                onClick={() => handleQuickQuestion("What are the key points about ")}
                className="quick-question-btn text-xs bg-blue-600/30 hover:bg-blue-600/50 text-blue-300 px-3 py-1.5 rounded-full transition-colors flex items-center shadow-sm"
              >
                <List size={14} className="mr-1.5" />
                Key points
              </button>
            </div>
          </>
        )}
        
        <div className="p-4 border-t border-gray-700">
          <div className="relative">
            <textarea
              ref={inputRef}
              value={question}
              onChange={handleQuestionChange}
              onKeyDown={handleKeyDown}
              placeholder="Type your question..."
              className="w-full p-3 bg-gray-700 rounded-md border border-gray-600 text-white focus:border-blue-500 focus:ring-1 focus:ring-blue-500 outline-none resize-none overflow-hidden"
              style={{ minHeight: '50px', maxHeight: '150px' }}
              disabled={isLoading}
            />
            <button
              onClick={() => submitQuestion()}
              disabled={isLoading || !question.trim()}
              className="absolute right-3 bottom-3 p-1.5 rounded-full bg-blue-600 hover:bg-blue-700 text-white disabled:opacity-50 disabled:cursor-not-allowed transition-colors duration-200"
              aria-label="Send question"
            >
              <Send size={18} />
            </button>
          </div>
          <div className="flex justify-between mt-2">
            <button 
              onClick={clearConversation}
              className="text-xs text-gray-400 hover:text-blue-400"
              style={{ visibility: messages.length > 1 ? 'visible' : 'hidden' }}
            >
              Clear conversation
            </button>
            <div className="text-xs text-gray-500">
              {isLoading ? 'Processing...' : 'Press Enter to send'}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AIQuestionModal;