import React, { Fragment, useEffect, useState } from 'react';
import { components, createComponentId } from '../slideComponents';
import Slide from './Slide';

/**
 * SlideRenderer - A component to render the appropriate components based on slide data
 * 
 * This component acts as a controller for slide content, instantiating the 
 * appropriate component based on the slide's content items.
 */
const SlideRenderer = ({ 
  slide, 
  onNext, 
  onPrevious, 
  isFirst, 
  isLast,
  onQuizAnswer,
  onInputSubmit,
  onCodeSubmit,
  onCreateOverflowSlide,
  isOverflowSlide = false,
  hideNavigation = false,
  preProcessOverflow = false // New prop to indicate if we should bypass overflow handling
}) => {
  // Track if any components have generated overflow
  const [overflowComponents, setOverflowComponents] = useState([]);
  // Add loading state to prevent flash of content
  const [isCheckingOverflow, setIsCheckingOverflow] = useState(true);
  
  // Clear overflow components when the slide changes
  useEffect(() => {
    setOverflowComponents([]);
    // Set checking flag to true to avoid flash of content
    setIsCheckingOverflow(true);
    
    // Use a small delay to detect overflow after render
    const timer = setTimeout(() => {
      setIsCheckingOverflow(false);
    }, 100);
    
    return () => clearTimeout(timer);
  }, [slide?.id]);

  // Handle overflow when components don't fit in the slide
  const handleOverflow = (overflowingComponents) => {
    // If we're in pre-process mode, bypass the normal flow and just call onCreateOverflowSlide
    if (preProcessOverflow) {
      if (onCreateOverflowSlide && overflowingComponents.length > 0) {
        createOverflowSlide(overflowingComponents);
      }
      return;
    }
    
    // If we already have an overflow slide or if there's no handler, do nothing
    if (isOverflowSlide || !onCreateOverflowSlide || overflowingComponents.length === 0) {
      setIsCheckingOverflow(false);
      return;
    }
    
    // Create overflow slide
    createOverflowSlide(overflowingComponents);
    
    // Update our local state to track which components are in overflow
    setOverflowComponents(overflowingComponents);
    setIsCheckingOverflow(false);
  };

  // Function to create overflow slide
  const createOverflowSlide = (overflowingComponents) => {
    // Create a new overflow slide with the overflowing components
    // Use a consistent identifier rather than timestamp
    const overflowSlideId = `${slide.id}-overflow-1`;
    
    // Create the overflow slide depending on the format of the slide data
    let overflowSlide;
    
    if (slide.components && Array.isArray(slide.components)) {
      // Handle component-based format (legacy format from testCourse.js)
      const overflowContent = overflowingComponents.map(componentId => {
        // Find the original component by ID
        const originalComponent = slide.components.find(c => c.id === componentId);
        
        if (originalComponent) {
          return originalComponent;
        } else {
          // If we can't find by ID, try to find by index (for older data without IDs)
          const index = parseInt(componentId.split('-').pop());
          if (!isNaN(index) && index < slide.components.length) {
            return slide.components[index];
          } else {
            // Create a placeholder component with a consistent ID
            return { 
              type: 'text',
              id: `overflow-placeholder-${slide.id}-1-${componentId}`,
              props: {
                content: 'Content continued from previous slide...'
              }
            };
          }
        }
      });
      
      overflowSlide = {
        id: overflowSlideId,
        title: `${slide.title} (continued)`,
        components: overflowContent,
        isOverflowSlide: true,
        parentSlideId: slide.id
      };
    } else if (slide.content && Array.isArray(slide.content)) {
      // Handle content-based format (new format)
      const overflowContent = overflowingComponents.map(componentId => {
        // Find the original component in the slide's content
        const originalComponent = slide.content.find(c => c.id === componentId);
        
        if (originalComponent) {
          return originalComponent;
        } else {
          // If we can't find the component by ID, create a placeholder with consistent ID
          return { 
            type: 'text', 
            id: `overflow-placeholder-${slide.id}-1-${componentId}`,
            content: 'Content continued from previous slide...' 
          };
        }
      });
      
      overflowSlide = {
        id: overflowSlideId,
        title: `${slide.title} (continued)`,
        content: overflowContent,
        isOverflowSlide: true,
        parentSlideId: slide.id
      };
    } else {
      // No valid content format found
      console.warn('Unable to create overflow slide: invalid slide format');
      return;
    }
    
    // Call the callback to create a new overflow slide
    onCreateOverflowSlide(overflowSlide);
  };

  // Method to render components based on content type
  const renderComponents = () => {
    if (!slide) return null;
    
    // Handle slide.components format (legacy format used in testCourse.js)
    if (slide.components && Array.isArray(slide.components)) {
      return slide.components.map((component, index) => {
        // Legacy format uses type and props
        const { type, props = {} } = component;
        
        // Get the component type from the registry
        const Component = components[type.toLowerCase()];
        
        if (!Component) {
          console.warn(`Unknown component type: ${type}`);
          return null;
        }
        
        // Use the component's id if available, or create a unique ID for this component
        const componentId = component.id || `component-${index}`;
        
        // Skip rendering if this component ID is in the overflow list
        // Also skip if we're still checking for overflow to prevent flash
        if ((!isOverflowSlide && overflowComponents.includes(componentId)) || 
            (!isOverflowSlide && isCheckingOverflow && index >= 3)) { // Only show first few items while checking
          return null;
        }
        
        // Create props for the component
        const componentProps = {
          data: {
            id: componentId,
            ...props
          },
          onSubmit: getSubmitHandlerForLegacy(type),
          onNext: onNext && component.shouldTriggerNext ? onNext : undefined,
        };
        
        return <Component key={componentId} {...componentProps} />;
      });
    }
    
    // Handle slide.content format (new format)
    if (slide.content && Array.isArray(slide.content)) {
      // If this is an overflow slide, we only want to render the components
      // that were moved to this slide
      let contentToRender = slide.content;
      
      // For normal slides in a paginated view, skip components that are in overflow
      if (!isOverflowSlide && overflowComponents.length > 0) {
        contentToRender = slide.content.filter(item => !overflowComponents.includes(item.id));
      } else if (!isOverflowSlide && isCheckingOverflow) {
        // Only show first few items while checking for overflow
        contentToRender = slide.content.slice(0, 3);
      }
      
      return contentToRender.map((item, index) => {
        // Ensure the component has an ID
        const componentId = item.id || createComponentId(item.type || 'unknown');
        const itemWithId = item.id ? item : { ...item, id: componentId };
        
        // Get the component type from the registry
        const Component = components[item.type];
        
        // Skip if component type doesn't exist
        if (!Component) {
          console.warn(`Unknown component type: ${item.type}`);
          return null;
        }
        
        // Create props for the component
        const componentProps = {
          data: itemWithId,
          onSubmit: getSubmitHandler(item),
          onNext: onNext && item.shouldTriggerNext ? onNext : undefined,
        };
        
        return <Component key={componentId} {...componentProps} />;
      });
    }
    
    // If we have neither content nor components, show a placeholder
    return (
      <div className="text-red-500 p-4 border border-red-300 rounded">
        No content available for this slide.
      </div>
    );
  };

  // Get the appropriate submit handler for interactive components (legacy format)
  const getSubmitHandlerForLegacy = (type) => {
    const lowercaseType = type.toLowerCase();
    
    switch (lowercaseType) {
      case 'quiz':
        return result => onQuizAnswer && onQuizAnswer(result, slide);
      case 'input':
        return input => onInputSubmit && onInputSubmit(input, slide);
      case 'code':
      case 'coding':
        return code => onCodeSubmit && onCodeSubmit(code, slide);
      default:
        return null;
    }
  };

  // Get the appropriate submit handler for interactive components
  const getSubmitHandler = (item) => {
    switch (item.type) {
      case 'quiz':
        return result => onQuizAnswer && onQuizAnswer(result, slide);
      case 'input':
        return input => onInputSubmit && onInputSubmit(input, slide);
      case 'code':
        return code => onCodeSubmit && onCodeSubmit(code, slide);
      default:
        return null;
    }
  };

  return (
    <Slide
      id={slide?.id}
      title={slide?.title}
      className={`slide ${isOverflowSlide ? 'overflow-slide' : ''} ${isCheckingOverflow ? 'checking-overflow' : ''}`}
      onNext={hideNavigation ? null : onNext}
      onPrevious={hideNavigation ? null : onPrevious}
      isFirst={isFirst}
      isLast={isLast}
      onOverflow={handleOverflow}
    >
      {renderComponents()}
      {isCheckingOverflow && !isOverflowSlide && (
        <div className="text-gray-400 text-center py-2 italic text-sm animate-pulse">
          Loading content...
        </div>
      )}
    </Slide>
  );
};

export default SlideRenderer; 