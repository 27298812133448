import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "./AuthContext";
import UserProfile from "./UserProfile";
import { LogIn } from "lucide-react";

export default function AuthButton() {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  if (currentUser) {
    return <UserProfile />;
  }

  return (
    <button
      onClick={() => navigate("/login", { state: { returnTo: location.pathname } })}
      className="flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
    >
      <LogIn className="h-4 w-4" />
      <span className="hidden sm:inline">Log In</span>
    </button>
  );
} 