import React, { useEffect, useState, useRef } from 'react';
import { MessageSquareText, Bot, CornerDownLeft } from 'lucide-react';
import { useTextSelection } from '../../context/TextSelectionContext';

/**
 * AskAI button that's always visible, with enhanced animation when text is selected
 * Now shows a bot icon normally, and a return key icon when text is selected
 * Also responds to Enter key press when text is selected
 */
const AskAIButton = () => {
  const { isPopupVisible, selectionPosition, openQuestionModal, selectedText } = useTextSelection();
  const buttonRef = useRef(null);

  // Handle button click
  const handleButtonClick = (e) => {
    // Prevent default behavior
    e.preventDefault();
    e.stopPropagation();
    
    // Use setTimeout to ensure we don't interfere with the selection
    setTimeout(openQuestionModal, 0);
    
    return false;
  };

  // Handle key presses globally to detect Enter key when text is selected
  useEffect(() => {
    const handleKeyDown = (e) => {
      // Check if text is selected and Enter key is pressed
      if (isPopupVisible && selectedText && (e.key === 'Enter' || e.keyCode === 13)) {
        // Prevent default behavior (like form submission)
        e.preventDefault();
        // Open the question modal
        openQuestionModal();
      }
    };

    // Add the event listener
    document.addEventListener('keydown', handleKeyDown);

    // Clean up
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isPopupVisible, selectedText, openQuestionModal]);

  // Determine button classes based on whether text is selected
  const buttonClasses = `
    fixed bottom-6 right-6 z-[1000] shadow-lg rounded-full 
    ${isPopupVisible 
      ? 'bg-blue-600 hover:bg-blue-700 text-white animate-ai-pulse' 
      : 'bg-blue-600 hover:bg-blue-700 text-white opacity-80 hover:opacity-100'
    }
    transition-all duration-300 ease-in-out
    flex items-center gap-1 p-3 
    md:p-3 text-xs md:text-sm
    preserve-selection ask-ai-button
  `;

  return (
    <div 
      ref={buttonRef}
      className="fixed bottom-6 right-6 z-[1000] preserve-selection"
      onMouseDown={(e) => {
        e.preventDefault();
        e.stopPropagation();
        return false;
      }}
    >
      <button
        className={buttonClasses}
        onClick={handleButtonClick}
        aria-label={isPopupVisible ? "Press Enter to ask AI about selected text" : "Ask AI a question"}
        title={isPopupVisible ? "Press Enter to ask about selected text" : "Ask AI a question"}
      >
        {isPopupVisible ? (
          <CornerDownLeft size={20} />
        ) : (
          <Bot size={20} />
        )}
        <span className="hidden md:inline">
          Ask AI
        </span>
      </button>
    </div>
  );
};

// Add CSS for animations
const style = document.createElement('style');
style.textContent = `
  @keyframes fadeIn {
    from { opacity: 0; transform: translateY(10px); }
    to { opacity: 1; transform: translateY(0); }
  }
  
  @keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.05); }
    100% { transform: scale(1); }
  }

  @keyframes gradientRotation {
    0% {
      background: linear-gradient(45deg, #3b82f6, #2563eb);
      box-shadow: 0 0 15px rgba(59, 130, 246, 0.5);
    }
    50% {
      background: linear-gradient(225deg, #3b82f6, #2563eb);
      box-shadow: 0 0 25px rgba(59, 130, 246, 0.7);
    }
    100% {
      background: linear-gradient(45deg, #3b82f6, #2563eb);
      box-shadow: 0 0 15px rgba(59, 130, 246, 0.5);
    }
  }
  
  /* Smooth AI glow animation */
  @keyframes aiGlow {
    0%, 100% {
      box-shadow: 0 0 15px rgba(59, 130, 246, 0.6);
    }
    50% {
      box-shadow: 0 0 25px rgba(79, 70, 229, 0.75);
    }
  }
  
  /* Smooth scale animation */
  @keyframes aiScale {
    0%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
  }
  
  /* Smooth background position animation for the gradient */
  @keyframes aiGradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  
  .animate-fade-in {
    animation: fadeIn 0.3s ease-out;
  }
  
  .animate-pulse {
    animation: pulse 1.5s infinite;
  }

  .animate-gradient-rotation {
    animation: gradientRotation 3s infinite;
  }

  .animate-ai-pulse {
    background: linear-gradient(90deg, #3b82f6, #4f46e5, #2563eb, #3b82f6);
    background-size: 300% 100%;
    animation: 
      aiGradient 6s infinite ease, 
      aiGlow 3s infinite ease-in-out,
      aiScale 3s infinite ease-in-out;
    will-change: transform, box-shadow, background-position;
    transition: all 0.3s ease;
  }

  /* Make sure the button is visible and properly sized on mobile */
  @media (max-width: 640px) {
    .ask-ai-button {
      padding: 0.75rem;
      bottom: 1.5rem;
      right: 1.5rem;
    }
  }
`;
document.head.appendChild(style);

export default AskAIButton;
