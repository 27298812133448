import React from 'react';
import { Copy, Code2, Info, AlertTriangle, AlertCircle, CheckCircle } from 'lucide-react';

/**
 * CustomMarkdown component
 * 
 * A custom markdown parser and renderer that doesn't rely on external libraries
 * Supports basic markdown syntax plus custom extensions
 */
const CustomMarkdown = ({ content }) => {
  // If content is not provided, return null
  if (!content) return null;
  
  // Function to determine language based on file extension or content
  const detectLanguage = (code) => {
    if (!code) return 'javascript'; // default if no code
    
    // Check for SQL patterns
    if (code.includes('SELECT') || 
        code.includes('INSERT INTO') || 
        code.includes('UPDATE') || 
        code.includes('DELETE FROM') ||
        code.includes('CREATE TABLE') ||
        code.includes('ALTER TABLE') ||
        code.match(/\bFROM\b\s+\w+/) ||
        code.match(/\bWHERE\b\s+\w+/)) {
      return 'sql';
    }
    
    // Check for JSX patterns
    if ((code.includes('<') && code.includes('/>')) || 
        code.includes('React') || 
        code.includes('render') || 
        code.includes('component') ||
        code.includes('props') ||
        code.includes('useState') ||
        code.includes('jsx')) {
      return 'jsx';
    }
    
    // Check for CSS patterns
    if (code.includes('body {') || 
        code.includes('@media') || 
        code.includes('color:') || 
        code.includes('margin:') || 
        code.includes('padding:') || 
        code.match(/[a-z\-]+\s*:\s*[^;]+;/)) {
      return 'css';
    }
    
    // Check for HTML patterns
    if (code.includes('<html>') || 
        code.includes('</div>') || 
        code.includes('<body>') || 
        code.match(/<[a-z]+[^>]*>/i)) {
      return 'html';
    }
    
    // Check for JavaScript/React patterns
    if (code.includes('import React') || 
        code.includes('jsx') || 
        code.includes('function') || 
        code.includes('const') || 
        code.includes('let') || 
        code.includes('var') || 
        code.includes('return')) {
      return 'javascript';
    }
    
    // Check for Python patterns
    if (code.includes('def ') || 
        code.includes('import ') && !code.includes(';') || 
        code.includes('print(') || 
        code.includes('if __name__ == "__main__"')) {
      return 'python';
    }
    
    // Default to JavaScript if no patterns match
    return 'javascript';
  };
  
  // Apply syntax highlighting to JavaScript code
  const highlightJavaScript = (code) => {
    // Keywords
    const keywords = [
      'const', 'let', 'var', 'function', 'return', 'if', 'else', 'for', 'while', 'do',
      'switch', 'case', 'break', 'continue', 'default', 'try', 'catch', 'finally',
      'throw', 'new', 'delete', 'typeof', 'instanceof', 'void', 'class', 'extends',
      'super', 'import', 'export', 'from', 'as', 'async', 'await', 'yield', 'this',
      // JSX/React specific keywords
      'render', 'component', 'props', 'state', 'useState', 'useEffect', 'useContext', 'useRef'
    ];
    
    // Built-in objects and functions
    const builtIns = [
      'Array', 'Object', 'String', 'Number', 'Boolean', 'RegExp', 'Math', 'Date',
      'JSON', 'Promise', 'Map', 'Set', 'WeakMap', 'WeakSet', 'Symbol', 'Proxy',
      'Reflect', 'console', 'document', 'window', 'fetch', 'parseInt', 'parseFloat',
      // React specific
      'React', 'ReactDOM', 'Component'
    ];
    
    // Split the code into lines for processing
    const lines = code.split('\n');
    
    return lines.map((line, lineIndex) => {
      // Process strings
      let inString = null;
      let inComment = false;
      let inJSX = false;
      let tokenized = [];
      let currentToken = '';
      
      for (let i = 0; i < line.length; i++) {
        const char = line[i];
        const nextChar = line[i + 1];
        
        // Handle comments
        if (!inString && char === '/' && nextChar === '/') {
          if (currentToken) {
            tokenized.push(<span key={`token-${lineIndex}-${tokenized.length}`}>{currentToken}</span>);
            currentToken = '';
          }
          tokenized.push(
            <span key={`comment-${lineIndex}`} className="text-gray-500">
              {line.substring(i)}
            </span>
          );
          break;
        }
        
        // Handle JSX tags
        if (!inString && !inComment) {
          // Opening JSX tag
          if (char === '<' && /[A-Za-z]/.test(nextChar)) {
            if (currentToken) {
              tokenized.push(<span key={`token-${lineIndex}-${tokenized.length}`}>{currentToken}</span>);
              currentToken = '';
            }
            
            // Find the end of the tag name
            let tagEnd = i + 1;
            while (tagEnd < line.length && /[A-Za-z0-9_.]/.test(line[tagEnd])) {
              tagEnd++;
            }
            
            // Extract tag name
            const tagName = line.substring(i + 1, tagEnd);
            
            // Add the opening bracket
            tokenized.push(
              <span key={`jsx-bracket-${lineIndex}-${tokenized.length}`} className="text-gray-400">
                {char}
              </span>
            );
            
            // Add the tag name with appropriate color
            tokenized.push(
              <span key={`jsx-tag-${lineIndex}-${tokenized.length}`} className="text-blue-400">
                {tagName}
              </span>
            );
            
            i = tagEnd - 1; // -1 because the loop will increment i
            continue;
          }
          
          // JSX attribute
          if (inJSX && /[A-Za-z]/.test(char) && line.substring(i).includes('=')) {
            let attrEnd = i;
            while (attrEnd < line.length && line[attrEnd] !== '=' && line[attrEnd] !== ' ' && line[attrEnd] !== '>') {
              attrEnd++;
            }
            
            if (attrEnd > i) {
              const attrName = line.substring(i, attrEnd);
              tokenized.push(
                <span key={`jsx-attr-${lineIndex}-${tokenized.length}`} className="text-green-300">
                  {attrName}
                </span>
              );
              i = attrEnd - 1; // -1 because the loop will increment i
              continue;
            }
          }
          
          // JSX closing tag or self-closing tag
          if (char === '/' && (line[i-1] === '<' || line[i+1] === '>')) {
            tokenized.push(
              <span key={`jsx-slash-${lineIndex}-${tokenized.length}`} className="text-gray-400">
                {char}
              </span>
            );
            continue;
          }
          
          // JSX brackets
          if (char === '<' || char === '>' || char === '{' || char === '}') {
            if (currentToken) {
              tokenized.push(<span key={`token-${lineIndex}-${tokenized.length}`}>{currentToken}</span>);
              currentToken = '';
            }
            
            tokenized.push(
              <span key={`jsx-bracket-${lineIndex}-${tokenized.length}`} className="text-gray-400">
                {char}
              </span>
            );
            
            // Toggle JSX mode
            if (char === '<') inJSX = true;
            if (char === '>') inJSX = false;
            
            continue;
          }
        }
        
        // Handle strings
        if ((char === '"' || char === "'" || char === '`') && (i === 0 || line[i - 1] !== '\\')) {
          if (inString === char) {
            // End of string
            currentToken += char;
            tokenized.push(
              <span key={`string-${lineIndex}-${tokenized.length}`} className="text-yellow-300">
                {currentToken}
              </span>
            );
            currentToken = '';
            inString = null;
          } else if (!inString) {
            // Start of string
            if (currentToken) {
              tokenized.push(<span key={`token-${lineIndex}-${tokenized.length}`}>{currentToken}</span>);
              currentToken = '';
            }
            inString = char;
            currentToken = char;
          } else {
            // Different string delimiter inside a string
            currentToken += char;
          }
          continue;
        }
        
        if (inString) {
          currentToken += char;
          continue;
        }
        
        // Handle word boundaries
        if (/\w/.test(char)) {
          currentToken += char;
        } else {
          if (currentToken) {
            // Check if the token is a keyword, built-in, or number
            if (keywords.includes(currentToken)) {
              tokenized.push(
                <span key={`keyword-${lineIndex}-${tokenized.length}`} className="text-purple-400">
                  {currentToken}
                </span>
              );
            } else if (builtIns.includes(currentToken)) {
              tokenized.push(
                <span key={`builtin-${lineIndex}-${tokenized.length}`} className="text-blue-400">
                  {currentToken}
                </span>
              );
            } else if (/^[0-9]+(\.[0-9]+)?$/.test(currentToken)) {
              tokenized.push(
                <span key={`number-${lineIndex}-${tokenized.length}`} className="text-green-300">
                  {currentToken}
                </span>
              );
            } else if (currentToken === 'true' || currentToken === 'false' || currentToken === 'null' || currentToken === 'undefined') {
              tokenized.push(
                <span key={`literal-${lineIndex}-${tokenized.length}`} className="text-orange-300">
                  {currentToken}
                </span>
              );
            } else {
              tokenized.push(<span key={`token-${lineIndex}-${tokenized.length}`}>{currentToken}</span>);
            }
            currentToken = '';
          }
          
          // Handle special characters
          if (char === '(' || char === ')' || char === '{' || char === '}' || char === '[' || char === ']') {
            tokenized.push(
              <span key={`bracket-${lineIndex}-${tokenized.length}`} className="text-gray-400">
                {char}
              </span>
            );
          } else {
            tokenized.push(<span key={`char-${lineIndex}-${tokenized.length}`}>{char}</span>);
          }
        }
      }
      
      // Add any remaining token
      if (currentToken) {
        if (keywords.includes(currentToken)) {
          tokenized.push(
            <span key={`keyword-${lineIndex}-${tokenized.length}`} className="text-purple-400">
              {currentToken}
            </span>
          );
        } else if (builtIns.includes(currentToken)) {
          tokenized.push(
            <span key={`builtin-${lineIndex}-${tokenized.length}`} className="text-blue-400">
              {currentToken}
            </span>
          );
        } else if (/^[0-9]+(\.[0-9]+)?$/.test(currentToken)) {
          tokenized.push(
            <span key={`number-${lineIndex}-${tokenized.length}`} className="text-green-300">
              {currentToken}
            </span>
          );
        } else if (currentToken === 'true' || currentToken === 'false' || currentToken === 'null' || currentToken === 'undefined') {
          tokenized.push(
            <span key={`literal-${lineIndex}-${tokenized.length}`} className="text-orange-300">
              {currentToken}
            </span>
          );
        } else {
          tokenized.push(<span key={`token-${lineIndex}-${tokenized.length}`}>{currentToken}</span>);
        }
      }
      
      return (
        <React.Fragment key={`line-${lineIndex}`}>
          {tokenized}
          {lineIndex < lines.length - 1 && '\n'}
        </React.Fragment>
      );
    });
  };
  
  // Apply syntax highlighting to CSS code
  const highlightCSS = (code) => {
    // CSS properties
    const properties = [
      'color', 'background', 'margin', 'padding', 'border', 'display', 'position',
      'top', 'right', 'bottom', 'left', 'width', 'height', 'font', 'text-align',
      'flex', 'grid', 'transition', 'transform', 'animation', 'opacity', 'z-index'
    ];
    
    // CSS values
    const values = [
      'block', 'inline', 'flex', 'grid', 'none', 'hidden', 'visible', 'absolute',
      'relative', 'fixed', 'sticky', 'auto', 'inherit', 'initial', 'unset', 'center',
      'left', 'right', 'top', 'bottom', 'transparent', 'currentColor'
    ];
    
    // CSS units
    const units = ['px', 'em', 'rem', '%', 'vh', 'vw', 'ch', 'ex', 'pt', 'pc', 'in', 'cm', 'mm'];
    
    // Split the code into lines for processing
    const lines = code.split('\n');
    
    return lines.map((line, lineIndex) => {
      // Check for comments
      if (line.trim().startsWith('/*') || line.includes('*/')) {
        return (
          <span key={`comment-${lineIndex}`} className="text-gray-500">
            {line}
            {lineIndex < lines.length - 1 && '\n'}
          </span>
        );
      }
      
      // Check for selectors (anything before {)
      const selectorMatch = line.match(/^([^{]*?)(\{.*)?$/);
      if (selectorMatch && selectorMatch[1].trim()) {
        const selector = selectorMatch[1];
        const rest = selectorMatch[2] || '';
        
        return (
          <React.Fragment key={`line-${lineIndex}`}>
            <span className="text-blue-300">{selector}</span>
            {rest && highlightCSSDeclaration(rest)}
            {lineIndex < lines.length - 1 && '\n'}
          </React.Fragment>
        );
      }
      
      // Check for declarations (property: value;)
      if (line.includes(':')) {
        return (
          <React.Fragment key={`line-${lineIndex}`}>
            {highlightCSSDeclaration(line)}
            {lineIndex < lines.length - 1 && '\n'}
          </React.Fragment>
        );
      }
      
      // Default case
      return (
        <React.Fragment key={`line-${lineIndex}`}>
          {line}
          {lineIndex < lines.length - 1 && '\n'}
        </React.Fragment>
      );
    });
  };
  
  // Helper function to highlight CSS declarations
  const highlightCSSDeclaration = (text) => {
    const propertyMatch = text.match(/([a-zA-Z-]+)(\s*:\s*)([^;]*)(;?)/);
    
    if (propertyMatch) {
      const [fullMatch, property, separator, value, semicolon] = propertyMatch;
      const beforeMatch = text.substring(0, text.indexOf(fullMatch));
      const afterMatch = text.substring(text.indexOf(fullMatch) + fullMatch.length);
      
      return (
        <>
          {beforeMatch}
          <span className="text-green-300">{property}</span>
          <span>{separator}</span>
          <span className="text-yellow-300">{value}</span>
          <span>{semicolon}</span>
          {afterMatch && highlightCSSDeclaration(afterMatch)}
        </>
      );
    }
    
    return text;
  };
  
  // Apply syntax highlighting to HTML code
  const highlightHTML = (code) => {
    // HTML keywords and elements
    const htmlElements = [
      'html', 'head', 'body', 'div', 'span', 'p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6',
      'ul', 'ol', 'li', 'a', 'img', 'button', 'form', 'input', 'textarea', 'select',
      'option', 'table', 'tr', 'td', 'th', 'header', 'footer', 'nav', 'main', 'section',
      'article', 'aside', 'meta', 'link', 'script', 'style', 'title', 'strong', 'em'
    ];

    // Split the code into lines for processing
    const lines = code.split('\n');
    
    return lines.map((line, lineIndex) => {
      // Break down line into tokens for syntax highlighting
      let tokens = [];
      let inTag = false;
      let inTagName = false;
      let inAttrName = false;
      let inAttrValue = false;
      let currentToken = '';
      let quoteType = null;
      
      for (let i = 0; i < line.length; i++) {
        const char = line[i];
        
        // Handle opening tag bracket
        if (char === '<' && !inTag) {
          if (currentToken) {
            tokens.push({ type: 'text', content: currentToken });
            currentToken = '';
          }
          currentToken = char;
          inTag = true;
          inTagName = true;
          continue;
        }
        
        // Handle closing tag bracket
        if (char === '>' && inTag) {
          currentToken += char;
          tokens.push({ type: 'tag', content: currentToken });
          currentToken = '';
          inTag = false;
          inTagName = false;
          inAttrName = false;
          inAttrValue = false;
          quoteType = null;
          continue;
        }
        
        // Handle attributes
        if (inTag) {
          if (inTagName && (char === ' ' || char === '\t')) {
            // End of tag name
            tokens.push({ type: 'tag-name', content: currentToken });
            currentToken = '';
            inTagName = false;
            inAttrName = true;
            continue;
          } else if (inAttrName && char === '=') {
            // End of attribute name
            tokens.push({ type: 'attr-name', content: currentToken });
            tokens.push({ type: 'text', content: '=' });
            currentToken = '';
            inAttrName = false;
            continue;
          } else if (!inAttrValue && !inAttrName && (char === ' ' || char === '\t')) {
            // Whitespace between attributes
            if (currentToken) {
              tokens.push({ type: 'text', content: currentToken });
              currentToken = '';
            }
            continue;
          } else if (!inAttrValue && (char === '"' || char === "'")) {
            // Start of attribute value
            tokens.push({ type: 'text', content: char });
            currentToken = '';
            inAttrValue = true;
            quoteType = char;
            continue;
          } else if (inAttrValue && char === quoteType) {
            // End of attribute value
            tokens.push({ type: 'attr-value', content: currentToken });
            tokens.push({ type: 'text', content: char });
            currentToken = '';
            inAttrValue = false;
            quoteType = null;
            inAttrName = true;
            continue;
          }
        }
        
        // Just add to current token
        currentToken += char;
      }
      
      // Add any remaining token
      if (currentToken) {
        if (inTagName) {
          tokens.push({ type: 'tag-name', content: currentToken });
        } else if (inAttrName) {
          tokens.push({ type: 'attr-name', content: currentToken });
        } else if (inAttrValue) {
          tokens.push({ type: 'attr-value', content: currentToken });
        } else {
          tokens.push({ type: 'text', content: currentToken });
        }
      }
      
      // Create React elements from tokens
      const elements = tokens.map((token, tokenIndex) => {
        switch(token.type) {
          case 'tag':
            return (
              <span key={`tag-${lineIndex}-${tokenIndex}`} className="text-gray-400">
                {token.content}
              </span>
            );
          case 'tag-name':
            // Check if it's an opening tag, closing tag, or self-closing
            if (token.content.startsWith('</')) {
              // Closing tag
              return (
                <span key={`tag-name-${lineIndex}-${tokenIndex}`}>
                  <span className="text-gray-400">{'</'}</span>
                  <span className="text-blue-400">{token.content.substring(2)}</span>
                </span>
              );
            } else if (token.content.startsWith('<')) {
              // Opening tag
              return (
                <span key={`tag-name-${lineIndex}-${tokenIndex}`}>
                  <span className="text-gray-400">{'<'}</span>
                  <span className="text-blue-400">{token.content.substring(1)}</span>
                </span>
              );
            } else {
              // Tag name only (after space in an opening tag)
              return (
                <span key={`tag-name-${lineIndex}-${tokenIndex}`} className="text-blue-400">
                  {token.content}
                </span>
              );
            }
          case 'attr-name':
            return (
              <span key={`attr-name-${lineIndex}-${tokenIndex}`} className="text-green-300">
                {token.content}
              </span>
            );
          case 'attr-value':
            return (
              <span key={`attr-value-${lineIndex}-${tokenIndex}`} className="text-yellow-300">
                {token.content}
              </span>
            );
          case 'text':
          default:
            return (
              <span key={`text-${lineIndex}-${tokenIndex}`}>
                {token.content}
              </span>
            );
        }
      });
      
      return (
        <React.Fragment key={`line-${lineIndex}`}>
          {elements}
          {lineIndex < lines.length - 1 && '\n'}
        </React.Fragment>
      );
    });
  };
  
  // Apply syntax highlighting to Python code
  const highlightPython = (code) => {
    // Python keywords
    const keywords = [
      'and', 'as', 'assert', 'break', 'class', 'continue', 'def', 'del', 'elif', 
      'else', 'except', 'False', 'finally', 'for', 'from', 'global', 'if', 'import', 
      'in', 'is', 'lambda', 'None', 'nonlocal', 'not', 'or', 'pass', 'raise', 
      'return', 'True', 'try', 'while', 'with', 'yield'
    ];
    
    // Python built-ins
    const builtIns = [
      'abs', 'all', 'any', 'ascii', 'bin', 'bool', 'bytearray', 'bytes', 'callable',
      'chr', 'classmethod', 'compile', 'complex', 'delattr', 'dict', 'dir', 'divmod',
      'enumerate', 'eval', 'exec', 'filter', 'float', 'format', 'frozenset', 'getattr',
      'globals', 'hasattr', 'hash', 'help', 'hex', 'id', 'input', 'int', 'isinstance',
      'issubclass', 'iter', 'len', 'list', 'locals', 'map', 'max', 'memoryview', 'min',
      'next', 'object', 'oct', 'open', 'ord', 'pow', 'print', 'property', 'range',
      'repr', 'reversed', 'round', 'set', 'setattr', 'slice', 'sorted', 'staticmethod',
      'str', 'sum', 'super', 'tuple', 'type', 'vars', 'zip', '__import__'
    ];
    
    // Split the code into lines for processing
    const lines = code.split('\n');
    
    return lines.map((line, lineIndex) => {
      // Check for comments
      const commentIdx = line.indexOf('#');
      if (commentIdx >= 0) {
        const beforeComment = line.substring(0, commentIdx);
        const comment = line.substring(commentIdx);
        
        return (
          <React.Fragment key={`line-${lineIndex}`}>
            {highlightPythonLine(beforeComment, keywords, builtIns)}
            <span className="text-gray-500">{comment}</span>
            {lineIndex < lines.length - 1 && '\n'}
          </React.Fragment>
        );
      }
      
      return (
        <React.Fragment key={`line-${lineIndex}`}>
          {highlightPythonLine(line, keywords, builtIns)}
          {lineIndex < lines.length - 1 && '\n'}
        </React.Fragment>
      );
    });
  };
  
  // Helper function to highlight a single line of Python code
  const highlightPythonLine = (line, keywords, builtIns) => {
    // Process strings
    let inString = null;
    let tokenized = [];
    let currentToken = '';
    
    for (let i = 0; i < line.length; i++) {
      const char = line[i];
      const nextChar = line[i + 1];
      const prevChar = i > 0 ? line[i - 1] : '';
      
      // Handle strings
      if ((char === '"' || char === "'") && prevChar !== '\\') {
        if (!inString) {
          // Start of string
          if (currentToken) {
            tokenized.push(processPythonToken(currentToken, keywords, builtIns));
            currentToken = '';
          }
          inString = char === '"' ? 
            (nextChar === '"' && line[i+2] === '"' ? '"""' : '"') : 
            (nextChar === "'" && line[i+2] === "'" ? "'''" : "'");
          
          if (inString === '"""' || inString === "'''") {
            currentToken = inString;
            i += 2; // Skip the next two quotes
          } else {
            currentToken = char;
          }
        } else if (
          (inString === '"' && char === '"' && nextChar !== '"') ||
          (inString === "'" && char === "'" && nextChar !== "'") ||
          (inString === '"""' && char === '"' && nextChar === '"' && line[i+2] === '"') ||
          (inString === "'''" && char === "'" && nextChar === "'" && line[i+2] === "'")
        ) {
          // End of string
          if (inString === '"""' || inString === "'''") {
            currentToken += '"""';
            i += 2; // Skip the next two quotes
          } else {
            currentToken += char;
          }
          
          tokenized.push(
            <span key={`string-${i}`} className="text-yellow-300">
              {currentToken}
            </span>
          );
          currentToken = '';
          inString = null;
        } else {
          // String content
          currentToken += char;
        }
      } else if (inString) {
        // Inside string content
        currentToken += char;
      } else {
        // Not in string
        
        // Handle word boundaries
        if (/[\w_]/.test(char)) {
          currentToken += char;
        } else {
          // Process the current token if it exists
          if (currentToken) {
            tokenized.push(processPythonToken(currentToken, keywords, builtIns));
            currentToken = '';
          }
          
          // Special characters
          if (/[()[\]{}.,;:]/.test(char)) {
            tokenized.push(
              <span key={`punct-${i}`} className="text-gray-400">
                {char}
              </span>
            );
          } else {
            tokenized.push(<span key={`char-${i}`}>{char}</span>);
          }
        }
      }
    }
    
    // Process any remaining token
    if (currentToken) {
      if (inString) {
        tokenized.push(
          <span key={`string-end`} className="text-yellow-300">
            {currentToken}
          </span>
        );
      } else {
        tokenized.push(processPythonToken(currentToken, keywords, builtIns));
      }
    }
    
    return tokenized;
  };
  
  // Helper function to process Python tokens
  const processPythonToken = (token, keywords, builtIns) => {
    // Check if the token is a keyword, built-in, or number
    if (keywords.includes(token)) {
      return (
        <span key={`keyword-${token}`} className="text-purple-400">
          {token}
        </span>
      );
    } else if (builtIns.includes(token)) {
      return (
        <span key={`builtin-${token}`} className="text-blue-400">
          {token}
        </span>
      );
    } else if (/^[0-9]+(\.[0-9]+)?$/.test(token)) {
      return (
        <span key={`number-${token}`} className="text-green-300">
          {token}
        </span>
      );
    } else if (token === 'self') {
      return (
        <span key={`self-${token}`} className="text-orange-300">
          {token}
        </span>
      );
    }
    
    return <span key={`text-${token}`}>{token}</span>;
  };
  
  // Apply syntax highlighting to SQL code
  const highlightSQL = (code) => {
    // SQL keywords
    const keywords = [
      'SELECT', 'FROM', 'WHERE', 'AND', 'OR', 'NOT', 'ORDER BY', 'GROUP BY', 'HAVING',
      'INSERT', 'INTO', 'VALUES', 'UPDATE', 'SET', 'DELETE', 'CREATE', 'ALTER', 'DROP',
      'TABLE', 'INDEX', 'VIEW', 'PROCEDURE', 'FUNCTION', 'TRIGGER', 'DATABASE', 'SCHEMA',
      'JOIN', 'INNER', 'LEFT', 'RIGHT', 'OUTER', 'FULL', 'ON', 'AS', 'DISTINCT', 'COUNT',
      'SUM', 'AVG', 'MIN', 'MAX', 'UNION', 'ALL', 'CASE', 'WHEN', 'THEN', 'ELSE', 'END',
      'NULL', 'IS', 'IN', 'LIKE', 'BETWEEN', 'EXISTS', 'PRIMARY', 'KEY', 'FOREIGN',
      'CONSTRAINT', 'DEFAULT', 'CASCADE', 'ASC', 'DESC', 'LIMIT', 'OFFSET', 'TOP',
      'WITH', 'ROLLUP', 'CUBE', 'GRANT', 'REVOKE', 'COMMIT', 'ROLLBACK', 'SAVEPOINT',
      'TRUNCATE', 'EXPLAIN', 'REFERENCES', 'UNIQUE', 'CHECK', 'COLUMN', 'ADD', 'MODIFY'
    ];
    
    // SQL data types
    const dataTypes = [
      'INT', 'INTEGER', 'SMALLINT', 'TINYINT', 'MEDIUMINT', 'BIGINT',
      'DECIMAL', 'NUMERIC', 'FLOAT', 'DOUBLE', 'REAL',
      'BIT', 'BOOLEAN', 'SERIAL',
      'DATE', 'DATETIME', 'TIMESTAMP', 'TIME', 'YEAR',
      'CHAR', 'VARCHAR', 'BINARY', 'VARBINARY', 'BLOB', 'TEXT', 'ENUM', 'SET',
      'JSON', 'UUID', 'ARRAY', 'JSONB', 'XML', 'MONEY', 'INET'
    ];
    
    // Split the code into lines for processing
    const lines = code.split('\n');
    
    return lines.map((line, lineIndex) => {
      // Check for comments
      if (line.trim().startsWith('--') || line.trim().startsWith('#')) {
        return (
          <span key={`comment-${lineIndex}`} className="text-gray-500">
            {line}
            {lineIndex < lines.length - 1 && '\n'}
          </span>
        );
      }
      
      const commentIdx = line.indexOf('--');
      if (commentIdx >= 0) {
        const beforeComment = line.substring(0, commentIdx);
        const comment = line.substring(commentIdx);
        
        return (
          <React.Fragment key={`line-${lineIndex}`}>
            {highlightSQLLine(beforeComment, keywords, dataTypes)}
            <span className="text-gray-500">{comment}</span>
            {lineIndex < lines.length - 1 && '\n'}
          </React.Fragment>
        );
      }
      
      return (
        <React.Fragment key={`line-${lineIndex}`}>
          {highlightSQLLine(line, keywords, dataTypes)}
          {lineIndex < lines.length - 1 && '\n'}
        </React.Fragment>
      );
    });
  };
  
  // Helper function to highlight a single line of SQL code
  const highlightSQLLine = (line, keywords, dataTypes) => {
    // Process strings
    let inString = null;
    let tokenized = [];
    let currentToken = '';
    
    for (let i = 0; i < line.length; i++) {
      const char = line[i];
      const prevChar = i > 0 ? line[i - 1] : '';
      
      // Handle strings
      if ((char === "'" || char === '"') && prevChar !== '\\') {
        if (!inString) {
          // Start of string
          if (currentToken) {
            tokenized.push(processSQLToken(currentToken, keywords, dataTypes));
            currentToken = '';
          }
          inString = char;
          currentToken = char;
        } else if (inString === char) {
          // End of string
          currentToken += char;
          tokenized.push(
            <span key={`string-${i}`} className="text-yellow-300">
              {currentToken}
            </span>
          );
          currentToken = '';
          inString = null;
        } else {
          // String content
          currentToken += char;
        }
      } else if (inString) {
        // Inside string content
        currentToken += char;
      } else {
        // Not in string
        
        // Handle word boundaries and SQL specific syntax
        if (/[a-zA-Z0-9_]/.test(char)) {
          currentToken += char;
        } else {
          // Process the current token if it exists
          if (currentToken) {
            tokenized.push(processSQLToken(currentToken, keywords, dataTypes));
            currentToken = '';
          }
          
          // Special characters and punctuation
          if (/[.,;:()\[\]{}=<>+\-*/%]/.test(char)) {
            tokenized.push(
              <span key={`punct-${i}`} className="text-gray-400">
                {char}
              </span>
            );
          } else {
            tokenized.push(<span key={`char-${i}`}>{char}</span>);
          }
        }
      }
    }
    
    // Process any remaining token
    if (currentToken) {
      if (inString) {
        tokenized.push(
          <span key={`string-end`} className="text-yellow-300">
            {currentToken}
          </span>
        );
      } else {
        tokenized.push(processSQLToken(currentToken, keywords, dataTypes));
      }
    }
    
    return tokenized;
  };
  
  // Helper function to process SQL tokens
  const processSQLToken = (token, keywords, dataTypes) => {
    // Check if the token is a keyword (case-insensitive)
    if (keywords.some(kw => kw.toUpperCase() === token.toUpperCase())) {
      return (
        <span key={`keyword-${token}`} className="text-purple-400">
          {token}
        </span>
      );
    } 
    // Check if the token is a data type (case-insensitive)
    else if (dataTypes.some(dt => dt.toUpperCase() === token.toUpperCase())) {
      return (
        <span key={`datatype-${token}`} className="text-blue-400">
          {token}
        </span>
      );
    } 
    // Check if the token is a number
    else if (/^[0-9]+(\.[0-9]+)?$/.test(token)) {
      return (
        <span key={`number-${token}`} className="text-green-300">
          {token}
        </span>
      );
    }
    // Check if token is a boolean or NULL
    else if (['TRUE', 'FALSE', 'NULL'].includes(token.toUpperCase())) {
      return (
        <span key={`constant-${token}`} className="text-orange-300">
          {token}
        </span>
      );
    }
    
    return <span key={`text-${token}`}>{token}</span>;
  };
  
  // Parse and render code blocks with syntax highlighting
  const renderCodeBlock = (code, language = null) => {
    const detectedLanguage = language || detectLanguage(code);
    
    // Apply syntax highlighting based on language
    const highlightedCode = (() => {
      switch (detectedLanguage) {
        case 'javascript':
        case 'js':
          return highlightJavaScript(code);
        case 'jsx':
          return highlightJavaScript(code); // Use the same highlighter for JSX
        case 'css':
          return highlightCSS(code);
        case 'html':
          return highlightHTML(code);
        case 'python':
        case 'py':
          return highlightPython(code);
        case 'sql':
          return highlightSQL(code);
        default:
          return code;
      }
    })();
    
    return (
      <div className="code-snippet mb-4 rounded-lg overflow-hidden border border-gray-700 selectable-text">
        {/* Code header with language and actions */}
        <div className="flex justify-between items-center bg-gray-800 px-3 py-1 border-b border-gray-700">
          <div className="flex items-center">
            <Code2 size={14} className="mr-2 text-gray-400" />
            <span className="text-xs font-medium text-gray-300">
              {detectedLanguage.charAt(0).toUpperCase() + detectedLanguage.slice(1)}
            </span>
          </div>
          <div className="flex space-x-1">
            <button 
              className="text-gray-400 hover:text-white p-1 rounded hover:bg-gray-700 transition-colors"
              onClick={() => navigator.clipboard.writeText(code)}
              aria-label="Copy code"
              title="Copy code"
            >
              <Copy size={12} />
            </button>
          </div>
        </div>
        
        {/* Notion-like code content with pre/code */}
        <div className="bg-gray-900 p-3 overflow-x-auto">
          <pre className="font-mono text-sm whitespace-pre text-gray-300 selectable-text">
            <code>{highlightedCode}</code>
          </pre>
        </div>
      </div>
    );
  };
  
  // Parse and render callout blocks
  const renderCallout = (content, type = 'info') => {
    // Determine icon and styling based on callout type
    let Icon = Info;
    let iconColor = 'text-blue-400';
    let borderColor = 'border-blue-500';
    let bgColor = 'bg-blue-500/10';
    
    if (type === 'warning') {
      Icon = AlertTriangle;
      iconColor = 'text-yellow-400';
      borderColor = 'border-yellow-500';
      bgColor = 'bg-yellow-500/10';
    } else if (type === 'error') {
      Icon = AlertCircle;
      iconColor = 'text-red-400';
      borderColor = 'border-red-500';
      bgColor = 'bg-red-500/10';
    } else if (type === 'success') {
      Icon = CheckCircle;
      iconColor = 'text-green-400';
      borderColor = 'border-green-500';
      bgColor = 'bg-green-500/10';
    }
    
    return (
      <div className={`flex p-2 mb-3 rounded-md border-l-4 ${borderColor} ${bgColor} selectable-text`}>
        <div className={`mr-2 ${iconColor}`}>
          <Icon size={16} />
        </div>
        <div className="text-sm selectable-text">
          {parseInlineMarkdown(content)}
        </div>
      </div>
    );
  };
  
  // Parse and render blockquotes
  const renderBlockquote = (content) => {
    return (
      <blockquote className="border-l-4 border-gray-500 pl-3 py-1 mb-3 italic bg-gray-800/20 rounded-r-md text-sm selectable-text">
        {parseInlineMarkdown(content)}
      </blockquote>
    );
  };
  
  // Parse and render headings
  const renderHeading = (content, level) => {
    const headingKey = `heading-${level}-${content.substring(0, 10)}`;
    switch (level) {
      case 1:
        return <h1 key={headingKey} className="text-2xl font-bold mt-4 mb-2 selectable-text">{parseInlineMarkdown(content)}</h1>;
      case 2:
        return <h2 key={headingKey} className="text-xl font-bold mt-3 mb-2 selectable-text">{parseInlineMarkdown(content)}</h2>;
      case 3:
        return <h3 key={headingKey} className="text-lg font-semibold mt-2 mb-1 selectable-text">{parseInlineMarkdown(content)}</h3>;
      case 4:
        return <h4 key={headingKey} className="text-base font-semibold mt-2 mb-1 selectable-text">{parseInlineMarkdown(content)}</h4>;
      default:
        return <h5 key={headingKey} className="text-sm font-semibold mt-2 mb-1 selectable-text">{parseInlineMarkdown(content)}</h5>;
    }
  };
  
  // Parse and render lists
  const renderList = (items, ordered = false, lineNumber = 0) => {
    const ListTag = ordered ? 'ol' : 'ul';
    const listId = `list-${lineNumber}-${ordered ? 'ol' : 'ul'}`;
    
    return (
      <ListTag key={listId} className="mb-3 pl-5 selectable-text">
        {items.map((item, index) => {
          // Create a more unique key using item content
          const itemKey = `${listId}-item-${index}-${item.substring(0, 10).replace(/\s+/g, '-')}`;
          return (
            <li key={itemKey} className="mb-1 text-base selectable-text">
              {parseInlineMarkdown(item)}
            </li>
          );
        })}
      </ListTag>
    );
  };
  
  // Parse inline markdown elements (bold, italic, code, links, etc.)
  const parseInlineMarkdown = (text) => {
    if (!text) return null;
    
    // Create a temporary structure to track the formatted regions
    let regions = [];
    let result = [];
    
    // Helper function to add a formatted region
    const addRegion = (start, end, type, content, metadata = null) => {
      regions.push({ start, end, type, content, metadata });
    };
    
    // Find all inline code blocks first (to protect them)
    const codeRegex = /`([^`]+)`/g;
    let codeMatch;
    while ((codeMatch = codeRegex.exec(text)) !== null) {
      addRegion(codeMatch.index, codeMatch.index + codeMatch[0].length, 'code', codeMatch[1]);
    }
    
    // Find image links
    const imageRegex = /!\[(.*?)\]\((.*?)\)/g;
    let imageMatch;
    while ((imageMatch = imageRegex.exec(text)) !== null) {
      // Check if this region overlaps with an existing code region
      const overlaps = regions.some(region => 
        (imageMatch.index >= region.start && imageMatch.index < region.end) ||
        (imageMatch.index + imageMatch[0].length > region.start && imageMatch.index + imageMatch[0].length <= region.end)
      );
      
      if (!overlaps) {
        // The alt text is in imageMatch[1] and the URL is in imageMatch[2]
        addRegion(imageMatch.index, imageMatch.index + imageMatch[0].length, 'image', imageMatch[1], imageMatch[2]);
      }
    }
    
    // Find normal links
    const linkRegex = /\[([^\]]+)\]\(([^)]+)\)/g;
    let linkMatch;
    while ((linkMatch = linkRegex.exec(text)) !== null) {
      // Check if this region overlaps with an existing region
      const overlaps = regions.some(region => 
        (linkMatch.index >= region.start && linkMatch.index < region.end) ||
        (linkMatch.index + linkMatch[0].length > region.start && linkMatch.index + linkMatch[0].length <= region.end)
      );
      
      if (!overlaps) {
        addRegion(linkMatch.index, linkMatch.index + linkMatch[0].length, 'link', linkMatch[1], linkMatch[2]);
      }
    }
    
    // Find bold text
    const boldRegex = /(\*\*|__)([^\*_]+?)\1/g;
    let boldMatch;
    while ((boldMatch = boldRegex.exec(text)) !== null) {
      // Check if this region overlaps with an existing code region
      const overlaps = regions.some(region => 
        (boldMatch.index >= region.start && boldMatch.index < region.end) ||
        (boldMatch.index + boldMatch[0].length > region.start && boldMatch.index + boldMatch[0].length <= region.end)
      );
      
      if (!overlaps) {
        addRegion(boldMatch.index, boldMatch.index + boldMatch[0].length, 'bold', boldMatch[2]);
      }
    }
    
    // Find italic text
    const italicRegex = /(\*|_)([^\*_]+?)\1/g;
    let italicMatch;
    while ((italicMatch = italicRegex.exec(text)) !== null) {
      // Check if this region overlaps with existing regions
      const overlaps = regions.some(region => 
        (italicMatch.index >= region.start && italicMatch.index < region.end) ||
        (italicMatch.index + italicMatch[0].length > region.start && italicMatch.index + italicMatch[0].length <= region.end)
      );
      
      if (!overlaps) {
        addRegion(italicMatch.index, italicMatch.index + italicMatch[0].length, 'italic', italicMatch[2]);
      }
    }
    
    // Find highlighted text
    const highlightRegex = /==([^=]+?)==/g;
    let highlightMatch;
    while ((highlightMatch = highlightRegex.exec(text)) !== null) {
      // Check if this region overlaps with existing regions
      const overlaps = regions.some(region => 
        (highlightMatch.index >= region.start && highlightMatch.index < region.end) ||
        (highlightMatch.index + highlightMatch[0].length > region.start && highlightMatch.index + highlightMatch[0].length <= region.end)
      );
      
      if (!overlaps) {
        addRegion(highlightMatch.index, highlightMatch.index + highlightMatch[0].length, 'highlight', highlightMatch[1]);
      }
    }
    
    // Find colored text
    const colorRegex = /\{#([0-9a-f]{6}):([^}]+?)\}/g;
    let colorMatch;
    while ((colorMatch = colorRegex.exec(text)) !== null) {
      // Check if this region overlaps with existing regions
      const overlaps = regions.some(region => 
        (colorMatch.index >= region.start && colorMatch.index < region.end) ||
        (colorMatch.index + colorMatch[0].length > region.start && colorMatch.index + colorMatch[0].length <= region.end)
      );
      
      if (!overlaps) {
        addRegion(colorMatch.index, colorMatch.index + colorMatch[0].length, 'color', colorMatch[2], colorMatch[1]);
      }
    }
    
    // Sort regions by start position
    regions.sort((a, b) => a.start - b.start);
    
    // Build the result by iterating through the text
    let lastIdx = 0;
    for (const region of regions) {
      // Add text before this region
      if (region.start > lastIdx) {
        const textContent = text.substring(lastIdx, region.start);
        result.push(<React.Fragment key={`text-${lastIdx}-${textContent.substring(0, 5).replace(/\s+/g, '')}`}>{textContent}</React.Fragment>);
      }
      
      // Add the formatted content
      switch (region.type) {
        case 'code':
          result.push(
            <code key={`code-${region.start}-${region.content.substring(0, 5).replace(/\s+/g, '')}`} className="bg-gray-800 px-1 py-0.5 rounded font-mono text-sm">
              {region.content}
            </code>
          );
          break;
        case 'image':
          result.push(
            <img 
              key={`img-${region.start}-${region.content.substring(0, 5).replace(/\s+/g, '')}`}
              src={region.metadata}
              alt={region.content || 'Image'}
              className="my-4 max-w-full rounded-lg image-display"
              style={{ maxHeight: '500px' }}
            />
          );
          break;
        case 'link':
          result.push(
            <a 
              key={`link-${region.start}-${region.content.substring(0, 5).replace(/\s+/g, '')}`}
              href={region.metadata}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-400 hover:text-blue-300 underline"
            >
              {region.content}
            </a>
          );
          break;
        case 'bold':
          result.push(
            <strong key={`bold-${region.start}-${region.content.substring(0, 5).replace(/\s+/g, '')}`} className="font-bold text-white selectable-text">
              {region.content}
            </strong>
          );
          break;
        case 'italic':
          result.push(
            <em key={`italic-${region.start}-${region.content.substring(0, 5).replace(/\s+/g, '')}`} className="italic text-blue-300 selectable-text">
              {region.content}
            </em>
          );
          break;
        case 'highlight':
          result.push(
            <mark key={`highlight-${region.start}-${region.content.substring(0, 5).replace(/\s+/g, '')}`} className="bg-yellow-500/30 text-yellow-100 px-1 rounded selectable-text">
              {region.content}
            </mark>
          );
          break;
        case 'color':
          result.push(
            <span 
              key={`color-${region.start}-${region.content.substring(0, 5).replace(/\s+/g, '')}`} 
              style={{ color: `#${region.metadata}` }}
              className="selectable-text"
            >
              {region.content}
            </span>
          );
          break;
      }
      
      lastIdx = region.end;
    }
    
    // Add any text after the last region
    if (lastIdx < text.length) {
      const textContent = text.substring(lastIdx);
      result.push(<React.Fragment key={`text-${lastIdx}-${textContent.substring(0, 5).replace(/\s+/g, '')}`}>{textContent}</React.Fragment>);
    }
    
    return result.length > 0 ? result : text;
  };
  
  // Parse and render tables
  const renderTable = (headerRow, bodyRows) => {
    return (
      <div className="overflow-x-auto mb-4">
        <table className="w-full border-collapse border border-gray-700 selectable-text">
          <thead className="bg-gray-800">
            <tr>
              {headerRow.map((cell, cellIndex) => (
                <th 
                  key={`th-${cellIndex}`} 
                  className="border border-gray-700 px-3 py-2 text-left text-sm font-medium"
                >
                  {parseInlineMarkdown(cell.trim())}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {bodyRows.map((row, rowIndex) => (
              <tr key={`tr-${rowIndex}`} className={rowIndex % 2 === 0 ? 'bg-gray-900' : 'bg-gray-800/50'}>
                {row.map((cell, cellIndex) => (
                  <td 
                    key={`td-${rowIndex}-${cellIndex}`} 
                    className="border border-gray-700 px-3 py-2 text-sm"
                  >
                    {parseInlineMarkdown(cell.trim())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  // Parse table rows from markdown format
  const parseTableRows = (lines, startIndex) => {
    const rows = [];
    let currentIndex = startIndex;
    let headerRow = null;
    
    // Extract the header row
    if (currentIndex < lines.length && lines[currentIndex].includes('|')) {
      const headerCells = lines[currentIndex]
        .split('|')
        .filter((cell, i, arr) => i !== 0 && i !== arr.length - 1 || cell.trim() !== '');  // Filter out empty cells at the start/end
      
      headerRow = headerCells;
      currentIndex++;
    }
    
    // Skip the separator line (e.g., |------|------|)
    if (currentIndex < lines.length && lines[currentIndex].match(/^\|[\s\-:|]*\|$/)) {
      currentIndex++;
    }
    
    // Extract body rows
    while (currentIndex < lines.length && lines[currentIndex].includes('|')) {
      const cells = lines[currentIndex]
        .split('|')
        .filter((cell, i, arr) => i !== 0 && i !== arr.length - 1 || cell.trim() !== '');  // Filter out empty cells at the start/end
      
      rows.push(cells);
      currentIndex++;
    }
    
    return {
      headerRow,
      bodyRows: rows,
      endIndex: currentIndex
    };
  };
  
  // Main parsing function for the entire markdown content
  const parseMarkdown = (markdownText) => {
    if (!markdownText) return null;
    
    // Process custom syntax first
    markdownText = processCustomSyntax(markdownText);
    
    // Split the content by lines
    const lines = markdownText.split('\n');
    const parsedContent = [];
    let currentIndex = 0;
    
    // Process line by line
    while (currentIndex < lines.length) {
      const line = lines[currentIndex];
      
      // Skip empty lines
      if (!line.trim()) {
        currentIndex++;
        continue;
      }
      
      // Check for standalone image
      const imageMatch = line.match(/^!\[(.*?)\]\((.*?)\)$/);
      if (imageMatch) {
        const altText = imageMatch[1] || "Image";
        const imageUrl = imageMatch[2];
        
        parsedContent.push(
          <div key={`img-block-${currentIndex}`} className="my-4 flex justify-center">
            <img 
              src={imageUrl} 
              alt={altText} 
              className="max-w-full rounded-lg image-display" 
              style={{ maxHeight: '500px' }}
            />
          </div>
        );
        
        currentIndex++;
        continue;
      }
      
      // Check for headings (# Heading)
      const headingMatch = line.match(/^(#{1,6})\s+(.+)$/);
      if (headingMatch) {
        parsedContent.push(renderHeading(headingMatch[2], headingMatch[1].length));
        currentIndex++;
        continue;
      }
      
      // Check for code blocks (```language)
      if (line.startsWith('```')) {
        const language = line.slice(3).trim();
        let codeContent = '';
        let endIndex = currentIndex + 1;
        
        // Find the closing code block
        while (endIndex < lines.length && !lines[endIndex].startsWith('```')) {
          codeContent += lines[endIndex] + '\n';
          endIndex++;
        }
        
        parsedContent.push(renderCodeBlock(codeContent, language || null));
        currentIndex = endIndex + 1;
        continue;
      }
      
      // Check for callout blocks (:::type)
      if (line.startsWith(':::')) {
        const calloutType = line.slice(3).trim() || 'info';
        let calloutContent = '';
        let endIndex = currentIndex + 1;
        
        // Find the closing callout block
        while (endIndex < lines.length && !lines[endIndex].startsWith(':::')) {
          calloutContent += lines[endIndex] + '\n';
          endIndex++;
        }
        
        parsedContent.push(renderCallout(calloutContent, calloutType));
        currentIndex = endIndex + 1;
        continue;
      }
      
      // Check for blockquotes (> Quote)
      if (line.startsWith('>')) {
        let blockquoteContent = line.slice(1).trim();
        let endIndex = currentIndex + 1;
        
        // Find multi-line blockquotes
        while (endIndex < lines.length && lines[endIndex].startsWith('>')) {
          blockquoteContent += '\n' + lines[endIndex].slice(1).trim();
          endIndex++;
        }
        
        parsedContent.push(renderBlockquote(blockquoteContent));
        currentIndex = endIndex;
        continue;
      }
      
      // Check for tables (starting with |)
      if (line.startsWith('|') && line.endsWith('|')) {
        // Parse table rows
        const { headerRow, bodyRows, endIndex } = parseTableRows(lines, currentIndex);
        
        if (headerRow && bodyRows.length > 0) {
          parsedContent.push(renderTable(headerRow, bodyRows));
        }
        
        currentIndex = endIndex;
        continue;
      }
      
      // Check for unordered lists (- item or * item)
      if (line.match(/^\s*[-*]\s+(.+)$/)) {
        const listItems = [];
        let endIndex = currentIndex;
        
        // Collect all list items
        while (endIndex < lines.length && lines[endIndex].match(/^\s*[-*]\s+(.+)$/)) {
          listItems.push(lines[endIndex].replace(/^\s*[-*]\s+/, ''));
          endIndex++;
        }
        
        parsedContent.push(renderList(listItems, false, currentIndex));
        currentIndex = endIndex;
        continue;
      }
      
      // Check for ordered lists (1. item)
      if (line.match(/^\s*\d+\.\s+(.+)$/)) {
        const listItems = [];
        let endIndex = currentIndex;
        
        // Collect all list items
        while (endIndex < lines.length && lines[endIndex].match(/^\s*\d+\.\s+(.+)$/)) {
          listItems.push(lines[endIndex].replace(/^\s*\d+\.\s+/, ''));
          endIndex++;
        }
        
        parsedContent.push(renderList(listItems, true, currentIndex));
        currentIndex = endIndex;
        continue;
      }
      
      // Regular paragraph
      let paragraphContent = line;
      let endIndex = currentIndex + 1;
      
      // Collect multi-line paragraphs
      while (
        endIndex < lines.length && 
        lines[endIndex].trim() !== '' && 
        !lines[endIndex].match(/^(#{1,6}|```|:::|\>|\||\s*[-*]|\s*\d+\.)/)
      ) {
        paragraphContent += ' ' + lines[endIndex];
        endIndex++;
      }
      
      parsedContent.push(
        <p key={`p-${currentIndex}`} className="mb-2 text-base leading-relaxed selectable-text">
          {parseInlineMarkdown(paragraphContent)}
        </p>
      );
      
      currentIndex = endIndex;
    }
    
    return parsedContent;
  };
  
  // Process custom syntax extensions
  const processCustomSyntax = (text) => {
    if (!text) return '';
    
    // We don't need this function anymore as we're handling everything in parseInlineMarkdown
    // But keeping it for emoji processing
    
    // Replace emoji shortcodes with actual emojis
    const emojiMap = {
      ':rocket:': '🚀',
      ':fire:': '🔥',
      ':smile:': '😊',
      ':heart:': '❤️',
      ':check:': '✅',
      ':warning:': '⚠️',
      ':bulb:': '💡',
      ':star:': '⭐',
    };
    
    let processed = text;
    Object.entries(emojiMap).forEach(([shortcode, emoji]) => {
      processed = processed.replace(new RegExp(shortcode, 'g'), emoji);
    });
    
    return processed;
  };
  
  // Parse the markdown content
  const parsedContent = parseMarkdown(content);
  
  return (
    <div className="custom-markdown-content text-left">
      {parsedContent}
    </div>
  );
};

export default CustomMarkdown; 