import React from 'react';

/**
 * ImageComponent - Displays an image within a slide
 * 
 * This component handles the rendering of images with captions.
 */
const ImageComponent = ({ data }) => {
  // Extract props from data object
  const src = data?.src || data?.url;
  const alt = data?.alt || 'Image';
  const caption = data?.caption;
  const width = data?.width || 'auto';
  const height = data?.height || 'auto';
  
  if (!src) return null;
  
  return (
    <div className="image-component my-4">
      <img 
        src={src} 
        alt={alt} 
        className="max-w-full rounded-md shadow-md"
        style={{ width, height }}
      />
      {caption && (
        <p className="text-sm text-gray-500 mt-2 text-center">{caption}</p>
      )}
    </div>
  );
};

export default ImageComponent; 