import React, { useState, useEffect, useRef } from 'react';
import { Send, Loader, ArrowLeft, CheckCircle, AlertTriangle } from 'lucide-react';

/**
 * InputComponent - Handles free-form text input from users
 * 
 * This component renders a text input or textarea with submission functionality,
 * and displays feedback on the user's response.
 */
const InputComponent = ({ data, onSubmit }) => {
  // Extract props from data object
  const question = data?.question || '';
  const placeholder = data?.placeholder || 'Type your answer here...';
  const multiline = data?.multiline !== false;
  const onInputSubmit = onSubmit;
  
  const [input, setInput] = useState('');
  const [feedback, setFeedback] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [activeStep, setActiveStep] = useState(0); // 0: Input, 1: Feedback
  const inputRef = useRef(null);
  
  // Reset state when the question changes
  useEffect(() => {
    setInput('');
    setFeedback(null);
    setIsSubmitting(false);
    setActiveStep(0);
  }, [question]);
  
  // Focus input when component mounts
  useEffect(() => {
    if (inputRef.current && activeStep === 0) {
      inputRef.current.focus();
    }
  }, [activeStep]);
  
  // Handle input changes
  const handleInputChange = (e) => {
    setInput(e.target.value);
  };
  
  // Handle form submission
  const handleSubmit = async (e) => {
    if (e) e.preventDefault();
    
    if (!input.trim() || isSubmitting) return;
    
    setIsSubmitting(true);
    
    try {
      // If there's a submission handler, use it
      if (onInputSubmit) {
        const result = await onInputSubmit(input);
        
        // If the handler returned feedback, use it
        if (result) {
          setFeedback(result);
          setActiveStep(1);
        }
      } else {
        // Default simple feedback
        setFeedback({
          isCorrect: true,
          feedback: 'Your response has been recorded.'
        });
        setActiveStep(1);
      }
    } catch (error) {
      console.error('Error submitting input:', error);
      setFeedback({
        isCorrect: false,
        feedback: 'There was an error processing your response. Please try again.'
      });
      setActiveStep(1);
    } finally {
      setIsSubmitting(false);
    }
  };
  
  // Handle back button click
  const handleBack = () => {
    setActiveStep(0);
  };
  
  // Render the input step
  const renderInputStep = () => (
    <form onSubmit={handleSubmit} className="w-full">
      <div className="mb-4">
        <label className="block text-white text-lg font-medium mb-2">
          {question}
        </label>
        
        {multiline ? (
          <textarea
            ref={inputRef}
            className="w-full bg-gray-800 border border-gray-700 rounded-lg px-4 py-3 text-white placeholder-gray-500 focus:ring-2 focus:ring-blue-500 focus:border-transparent resize-none transition-colors"
            rows={4}
            value={input}
            onChange={handleInputChange}
            placeholder={placeholder}
          />
        ) : (
          <input
            ref={inputRef}
            type="text"
            className="w-full bg-gray-800 border border-gray-700 rounded-lg px-4 py-3 text-white placeholder-gray-500 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-colors"
            value={input}
            onChange={handleInputChange}
            placeholder={placeholder}
          />
        )}
      </div>
      
      <div className="flex justify-end">
        <button
          type="submit"
          disabled={!input.trim() || isSubmitting}
          className={`px-4 py-2 rounded-lg flex items-center ${
            !input.trim() || isSubmitting
              ? 'bg-gray-700 text-gray-400 cursor-not-allowed'
              : 'bg-blue-600 hover:bg-blue-700 text-white transition-colors'
          }`}
        >
          {isSubmitting ? (
            <>
              <Loader size={16} className="mr-2 animate-spin" />
              Processing...
            </>
          ) : (
            <>
              Submit
              <Send size={16} className="ml-2" />
            </>
          )}
        </button>
      </div>
    </form>
  );
  
  // Render the feedback step
  const renderFeedbackStep = () => {
    if (!feedback) return null;
    
    const isCorrect = feedback.isCorrect || false;
    
    return (
      <div className="w-full">
        <div className={`p-4 rounded-lg mb-4 ${
          isCorrect 
            ? 'bg-green-900/30 border border-green-600' 
            : 'bg-red-900/30 border border-red-600'
        }`}>
          <div className="flex items-center mb-2">
            {isCorrect ? (
              <CheckCircle size={20} className="text-green-500 mr-2" />
            ) : (
              <AlertTriangle size={20} className="text-red-500 mr-2" />
            )}
            <h3 className={`font-bold ${isCorrect ? 'text-green-400' : 'text-red-400'}`}>
              {isCorrect ? 'Good job!' : 'Try again'}
            </h3>
          </div>
          
          <div className="text-white">
            {feedback.feedback}
          </div>
          
          {feedback.expectedAnswer && (
            <div className="mt-3 pt-3 border-t border-gray-700">
              <h4 className="text-gray-300 font-medium mb-1">Sample answer:</h4>
              <p className="text-gray-300 italic">{feedback.expectedAnswer}</p>
            </div>
          )}
        </div>
        
        <div className="mt-4 flex justify-between">
          <button
            type="button"
            onClick={handleBack}
            className="px-4 py-2 bg-gray-800 hover:bg-gray-700 text-white rounded-lg transition-colors flex items-center"
          >
            <ArrowLeft size={16} className="mr-2" />
            Edit Answer
          </button>
          
          <button
            type="button"
            onClick={() => {
              setInput('');
              setFeedback(null);
              setActiveStep(0);
            }}
            className="px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-lg transition-colors"
          >
            New Response
          </button>
        </div>
      </div>
    );
  };
  
  return (
    <div className="input-component w-full my-4">
      {activeStep === 0 ? renderInputStep() : renderFeedbackStep()}
    </div>
  );
};

export default InputComponent; 