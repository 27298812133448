/**
 * Interactive Lessons Main Export
 * 
 * This file exports all components and utilities for the interactive lessons system.
 */

// Import for default export
import * as core from './core';

// Export directly from subdirectories
export * from './core';

// UI Components 
export { MobileNavigation, NavigationButtons, PhaseLabel, SlideContainer, getPhaseIcon } from './ui/CommonUI';

// Context and Providers
export { useLanguage, LanguageProvider } from './context/LanguageContext';
export { useTextSelection, TextSelectionProvider } from './context/TextSelectionContext';

// Utilities
export { getTranslation } from './utils/translations';
export { createSlug, getIdFromSlug } from './utils/urlUtils';

// Hooks
export { default as useAutoFocus } from './hooks/useAutoFocus';

// Course components - for backward compatibility
export { default as CourseSelector } from './course/CourseSelector';
export { default as CourseCreationSelector } from './course/CourseCreationSelector';
export { default as CoursePlayer } from './course/CoursePlayer';
export { default as CourseCreator } from './course/CourseCreator';

// Let default export be all components
export default core;