import { supabase } from './supabase';

/**
 * Check if the current user session is valid
 * @returns {Promise<boolean>} - True if the session is valid, false otherwise
 */
export const isSessionValid = async () => {
  try {
    const { data, error } = await supabase.auth.getSession();
    
    if (error) {
      console.warn('Session validation error:', error);
      return false;
    }
    
    // Check if we have a session and it's not expired
    return !!(data.session && data.session.expires_at > Date.now() / 1000);
  } catch (err) {
    console.warn('Error checking session validity:', err);
    return false;
  }
};

/**
 * Log user activity to Supabase for the review feature
 * @param {string} userId - The user's ID
 * @param {string} activityType - One of 'ask_ai_question', 'user_input_answer', 'selection_quiz_answer'
 * @param {object} activityDetails - Details about the activity, varies by type
 * @returns {Promise} - The result of the database insertion
 */
export const logUserActivity = async (userId, activityType, activityDetails) => {
  if (!userId) {
    console.warn('Cannot log activity: No user ID provided');
    return { error: 'No user ID provided' };
  }

  // First check if the session is valid
  const sessionValid = await isSessionValid();
  if (!sessionValid) {
    console.warn('Cannot log activity: Session is not valid');
    return { error: 'Invalid session', silent: true };
  }

  let retries = 2;
  while (retries >= 0) {
    try {
      const { data, error } = await supabase
        .from('user_activity')
        .insert([
          {
            user_id: userId,
            activity_type: activityType,
            activity_details: activityDetails
          }
        ]);

      if (error) {
        // Check for common errors that indicate permissions/auth issues
        if (error.code === '42501' || error.code === '403' || error.status === 403) {
          console.warn('Permission denied while logging user activity. The activity will not be recorded:', error);
          return { error, silent: true }; // Return silently to prevent UI disruption
        }
        
        if (retries > 0) {
          console.warn(`Error logging user activity, retrying... (${retries} attempts left)`, error);
          retries--;
          await new Promise(resolve => setTimeout(resolve, 1000)); // Wait 1 second before retry
          continue;
        } else {
          console.warn('All retries failed when logging user activity:', error);
          // Return but don't throw - we don't want activity logging to break the app
          return { error, silent: true };
        }
      }

      return { data };
    } catch (err) {
      if (retries > 0) {
        console.warn(`Exception logging user activity, retrying... (${retries} attempts left)`, err);
        retries--;
        await new Promise(resolve => setTimeout(resolve, 1000)); // Wait 1 second before retry
      } else {
        console.warn('All retries failed when handling exception in user activity:', err);
        // Return but don't throw - we don't want activity logging to break the app
        return { error: err.message, silent: true };
      }
    }
  }
  
  // We should never reach here, but just in case
  return { error: 'Unknown error in logUserActivity', silent: true };
};

/**
 * Get user activity for review
 * @param {string} userId - The user's ID
 * @param {Array<string>} activityTypes - Array of activity types to retrieve
 * @param {number} limit - Maximum number of records to retrieve
 * @returns {Promise} - The activity data and any errors
 */
export const getUserActivities = async (userId, activityTypes = ['ask_ai_question', 'user_input_answer', 'selection_quiz_answer'], limit = 50) => {
  if (!userId) {
    console.error('Cannot get activities: No user ID provided');
    return { error: 'No user ID provided' };
  }

  try {
    const { data, error } = await supabase
      .from('user_activity')
      .select('*')
      .eq('user_id', userId)
      .in('activity_type', activityTypes)
      .order('created_at', { ascending: false })
      .limit(limit);

    if (error) {
      console.error('Error getting user activities:', error);
      return { error };
    }

    return { data };
  } catch (err) {
    console.error('Exception getting user activities:', err);
    return { error: err.message };
  }
};

/**
 * Get incorrect answers for review
 * @param {string} userId - The user's ID
 * @param {number} limit - Maximum number of records to retrieve
 * @returns {Promise} - The activity data and any errors
 */
export const getIncorrectAnswers = async (userId, limit = 50) => {
  if (!userId) {
    console.error('Cannot get incorrect answers: No user ID provided');
    return { error: 'No user ID provided' };
  }

  try {
    const { data, error } = await supabase
      .from('user_activity')
      .select('*')
      .eq('user_id', userId)
      .in('activity_type', ['user_input_answer', 'selection_quiz_answer'])
      .filter('activity_details->is_correct', 'eq', false)
      .order('created_at', { ascending: false })
      .limit(limit);

    if (error) {
      console.error('Error getting incorrect answers:', error);
      return { error };
    }

    return { data };
  } catch (err) {
    console.error('Exception getting incorrect answers:', err);
    return { error: err.message };
  }
};