import { supabase } from '../auth/supabase';
import { createSlug } from '../components/interactivelessons/utils/urlUtils';
import { 
  invalidateLessonCache, 
  invalidateLessonCachesByCourse,
  CACHE_KEYS
} from './cachingService';

// Constants
const TABLES = {
  COURSES: 'courses',
  LESSONS: 'lessons'
};

/**
 * Get all lessons for a course
 * @param {string} courseId - Course ID
 * @returns {Promise<Array>} - Array of lesson objects
 */
export const getLessonsByCourse = async (courseId) => {
  try {
    // Check cache first
    const cacheKey = CACHE_KEYS.LESSONS_BY_COURSE(courseId);
    const cachedLessons = localStorage.getItem(cacheKey);
    
    if (cachedLessons) {
      try {
        const parsedData = JSON.parse(cachedLessons);
        const cacheTimestamp = parsedData._cachedAt || 0;
        const cacheAge = Date.now() - cacheTimestamp;
        
        // Use cache if it's less than 5 minutes old
        if (cacheAge < 5 * 60 * 1000) {
          console.log(`Using cached lessons for course ${courseId}`);
          return parsedData.lessons;
        } else {
          console.log('Cache expired, fetching fresh lessons data');
        }
      } catch (e) {
        console.error('Error parsing cached lessons:', e);
      }
    }
    
    // Cache miss or expired, fetch from Supabase
    const { data: lessons, error } = await supabase
      .from(TABLES.LESSONS)
      .select('*')
      .eq('course_id', courseId)
      .order('order', { ascending: true });
    
    if (error) {
      throw error;
    }
    
    // Format and sort lessons
    const formattedLessons = lessons.map(lesson => ({
      id: lesson.id,
      ...lesson
    }));
    
    // Cache the lessons
    try {
      localStorage.setItem(cacheKey, JSON.stringify({
        lessons: formattedLessons,
        _cachedAt: Date.now()
      }));
    } catch (cacheError) {
      console.error('Error caching lessons:', cacheError);
    }
    
    return formattedLessons;
  } catch (error) {
    console.error(`Error fetching lessons for course ${courseId}:`, error);
    throw error;
  }
};

/**
 * Get a single lesson by ID
 * @param {string} lessonId - The lesson ID
 * @returns {Promise<Object>} - Lesson object
 */
export const getLessonById = async (lessonId) => {
  try {
    // Check cache first
    const cacheKey = CACHE_KEYS.LESSON_DETAILS(lessonId);
    const cachedLesson = localStorage.getItem(cacheKey);
    
    if (cachedLesson) {
      try {
        const parsedData = JSON.parse(cachedLesson);
        const cacheTimestamp = parsedData._cachedAt || 0;
        const cacheAge = Date.now() - cacheTimestamp;
        
        // Use cache if it's less than 5 minutes old
        if (cacheAge < 5 * 60 * 1000) {
          console.log(`Using cached lesson data for ${lessonId}`);
          return parsedData.lesson;
        } else {
          console.log('Cache expired, fetching fresh lesson data');
        }
      } catch (e) {
        console.error('Error parsing cached lesson:', e);
      }
    }
    
    // Cache miss or expired, fetch from Supabase
    const { data: lesson, error } = await supabase
      .from(TABLES.LESSONS)
      .select('*')
      .eq('id', lessonId)
      .single();
    
    if (error) {
      throw new Error(`Lesson with ID ${lessonId} not found: ${error.message}`);
    }
    
    // Cache the lesson
    try {
      localStorage.setItem(cacheKey, JSON.stringify({
        lesson: {
          id: lesson.id,
          ...lesson
        },
        _cachedAt: Date.now()
      }));
    } catch (cacheError) {
      console.error('Error caching lesson:', cacheError);
    }
    
    return {
      id: lesson.id,
      ...lesson
    };
  } catch (error) {
    console.error(`Error fetching lesson with ID ${lessonId}:`, error);
    throw error;
  }
};

/**
 * Get a single lesson by slug
 * @param {string} slug - The lesson slug
 * @param {string} courseId - Optional course ID to narrow down the search
 * @returns {Promise<Object>} - Lesson object
 */
export const getLessonBySlug = async (slug, courseId = null) => {
  try {
    console.log(`Looking for lesson with slug: ${slug}${courseId ? ` in course ${courseId}` : ''}`);
    
    // Build the query
    let query = supabase
      .from(TABLES.LESSONS)
      .select('*')
      .eq('slug', slug);
    
    // Add course ID filter if provided
    if (courseId) {
      query = query.eq('course_id', courseId);
    }
    
    // Execute the query
    const { data: lessons, error } = await query;
    
    if (error) {
      throw new Error(`Error looking up lesson with slug ${slug}: ${error.message}`);
    }
    
    // If exact match found, return the lesson
    if (lessons && lessons.length > 0) {
      console.log(`Found lesson with exact slug match: ${lessons[0].title}`);
      return {
        id: lessons[0].id,
        ...lessons[0]
      };
    }
    
    // If no exact match, try a more flexible approach
    console.log('No exact slug match, trying flexible matching');
    
    // Get all lessons, optionally filtered by course
    let allLessonsQuery = supabase.from(TABLES.LESSONS).select('*');
    if (courseId) {
      allLessonsQuery = allLessonsQuery.eq('course_id', courseId);
    }
    
    const { data: allLessons, error: allLessonsError } = await allLessonsQuery;
    
    if (allLessonsError) {
      throw new Error(`Error fetching all lessons: ${allLessonsError.message}`);
    }
    
    // Look for partial matches
    const matchingLesson = allLessons.find(lesson => 
      (lesson.slug && lesson.slug.includes(slug)) || 
      (slug && slug.includes(lesson.slug)) ||
      (lesson.title && lesson.title.toLowerCase().includes(slug.toLowerCase()))
    );
    
    if (matchingLesson) {
      console.log(`Found lesson with flexible slug match: ${matchingLesson.title}`);
      return {
        id: matchingLesson.id,
        ...matchingLesson
      };
    }
    
    throw new Error(`No lesson found with slug matching ${slug}`);
  } catch (error) {
    console.error(`Error fetching lesson with slug ${slug}:`, error);
    throw error;
  }
};

/**
 * Create a new lesson
 * @param {Object} lessonData - Lesson data
 * @param {string} lessonId - Optional custom ID
 * @returns {Promise<string>} - New lesson ID
 */
export const createLesson = async (lessonData, lessonId = null) => {
  try {
    const now = new Date().toISOString();
    
    // Check if course exists
    const { data: course, error: courseError } = await supabase
      .from(TABLES.COURSES)
      .select('*')
      .eq('id', lessonData.course_id)
      .single();
    
    if (courseError) {
      throw new Error(`Course with ID ${lessonData.course_id} not found: ${courseError.message}`);
    }
    
    // Generate a slug if not provided
    let slug = lessonData.slug;
    if (!slug && lessonData.title) {
      slug = createSlug(lessonData.title, lessonId || '');
      console.log('Generated slug for new lesson:', slug);
    }
    
    // Handle slides data - convert from string to array if needed
    let formattedSlides = [];
    if (lessonData.slides) {
      if (typeof lessonData.slides === 'string') {
        try {
          formattedSlides = JSON.parse(lessonData.slides);
        } catch (e) {
          console.error('Error parsing slides JSON:', e);
          formattedSlides = [];
        }
      } else if (Array.isArray(lessonData.slides)) {
        formattedSlides = lessonData.slides;
      }
    }
    
    // Prepare the new lesson data
    const newLesson = {
      ...lessonData,
      slug,
      slides: formattedSlides, // Use the properly formatted slides
      created_at: now,
      updated_at: now
    };
    
    // If ID is provided, include it in the data
    if (lessonId) {
      newLesson.id = lessonId;
    }
    
    console.log("Creating lesson with data:", JSON.stringify(newLesson, null, 2));
    
    // Create the lesson
    const { data, error } = await supabase
      .from(TABLES.LESSONS)
      .insert([newLesson])
      .select();
    
    if (error) {
      console.error('Error creating lesson:', error);
      throw error;
    }
    
    // Update the course's total lessons count
    const { error: updateError } = await supabase
      .from(TABLES.COURSES)
      .update({ 
        total_lessons: (course.total_lessons || 0) + 1,
        updated_at: now
      })
      .eq('id', lessonData.course_id);
    
    if (updateError) {
      console.error(`Error updating course lesson count:`, updateError);
      // We'll still return the lesson ID even if the course update fails
    }
    
    // Invalidate relevant caches
    invalidateLessonCachesByCourse(lessonData.course_id);
    
    return data[0].id;
  } catch (error) {
    console.error('Error creating lesson:', error);
    throw error;
  }
};

/**
 * Create multiple lessons in bulk
 * @param {Array<Object>} lessonsData - Array of lesson data objects
 * @returns {Promise<Array<string>>} - Array of created lesson IDs
 */
export const createLessonsInBulk = async (lessonsData) => {
  try {
    if (!Array.isArray(lessonsData) || lessonsData.length === 0) {
      throw new Error('Invalid lessons data: must be a non-empty array');
    }
    
    const now = new Date().toISOString();
    const courseId = lessonsData[0].course_id;
    
    // Check if all lessons belong to the same course
    if (!lessonsData.every(lesson => lesson.course_id === courseId)) {
      throw new Error('All lessons in a bulk operation must belong to the same course');
    }
    
    // Check if course exists
    const { data: course, error: courseError } = await supabase
      .from(TABLES.COURSES)
      .select('*')
      .eq('id', courseId)
      .single();
    
    if (courseError) {
      throw new Error(`Course with ID ${courseId} not found: ${courseError.message}`);
    }
    
    // Process each lesson to ensure it has the required fields
    const processedLessons = lessonsData.map(lessonData => {
      // Generate a slug if not provided
      let slug = lessonData.slug;
      if (!slug && lessonData.title) {
        slug = createSlug(lessonData.title, lessonData.id || '');
      }
      
      // Handle slides data - convert from string to array if needed
      let formattedSlides = [];
      if (lessonData.slides) {
        if (typeof lessonData.slides === 'string') {
          try {
            formattedSlides = JSON.parse(lessonData.slides);
          } catch (e) {
            console.error('Error parsing slides JSON:', e);
            formattedSlides = [];
          }
        } else if (Array.isArray(lessonData.slides)) {
          formattedSlides = lessonData.slides;
        }
      }
      
      // Prepare the lesson data
      return {
        ...lessonData,
        slug,
        slides: formattedSlides,
        created_at: now,
        updated_at: now
      };
    });
    
    console.log(`Creating ${processedLessons.length} lessons in bulk for course ID: ${courseId}`);
    
    // Insert all lessons in a single operation
    const { data, error } = await supabase
      .from(TABLES.LESSONS)
      .insert(processedLessons)
      .select();
    
    if (error) {
      console.error('Error creating lessons in bulk:', error);
      throw error;
    }
    
    // Update the course's total lessons count
    const { error: updateError } = await supabase
      .from(TABLES.COURSES)
      .update({ 
        total_lessons: (course.total_lessons || 0) + processedLessons.length,
        updated_at: now
      })
      .eq('id', courseId);
    
    if (updateError) {
      console.error(`Error updating course lesson count:`, updateError);
    }
    
    // Invalidate relevant caches
    invalidateLessonCachesByCourse(courseId);
    
    return data.map(lesson => lesson.id);
  } catch (error) {
    console.error('Error creating lessons in bulk:', error);
    throw error;
  }
};

/**
 * Update an existing lesson
 * @param {string} lessonId - The lesson ID
 * @param {Object} lessonData - Updated lesson data
 * @returns {Promise<void>}
 */
export const updateLesson = async (lessonId, lessonData) => {
  try {
    let lesson; // Declare the lesson variable once at the top

    // Check if lesson exists using the improved approach
    const { data, error } = await supabase
      .from(TABLES.LESSONS)
      .select('*')
      .filter('id', 'eq', lessonId)
      .maybeSingle();
    
    if (error || !data) {
      // Try the fallback approach
      const { data: allLessons, error: allError } = await supabase
        .from(TABLES.LESSONS)
        .select('*');
        
      if (allError) {
        throw new Error(`Unable to fetch lessons: ${allError.message}`);
      }
      
      const foundLesson = allLessons.find(l => l.id === lessonId);
      
      if (!foundLesson) {
        // If the lesson doesn't exist, try to create it
        if (lessonData.id && lessonData.course_id) {
          // Generate a slug if not provided
          let slug = lessonData.slug;
          if (!slug && lessonData.title) {
            slug = createSlug(lessonData.title, lessonId || '');
            console.log('Generated slug for new lesson:', slug);
          }
          
          // Handle slides data for new lesson creation
          let formattedSlides = [];
          if (lessonData.slides) {
            if (typeof lessonData.slides === 'string') {
              try {
                formattedSlides = JSON.parse(lessonData.slides);
              } catch (e) {
                console.error('Error parsing slides JSON:', e);
                formattedSlides = [];
              }
            } else if (Array.isArray(lessonData.slides)) {
              formattedSlides = lessonData.slides;
            }
          }
          
          const { data: inserted, error: insertError } = await supabase
            .from(TABLES.LESSONS)
            .insert([{
              id: lessonId,
              ...lessonData,
              slug,
              slides: formattedSlides,
              created_at: new Date().toISOString(),
              updated_at: new Date().toISOString()
            }])
            .select();
          
          if (insertError) {
            throw new Error(`Failed to create lesson: ${insertError.message}`);
          }
          
          // Invalidate caches after creating a new lesson
          invalidateLessonCache(lessonId, lessonData.course_id);
          invalidateLessonCachesByCourse(lessonData.course_id);
          
          return;
        } else {
          throw new Error(`Lesson with ID ${lessonId} not found and cannot be created without proper data`);
        }
      }
      
      // If we found the lesson, proceed with the original data
      lesson = foundLesson;
    } else {
      lesson = data;
    }
    
    const now = new Date().toISOString();
    
    // Store the original course ID for cache invalidation
    const originalCourseId = lesson.course_id;
    
    // If changing the course, verify the new course exists and update counts
    if (lessonData.course_id && lessonData.course_id !== lesson.course_id) {
      // Check if new course exists
      const { data: newCourse, error: newCourseError } = await supabase
        .from(TABLES.COURSES)
        .select('*')
        .eq('id', lessonData.course_id)
        .single();
      
      if (newCourseError) {
        throw new Error(`New course with ID ${lessonData.course_id} not found: ${newCourseError.message}`);
      }
      
      // Update the old course's lesson count
      const { error: oldCourseError } = await supabase
        .from(TABLES.COURSES)
        .select('*')
        .eq('id', lesson.course_id)
        .single();
      
      if (!oldCourseError) {
        await supabase
          .from(TABLES.COURSES)
          .update({ 
            total_lessons: supabase.rpc('decrement', { x: 1 }),
            updated_at: now
          })
          .eq('id', lesson.course_id);
      }
      
      // Update the new course's lesson count
      await supabase
        .from(TABLES.COURSES)
        .update({ 
          total_lessons: supabase.rpc('increment', { x: 1 }),
          updated_at: now
        })
        .eq('id', lessonData.course_id);
    }
    
    // Handle slides data for update
    let formattedSlides = [];
    if (lessonData.slides) {
      if (typeof lessonData.slides === 'string') {
        try {
          formattedSlides = JSON.parse(lessonData.slides);
        } catch (e) {
          console.error('Error parsing slides JSON:', e);
          formattedSlides = [];
        }
      } else if (Array.isArray(lessonData.slides)) {
        formattedSlides = lessonData.slides;
      }
    }
    
    // Remove slides from lessonData to avoid duplication
    const updatedLessonData = { ...lessonData };
    delete updatedLessonData.slides;
    
    console.log("Updating lesson with slides:", JSON.stringify(formattedSlides, null, 2));
    
    // Update the lesson
    const { error: updateError } = await supabase
      .from(TABLES.LESSONS)
      .update({
        ...updatedLessonData,
        slides: formattedSlides,
        updated_at: now
      })
      .filter('id', 'eq', lessonId);
    
    if (updateError) {
      throw updateError;
    }
    
    // Invalidate caches for both original and new course (if changed)
    invalidateLessonCache(lessonId, lessonData.course_id);
    invalidateLessonCachesByCourse(lessonData.course_id);
    
    // If the course ID changed, also invalidate cache for the original course
    if (originalCourseId && originalCourseId !== lessonData.course_id) {
      invalidateLessonCachesByCourse(originalCourseId);
    }
  } catch (error) {
    console.error(`Error updating lesson with ID ${lessonId}:`, error);
    throw error;
  }
};

/**
 * Delete a lesson
 * @param {string} lessonId - The lesson ID
 * @returns {Promise<void>}
 */
export const deleteLesson = async (lessonId) => {
  try {
    // First check if lesson exists and get its course ID
    const { data: lesson, error: checkError } = await supabase
      .from(TABLES.LESSONS)
      .select('*')
      .eq('id', lessonId)
      .single();
    
    if (checkError) {
      throw new Error(`Lesson with ID ${lessonId} not found: ${checkError.message}`);
    }
    
    const courseId = lesson.course_id;
    
    // Delete the lesson
    const { error: deleteError } = await supabase
      .from(TABLES.LESSONS)
      .delete()
      .eq('id', lessonId);
    
    if (deleteError) {
      throw deleteError;
    }
    
    // Update the course's total lessons count
    if (courseId) {
      const { data: course, error: courseError } = await supabase
        .from(TABLES.COURSES)
        .select('total_lessons')
        .eq('id', courseId)
        .single();
      
      if (!courseError && course) {
        const currentLessonCount = course.total_lessons || 0;
        
        await supabase
          .from(TABLES.COURSES)
          .update({ 
            total_lessons: Math.max(0, currentLessonCount - 1),
            updated_at: new Date().toISOString()
          })
          .eq('id', courseId);
      }
    }
    
    // Invalidate caches after deletion
    invalidateLessonCache(lessonId, courseId);
    if (courseId) {
      invalidateLessonCachesByCourse(courseId);
    }
  } catch (error) {
    console.error(`Error deleting lesson with ID ${lessonId}:`, error);
    throw error;
  }
};

/**
 * Reorder lessons within a course
 * @param {string} courseId - The course ID
 * @param {Array} lessonOrder - Array of lesson IDs in the desired order
 * @returns {Promise<void>}
 */
export const reorderLessons = async (courseId, lessonOrder) => {
  try {
    // Validate course exists
    const { data: course, error: courseError } = await supabase
      .from(TABLES.COURSES)
      .select('*')
      .eq('id', courseId)
      .single();
    
    if (courseError) {
      throw new Error(`Course with ID ${courseId} not found: ${courseError.message}`);
    }
    
    // Get all lessons for the course
    const { data: lessons, error: lessonsError } = await supabase
      .from(TABLES.LESSONS)
      .select('*')
      .eq('course_id', courseId);
    
    if (lessonsError) {
      throw lessonsError;
    }
    
    // Create a map of lessons for quick lookup
    const lessonMap = {};
    lessons.forEach(lesson => {
      lessonMap[lesson.id] = lesson;
    });
    
    // Validate that all lessons in the order array exist
    for (const lessonId of lessonOrder) {
      if (!lessonMap[lessonId]) {
        throw new Error(`Lesson with ID ${lessonId} not found in course ${courseId}`);
      }
    }
    
    // Update the order of each lesson
    const updatePromises = lessonOrder.map((lessonId, index) => {
      return supabase
        .from(TABLES.LESSONS)
        .update({ order: index + 1, updated_at: new Date().toISOString() })
        .eq('id', lessonId);
    });
    
    await Promise.all(updatePromises);
    
    // Update the course
    await supabase
      .from(TABLES.COURSES)
      .update({ updated_at: new Date().toISOString() })
      .eq('id', courseId);
    
    return true;
  } catch (error) {
    console.error(`Error reordering lessons for course ${courseId}:`, error);
    throw error;
  }
}; 