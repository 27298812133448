import React from 'react';
import { AlertTriangle, WifiOff, RefreshCw } from 'react-feather';

/**
 * Component to display mobile-friendly error messages for AI failures
 * 
 * @param {Object} props
 * @param {boolean} props.isNetworkError - If this is a network connectivity error
 * @param {boolean} props.isTimeoutError - If this is a timeout error
 * @param {boolean} props.isMobileError - If this is specifically a mobile device issue
 * @param {string} props.message - Custom error message
 * @param {Function} props.onRetry - Callback for retry button
 */
const MobileAIErrorMessage = ({ 
  isNetworkError, 
  isTimeoutError, 
  isMobileError, 
  message,
  onRetry
}) => {
  let title = "AI Service Error";
  let icon = <AlertTriangle className="h-6 w-6 text-yellow-400" />;
  let errorMessage = message || "There was an error connecting to the AI service. Please try again later.";
  
  // Set specific error type message and icon
  if (isNetworkError) {
    title = "Network Error";
    icon = <WifiOff className="h-6 w-6 text-yellow-400" />;
    errorMessage = message || "You appear to be offline. Please check your internet connection and try again.";
  } else if (isTimeoutError) {
    title = "Request Timeout";
    icon = <RefreshCw className="h-6 w-6 text-yellow-400" />;
    errorMessage = message || "The request took too long to process. Please try again with a simpler question.";
  } else if (isMobileError) {
    title = "Mobile Connection Issue";
    icon = <AlertTriangle className="h-6 w-6 text-yellow-400" />;
    errorMessage = message || "There was an issue with the AI service on your mobile device. Try switching to WiFi if you're using mobile data.";
  }
  
  return (
    <div className="bg-gray-800 border border-yellow-500/20 rounded-lg p-4 mb-4 max-w-full">
      <div className="flex items-start">
        <div className="flex-shrink-0 mt-0.5">
          {icon}
        </div>
        <div className="ml-3 flex-1">
          <h3 className="text-sm font-medium text-yellow-300">{title}</h3>
          <div className="mt-1 text-sm text-gray-300">
            <p>{errorMessage}</p>
            {/* Troubleshooting tips for mobile users */}
            {(isMobileError || isTimeoutError) && (
              <ul className="list-disc pl-5 mt-2 text-xs text-gray-400">
                <li>Try using WiFi instead of mobile data</li>
                <li>Close other apps running in the background</li>
                <li>Try a shorter question or selecting less text</li>
                <li>Refresh the page and try again</li>
              </ul>
            )}
          </div>
          {onRetry && (
            <div className="mt-3">
              <button
                type="button"
                onClick={onRetry}
                className="inline-flex items-center px-3 py-1.5 border border-blue-500/30 text-xs font-medium rounded-md bg-blue-600/20 text-blue-300 hover:bg-blue-600/30 transition-colors"
              >
                <RefreshCw className="mr-1.5 h-3 w-3" />
                Try Again
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MobileAIErrorMessage; 