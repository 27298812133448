import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthContext";
import { LogOut, User, Settings, ChevronDown, Layout } from "lucide-react";
import { useLanguage } from "../components/interactivelessons/context/LanguageContext";
import { getTranslation } from "../components/interactivelessons/utils/translations";

export default function UserProfile() {
  const [isOpen, setIsOpen] = useState(false);
  const { currentUser, logout } = useAuth();
  const { language } = useLanguage();
  const navigate = useNavigate();

  // Get user avatar URL if available from OAuth providers
  const userAvatar = currentUser?.user_metadata?.avatar_url || null;
  
  async function handleLogout() {
    try {
      await logout();
      navigate("/courses");
    } catch (error) {
      console.error("Failed to log out", error);
    }
  }

  return (
    <div className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center gap-2 text-white"
      >
        {userAvatar ? (
          <img 
            src={userAvatar} 
            alt="User Avatar" 
            className="h-8 w-8 rounded-full object-cover"
          />
        ) : (
          <div className="h-8 w-8 bg-blue-600 rounded-full flex items-center justify-center text-white">
            {currentUser?.displayName ? (
              currentUser.displayName.charAt(0).toUpperCase()
            ) : (
              <User className="h-5 w-5" />
            )}
          </div>
        )}
        <span className="hidden md:inline text-sm font-medium">
          {currentUser?.displayName || currentUser?.email?.split('@')[0] || "User"}
        </span>
        <ChevronDown className="h-4 w-4 text-gray-400" />
      </button>

      {isOpen && (
        <div className="absolute right-0 mt-2 w-48 py-2 bg-[#23273a] rounded-md shadow-xl z-10 border border-gray-700">
          <div className="px-4 py-2 border-b border-gray-700">
            <p className="text-sm font-medium text-white truncate">
              {currentUser?.displayName || currentUser?.email?.split('@')[0]}
            </p>
            <p className="text-xs text-gray-400 truncate">{currentUser?.email}</p>
          </div>
          <button
            onClick={() => {
              setIsOpen(false);
              navigate("/dashboard");
            }}
            className="block w-full px-4 py-2 text-sm text-white hover:bg-gray-700 text-left"
          >
            <div className="flex items-center gap-2">
              <Layout className="h-4 w-4" />
              <span>{getTranslation('dashboard', language)}</span>
            </div>
          </button>
          <button
            onClick={() => {
              setIsOpen(false);
              navigate("/settings");
            }}
            className="block w-full px-4 py-2 text-sm text-white hover:bg-gray-700 text-left"
          >
            <div className="flex items-center gap-2">
              <Settings className="h-4 w-4" />
              <span>{getTranslation('settings', language)}</span>
            </div>
          </button>
          <button
            onClick={() => {
              setIsOpen(false);
              handleLogout();
            }}
            className="block w-full px-4 py-2 text-sm text-white hover:bg-gray-700 text-left"
          >
            <div className="flex items-center gap-2">
              <LogOut className="h-4 w-4" />
              <span>{getTranslation('logout', language)}</span>
            </div>
          </button>
        </div>
      )}
    </div>
  );
} 