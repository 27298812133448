import React from 'react';
import { XCircle, Crown, Check, Lock } from 'lucide-react';

const PaywallModal = ({ 
  isOpen, 
  onClose, 
  feature = 'feature',
  onUpgrade
}) => {
  if (!isOpen) return null;

  const handleUpgrade = () => {
    // Navigate to the pricing page using the correct path we defined in App.js
    window.location.href = '/pricing';
  };

  return (
    <div className="fixed inset-0 bg-black/80 flex items-center justify-center z-50 p-4 paywall-modal">
      <div className="bg-gradient-to-br from-[#1a1f2d] to-[#161a24] rounded-xl w-full max-w-md overflow-hidden shadow-xl border border-yellow-500/20">
        {/* Header */}
        <div className="relative bg-gradient-to-r from-yellow-500/20 to-yellow-600/5 p-5 flex justify-between items-center">
          <div className="flex items-center space-x-2">
            <Crown className="h-6 w-6 text-yellow-400" />
            <h3 className="text-lg font-semibold text-white">Premium Feature</h3>
          </div>
          <button 
            onClick={onClose}
            className="text-gray-400 hover:text-white transition-colors"
            aria-label="Close"
          >
            <XCircle className="h-6 w-6" />
          </button>
        </div>
        
        {/* Content */}
        <div className="p-6">
          <div className="flex justify-center mb-6">
            <div className="w-16 h-16 bg-yellow-500/20 rounded-full flex items-center justify-center">
              <Lock className="h-7 w-7 text-yellow-400" />
            </div>
          </div>
          
          <h4 className="text-xl font-bold text-center text-white mb-3">
            Unlock {feature}
          </h4>
          
          <p className="text-gray-300 text-center mb-6">
            This is a premium feature. Upgrade to Premium to unlock all lessons, AI features, and more!
          </p>
          
          <div className="bg-[#12141c] rounded-lg p-4 mb-6">
            <h5 className="text-white font-medium mb-3 flex items-center">
              <Crown className="h-4 w-4 text-yellow-400 mr-2" />
              Premium Benefits
            </h5>
            <ul className="space-y-2">
              <li className="flex items-start">
                <Check className="h-5 w-5 text-yellow-400 mr-2 flex-shrink-0 mt-0.5" />
                <span className="text-gray-300">Unlimited access to all courses</span>
              </li>
              <li className="flex items-start">
                <Check className="h-5 w-5 text-yellow-400 mr-2 flex-shrink-0 mt-0.5" />
                <span className="text-gray-300">AI-powered learning assistance</span>
              </li>
              <li className="flex items-start">
                <Check className="h-5 w-5 text-yellow-400 mr-2 flex-shrink-0 mt-0.5" />
                <span className="text-gray-300">Advanced coding exercises</span>
              </li>
              <li className="flex items-start">
                <Check className="h-5 w-5 text-yellow-400 mr-2 flex-shrink-0 mt-0.5" />
                <span className="text-gray-300">Personalized learning recommendations</span>
              </li>
            </ul>
          </div>
          
          <div className="flex justify-center">
            <button
              onClick={handleUpgrade}
              className="px-6 py-3 bg-gradient-to-r from-yellow-500 to-yellow-600 hover:from-yellow-600 hover:to-yellow-700 text-white rounded-lg transition-colors shadow-lg hover:shadow-yellow-600/30 font-medium flex items-center"
            >
              <Crown className="h-5 w-5 mr-2" />
              Upgrade to Premium
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaywallModal; 