import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import { useNavigate, useParams, useLocation, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ChevronLeft, BookOpen, ArrowRight, GraduationCap, HelpCircle, CheckCircle, LogOut, Crown, Lock } from 'lucide-react';
import { AskAIButton } from '../../ui/AskAI';
import { useLanguage } from '../../context/LanguageContext';
import TranslatedText from '../../ui/TranslatedText';
import { translate } from '../../utils/translations';
import { getQueryParam, updateQueryParam, createSlug, getIdFromSlug } from '../../utils/urlUtils';
import { useAuth } from '../../../../auth/AuthContext';
import { getUserProgress, getCourseProgress, enrollInCourse, deEnrollFromCourse } from '../../../../services/userProgress';
import { getLessonsByCourse, getLessonById, getLessonBySlug } from '../../../../services/lessonService';
import { getCourseById, getCourseBySlug, getAllCourses } from '../../../../services/courseService';
import { TextSelectionProvider } from '../../context/TextSelectionContext';

const LessonSelector = () => {
  const navigate = useNavigate();
  const { courseSlug } = useParams();
  const [course, setCourse] = useState(null);
  const [expandedDescriptions, setExpandedDescriptions] = useState({});
  const { currentUser, isPremium } = useAuth();
  const [courseProgress, setCourseProgress] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isEnrolled, setIsEnrolled] = useState(false);
  const [isEnrolling, setIsEnrolling] = useState(false);
  const [isLoadingLessons, setIsLoadingLessons] = useState(false);
  const [error, setError] = useState(null);
  const loadingTimeoutRef = useRef(null);
  const loadingLessonsTimeoutRef = useRef(null);
  
  // Add loading timeout to force end loading state after max time - reduced from 10s to 5s
  useEffect(() => {
    loadingTimeoutRef.current = setTimeout(() => {
      if (isLoading) {
        console.log('Forcing end of loading state due to timeout (course)');
        setIsLoading(false);
      }
    }, 5000);
    
    return () => {
      if (loadingTimeoutRef.current) clearTimeout(loadingTimeoutRef.current);
    };
  }, [isLoading]);
  
  // Add loading timeout for lessons - reduced from 10s to 5s
  useEffect(() => {
    loadingLessonsTimeoutRef.current = setTimeout(() => {
      if (isLoadingLessons) {
        console.log('Forcing end of loading lessons state due to timeout');
        setIsLoadingLessons(false);
      }
    }, 5000);
    
    return () => {
      if (loadingLessonsTimeoutRef.current) clearTimeout(loadingLessonsTimeoutRef.current);
    };
  }, [isLoadingLessons]);
  
  // Reset loading state when visibility changes
  useEffect(() => {
    let visibilityChangeTimeout = null;
    
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        if (visibilityChangeTimeout) clearTimeout(visibilityChangeTimeout);
        
        if (isLoading || isLoadingLessons) {
          setIsLoading(false);
          setIsLoadingLessons(false);
          
          if (!course) {
            visibilityChangeTimeout = setTimeout(() => {
              fetchCourseAndProgress();
            }, 150); // Further reduced timeout for better responsiveness
          }
        }
      }
    };
    
    document.addEventListener('visibilitychange', handleVisibilityChange);
    
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      if (visibilityChangeTimeout) clearTimeout(visibilityChangeTimeout);
    };
  }, [isLoading, isLoadingLessons, course]);
  
  // Find the course based on slug parameter
  const fetchCourseAndProgress = useCallback(async () => {
    setIsLoading(true);
    setIsLoadingLessons(true);
    setError(null);
    
    console.log('===== COURSE SEARCH DIAGNOSTICS =====');
    console.log('Looking for course with slug:', courseSlug);
    
    try {
      // Check localStorage cache first with improved caching
      const cacheKey = `course_${courseSlug}`;
      const cachedCourse = localStorage.getItem(cacheKey);
      
      if (cachedCourse) {
        try {
          const parsedCourse = JSON.parse(cachedCourse);
          console.log('Found cached course:', parsedCourse.title, parsedCourse.id);
          const cacheTimestamp = parsedCourse._cachedAt || 0;
          const cacheAge = Date.now() - cacheTimestamp;
          
          // Extended cache lifetime to 30 minutes for better performance
          if (cacheAge < 30 * 60 * 1000) {
            setCourse(parsedCourse);
            console.log('Using cached course, does it have lessons?', 
              parsedCourse.lessons ? `Yes, ${parsedCourse.lessons.length} lessons` : 'No lessons found');
            
            // Fix any potentially corrupt course object by re-processing it
            try {
              // Ensure we have a clean, properly formed object with an array for lessons
              const cleanCourse = JSON.parse(JSON.stringify(parsedCourse));
              if (!cleanCourse.lessons) {
                cleanCourse.lessons = [];
                console.warn('Added missing lessons array to cached course');
              } else if (!Array.isArray(cleanCourse.lessons)) {
                console.warn('Lessons was not an array, fixing:', typeof cleanCourse.lessons);
                cleanCourse.lessons = Array.isArray(cleanCourse.lessons) ? cleanCourse.lessons : [];
              }
              setCourse(cleanCourse);
            } catch (e) {
              console.error('Error cleaning course object:', e);
              // If the cleaning fails, ensure we at least have an empty lessons array
              setCourse({...parsedCourse, lessons: parsedCourse.lessons || []});
            }
            
            // Fetch progress in background, but only if user is logged in
            if (currentUser && currentUser.uid) {
              console.log('User is authenticated, fetching progress...');
              getCourseProgress(currentUser.uid, parsedCourse.id)
                .then(progressData => {
                  if (progressData) {
                    setCourseProgress(progressData);
                    setIsEnrolled(true);
                    console.log('User progress loaded: enrolled=true');
                  } else {
                    console.log('No progress data returned, user not enrolled');
                  }
                })
                .catch(error => console.error('Error fetching course progress:', error));
            } else {
              console.log('User not authenticated yet, skipping progress fetch');
            }
            
            setIsLoading(false);
            setIsLoadingLessons(false);
            return;
          }
        } catch (e) {
          console.error('Error parsing cached course:', e);
        }
      } else {
        console.log('No cached course found, searching database');
      }
      
      // Optimized course fetching - parallel requests
      const allCoursesPromise = getAllCourses();
      
      // Get courses and check for matches
      const allCourses = await allCoursesPromise;
      console.log('Fetched all courses:', allCourses.length, 'courses');
      console.log('Available courses:', allCourses.map(c => ({
        title: c.title, 
        id: c.id,
        slug: c.slug
      })));
      
      // Try to find course by slug directly
      let selectedCourse = null;
      
      // More efficient search algorithm
      // Check for exact slug match
      let courseBySlug = allCourses.find(c => c.slug === courseSlug);
      if (courseBySlug) {
        console.log('Found exact slug match:', courseBySlug.title, courseBySlug.id, courseBySlug.slug);
      } else {
        console.log('No exact slug match found, trying keyword matching');
      }
      
      // Try keyword-based matching for any course type
      if (!courseBySlug) {
        console.log('Looking for course with flexible keyword matching');
        // Extract potential keywords from the slug
        const keywords = courseSlug.split(/[-_]/);
        console.log('Extracted keywords from slug:', keywords);
        
        // Try to find a course where title or slug contains any of these keywords
        courseBySlug = allCourses.find(c => 
          keywords.some(keyword => 
            keyword.length > 2 && (
              c.title?.toLowerCase().includes(keyword.toLowerCase()) || 
              c.slug?.toLowerCase().includes(keyword.toLowerCase())
            )
          )
        );
        
        if (courseBySlug) {
          console.log('Found course by keyword match:', courseBySlug.title, courseBySlug.id, courseBySlug.slug);
        } else {
          console.log('No courses found by keyword, trying partial matching');
        }
      }
      
      if (courseBySlug) {
        selectedCourse = courseBySlug;
      } else {
        // If not found by slug, try by ID (backward compatibility)
        const courseId = getIdFromSlug(courseSlug);
        console.log('Extracted courseId from slug:', courseId);
        try {
          const courseById = await getCourseById(courseId);
          if (courseById) {
            selectedCourse = courseById;
            console.log('Found course by ID:', courseById.title, courseById.id);
          } else {
            console.log('No course found by ID');
          }
        } catch (error) {
          console.log('Error finding course by ID:', error.message);
        }
      }
      
      // If course not found, return
      if (!selectedCourse) {
        console.log('❌ FINAL RESULT: No course found matching', courseSlug);
        setIsLoading(false);
        setIsLoadingLessons(false);
        console.log('===== END COURSE SEARCH DIAGNOSTICS =====');
        return;
      }
      
      console.log('✅ FINAL RESULT: Found course:', selectedCourse.title, selectedCourse.id);
      console.log('===== END COURSE SEARCH DIAGNOSTICS =====');
      
      // Load lessons from Supabase
      let courseWithLessons = { ...selectedCourse };
      
      if (courseWithLessons.id) {
        try {
          console.log('Loading lessons for course:', courseWithLessons.id);
          const lessonsFromSupabase = await getLessonsByCourse(courseWithLessons.id);
          console.log('Lessons from Supabase:', lessonsFromSupabase ? lessonsFromSupabase.length : 0);
          
          if (lessonsFromSupabase && lessonsFromSupabase.length > 0) {
            // Transform the lessons if needed to match the expected format with optimized parsing
            const transformedLessons = lessonsFromSupabase.map(lesson => {
              // Parse slides if they're stored as a JSON string
              let slides = lesson.slides;
              if (typeof slides === 'string') {
                try {
                  slides = JSON.parse(slides);
                } catch (e) {
                  console.error('Error parsing slides JSON for lesson:', lesson.id, e);
                  slides = [];
                }
              }
              
              console.log(`Processed lesson: ${lesson.title}, ID: ${lesson.id}, Slides: ${slides ? slides.length : 0}`);
              
              return {
                ...lesson,
                slides
              };
            });
            
            // Sort lessons by order
            courseWithLessons.lessons = transformedLessons.sort((a, b) => (a.order || 0) - (b.order || 0));
            console.log('Final sorted lessons:', courseWithLessons.lessons.length);
          } else {
            courseWithLessons.lessons = [];
            console.log('No lessons found for this course. Creating empty array.');
          }
          
          // Cache the course with lessons
          try {
            courseWithLessons._cachedAt = Date.now();
            localStorage.setItem(cacheKey, JSON.stringify(courseWithLessons));
            console.log('Course with lessons cached successfully');
          } catch (cacheError) {
            // Cache handling...
          }
          
        } catch (error) {
          console.error('Error loading lessons from Supabase:', error);
          courseWithLessons.lessons = [];
          console.log('Error loading lessons, setting empty array');
        }
      }
      
      // Ensure courseWithLessons has valid properties
      if (!courseWithLessons.lessons) {
        console.warn('Course has no lessons array after loading from DB, creating empty array');
        courseWithLessons.lessons = [];
      } else if (!Array.isArray(courseWithLessons.lessons)) {
        console.warn('Course lessons is not an array after loading from DB, fixing');
        courseWithLessons.lessons = [];
      }
      
      // Once more ensure the course has all required properties
      setCourse({
        ...courseWithLessons,
        lessons: Array.isArray(courseWithLessons.lessons) ? courseWithLessons.lessons : []
      });
      
      // Get user progress for the course if user is logged in - in parallel
      if (currentUser && courseWithLessons.id) {
        try {
          const progressData = await getCourseProgress(currentUser.uid, courseWithLessons.id);
          if (progressData) {
            setCourseProgress(progressData);
            setIsEnrolled(true);
          }
        } catch (error) {
          console.error('Error fetching course progress:', error);
        }
      }
      
    } catch (error) {
      console.error('Error in fetchCourseAndProgress:', error);
    } finally {
      setIsLoading(false);
      setIsLoadingLessons(false);
    }
  }, [courseSlug, currentUser]);
  
  useEffect(() => {
    // Only fetch course and progress if the user is authenticated or there is no currentUser required
    if (currentUser || !window.location.pathname.includes('/dashboard')) {
      console.log('Fetching course data with auth state:', currentUser ? 'authenticated' : 'unauthenticated');
      fetchCourseAndProgress();
    } else {
      console.log('Waiting for authentication before fetching course data');
    }
  }, [fetchCourseAndProgress, currentUser]);
  
  // Handle enrollment
  const handleEnroll = async () => {
    if (!currentUser) {
      // Redirect to login page with return URL
      navigate('/login', { 
        state: { 
          returnTo: `/courses/${courseSlug}`
        } 
      });
      return;
    }
    
    // Check if this is a premium course and the user is not premium
    if (course?.is_premium && !isPremium) {
      alert('This is a premium course. Please upgrade to a premium account to enroll.');
      return;
    }
    
    // Use the helper function for the actual enrollment
    const success = await handleEnrollAndGetStatus();
    
    if (success) {
      // Refresh the page to show updated enrollment status
      console.log('Successfully enrolled in course, refreshing page');
      // Refresh the current page to show updated enrollment status
      window.location.reload();
    }
  };
  
  // Handle de-enrollment
  const handleDeEnroll = async () => {
    if (!currentUser) {
      console.error('User not logged in, cannot de-enroll');
      return;
    }
    
    // Ask for confirmation
    const confirmed = window.confirm('Are you sure you want to de-enroll from this course? Your progress will be lost.');
    if (!confirmed) {
      return;
    }
    
    try {
      console.log('De-enrolling user from course with ID:', course.id);
      const success = await deEnrollFromCourse(currentUser.uid, course.id);
      
      if (success) {
        console.log('Successfully de-enrolled from course');
        // Refresh the page to show updated enrollment status
        window.location.reload();
      } else {
        console.error('Failed to de-enroll from course');
        alert('There was an error de-enrolling from this course. Please try again.');
      }
    } catch (error) {
      console.error('Error during de-enrollment:', error);
      alert('There was an error de-enrolling from this course. Please try again.');
    }
  };
  
  // Navigate back to courses list or dashboard
  const handleBack = () => {
    if (currentUser) {
      navigate('/dashboard/courses');
    } else {
      navigate('/courses');
    }
  };
  
  // Navigate to a specific lesson - use slug when available
  const handleSelectLesson = async (lesson, index) => {
    console.log('Selecting lesson:', lesson, 'index:', index);
    
    // Get the lesson slug or generate one from the title and ID
    let lessonSlug = lesson.slug;
    if (!lessonSlug) {
      // If the lesson doesn't have a slug, create one from the title
      lessonSlug = createSlug(lesson.title, lesson.id);
      console.log('Generated slug for lesson:', lessonSlug);
    }
    
    // If user is not logged in, redirect to login page
    if (!currentUser) {
      console.log('User not logged in, redirecting to login');
      navigate('/login', { 
        state: { 
          returnTo: `/dashboard/courses/${courseSlug}/lesson/${lessonSlug}`
        } 
      });
      return;
    }
    
    // Check for premium course restrictions
    if (course?.is_premium && !isPremium) {
      // Allow only the first lesson for non-premium users
      if (index === 0) {
        console.log('Non-premium user accessing first lesson of premium course as preview');
      } else {
        console.log('Non-premium user attempting to access premium content beyond first lesson');
        alert('This is premium content. Please upgrade to a premium account to access lessons beyond the first one.');
        return;
      }
    }
    
    // If user is logged in but not enrolled, check if this is the first lesson (free trial)
    if (!isEnrolled) {
      if (index === 0) {
        // First lesson is accessible as a trial
        console.log('User not enrolled, but accessing first lesson as trial');
      } else {
        // Not the first lesson, show message asking to enroll
        console.log('User not enrolled, showing enrollment message for non-first lesson');
        alert('Please enroll in this course to access more lessons.');
        return;
      }
    }
    
    // Get the current slide and progress for this lesson
    const lessonProgress = getLessonProgress(lesson.id);
    const currentSlide = getLessonCurrentSlide(lesson.id);
    const isCompleted = isLessonCompleted(lesson.id);
    
    console.log('Lesson progress:', lessonProgress);
    console.log('Current slide for lesson:', currentSlide);
    console.log('Lesson completed status:', isCompleted);
    
    // Apply improved slide navigation logic
    let slideHash = '';
    
    // Apply the requirements:
    // 1. If progress is 0% or 100%, go to the first slide
    if (lessonProgress === 0 || isCompleted) {
      slideHash = '#slide-1';
      console.log('Progress is 0% or lesson is completed, navigating to first slide');
    } 
    // 2. If progress is between 0% and 100%, go to the appropriate slide
    else if (lessonProgress > 0 && lessonProgress < 100) {
      // If there's a specific current slide stored, use that
      if (typeof currentSlide === 'number' && currentSlide >= 0) {
        slideHash = `#slide-${currentSlide + 1}`;
        console.log(`Using stored current slide position: ${currentSlide + 1}`);
      } 
      // Otherwise calculate based on progress percentage
      else {
        // Convert progress percentage to slide index
        const totalSlides = lesson.slides ? lesson.slides.length : 10; // Fallback to 10 if no slides count
        
        // Log calculation details
        console.log('--- Slide Calculation Details (LessonSelector) ---');
        console.log(`Progress: ${lessonProgress}%`);
        console.log(`Total slides: ${totalSlides}`);
        console.log(`Formula: Math.ceil((${lessonProgress} / 100) * ${totalSlides}) - 1`);
        
        // More intuitive slide calculation based on progress percentage:
        // If someone is 75% through a 10-slide lesson, they should be on slide 8
        // This maps the progress percentage directly to the relative position in the slides
        const calculatedSlideIndex = Math.ceil((lessonProgress / 100) * totalSlides) - 1;
        console.log(`Raw calculated slide index: ${calculatedSlideIndex}`);
        
        // Make sure we don't automatically go to the very last slide
        // This prevents sending users to the completion slide automatically
        const safeSlideIndex = Math.min(calculatedSlideIndex, totalSlides - 2);
        console.log(`After max slide limit (total - 2): ${safeSlideIndex}`);
        
        // Ensure we're at least on slide 1 (index 0)
        const finalSlideIndex = Math.max(0, safeSlideIndex);
        console.log(`Final slide index: ${finalSlideIndex} (slide #${finalSlideIndex + 1})`);
        
        // Convert from 0-indexed to 1-indexed for the URL
        const slideNumber = finalSlideIndex + 1;
        console.log(`URL hash will be: #slide-${slideNumber}`);
        console.log('----------------------------------------------');
        
        slideHash = `#slide-${slideNumber}`;
      }
    } else {
      // Default to first slide if logic fails
      slideHash = '#slide-1';
      console.log('Using default first slide');
    }
    
    // Use the original course slug for the URL to maintain consistency
    const lessonUrl = `/dashboard/courses/${courseSlug}/lesson/${lessonSlug}${slideHash}`;
    console.log('Navigating to lesson URL:', lessonUrl);
    navigate(lessonUrl);
  };
  
  // Helper function to enroll and return enrollment status
  const handleEnrollAndGetStatus = async () => {
    try {
      if (!course) {
        console.error('Course object is missing or invalid');
        return false;
      }
      
      if (!course.id) {
        console.error('Course ID is missing. Course object:', course);
        return false;
      }
      
      if (!currentUser) {
        console.error('User not logged in');
        navigate('/login', { state: { returnTo: `/courses/${courseSlug}` } });
        return false;
      }
      
      if (!currentUser.uid) {
        console.error('User ID is missing or undefined', currentUser);
        // Try to get better debug information
        console.log('User object:', JSON.stringify(currentUser, null, 2));
        
        // Show authentication error and redirect to login
        alert('Authentication error. Please log in again.');
        navigate('/login', { state: { returnTo: `/courses/${courseSlug}` } });
        return false;
      }
      
      // Check for premium course restrictions
      if (course.is_premium && !isPremium) {
        console.error('Non-premium user attempting to enroll in premium course');
        alert('This is a premium course. Please upgrade to a premium account to enroll.');
        return false;
      }
      
      setIsEnrolling(true);
      const courseId = course.id;
      console.log('Enrolling user in course with ID:', courseId, 'User ID:', currentUser.uid);
      
      // Call the enrollInCourse function to update the user's enrolled courses in Supabase
      const success = await enrollInCourse(currentUser.uid, courseId);
      
      if (success) {
        // Update the local enrollment state
        setIsEnrolled(true);
        
        // Wait a moment to ensure data is saved before fetching
        await new Promise(resolve => setTimeout(resolve, 500));
        
        // Fetch updated progress data
        const progress = await getCourseProgress(currentUser.uid, courseId);
        setCourseProgress(progress);
        
        console.log('User successfully enrolled in course:', courseId);
        return true;
      } else {
        console.error('Failed to enroll in course');
        return false;
      }
    } catch (error) {
      console.error('Error enrolling in course:', error);
      return false;
    } finally {
      setIsEnrolling(false);
    }
  };
  
  // Toggle description visibility
  const toggleDescription = (index) => {
    setExpandedDescriptions(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
  };
  
  // Helper function to get lesson progress
  const getLessonProgress = (lessonId) => {
    console.log('Getting progress for lesson:', lessonId);
    console.log('Course progress data:', courseProgress);
    
    if (!courseProgress || !courseProgress.lessons) {
      console.log('No course progress data available');
      return 0;
    }

    // Get the lesson progress data
    const lessonProgress = courseProgress.lessons[lessonId];
    console.log('Lesson progress data:', lessonProgress);

    if (!lessonProgress) {
      console.log('No progress data for this lesson');
      return 0;
    }

    return lessonProgress.progress || 0;
  };
  
  // Helper function to check if a lesson is completed
  const isLessonCompleted = (lessonId) => {
    if (!courseProgress || !courseProgress.lessons || !courseProgress.lessons[lessonId]) {
      return false;
    }
    
    const lessonData = courseProgress.lessons[lessonId];
    
    // Consider a lesson completed if either the completed flag is true OR progress is 100%
    return lessonData.completed || lessonData.progress === 100;
  };
  
  // Helper to get the current slide for a lesson
  const getLessonCurrentSlide = (lessonId) => {
    if (!courseProgress || !courseProgress.lessons || !courseProgress.lessons[lessonId]) {
      return 0;
    }
    return courseProgress.lessons[lessonId].current_slide || 0;
  };
  
  if (isLoading || isLoadingLessons) {
    return <div className="min-h-screen bg-gradient-to-br from-[#050508] via-[#0a0d14] to-[#0f1218] flex items-center justify-center text-white">
      <div className="flex flex-col items-center">
        <div className="w-12 h-12 border-4 border-blue-600 border-t-transparent rounded-full animate-spin mb-4"></div>
        <p>{isLoadingLessons ? "Loading lessons..." : "Loading course..."}</p>
      </div>
    </div>;
  }
  
  if (!course) {
    return <div className="min-h-screen bg-gradient-to-br from-[#050508] via-[#0a0d14] to-[#0f1218] flex items-center justify-center text-white">Course not found</div>;
  }

  // If course has no lessons, show a message
  if (!course.lessons || course.lessons.length === 0) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-[#050508] via-[#0a0d14] to-[#0f1218] flex items-center justify-center text-white">
        <div className="max-w-xl text-center px-4">
          <h2 className="text-2xl font-bold mb-4">No Lessons Available</h2>
          <p className="text-gray-300 mb-6">This course doesn't have any lessons yet. Please check back later or contact support.</p>
          <button
            onClick={() => navigate('/dashboard/courses')}
            className="text-blue-400 hover:text-white flex items-center group text-sm font-medium transition-all duration-200 py-2 px-4 mx-auto bg-blue-900/20 hover:bg-blue-700/30 rounded-lg border border-blue-500/20 hover:border-blue-400/40 shadow-md hover:shadow-blue-500/10"
          >
            <ChevronLeft className="mr-2 h-5 w-5 transition-transform group-hover:-translate-x-1" />
            <span className="text-base">Back to Courses</span>
          </button>
        </div>
      </div>
    );
  }
  
  // The component to be rendered
  const LessonSelectorContent = () => {
    const { language } = useLanguage();
    
    // Calculate overall course progress percentage
    const overallProgressPercentage = courseProgress ? courseProgress.progress : 0;
    
    // Debug logs to identify rendering issues
    console.log('=== RENDERING LESSON SELECTOR ===');
    console.log('Course object:', course);
    console.log('Lesson count:', course?.lessons?.length);
    console.log('Course progress:', courseProgress);
    console.log('Is enrolled:', isEnrolled);
    console.log('Current user:', currentUser?.uid);
    
    // Check if lessons array exists and has content
    if (!course.lessons || !Array.isArray(course.lessons)) {
      console.error('Lessons is not an array:', course.lessons);
    } else if (course.lessons.length === 0) {
      console.warn('Lessons array is empty');
    } else {
      console.log('First lesson sample:', course.lessons[0]);
    }
    
    // Make sure course.lessons is an array before rendering
    const lessonsArray = course.lessons && Array.isArray(course.lessons) 
      ? course.lessons 
      : [];
    
    return (
      <div className="min-h-screen bg-gradient-to-br from-[#050508] via-[#0a0d14] to-[#0f1218]">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 pb-24 md:pb-8">
          {/* Course Header */}
          <div className="max-w-3xl mx-auto mb-12">
            <button
              onClick={handleBack}
              className="text-blue-400 hover:text-white mb-6 flex items-center group text-sm font-medium transition-all duration-200 py-2 px-4 bg-blue-900/20 hover:bg-blue-700/30 rounded-lg border border-blue-500/20 hover:border-blue-400/40 shadow-md hover:shadow-blue-500/10"
            >
              <ChevronLeft className="mr-2 h-5 w-5 transition-transform group-hover:-translate-x-1" />
              <span className="text-base"><TranslatedText id="backToCourses" /></span>
            </button>
            
            <h1 className="text-4xl font-bold text-white mb-4 leading-tight">
              {course.title}
              {course.is_premium && (
                <span className="ml-3 inline-flex items-center bg-yellow-500 bg-opacity-20 text-yellow-500 px-2 py-1 rounded-full text-sm font-medium">
                  <Crown className="h-4 w-4 mr-1.5" /> Premium
                </span>
              )}
            </h1>
            <p className="text-gray-300 text-lg mb-6 leading-relaxed">
              {course.description}
            </p>
            
            <div className="flex flex-wrap items-center gap-4 text-sm text-gray-400">
              {course.author && (
                <div className="flex items-center bg-[#12141c] px-3 py-2 rounded-lg shadow-md">
                  <GraduationCap className="mr-2 h-4 w-4 text-blue-400" />
                  {course.author}
                </div>
              )}
              <div className="flex items-center bg-[#12141c] px-3 py-2 rounded-lg shadow-md">
                <BookOpen className="mr-2 h-4 w-4 text-blue-400" />
                <TranslatedText 
                  id="lessonCount" 
                  params={{ count: lessonsArray.length }}
                />
              </div>
              
              {/* Premium warning for non-premium users */}
              {course.is_premium && !isPremium && (
                <div className="flex items-center bg-yellow-500 bg-opacity-10 text-yellow-500 px-3 py-2 rounded-lg border border-yellow-500 border-opacity-20 shadow-md">
                  <Crown className="mr-2 h-4 w-4" />
                  Only the first lesson is available for free users
                </div>
              )}
              
              {/* Enroll Button */}
              {!isEnrolled && (!course.is_premium || isPremium) && (
                <button
                  onClick={handleEnroll}
                  disabled={isEnrolling}
                  className={`flex items-center bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-lg transition-colors shadow-md hover:shadow-xl hover:shadow-blue-600/20 ${isEnrolling ? 'opacity-70' : ''}`}
                >
                  {isEnrolling ? 'Enrolling...' : 'Enroll Now'}
                  <ArrowRight className="ml-2 h-4 w-4" />
                </button>
              )}
              
              {/* Upgrade button for premium courses */}
              {!isEnrolled && course.is_premium && !isPremium && (
                <button
                  onClick={() => navigate('/dashboard/settings')}
                  className="flex items-center bg-yellow-600 hover:bg-yellow-700 text-white px-4 py-2 rounded-lg transition-colors shadow-md hover:shadow-xl hover:shadow-yellow-600/20"
                >
                  <Crown className="mr-2 h-4 w-4" />
                  Upgrade to Premium
                </button>
              )}
              
              {/* Enrolled Status and De-enroll button */}
              {isEnrolled && (
                <div className="flex items-center gap-2">
                  <div className="flex items-center bg-emerald-600 text-white px-4 py-2 rounded-lg shadow-md">
                    <CheckCircle className="mr-2 h-4 w-4" />
                    Enrolled
                  </div>
                  
                  <button
                    onClick={handleDeEnroll}
                    title="De-enroll from this course"
                    className="flex items-center bg-red-600 hover:bg-red-700 text-white px-3 py-2 rounded-lg transition-colors shadow-md hover:shadow-xl hover:shadow-red-600/20"
                  >
                    <LogOut className="h-4 w-4" />
                  </button>
                </div>
              )}
            </div>
          </div>

          {/* Lessons Timeline */}
          <div className="max-w-4xl mx-auto">
            <div className="relative">
              <div className="absolute left-0 sm:left-9 top-0 bottom-0 w-px bg-[#1f212a]"></div>
              
              <div className="space-y-8">
                {/* Safely render lessons or show error message */}
                {!Array.isArray(lessonsArray) ? (
                  <div className="text-white p-4 bg-red-500/20 border border-red-500/30 rounded-xl">
                    <p>Error: Course has invalid lessons data. Please try refreshing the page.</p>
                  </div>
                ) : lessonsArray.length === 0 ? (
                  <div className="text-white p-4 bg-blue-500/20 border border-blue-500/30 rounded-xl">
                    <p>This course doesn't have any lessons yet. Please check back later.</p>
                  </div>
                ) : (
                  // If we have valid lessons array, map through it
                  lessonsArray.map((lesson, index) => {
                    try {
                      // Use the lesson's ID or generate one based on index
                      const lessonId = lesson.id || `${course.id}-lesson-${String(index + 1).padStart(3, '0')}`;
                      console.log('Generated lessonId:', lessonId);
                      const progress = getLessonProgress(lessonId);
                      console.log('Progress for lesson:', progress);
                      const isCompleted = isLessonCompleted(lessonId);
                      const currentSlide = getLessonCurrentSlide(lessonId);
                      
                      // Check if lesson is locked due to premium restrictions
                      const isPremiumLocked = course.is_premium && !isPremium && index > 0;
                      
                      return (
                        <div 
                          key={index}
                          className="group relative flex flex-col sm:flex-row items-start gap-6 sm:gap-0"
                        >
                          {/* Timeline Node */}
                          <div className="hidden sm:flex absolute left-0 w-[74px] h-[74px] items-center justify-center">
                            <div className={`w-[74px] h-[74px] rounded-full border-4 ${
                              isPremiumLocked 
                                ? 'border-yellow-500/20 bg-yellow-500/10' 
                                : isCompleted 
                                  ? 'border-emerald-500/20 bg-emerald-500/10' 
                                  : 'border-blue-500/20 bg-blue-500/10'
                            } flex items-center justify-center text-2xl font-bold shadow-lg transition-all duration-300 group-hover:scale-105`}>
                              {isPremiumLocked 
                                ? <Lock className="h-8 w-8 text-yellow-500" /> 
                                : isCompleted 
                                  ? <CheckCircle className="h-8 w-8 text-emerald-500" /> 
                                  : index + 1}
                            </div>
                          </div>
                          
                          {/* Lesson Card */}
                          <div 
                            className={`flex-1 sm:ml-24 bg-[#12141c] rounded-xl border ${
                              isPremiumLocked 
                                ? 'border-yellow-500 bg-gradient-to-r from-[#12141c] to-yellow-900/10'
                                : isCompleted 
                                  ? 'border-emerald-500 bg-gradient-to-r from-[#12141c] to-emerald-900/10' 
                                  : 'border-[#1f212a] hover:border-blue-500'
                            } transition-all duration-200 cursor-pointer overflow-hidden shadow-lg hover:shadow-xl hover:-translate-y-1 w-full relative`}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              handleSelectLesson(lesson, index);
                            }}
                          >
                            {/* Progress bar at top of card */}
                            <div className="absolute top-0 left-0 w-full h-1.5 bg-[#0a0c14]/50">
                              <div 
                                className={`h-full transition-all duration-300 ${
                                  isPremiumLocked
                                    ? 'bg-gradient-to-r from-yellow-500 to-yellow-400'
                                    : isCompleted 
                                      ? 'bg-gradient-to-r from-emerald-500 to-emerald-400' 
                                      : progress > 0 
                                        ? 'bg-gradient-to-r from-blue-600 to-blue-400'
                                        : 'bg-transparent'
                                }`}
                                style={{ 
                                  width: isPremiumLocked ? '100%' : `${progress}%`,
                                  boxShadow: progress > 0 || isPremiumLocked ? '0 0 8px rgba(59, 130, 246, 0.5)' : 'none'
                                }}
                              />
                            </div>

                            <div className="p-6">
                              <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-4">
                                <div className="flex-1">
                                  {/* Mobile Timeline Number */}
                                  <div className="flex sm:hidden items-center gap-2 mb-2">
                                    <div className={`w-8 h-8 rounded-full ${
                                      isPremiumLocked 
                                        ? 'bg-yellow-500/20 text-yellow-400'
                                        : isCompleted 
                                          ? 'bg-emerald-500/20 text-emerald-400' 
                                          : 'bg-blue-500/20 text-blue-400'
                                    } flex items-center justify-center font-bold shadow-md`}>
                                      {isPremiumLocked 
                                        ? <Lock className="h-4 w-4" />
                                        : isCompleted 
                                          ? <CheckCircle className="h-4 w-4" /> 
                                          : index + 1}
                                    </div>
                                  </div>
                                  
                                  <h3 className={`text-xl font-bold ${
                                    isPremiumLocked 
                                      ? 'text-yellow-400'
                                      : isCompleted 
                                        ? 'text-emerald-400' 
                                        : 'text-white group-hover:text-blue-400'
                                  } transition-colors flex items-center gap-2`}>
                                    {lesson.title || 'Untitled Lesson'}
                                    <button 
                                      className={`${
                                        isPremiumLocked 
                                          ? 'text-yellow-400 hover:text-yellow-300'
                                          : isCompleted 
                                            ? 'text-emerald-400 hover:text-emerald-300' 
                                            : 'text-blue-400 hover:text-blue-300'
                                      } transition-transform duration-200 hover:scale-110`}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        toggleDescription(index);
                                      }}
                                    >
                                      <HelpCircle className="h-5 w-5" />
                                    </button>
                                  </h3>
                                  
                                  {/* Status indicators */}
                                  {currentUser && (
                                    <div className="mt-2 flex items-center">
                                      {isPremiumLocked ? (
                                        <span className="text-xs text-yellow-400 flex items-center">
                                          <Lock className="h-3 w-3 mr-1" /> Premium content
                                        </span>
                                      ) : isCompleted ? (
                                        <span className="text-xs text-emerald-400 flex items-center">
                                          <CheckCircle className="h-3 w-3 mr-1" /> Completed
                                        </span>
                                      ) : progress > 0 ? (
                                        <span className="text-xs text-blue-400 flex items-center">
                                          {Math.round(progress)}% complete
                                        </span>
                                      ) : null}
                                    </div>
                                  )}
                                </div>
                                
                                {/* Buttons for lesson actions */}
                                {isPremiumLocked && (
                                  <button 
                                    className="px-4 py-2 bg-yellow-600 hover:bg-yellow-700 text-white text-sm font-medium rounded-md transition-colors shadow-md hover:shadow-lg hover:shadow-yellow-600/20 flex items-center"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      navigate('/dashboard/settings');
                                    }}
                                  >
                                    <Crown className="mr-2 h-4 w-4" />
                                    Upgrade
                                  </button>
                                )}
                                
                                {currentUser && isEnrolled && !isCompleted && !isPremiumLocked && (
                                  <button 
                                    className="px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white text-sm font-medium rounded-md transition-colors shadow-md hover:shadow-lg hover:shadow-blue-600/20"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleSelectLesson(lesson, index);
                                    }}
                                  >
                                    {progress > 0 ? 'Continue' : 'Start'}
                                  </button>
                                )}
                                
                                {currentUser && isEnrolled && isCompleted && !isPremiumLocked && (
                                  <button 
                                    className="px-4 py-2 bg-emerald-600 hover:bg-emerald-700 text-white text-sm font-medium rounded-md transition-colors shadow-md hover:shadow-lg hover:shadow-emerald-600/20"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleSelectLesson(lesson, index);
                                    }}
                                  >
                                    Review
                                  </button>
                                )}
                              </div>
                            </div>
                            
                            {/* Description expansion area */}
                            {expandedDescriptions[index] && lesson.description && (
                              <div className="px-6 pb-5 pt-1 text-gray-300 border-t border-[#1f212a] mt-1">
                                <p>{lesson.description}</p>
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    } catch (error) {
                      console.error(`Error rendering lesson ${index}:`, error);
                      return (
                        <div key={index} className="text-white p-4 bg-red-500/20 border border-red-500/30 rounded-xl">
                          <p>Error rendering lesson {index + 1}: {error.message}</p>
                        </div>
                      );
                    }
                  })
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  return (
    <TextSelectionProvider>
      <LessonSelectorContent />
      <AskAIButton />
    </TextSelectionProvider>
  );
};

export default LessonSelector; 