import React, { createContext, useState, useContext, useEffect } from 'react';

// Available languages
export const LANGUAGES = {
  EN: 'en',
  JA: 'ja',
  MY: 'my',
};

// Create the language context
const LanguageContext = createContext();

// Custom hook to use the language context
export const useLanguage = () => useContext(LanguageContext);

// Language provider component
export const LanguageProvider = ({ children }) => {
  // Initialize language from localStorage or default to English
  const [language, setLanguage] = useState(() => {
    const savedLanguage = localStorage.getItem('preferredLanguage');
    return savedLanguage && Object.values(LANGUAGES).includes(savedLanguage)
      ? savedLanguage
      : LANGUAGES.EN;
  });

  // Effect to save language preference to localStorage and update HTML attributes
  useEffect(() => {
    localStorage.setItem('preferredLanguage', language);
    
    // Update the HTML lang attribute
    document.documentElement.lang = language;
    
    // Add language-specific classes to the HTML element
    document.documentElement.classList.remove('lang-en', 'lang-ja', 'lang-my');
    document.documentElement.classList.add(`lang-${language}`);
    
    // Update font classes based on language
    if (language === LANGUAGES.JA) {
      document.documentElement.classList.add('font-japanese');
      document.documentElement.classList.remove('font-sans', 'font-burmese');
    } else if (language === LANGUAGES.MY) {
      document.documentElement.classList.add('font-burmese');
      document.documentElement.classList.remove('font-sans', 'font-japanese');
    } else {
      document.documentElement.classList.add('font-sans');
      document.documentElement.classList.remove('font-japanese', 'font-burmese');
    }
  }, [language]);

  // Function to change language
  const changeLanguage = (lang) => {
    if (Object.values(LANGUAGES).includes(lang)) {
      setLanguage(lang);
    }
  };

  return (
    <LanguageContext.Provider value={{ language, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageContext; 