import AskAIButton from './AskAIButton';
import AIQuestionModal from './AIQuestionModal';
import { TextSelectionProvider, useTextSelection } from '../../context/TextSelectionContext';

export { AskAIButton, AIQuestionModal, TextSelectionProvider };

/**
 * AskAIWrapper - Wraps children with TextSelectionProvider and AI components
 * 
 * This wrapper component provides the text selection functionality and the UI components
 * that allow users to ask questions about selected text.
 * 
 * Props:
 * - children: Either React components to wrap, or a render function that receives
 *   the openQuestionModal function to use in child components
 * - showAskAI: Boolean to determine if Ask AI button should be shown (defaults to true)
 */
const AskAIWrapper = ({ children, showAskAI = true }) => {
  // Check if children is a function (render props pattern)
  const isRenderProps = typeof children === 'function';

  // Get the current URL to determine if we're inside a lesson
  const isInLesson = window.location.pathname.includes('/lesson/');

  // Only show Ask AI in lessons unless explicitly overridden by prop
  const shouldShowAskAI = showAskAI && isInLesson;

  return (
    <TextSelectionProvider>
      {/* Render the context provider */}
      {isRenderProps ? (
        /* Use render props pattern to pass the openQuestionModal function */
        <AskAIWrapperInner showAskAI={shouldShowAskAI}>
          {(contextProps) => children(contextProps)}
        </AskAIWrapperInner>
      ) : (
        /* Standard children */
        <>
          {children}
          {shouldShowAskAI && (
            <>
              <AskAIButton />
              <AIQuestionModal />
            </>
          )}
        </>
      )}
    </TextSelectionProvider>
  );
};

/**
 * Inner component that accesses the context and passes it to render props
 */
const AskAIWrapperInner = ({ children, showAskAI }) => {
  const { openQuestionModal } = useTextSelection();
  
  return (
    <>
      {children({ openQuestionModal })}
      {showAskAI && (
        <>
          <AskAIButton />
          <AIQuestionModal />
        </>
      )}
    </>
  );
};

export default AskAIWrapper;