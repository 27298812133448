import React, { useState, useEffect } from 'react';
import MonacoEditor from '@monaco-editor/react';

/**
 * CodingComponent - Provides an interactive code editor
 * 
 * This component offers a code editor for users to write and test code.
 * It uses Monaco Editor to provide a rich coding experience.
 */
const CodingComponent = ({ data, onSubmit }) => {
  // Extract props from data object or use direct props for backward compatibility
  const codeInstructions = data?.codeInstructions || data?.instructions;
  const startingCode = data?.startingCode || data?.code || '';
  const language = data?.language || 'javascript';
  const onCodeChange = onSubmit;
  
  const [code, setCode] = useState(startingCode || '');
  
  useEffect(() => {
    // If the starting code changes, update the internal state
    if (startingCode !== undefined) {
      setCode(startingCode);
    }
  }, [startingCode]);
  
  const handleEditorChange = (value) => {
    setCode(value);
    if (onCodeChange) {
      onCodeChange(value);
    }
  };
  
  // Monaco editor options
  const editorOptions = {
    minimap: { enabled: false },
    fontSize: 14,
    lineNumbers: 'on',
    scrollBeyondLastLine: false,
    automaticLayout: true,
    tabSize: 2,
    wordWrap: 'on',
    fixedOverflowWidgets: true,
    padding: { top: 16, bottom: 16 }
  };
  
  return (
    <div className="coding-component w-full my-4">
      {/* Instructions */}
      {codeInstructions && (
        <div className="mb-3 p-3 bg-gray-800 border-l-4 border-blue-500 rounded-lg text-gray-200">
          <h3 className="text-lg text-blue-400 font-semibold mb-1">Instructions</h3>
          <p className="text-sm">{codeInstructions}</p>
        </div>
      )}
      
      {/* Code editor */}
      <div className="border border-gray-700 rounded-lg overflow-hidden">
        <div className="bg-gray-800 text-gray-300 px-4 py-2 border-b border-gray-700 flex justify-between items-center">
          <div className="flex items-center">
            <span className="text-sm font-medium">{language.charAt(0).toUpperCase() + language.slice(1)} Editor</span>
          </div>
        </div>
        <div className="h-64">
          <MonacoEditor
            height="100%"
            language={language}
            value={code}
            theme="vs-dark"
            options={editorOptions}
            onChange={handleEditorChange}
          />
        </div>
      </div>
    </div>
  );
};

export default CodingComponent; 