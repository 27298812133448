import { useEffect, useRef } from 'react';

/**
 * Custom hook to auto-focus an element when component mounts or dependencies change
 * 
 * @param {boolean} shouldFocus - Whether the element should be focused
 * @param {number} delay - Optional delay in ms before focusing
 * @param {Array} deps - Optional dependency array for when to re-focus
 * @returns {React.RefObject} - Ref to attach to the element
 */
const useAutoFocus = (shouldFocus = true, delay = 300, deps = []) => {
  const elementRef = useRef(null);
  
  useEffect(() => {
    if (shouldFocus && elementRef.current) {
      const timeoutId = setTimeout(() => {
        elementRef.current.focus();
      }, delay);
      
      return () => clearTimeout(timeoutId);
    }
  }, deps); // eslint-disable-line react-hooks/exhaustive-deps
  
  return elementRef;
};

export default useAutoFocus; 