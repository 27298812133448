import { supabase } from '../auth/supabase';
import { saveToCache, loadFromCache, CACHE_KEYS, CACHE_DURATIONS } from './cachingService';

// Constants
const TABLES = {
  USERS: 'users',
  PROGRESS: 'progress',
  STREAK: 'streak'
};

// Initialize user data when a new user signs up
export const initUserData = async (userId) => {
  try {
    console.log('Initializing user data for ID:', userId);
    
    // Skipping connection test to avoid timeout issues
    console.log('Proceeding with user data initialization (connection test skipped)');

    // Add timeout protection for database operations
    const dbOperationTimeout = setTimeout(() => {
      console.error('Database operation timeout in initUserData');
      throw new Error('Database operation timeout');
    }, 15000); // 15 second timeout
    
    try {
      // Check if user exists
      const { data: existingUser, error: checkError } = await supabase
        .from(TABLES.USERS)
        .select('*')
        .eq('id', userId)
        .single();
      
      clearTimeout(dbOperationTimeout);
      
      // If there's an error other than "not found", log it and return false
      if (checkError && checkError.code !== 'PGRST116') {
        console.error('Error checking user:', checkError);
        return { status: 'error', error: checkError, errorType: 'user_check' };
      }
      
      // If user already exists, just update the login time
      if (existingUser) {
        console.log('User already exists in database, updating login time');
        try {
          const result = await updateUserLoginTime(userId);
          console.log('Login time update result:', result);
          return { status: 'updated', data: existingUser, updateResult: result };
        } catch (updateError) {
          console.error('Error updating login time:', updateError);
          return { status: 'error', error: updateError, errorType: 'login_update' };
        }
      }
      
      // User doesn't exist, create a new record
      console.log('Creating new user record in database');
      const now = new Date().toISOString();
      
      // Get user metadata from auth if available
      try {
        const { data: { user: authUser }, error: authError } = await supabase.auth.getUser(userId);
        
        if (authError) {
          console.error('Error fetching auth user:', authError);
          return { status: 'error', error: authError, errorType: 'auth_user_fetch' };
        }
        
        console.log('Auth user data:', authUser);
        
        // Get user email and name from auth with better handling for OAuth providers
        const email = authUser?.email;
        
        // Different providers store display names in different places
        let displayName;
        
        if (authUser?.app_metadata?.provider === 'google') {
          // Try to extract name from Google provider data
          displayName = 
            authUser?.user_metadata?.full_name || 
            authUser?.user_metadata?.name ||
            authUser?.user_metadata?.displayName || 
            email?.split('@')[0] || 
            'Learner';
            
          console.log('Created display name for Google user:', displayName);
        } else {
          // Regular email users
          displayName = 
            authUser?.user_metadata?.displayName || 
            email?.split('@')[0] || 
            'Learner';
        }
        
        // Build user data object
        const userData = {
          id: userId,
          created_at: now,
          last_login: now,
          last_activity: now,
          display_name: displayName,
          email: email,
          courses: {},
          streak: {
            current: 0,
            last_login: now.split('T')[0], // Store as YYYY-MM-DD
            highest_streak: 0
          },
          saved_courses: [],
          preferences: {
            notifications_enabled: false,
            dark_mode: true,
            language: 'en'
          },
          // Add auth provider info
          auth_provider: authUser?.app_metadata?.provider || 'email'
        };
        
        console.log('Inserting new user record:', userData);
        
        // Try database insertion with retry logic
        let insertError = null;
        let retryCount = 0;
        const maxRetries = 2;
        
        while (retryCount <= maxRetries) {
          try {
            const { error } = await supabase
              .from(TABLES.USERS)
              .insert(userData);
              
            if (!error) {
              console.log('Successfully created user data record');
              return { status: 'created', data: userData };
            }
            
            insertError = error;
            console.error(`Error initializing user data (attempt ${retryCount + 1}):`, error);
            retryCount++;
            
            if (retryCount <= maxRetries) {
              // Exponential backoff
              const delay = 1000 * Math.pow(2, retryCount - 1);
              console.log(`Retrying in ${delay}ms...`);
              await new Promise(resolve => setTimeout(resolve, delay));
            }
          } catch (error) {
            insertError = error;
            console.error(`Exception during insert (attempt ${retryCount + 1}):`, error);
            retryCount++;
            
            if (retryCount <= maxRetries) {
              // Exponential backoff
              const delay = 1000 * Math.pow(2, retryCount - 1);
              console.log(`Retrying in ${delay}ms...`);
              await new Promise(resolve => setTimeout(resolve, delay));
            }
          }
        }
        
        console.error('Failed to initialize user data after retries:', insertError);
        return { status: 'error', error: insertError, errorType: 'db_insert' };
      } catch (authError) {
        console.error('Error in auth user fetch:', authError);
        return { status: 'error', error: authError, errorType: 'auth_error' };
      }
    } catch (dbError) {
      clearTimeout(dbOperationTimeout);
      console.error('Database operation error:', dbError);
      return { status: 'error', error: dbError, errorType: 'db_operation' };
    }
  } catch (error) {
    console.error('Error initializing user data:', error);
    return { status: 'error', error, errorType: 'unknown' };
  }
};

// Update user login time and calculate streak
export const updateUserLoginTime = async (userId) => {
  try {
    // Get current user data
    const { data: userData, error: fetchError } = await supabase
      .from(TABLES.USERS)
      .select('*')
      .eq('id', userId)
      .single();
    
    if (fetchError) {
      console.error('Error fetching user data:', fetchError);
      return null;
    }
    
    const today = new Date().toISOString().split('T')[0]; // YYYY-MM-DD
    
    // Calculate streak
    let streak = userData.streak || { current: 0, last_login: today, highest_streak: 0 };
    
    if (streak.last_login) {
      const lastLoginDate = new Date(streak.last_login);
      const currentDate = new Date(today);
      
      // Calculate the difference in days
      const timeDiff = Math.abs(currentDate - lastLoginDate);
      const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
      
      if (daysDiff === 1) {
        // Consecutive day login, increment streak
        streak.current += 1;
        // Update highest streak if needed
        streak.highest_streak = Math.max(streak.current, streak.highest_streak);
      } else if (daysDiff > 1) {
        // Streak broken
        streak.current = 1;
      }
      // If daysDiff === 0, it's the same day, don't change streak
    } else {
      // First time tracking streak
      streak.current = 1;
      streak.highest_streak = 1;
    }
    
    // Update last login to today
    streak.last_login = today;
    
    // Update user record
    const { error: updateError } = await supabase
      .from(TABLES.USERS)
      .update({
        last_login: new Date().toISOString(),
        streak: streak
      })
      .eq('id', userId);
    
    if (updateError) {
      console.error('Error updating user login time:', updateError);
      return null;
    }
    
    return streak;
  } catch (error) {
    console.error('Error updating user login time:', error);
    return null;
  }
};

// Record user progress for a course
export const recordCourseProgress = async (userId, courseId, lessonId, progress, currentSlideIndex = 0, updateLocalState = null) => {
  try {
    const now = new Date().toISOString();
    let courses = {};
    
    // Create the progress data object that will be used for both local state and database
    const progressData = {
      progress: progress,
      current_slide: currentSlideIndex,
      completed: progress === 100,
      last_accessed: now
    };
    
    // If updateLocalState function is provided, update local state first for immediate UI update
    if (typeof updateLocalState === 'function') {
      // Call the function to update local state, which should return the updated user data
      const updatedUserData = updateLocalState(courseId, lessonId, progressData);
      
      // If we got valid user data back, we can use it instead of fetching again
      if (updatedUserData && updatedUserData.courses) {
        courses = updatedUserData.courses;
      }
    }
    
    // If we don't have courses data from local state, try to get from cache first
    if (Object.keys(courses).length === 0) {
      try {
        const cachedProgressData = loadFromCache(CACHE_KEYS.USER_PROGRESS(userId));
        if (cachedProgressData && cachedProgressData.data && cachedProgressData.data.courses) {
          courses = cachedProgressData.data.courses || {};
        }
      } catch (e) {
        console.error('Error reading progress from cache:', e);
      }
    }
    
    // If still no courses data, fetch from database
    if (Object.keys(courses).length === 0) {
      // Get current user data
      const { data: userData, error: fetchError } = await supabase
        .from(TABLES.USERS)
        .select('*')
        .eq('id', userId)
        .single();
      
      if (fetchError) {
        return false;
      }
      
      courses = userData.courses || {};
    }
    
    // Initialize course if it doesn't exist
    if (!courses[courseId]) {
      courses[courseId] = {
        started_at: now,
        last_accessed: now,
        progress: 0,
        lessons: {}
      };
    } else {
      // Update last accessed timestamp
      courses[courseId].last_accessed = now;
    }
    
    // Initialize or update lesson
    if (!courses[courseId].lessons[lessonId]) {
      courses[courseId].lessons[lessonId] = {
        started_at: now,
        last_accessed: now,
        progress: progress,
        current_slide: currentSlideIndex,
        completed: progress === 100
      };
    } else {
      // Only update progress if the new value is higher (prevent regression)
      if (progress > courses[courseId].lessons[lessonId].progress) {
        courses[courseId].lessons[lessonId].progress = progress;
      }
      courses[courseId].lessons[lessonId].last_accessed = now;
      courses[courseId].lessons[lessonId].current_slide = currentSlideIndex;
      courses[courseId].lessons[lessonId].completed = progress === 100;
    }
    
    // Calculate overall course progress based on lesson progress and completion status
    const totalLessons = Object.keys(courses[courseId].lessons).length;
    const completedLessons = Object.values(courses[courseId].lessons).filter(
      lesson => lesson.completed
    ).length;
    
    if (totalLessons > 0) {
      // Calculate course progress based on completed lessons percentage
      courses[courseId].progress = Math.floor((completedLessons / totalLessons) * 100);
    }
    
    // Update the user document
    const { error: updateError } = await supabase
      .from(TABLES.USERS)
      .update({
        courses: courses,
        last_activity: now
      })
      .eq('id', userId);
    
    if (updateError) {
      return false;
    }
    
    // Store in cache with timestamp using the cachingService
    try {
      const userData = {
        id: userId,
        courses: courses,
        last_activity: now
      };
      
      saveToCache(CACHE_KEYS.USER_PROGRESS(userId), userData);
      
      // Also invalidate/update dashboard cache since progress changed
      const dashboardCache = loadFromCache(CACHE_KEYS.DASHBOARD_CONTENT);
      if (dashboardCache && dashboardCache.data && dashboardCache.data.userId === userId) {
        // Mark it for refresh on next load by adjusting timestamp to be older
        const updatedCache = {
          ...dashboardCache.data,
          timestamp: Date.now() - CACHE_DURATIONS.DASHBOARD - 1000 // Force refresh
        };
        saveToCache(CACHE_KEYS.DASHBOARD_CONTENT, updatedCache);
      }
    } catch (cacheError) {
      console.error('Error caching user progress:', cacheError);
      // Continue even if caching fails
    }
    
    return true;
  } catch (error) {
    return false;
  }
};

// Mark a lesson as completed
export const markLessonCompleted = async (userId, courseId, lessonId, updateLocalState = null) => {
  try {
    return await recordCourseProgress(userId, courseId, lessonId, 100, 0, updateLocalState);
  } catch (error) {
    return false;
  }
};

// Mark a course as completed
export const markCourseCompleted = async (userId, courseId) => {
  try {
    // Get current user data
    const { data: userData, error: fetchError } = await supabase
      .from(TABLES.USERS)
      .select('*')
      .eq('id', userId)
      .single();
    
    if (fetchError) {
      return false;
    }
    
    const courses = userData.courses || {};
    
    if (courses[courseId]) {
      courses[courseId].progress = 100;
      courses[courseId].completed_at = new Date().toISOString();
      
      // Update the user document
      const { error: updateError } = await supabase
        .from(TABLES.USERS)
        .update({
          courses: courses,
          last_activity: new Date().toISOString()
        })
        .eq('id', userId);
      
      if (updateError) {
        return false;
      }
      
      return true;
    }
    
    return false;
  } catch (error) {
    return false;
  }
};

// Get user progress data
export const getUserProgress = async (userId) => {
  try {
    if (!userId) {
      console.error('getUserProgress called without a valid userId');
      return null;
    }
    
    console.log('getUserProgress called for userId:', userId);
    
    // Check cache first using the caching service
    const cachedProgressData = loadFromCache(CACHE_KEYS.USER_PROGRESS(userId));
    
    if (cachedProgressData && cachedProgressData.data) {
      console.log('Using cached user progress data');
      return cachedProgressData.data;
    }
    
    // Create abort controller for timeout
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 8000); // 8 second timeout
    
    const timeoutPromise = new Promise((_, reject) => {
      setTimeout(() => reject(new Error('getUserProgress timeout')), 8000); // 8 second timeout
    });
    
    console.log('Fetching user progress from database...');
    const startTime = Date.now();
    
    // If no cache or expired, fetch from database
    try {
      // Race with timeout to prevent hanging
      const { data, error } = await Promise.race([
        supabase
          .from(TABLES.USERS)
          .select('*')
          .eq('id', userId)
          .single()
          .abortSignal(controller.signal),
        timeoutPromise
      ]);
      
      const endTime = Date.now();
      console.log(`User progress fetch took ${endTime - startTime}ms`);
      
      clearTimeout(timeoutId);
      
      if (error) {
        console.error('Error fetching user progress:', error);
        
        // If we have cached data, use it even if it's expired
        if (cachedProgressData && cachedProgressData.data) {
          console.log('Using expired cached user progress data due to database error');
          return cachedProgressData.data;
        }
        
        // Create default empty user data structure
        console.log('Creating default user data structure due to database error');
        return {
          id: userId,
          courses: {},
          streak: { current: 0 },
          preferences: {
            dark_mode: true,
            language: 'en'
          }
        };
      }
      
      // Store in cache with timestamp
      try {
        saveToCache(CACHE_KEYS.USER_PROGRESS(userId), data);
      } catch (cacheError) {
        console.error('Error caching user progress:', cacheError);
        // Continue even if caching fails
      }
      
      return data;
    } catch (fetchError) {
      console.error('Fetch error in getUserProgress:', fetchError);
      clearTimeout(timeoutId);
      
      // Check if we have cached data, even if expired
      if (cachedProgressData && cachedProgressData.data) {
        console.log('Using expired cached user progress data due to fetch error');
        return cachedProgressData.data;
      }
      
      // Return empty data structure if we time out but need to show UI
      console.log('Creating default user data structure due to fetch error');
      return {
        id: userId,
        courses: {},
        streak: { current: 0 },
        preferences: {
          dark_mode: true,
          language: 'en'
        }
      };
    }
  } catch (error) {
    console.error('Critical error in getUserProgress:', error);
    
    // Always return a valid data structure even on critical errors
    return {
      id: userId,
      courses: {},
      streak: { current: 0 },
      preferences: {
        dark_mode: true,
        language: 'en'
      }
    };
  }
};

// Get user streak
export const getUserStreak = async (userId) => {
  try {
    if (!userId) {
      console.error('getUserStreak called without a valid userId');
      return { currentStreak: 0 };
    }
    
    console.log('getUserStreak called for userId:', userId);
    
    // Create abort controller for timeout
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 2000); // 2 second timeout
    
    const timeoutPromise = new Promise((_, reject) => {
      setTimeout(() => reject(new Error('getUserStreak timeout')), 2000);
    });
    
    try {
      // Race with timeout to prevent hanging
      const { data, error } = await Promise.race([
        supabase
          .from(TABLES.USERS)
          .select('streak')
          .eq('id', userId)
          .single()
          .abortSignal(controller.signal),
        timeoutPromise
      ]);
      
      clearTimeout(timeoutId);
      
      if (error) {
        console.error('Error getting user streak:', error);
        return { currentStreak: 0 };
      }
      
      const streakData = data.streak || { current: 0, last_login: null, highest_streak: 0 };
      return { currentStreak: streakData.current || 0, highestStreak: streakData.highest_streak || 0 };
    } catch (fetchError) {
      console.error('Fetch error in getUserStreak:', fetchError);
      return { currentStreak: 0 };
    }
  } catch (error) {
    console.error('Critical error in getUserStreak:', error);
    return { currentStreak: 0 };
  }
};

// Save a course to user's saved list
export const saveCourse = async (userId, courseId) => {
  try {
    console.log('Saving course:', { userId, courseId });
    
    const { data, error: fetchError } = await supabase
      .from(TABLES.USERS)
      .select('saved_courses')
      .eq('id', userId)
      .single();
    
    if (fetchError) {
      console.error('Error fetching saved courses:', fetchError);
      return false;
    }
    
    // Convert to array if it's null
    const savedCourses = Array.isArray(data.saved_courses) ? data.saved_courses : [];
    
    // Ensure courseId is a string
    const courseIdStr = String(courseId);
    
    // Check if course is already saved
    if (!savedCourses.includes(courseIdStr)) {
      savedCourses.push(courseIdStr);
      
      console.log('Updating saved courses:', savedCourses);
      
      const { error: updateError } = await supabase
        .from(TABLES.USERS)
        .update({
          saved_courses: savedCourses
        })
        .eq('id', userId);
      
      if (updateError) {
        console.error('Error saving course:', updateError);
        return false;
      }
    }
    
    return true;
  } catch (error) {
    console.error('Error saving course:', error);
    return false;
  }
};

// Remove a course from user's saved list
export const unsaveCourse = async (userId, courseId) => {
  try {
    console.log('Unsaving course:', { userId, courseId });
    
    const { data, error: fetchError } = await supabase
      .from(TABLES.USERS)
      .select('saved_courses')
      .eq('id', userId)
      .single();
    
    if (fetchError) {
      console.error('Error fetching saved courses:', fetchError);
      return false;
    }
    
    // Convert to array if it's null
    const savedCourses = Array.isArray(data.saved_courses) ? data.saved_courses : [];
    
    // Ensure courseId is a string
    const courseIdStr = String(courseId);
    
    // Filter out the courseId
    const updatedSavedCourses = savedCourses.filter(id => id !== courseIdStr);
    
    console.log('Updated saved courses:', updatedSavedCourses);
    
    const { error: updateError } = await supabase
      .from(TABLES.USERS)
      .update({
        saved_courses: updatedSavedCourses
      })
      .eq('id', userId);
    
    if (updateError) {
      console.error('Error unsaving course:', updateError);
      return false;
    }
    
    return true;
  } catch (error) {
    console.error('Error unsaving course:', error);
    return false;
  }
};

// Get user's saved courses
export const getSavedCourses = async (userId) => {
  try {
    const { data, error } = await supabase
      .from(TABLES.USERS)
      .select('saved_courses')
      .eq('id', userId)
      .single();
    
    if (error) {
      console.error('Error getting saved courses:', error);
      return [];
    }
    
    // Convert to array if it's null
    return Array.isArray(data.saved_courses) ? data.saved_courses : [];
  } catch (error) {
    console.error('Error getting saved courses:', error);
    return [];
  }
};

// New functions to get lesson and course progress
export const getLessonProgress = async (userId, courseId, lessonId) => {
  try {
    const { data, error } = await supabase
      .from(TABLES.USERS)
      .select('courses')
      .eq('id', userId)
      .single();
    
    if (error) {
      console.error('Error getting lesson progress:', error);
      return null;
    }
    
    if (data?.courses?.[courseId]?.lessons?.[lessonId]) {
      return data.courses[courseId].lessons[lessonId];
    }
    
    return null;
  } catch (error) {
    console.error('Error getting lesson progress:', error);
    return null;
  }
};

export const getCourseProgress = async (userId, courseId) => {
  try {
    if (!userId) {
      console.error('getCourseProgress: userId is undefined or null');
      return null;
    }
    
    if (!courseId) {
      console.error('getCourseProgress: courseId is undefined or null');
      return null;
    }
    
    const { data, error } = await supabase
      .from(TABLES.USERS)
      .select('courses')
      .eq('id', userId)
      .single();
    
    if (error) {
      console.error('Error getting course progress:', error);
      return null;
    }
    
    if (data?.courses) {
      // First check for direct match with provided courseId
      if (data.courses[courseId]) {
        const courseData = data.courses[courseId];
        
        // Ensure the progress object has all required fields
        return {
          progress: courseData.progress || 0,
          started_at: courseData.started_at || new Date().toISOString(),
          last_accessed: courseData.last_accessed || new Date().toISOString(),
          completed: courseData.completed || false,
          lessons: courseData.lessons || {}
        };
      }
      
      // If not found, try converting to number if string or to string if number
      const alternativeId = typeof courseId === 'string' && !isNaN(parseInt(courseId)) 
        ? parseInt(courseId) 
        : typeof courseId === 'number' ? courseId.toString() : null;
      
      if (alternativeId !== null && data.courses[alternativeId]) {
        const courseData = data.courses[alternativeId];
        console.log(`Found course progress using alternative ID: ${alternativeId}`);
        
        return {
          progress: courseData.progress || 0,
          started_at: courseData.started_at || new Date().toISOString(),
          last_accessed: courseData.last_accessed || new Date().toISOString(),
          completed: courseData.completed || false,
          lessons: courseData.lessons || {}
        };
      }
    }
    
    return null;
  } catch (error) {
    console.error('Error getting course progress:', error);
    return null;
  }
};

// Enroll user in a course
export const enrollInCourse = async (userId, courseId) => {
  try {
    if (!userId) {
      console.error('enrollInCourse: userId is undefined or null');
      return false;
    }
    
    if (!courseId) {
      console.error('enrollInCourse: courseId is undefined or null');
      return false;
    }
    
    // Ensure courseId is always a string to be consistent
    const courseIdStr = String(courseId);
    
    console.log('Starting enrollment process for user', userId, 'in course', courseIdStr);
    
    // Get current user data
    const { data: userData, error: fetchError } = await supabase
      .from(TABLES.USERS)
      .select('courses')
      .eq('id', userId)
      .single();
    
    if (fetchError) {
      console.error('Error fetching user data:', fetchError);
      return false;
    }
    
    console.log('Current user data:', userData);
    
    // Make sure we're working with an object
    const courses = userData.courses || {};
    const now = new Date().toISOString();
    
    // Check if user is already enrolled in this course using either string or numeric ID
    if (courses[courseIdStr] || courses[parseInt(courseIdStr)]) {
      const existingCourseId = courses[courseIdStr] ? courseIdStr : parseInt(courseIdStr);
      console.log(`User already enrolled in course with ID ${existingCourseId}, updating last_accessed`);
      
      // User is already enrolled, just update the last_accessed timestamp
      courses[existingCourseId].last_accessed = now;
    } else {
      console.log('Enrolling user in course for the first time with ID:', courseIdStr);
      // Initialize the course with default values
      courses[courseIdStr] = {
        started_at: now,
        last_accessed: now,
        progress: 0,
        completed: false,
        lessons: {}
      };
    }
    
    console.log('Updated courses data to save:', courses);
    
    // Update the user document
    const { data: updateData, error: updateError } = await supabase
      .from(TABLES.USERS)
      .update({
        courses: courses,
        last_activity: now
      })
      .eq('id', userId)
      .select();
    
    if (updateError) {
      console.error('Error enrolling in course:', updateError);
      return false;
    }
    
    console.log('Successfully enrolled user in course. Updated data:', updateData);
    return true;
  } catch (error) {
    console.error('Error enrolling in course:', error);
    return false;
  }
};

// De-enroll user from a course
export const deEnrollFromCourse = async (userId, courseId) => {
  try {
    if (!userId) {
      console.error('deEnrollFromCourse: userId is undefined or null');
      return false;
    }
    
    if (!courseId) {
      console.error('deEnrollFromCourse: courseId is undefined or null');
      return false;
    }
    
    // Ensure courseId is always a string to be consistent
    const courseIdStr = String(courseId);
    
    console.log('Starting de-enrollment process for user', userId, 'from course', courseIdStr);
    
    // Get current user data
    const { data: userData, error: fetchError } = await supabase
      .from(TABLES.USERS)
      .select('courses')
      .eq('id', userId)
      .single();
    
    if (fetchError) {
      console.error('Error fetching user data:', fetchError);
      return false;
    }
    
    // Make sure we're working with an object
    const courses = userData.courses || {};
    const now = new Date().toISOString();
    
    // Check if user is enrolled in this course
    if (!courses[courseIdStr] && !courses[parseInt(courseIdStr)]) {
      console.log('User is not enrolled in this course');
      return false;
    }
    
    // Remove the course from the user's enrolled courses
    const existingCourseId = courses[courseIdStr] ? courseIdStr : parseInt(courseIdStr);
    console.log(`Removing course with ID ${existingCourseId} from user's enrolled courses`);
    
    // Create a new courses object without the course to be removed
    const updatedCourses = { ...courses };
    delete updatedCourses[existingCourseId];
    
    console.log('Updated courses data to save:', updatedCourses);
    
    // Update the user document
    const { data: updateData, error: updateError } = await supabase
      .from(TABLES.USERS)
      .update({
        courses: updatedCourses,
        last_activity: now
      })
      .eq('id', userId)
      .select();
    
    if (updateError) {
      console.error('Error de-enrolling from course:', updateError);
      return false;
    }
    
    console.log('Successfully de-enrolled user from course');
    return true;
  } catch (error) {
    console.error('Error de-enrolling from course:', error);
    return false;
  }
};

// Record a user's answer result for a question
export const recordAnswerResult = async (userId, courseId, lessonId, slideId, answerData) => {
  if (!userId || !courseId || !lessonId) return false;
  
  try {
    // Get current user data
    const { data: userData, error: fetchError } = await supabase
      .from(TABLES.USERS)
      .select('*')
      .eq('id', userId)
      .single();
    
    if (fetchError) {
      console.error('Error fetching user data for recording answer:', fetchError);
      return false;
    }
    
    // Initialize courses object if it doesn't exist
    const courses = userData.courses || {};
    
    // Initialize course if it doesn't exist
    if (!courses[courseId]) {
      courses[courseId] = {
        started_at: new Date().toISOString(),
        last_accessed: new Date().toISOString(),
        progress: 0,
        lessons: {}
      };
    }
    
    // Initialize lesson if it doesn't exist
    if (!courses[courseId].lessons[lessonId]) {
      courses[courseId].lessons[lessonId] = {
        started_at: new Date().toISOString(),
        last_accessed: new Date().toISOString(),
        progress: 0,
        current_slide: 0,
        completed: false,
        answers: {}
      };
    } else if (!courses[courseId].lessons[lessonId].answers) {
      // Ensure answers object exists
      courses[courseId].lessons[lessonId].answers = {};
    }
    
    // Store the answer data
    courses[courseId].lessons[lessonId].answers[slideId] = answerData;
    
    // Update last accessed time
    courses[courseId].last_accessed = new Date().toISOString();
    courses[courseId].lessons[lessonId].last_accessed = new Date().toISOString();
    
    // Update the user document
    const { error: updateError } = await supabase
      .from(TABLES.USERS)
      .update({
        courses: courses,
        last_activity: new Date().toISOString()
      })
      .eq('id', userId);
    
    if (updateError) {
      console.error('Error saving answer result:', updateError);
      return false;
    }
    
    // Update cache
    try {
      saveToCache(CACHE_KEYS.USER_PROGRESS(userId), {
        id: userId,
        courses: courses,
        last_activity: new Date().toISOString()
      });
    } catch (cacheError) {
      console.error('Error caching user progress after recording answer:', cacheError);
      // Continue even if caching fails
    }
    
    return true;
  } catch (error) {
    console.error('Error in recordAnswerResult:', error);
    return false;
  }
}; 