import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { 
  Slide, 
  SlideRenderer, 
  COMPONENT_TYPES, 
  convertLegacySlideType
} from '../slides';
import { X, Heart, HeartOff, ArrowRight, ChevronLeft } from 'lucide-react';
import { COMPONENT_TYPES as SLIDE_TYPES } from '../slideComponents';
import { PHASE_TYPES } from '../../data/courseSchema';
import AskAIWrapper from '../../ui/AskAI';
import { TextSelectionProvider, useTextSelection } from '../../context/TextSelectionContext';
import { getIdFromSlug } from '../../utils/urlUtils';
import { useAuth } from '../../../../auth/AuthContext';
import { 
  recordCourseProgress, 
  markLessonCompleted, 
  saveCourse, 
  unsaveCourse, 
  getSavedCourses,
  getLessonProgress,
  enrollInCourse,
  getUserProgress
} from '../../../../services/userProgress';
import { createSlug } from '../../utils/urlUtils';
import { evaluateAnswer } from '../../../../services/aiService';
import { getLessonById, getLessonBySlug } from '../../../../services/lessonService';
import { getCourseById, getCourseBySlug } from '../../../../services/courseService';
import { getAllCourses } from '../../../../services/courseService';
import { createClient } from '@supabase/supabase-js';

// Add a debounce utility at the top of the file
// This function will limit how often the provided function can be called
const debounce = (func, wait) => {
  let timeout;
  return function(...args) {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
};

/**
 * CoursePlayer Component - Ultra Simplified Version
 * 
 * A basic slide navigator that displays all slides in a scrollable container.
 * No navigation buttons, progress bar, or slide count - just content.
 * 
 * Props:
 * - course: The course object containing slides and metadata
 * - onComplete: Callback when course is finished
 * - onBack: Callback to return to lesson selection
 */
const CoursePlayer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { courseSlug, lessonSlug } = useParams();
  const [course, setCourse] = useState(null);
  const [parentCourse, setParentCourse] = useState(null);
  const { currentUser, updateLocalUserProgress } = useAuth();
  const [isSaved, setIsSaved] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isEnrolled, setIsEnrolled] = useState(false);
  const [isEnrolling, setIsEnrolling] = useState(false);
  
  // Reference for slide elements
  const slideRefs = useRef([]);
  // Track current slide index for progress bar (now only using visibility-based detection)
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const slideContainerRef = useRef(null);
  // Track if user is on the last slide to show finish button
  const [isOnLastSlide, setIsOnLastSlide] = useState(false);
  // Flag to prevent hash update during programmatic scrolling
  const isScrollingProgrammatically = useRef(false);
  // Add textarea ref for user input slides
  const textareaRef = useRef(null);
  
  // Store input for each slide separately using a map
  const [slideInputs, setSlideInputs] = useState({});
  
  // Store the current input for the active slide
  const [activeInput, setActiveInput] = useState('');
  
  // State to track if a submission is currently being processed
  const [isSubmitting, setIsSubmitting] = useState(false);
  
  // Add this to the component, after the state declarations and before useEffect hooks
  // Create a debounced version of history.replaceState
  const updateUrlHash = useRef(
    debounce((newHash) => {
      if (location.hash !== `#${newHash}`) {
        window.history.replaceState(null, '', `${location.pathname}#${newHash}`);
      }
    }, 300) // Only allow updates every 300ms
  ).current;
  
  // Update handlers for user inputs
  const handleInputChange = useCallback((value, slideIndex = null) => {
    // Use provided slideIndex or fall back to current slide
    const targetIndex = slideIndex !== null ? slideIndex : currentSlideIndex;
    
    // Update the active input immediately to ensure UI responsiveness
    setActiveInput(value);
      
    // Store in the map for the correct slide
    setSlideInputs(prev => ({
      ...prev,
      [targetIndex]: value
    }));
  }, [currentSlideIndex]);
  
  const handleNavigateQuestion = useCallback((index) => {
    // Ensure we only ever navigate to question (0) or feedback (1) slides
    const safeIndex = index > 1 ? 1 : index;
    
    // Navigate between questions in UserInputSlide
    // Only update state if the index has changed
    setSlideInputs(prev => {
      const currentIndex = prev[`${currentSlideIndex}_activeQuestionIndex`] || 0;
      if (currentIndex !== safeIndex) {
        return {
          ...prev,
          [`${currentSlideIndex}_activeQuestionIndex`]: safeIndex
        };
      }
      return prev;
    });
  }, [currentSlideIndex]);
  
  const handleSubmit = useCallback(() => {
    const input = slideInputs[currentSlideIndex] || activeInput;
    
    // Make sure we have an input to process and we're not already submitting
    if (!input || isSubmitting) {
      return;
    }
    
    // Set loading state
    setIsSubmitting(true);
    
    // Get the current slide question
    const currentSlide = course?.slides?.[currentSlideIndex];
    if (!currentSlide) {
      setIsSubmitting(false);
      return;
    }
    
    const question = currentSlide.question || '';
    const questionDescription = currentSlide.questionDescription || '';
    
    // Call the OpenAI API to evaluate the answer
    (async () => {
      try {
        // Get AI feedback on the answer
        const feedback = await evaluateAnswer(question, input, questionDescription);
        
        // Update the slide inputs with the feedback
        setSlideInputs(prev => ({
          ...prev,
          [`${currentSlideIndex}_feedback`]: feedback
        }));
        
        // Reset loading state
        setIsSubmitting(false);
        
        // Navigate to the feedback slide (index 1)
        handleNavigateQuestion(1);
      } catch (error) {
        console.error('Error evaluating answer:', error);
        
        // Provide fallback feedback in case of error
        const fallbackFeedback = {
          isCorrect: false,
          feedback: "There was an error evaluating your answer. Please try again later.",
          expectedAnswer: "Unable to generate expected answer at this time."
        };
        
        setSlideInputs(prev => ({
          ...prev,
          [`${currentSlideIndex}_feedback`]: fallbackFeedback
        }));
        
        setIsSubmitting(false);
        handleNavigateQuestion(1);
      }
    })();
    
  }, [currentSlideIndex, slideInputs, activeInput, handleNavigateQuestion, course, isSubmitting]);
  
  // Initialize slide refs when course changes
  useEffect(() => {
    if (course?.slides) {
      slideRefs.current = Array(course.slides.length).fill().map((_, i) => slideRefs.current[i] || React.createRef());
    }
  }, [course]);
  
  // Visibility-based slide detection with improved reliability
  const setupVisibilityObserver = useCallback(() => {
    if (!course?.slides) return () => {};

    const options = {
      root: slideContainerRef.current,
      threshold: 0.2, 
      rootMargin: '0px'
    };

    // Create observer once slides are available
    const observer = new IntersectionObserver((entries) => {
      // Find the most visible slide (highest intersection ratio)
      const visibleEntry = entries
        .filter(entry => entry.isIntersecting)
        .sort((a, b) => b.intersectionRatio - a.intersectionRatio)[0];
      
      if (visibleEntry) {
        const slideElement = visibleEntry.target;
        const slideIndex = parseInt(slideElement.getAttribute('data-slide-index'));
        
        if (!isNaN(slideIndex) && slideIndex >= 0 && slideIndex < course.slides.length) {
          // Update current slide index based on visibility
          if (slideIndex !== currentSlideIndex) {
            setCurrentSlideIndex(slideIndex);
            
            // Update URL hash with debounced function
            const newHash = `slide-${slideIndex + 1}`;
            updateUrlHash(newHash);
            
            // Check if on last slide
            setIsOnLastSlide(slideIndex === course.slides.length - 1);
          }
        }
      }
    }, options);

    // More robust slide detection with retry limit and different approaches
    let retryCount = 0;
    const MAX_RETRIES = 10;
    
    const setupObserver = () => {
      // First check if we exceeded max retries
      if (retryCount >= MAX_RETRIES) {
        // Force setLoading to false if still loading
        if (isLoading) {
          setIsLoading(false);
        }
        
        // Try one final approach by force-traversing the DOM
        const slideContainer = document.getElementById('slide-container');
        if (slideContainer) {
          const allDivs = slideContainer.querySelectorAll('div[id^="slide-"]');
          if (allDivs.length > 0) {
            allDivs.forEach(element => {
              // Extract index from ID (slide-1, slide-2, etc.)
              if (!element.hasAttribute('data-slide-index')) {
                const idMatch = element.id && element.id.match(/slide-(\d+)/);
                if (idMatch && idMatch[1]) {
                  const slideIndex = parseInt(idMatch[1], 10) - 1;
                  element.setAttribute('data-slide-index', slideIndex);
                }
              }
              observer.observe(element);
            });
            return;
          }
        }
        
        // If we still can't find slides, render the content anyway
        return;
      }
      
      retryCount++;
      
      // Try multiple selector approaches to find slides
      let slideElements = [];
      
      // Approach 1: Direct query for data-slide-index
      slideElements = Array.from(document.querySelectorAll('[data-slide-index]'));
      
      // Approach 2: Find elements by slide-X ID pattern
      if (slideElements.length === 0) {
        const slidesByIdPattern = Array.from(document.querySelectorAll('div[id^="slide-"]'));
        if (slidesByIdPattern.length > 0) {
          slideElements = slidesByIdPattern;
          
          // Add data-slide-index attributes if missing
          slidesByIdPattern.forEach(element => {
            if (!element.hasAttribute('data-slide-index')) {
              const idMatch = element.id && element.id.match(/slide-(\d+)/);
              if (idMatch && idMatch[1]) {
                const slideIndex = parseInt(idMatch[1], 10) - 1;
                element.setAttribute('data-slide-index', slideIndex);
              }
            }
          });
        }
      }
      
      // Approach 3: Try the container's child elements
      if (slideElements.length === 0) {
        const slideContainer = slideContainerRef.current;
        if (slideContainer) {
          const directChildren = Array.from(slideContainer.children);
          if (directChildren.length > 0 && directChildren.length === course.slides.length) {
            slideElements = directChildren;
            
            // Add data-slide-index attributes
            directChildren.forEach((element, index) => {
              if (!element.hasAttribute('data-slide-index')) {
                element.setAttribute('data-slide-index', index);
              }
            });
          }
        }
      }
      
      // Approach 4: Look for elements with the specific class used for slides
      if (slideElements.length === 0) {
        const slidesByClass = Array.from(document.querySelectorAll('.min-h-screen.snap-start.snap-always'));
        if (slidesByClass.length > 0) {
          slideElements = slidesByClass;
          
          // Add data-slide-index attributes
          slidesByClass.forEach((element, index) => {
            if (!element.hasAttribute('data-slide-index')) {
              element.setAttribute('data-slide-index', index);
            }
          });
        }
      }
      
      if (slideElements.length > 0) {
        slideElements.forEach(element => {
          if (element) {
            observer.observe(element);
          }
        });
        
        // Set loading to false since we found slides
        if (isLoading) {
          setIsLoading(false);
        }
      } else {
        // Keep trying until slides are available or max retries reached
        setTimeout(setupObserver, 500);
      }
    };
    
    // Start trying to observe slides after DOM has had time to render
    setTimeout(setupObserver, 300);

    return () => observer.disconnect();
  }, [course, currentSlideIndex, location.pathname, isLoading]);
  
  // Effect to handle location hash changes and programmatic scrolling
  useEffect(() => {
    const slideContainer = slideContainerRef.current;
    
    if (!slideContainer || !course?.slides || course.slides.length === 0) return;
    
    // Handle programmatic scroll to slide based on URL hash
    if (location.hash) {
      const hashMatch = location.hash && location.hash.match(/slide-(\d+)/);
      if (hashMatch && hashMatch[1]) {
        const slideNumber = parseInt(hashMatch[1], 10);
        if (!isNaN(slideNumber) && slideNumber > 0 && slideNumber <= course.slides.length) {
          const targetIndex = slideNumber - 1;
          
          // Use setTimeout to ensure the scroll happens after render
          setTimeout(() => {
            isScrollingProgrammatically.current = true;
            slideContainer.scrollTo({
              top: targetIndex * slideContainer.clientHeight,
              behavior: 'smooth'
            });
            setCurrentSlideIndex(targetIndex);
            setIsOnLastSlide(targetIndex === course.slides.length - 1);
            
            // Reset the flag after scrolling is complete
            setTimeout(() => {
              isScrollingProgrammatically.current = false;
            }, 500);
          }, 300); // Increased delay for better reliability
        }
      }
    } else if (!isLoading && course && parentCourse && currentUser) {
      // No hash in URL - determine appropriate slide based on progress
      // This fixes the bug of automatically scrolling to wrong slides
      let targetSlide = 0; // Default to first slide
      
      // Try to get lesson progress
      if (course.id) {
        const calculateTargetSlide = async () => {
          try {
            const lessonProgress = await getLessonProgress(currentUser.uid, parentCourse.id, course.id);
            
            if (lessonProgress) {
              const progressPercent = lessonProgress.progress || 0;
              const isCompleted = lessonProgress.completed || progressPercent === 100;
              
              // Apply the new logic requirements:
              // 1. If progress is 0% or 100%, go to the first slide
              if (progressPercent === 0 || isCompleted) {
                targetSlide = 0;
              } 
              // 2. If progress is between 0% and 100%, calculate the appropriate slide
              else if (progressPercent > 0 && progressPercent < 100) {
                // If a specific current_slide is saved, use that
                if (typeof lessonProgress.current_slide === 'number') {
                  targetSlide = lessonProgress.current_slide;
                } else {
                  // Otherwise calculate based on percentage
                  // More intuitive slide calculation based on progress percentage
                  const totalSlides = course.slides.length;
                  
                  // Log calculation details
                  console.log('--- Slide Calculation Details (CoursePlayer) ---');
                  console.log(`Progress: ${progressPercent}%`);
                  console.log(`Total slides: ${totalSlides}`);
                  console.log(`Formula: Math.ceil((${progressPercent} / 100) * ${totalSlides}) - 1`);
                  
                  // If someone is 75% through a 10-slide lesson, they should be on slide 8
                  const calculatedSlideIndex = Math.ceil((progressPercent / 100) * totalSlides) - 1;
                  console.log(`Raw calculated slide index: ${calculatedSlideIndex}`);
                  
                  // Make sure we don't automatically go to the very last slide
                  // This prevents sending users to the completion slide automatically
                  const safeSlideIndex = Math.min(calculatedSlideIndex, totalSlides - 2);
                  console.log(`After max slide limit (total - 2): ${safeSlideIndex}`);
                  
                  // Ensure we're at least on slide 1 (index 0)
                  targetSlide = Math.max(0, safeSlideIndex);
                  console.log(`Final slide index: ${targetSlide} (slide #${targetSlide + 1})`);
                  console.log('----------------------------------------------');
                }
              }
              
              // Only scroll if not on the first slide already
              if (targetSlide > 0) {
                console.log(`Navigating to saved progress slide: ${targetSlide + 1}`);
                
                setTimeout(() => {
                  isScrollingProgrammatically.current = true;
                  slideContainer.scrollTo({
                    top: targetSlide * slideContainer.clientHeight,
                    behavior: 'smooth'
                  });
                  setCurrentSlideIndex(targetSlide);
                  setIsOnLastSlide(targetSlide === course.slides.length - 1);
                  
                  // Update URL with the calculated slide position using debounced function
                  const newHash = `slide-${targetSlide + 1}`;
                  updateUrlHash(newHash);
                  
                  // Reset the flag after scrolling is complete
                  setTimeout(() => {
                    isScrollingProgrammatically.current = false;
                  }, 500);
                }, 300);
              }
            }
          } catch (error) {
            console.error('Error calculating target slide:', error);
          }
        };
        
        calculateTargetSlide();
      }
    }
    
  }, [course, location.hash, location.pathname, isLoading, parentCourse, currentUser]);
  
  // Find the course and lesson based on URL params
  useEffect(() => {
    const loadCourseAndProgress = async () => {
      setIsLoading(true);
      const courseId = getIdFromSlug(courseSlug);
      const lessonId = getIdFromSlug(lessonSlug);
      
      console.log('Loading course and lesson with IDs/slugs:', courseId, lessonSlug);
      console.log('Full lesson slug:', lessonSlug);
      
      // Try to find the course in Supabase in several ways
      let foundCourse = null;
      
      try {
        // Strategy 1: Try direct ID match
        console.log('Trying to find course by ID:', courseId);
        foundCourse = await getCourseById(courseId);
        
        // Strategy 2: Try by slug
        if (!foundCourse) {
          console.log('Trying to find course by slug:', courseSlug);
          foundCourse = await getCourseBySlug(courseSlug);
        }
        
        // Strategy 3: For SQL courses, try with a more specific query
        if (!foundCourse && (courseSlug === 'sql' || courseSlug.startsWith('sql-'))) {
          console.log('Looking for SQL course');
          // This assumes courseService has a method to find SQL courses
          // You might need to add this method to courseService.js
          const allCourses = await getAllCourses();
          foundCourse = allCourses.find(c => 
            c.title?.toLowerCase().includes('sql') || 
            c.slug?.includes('sql')
          );
        }
        
        if (!foundCourse) {
          console.error('Course not found:', courseSlug);
          navigate('/dashboard/courses');
          return;
        }
        
        console.log('Found course:', foundCourse.title);
        setParentCourse(foundCourse);
        
        // First try to get the lesson by slug directly
        let lessonFromDB = null;
        
        try {
          // Primary lookup method: by slug
          console.log('Looking for lesson by slug:', lessonSlug);
          lessonFromDB = await getLessonBySlug(lessonSlug, foundCourse.id);
        } catch (slugError) {
          console.log('Failed to find lesson by slug:', slugError.message);
          
          // Fallback to ID-based methods if slug lookup fails
          const possibleLessonIds = [
            lessonId,                       // Original ID from slug
            `${foundCourse.id}-${lessonId}`, // Composite ID with course ID prefix
            lessonSlug                       // Use the full slug as ID
          ];
          
          // Add numbers-only IDs if applicable
          if (!isNaN(parseInt(lessonId))) {
            const numericId = parseInt(lessonId);
            possibleLessonIds.push(
              `${foundCourse.id}-lesson-${String(numericId).padStart(3, '0')}`
            );
          }
          
          console.log('Trying lesson IDs as fallback:', possibleLessonIds);
          
          // Try each possible ID format until we find the lesson
          for (const possibleId of possibleLessonIds) {
            try {
              console.log('Attempting to load lesson with ID:', possibleId);
              lessonFromDB = await getLessonById(possibleId);
              
              if (lessonFromDB) {
                console.log('Successfully loaded lesson from database:', lessonFromDB.title);
                break;
              }
            } catch (error) {
              console.log(`Failed to load lesson with ID ${possibleId}:`, error.message);
            }
          }
        }
        
        if (lessonFromDB) {
          // Parse slides if they're stored as a JSON string
          let slides = lessonFromDB.slides;
          if (typeof slides === 'string') {
            try {
              slides = JSON.parse(slides);
              console.log(`Parsed slides JSON, found ${slides.length} slides`);
            } catch (e) {
              console.error('Error parsing slides JSON:', e);
              slides = [];
            }
          } else if (!slides) {
            console.warn('No slides found in lesson data');
            slides = [];
          }
          
          // Create the complete lesson object
          const processedLesson = {
            ...lessonFromDB,
            slides: slides
          };
          
          setCourse(processedLesson);
          
          // Check if course is saved and if user is enrolled
          if (currentUser && currentUser.uid) {
            try {
              const savedCourses = await getSavedCourses(currentUser.uid);
              setIsSaved(savedCourses.includes(foundCourse.id));
              
              // Check if user is enrolled - improved verification
              const userData = await getUserProgress(currentUser.uid);
              let userIsEnrolled = false;
              
              if (userData && userData.courses) {
                // Check all possible ways the course ID might be stored
                const courseIdStr = foundCourse.id.toString();
                const courseIdNum = parseInt(foundCourse.id);
                
                // Direct string match
                if (userData.courses[courseIdStr] !== undefined) {
                  userIsEnrolled = true;
                }
                // Numeric ID match if possible
                else if (!isNaN(courseIdNum) && userData.courses[courseIdNum] !== undefined) {
                  userIsEnrolled = true;
                }
                // Check if the course ID is stored as a property with a different casing
                else {
                  const courseKeys = Object.keys(userData.courses);
                  for (const key of courseKeys) {
                    if (key && courseIdStr && key.toLowerCase() === courseIdStr.toLowerCase()) {
                      userIsEnrolled = true;
                      break;
                    }
                  }
                }
              }
              
              setIsEnrolled(userIsEnrolled);
              
              // The URL hash will override this logic - only apply if no hash is present
              // Don't set current slide here - let the dedicated scroll effect handle it
            } catch (error) {
              console.error('Error checking enrollment/saved status:', error);
              // Continue with default values
              setIsSaved(false);
              setIsEnrolled(false);
            }
          } else {
            setIsSaved(false);
            setIsEnrolled(false);
          }
        } else {
          // Lesson not found in Supabase - send back to lesson selector
          console.error('Lesson not found in database');
          alert('This lesson could not be found in our database. Please select another lesson.');
          navigate(`/dashboard/courses/${courseSlug}`);
          return;
        }
      } catch (error) {
        console.error('Error loading course or lesson:', error);
        navigate('/dashboard/courses');
        return;
      } finally {
        setIsLoading(false);
      }
    };
    
    loadCourseAndProgress();
  }, [courseSlug, lessonSlug, navigate, currentUser, location.pathname]);
  
  // Add visibility change detection to recover from blank screens
  useEffect(() => {
    let wasHidden = false;
    let blankScreenDetectionTimeout = null;
    
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        wasHidden = true;
        // Clear any pending detection timeout
        if (blankScreenDetectionTimeout) {
          clearTimeout(blankScreenDetectionTimeout);
        }
      } else if (document.visibilityState === 'visible' && wasHidden) {
        wasHidden = false;
        
        // Set a delayed check to see if content is visible
        blankScreenDetectionTimeout = setTimeout(() => {
          // Check if slide container has visible content
          const slideContainer = document.getElementById('slide-container');
          const hasVisibleSlides = slideContainer && 
            slideContainer.children.length > 0 && 
            Array.from(slideContainer.children).some(child => 
              child.offsetHeight > 0 && child.getBoundingClientRect().height > 0
            );
            
          if (!hasVisibleSlides && course && course.slides && course.slides.length > 0) {
            console.log('Blank screen detected after tab switch, refreshing content...');
            
            // Force re-render by toggling a state
            setIsLoading(true);
            setTimeout(() => {
              setIsLoading(false);
              
              // Wait a bit more and retry slide observer setup
              setTimeout(() => {
                // Re-trigger intersection observer
                setupVisibilityObserver();
              }, 300);
            }, 100);
          }
        }, 1000); // Check after 1 second to allow normal rendering to complete
      }
    };
    
    document.addEventListener('visibilitychange', handleVisibilityChange);
    
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      if (blankScreenDetectionTimeout) {
        clearTimeout(blankScreenDetectionTimeout);
      }
    };
  }, [course, setupVisibilityObserver]);
  
  // Setup visibility observer
  useEffect(() => {
    if (course?.slides) {
      console.log(`Setting up visibility observer for ${course.slides.length} slides`);
      const cleanup = setupVisibilityObserver();
      return cleanup;
    }
  }, [setupVisibilityObserver, course]);
  
  // Record progress immediately when currentSlideIndex changes - no debounce
  useEffect(() => {
    const recordProgress = async () => {
      try {
        if (!currentUser || !currentUser.uid || !course || !parentCourse) {
          return;
        }
        
        const courseId = parentCourse.id;
        const lessonId = course.id;
        
        if (!courseId || !lessonId) {
          return;
        }
        
        // Calculate progress percentage
        const progressPercentage = Math.min(
          Math.floor(((currentSlideIndex + 1) / course.slides.length) * 100), 
          100
        );
        
        // Record progress in Supabase immediately, including the current slide index
        await recordCourseProgress(
          currentUser.uid,
          courseId,
          lessonId,
          progressPercentage,
          currentSlideIndex,
          updateLocalUserProgress // Pass the function to update local state first
        );
      } catch (error) {
        console.error('Error recording progress:', error);
      }
    };
    
    // Only record progress when not loading and course data is available
    if (!isLoading && course && parentCourse && currentUser) {
      recordProgress();
    }
    
  }, [currentSlideIndex, course, parentCourse, currentUser, updateLocalUserProgress, isLoading]);
  
  // Override isLoading when stuck for too long
  useEffect(() => {
    if (isLoading && course?.slides) {
      // Set a timeout to force disable loading state if it's stuck
      const forceLoadingTimeout = setTimeout(() => {
        setIsLoading(false);
        console.log('Forced loading state to false after timeout');
      }, 5000); // 5 second timeout
      
      return () => clearTimeout(forceLoadingTimeout);
    }
  }, [isLoading, course]);
  
  // Handle back button click
  const handleBack = () => {
    // Determine the correct URL to navigate back to
    let backUrl = '/dashboard/courses';
    
    // If we have a parent course, create a better back URL
    if (parentCourse) {
      // For SQL courses, ensure we use the 'sql' slug consistently
      if (parentCourse.title && parentCourse.title.toLowerCase().includes('sql')) {
        backUrl = '/dashboard/courses/sql';
      } else {
        // For other courses, use the original course slug
        const courseSlugToUse = parentCourse.slug || createSlug(parentCourse.title, parentCourse.id);
        backUrl = `/dashboard/courses/${courseSlugToUse}`;
      }
    } else if (courseSlug) {
      // Fall back to the URL param courseSlug if we have it
      backUrl = `/dashboard/courses/${courseSlug}`;
    }
    
    navigate(backUrl);
  };
  
  // Handle lesson completion
  const handleComplete = async () => {
    try {
      if (currentUser && currentUser.uid && course && parentCourse) {
        const courseId = parentCourse.id;
        const lessonId = course.id;
        
        if (courseId && lessonId) {
          // Mark lesson as completed with local state update
          await markLessonCompleted(currentUser.uid, courseId, lessonId, updateLocalUserProgress);
        }
      }
    } catch (error) {
      // Silent error handling
    }
    
    // Always navigate away regardless of completion status
    navigate('/dashboard/courses');
  };
  
  // Handle save/unsave course
  const handleToggleSave = async () => {
    if (!currentUser || !currentUser.uid || !parentCourse) return;
    
    try {
      if (isSaved) {
        await unsaveCourse(currentUser.uid, parentCourse.id);
      } else {
        await saveCourse(currentUser.uid, parentCourse.id);
      }
      setIsSaved(!isSaved);
    } catch (error) {
      // Silent error handling
    }
  };
  
  // Handle enrollment
  const handleEnroll = async () => {
    if (!currentUser) {
      // Redirect to login page with return URL
      navigate('/login', { 
        state: { 
          returnTo: `/courses/${courseSlug}/${lessonSlug}`
        } 
      });
      return;
    }
    
    try {
      setIsEnrolling(true);
      const success = await enrollInCourse(currentUser.uid, parentCourse.id);
      
      if (success) {
        setIsEnrolled(true);
      }
    } catch (error) {
      // Silent error handling
    } finally {
      setIsEnrolling(false);
    }
  };
  
  // Calculate progress percentage based on current slide index and total slides
  const progressPercentage = course?.slides 
    ? Math.min(Math.floor(((currentSlideIndex + 1) / course.slides.length) * 100), 100)
    : 0;
  
  // Helper function to check if a slide is of type USER_INPUT
  const isUserInputSlide = useCallback((slide) => {
    return slide && slide.type === SLIDE_TYPES.USER_INPUT;
  }, []);
  
  // Effect to update active input when slide changes
  useEffect(() => {
    // Only update for user input slides
    if (course?.slides && isUserInputSlide(course.slides[currentSlideIndex])) {
      // When slide changes, set the active input to the stored value for this slide (if any)
      const storedInput = slideInputs[currentSlideIndex] || '';
      
      // Only update if the active input is different
      if (activeInput !== storedInput) {
        setActiveInput(storedInput);
      }
    }
  }, [currentSlideIndex, slideInputs, course, isUserInputSlide, activeInput]);
  
  // If no course data is provided
  if (isLoading) {
    return <div className="p-6 text-center text-white">Loading...</div>;
  }
  
  if (!course) {
    return <div className="p-6 text-center text-white">Lesson not found</div>;
  }
  
  // The main content of the CoursePlayer
  const renderSlides = () => {
    if (!course.slides || course.slides.length === 0) {
      return <div className="text-center">No course content available</div>;
    }
    
    return course.slides.map((slide, index) => {
      // Convert legacy slide types to new format
      const convertedSlide = convertLegacySlideType(slide);
      
      return (
        <div 
          key={index}
          ref={slideRefs.current[index]}
          className="min-h-screen snap-start snap-always flex flex-col justify-center"
          id={`slide-${index + 1}`}
          data-slide-index={index}
        >
          <TextSelectionProvider>
            <SlideRenderer
              slide={convertedSlide}
              onNext={null}
              onPrevious={null}
              isFirst={index === 0}
              isLast={index === course.slides.length - 1}
              hideNavigation={true}
              userInput={convertedSlide.type === COMPONENT_TYPES.USER_INPUT ? slideInputs[index] || activeInput || '' : undefined}
              onInputChange={convertedSlide.type === COMPONENT_TYPES.USER_INPUT ? handleInputChange : undefined}
              feedback={convertedSlide.type === COMPONENT_TYPES.USER_INPUT ? slideInputs[`${index}_feedback`] || null : undefined}
              onSubmit={convertedSlide.type === COMPONENT_TYPES.USER_INPUT ? handleSubmit : undefined}
              activeQuestionIndex={convertedSlide.type === COMPONENT_TYPES.USER_INPUT ? slideInputs[`${index}_activeQuestionIndex`] || 0 : undefined}
              onNavigateQuestion={convertedSlide.type === COMPONENT_TYPES.USER_INPUT ? handleNavigateQuestion : undefined}
              textareaRef={convertedSlide.type === COMPONENT_TYPES.USER_INPUT ? textareaRef : undefined}
              isProcessing={convertedSlide.type === COMPONENT_TYPES.USER_INPUT ? isSubmitting : undefined}
              isCodingExercise={convertedSlide.type === COMPONENT_TYPES.CODING ? true : undefined}
            />
          </TextSelectionProvider>
        </div>
      );
    });
  };
  
  return (
    <TextSelectionProvider>
      <AskAIWrapper>
        <div className="flex flex-col h-screen bg-black text-white">
          {/* Course header with progress bar */}
          <header className="bg-gray-900 py-3 px-4 border-b border-gray-800 sticky top-0 z-10">
            <div className="flex items-center justify-between">
              {/* Course title and progress */}
              <div className="flex items-center gap-2">
                <button
                  onClick={handleBack}
                  className="text-gray-400 hover:text-white p-1"
                  aria-label="Back to courses"
                  title="Back to courses"
                >
                  <ChevronLeft size={20} />
                </button>
                <h1 className="text-lg font-semibold text-white hidden sm:block">{course.title}</h1>
              </div>
              
              {/* Center area - progress bar or CTA */}
              <div className="flex-grow max-w-md mx-4">
                {isEnrolled ? (
                  <div className="w-full h-2 bg-gray-800 rounded-full overflow-hidden">
                    <div 
                      className="h-full bg-blue-500 rounded-full transition-all duration-300" 
                      style={{ width: `${progressPercentage}%` }}
                    ></div>
                  </div>
                ) : (
                  <button
                    onClick={handleEnroll}
                    disabled={isEnrolling}
                    className={`flex items-center justify-center bg-blue-600 hover:bg-blue-700 text-white px-4 py-1.5 rounded-lg transition-colors ${isEnrolling ? 'opacity-70' : ''} text-sm`}
                  >
                    {isEnrolling ? 'Enrolling...' : 'Enroll Now'}
                    <ArrowRight className="ml-2 h-4 w-4" />
                  </button>
                )}
              </div>

              {/* Actions */}
              <div className="flex items-center gap-2">
                {/* Close button */}
                <button
                  onClick={handleBack}
                  className="text-gray-400 hover:text-white p-2"
                  aria-label="Close lesson"
                  title="Close lesson"
                >
                  <X size={20} />
                </button>
              </div>
            </div>
          </header>
          
          {/* Main content area - enhanced scroll container */}
          <div 
            ref={slideContainerRef}
            className="flex-1 overflow-y-auto snap-y snap-mandatory slide-container pb-16 safe-bottom" 
            id="slide-container"
            style={{ 
              scrollSnapType: 'y mandatory', 
              height: 'calc(100vh - 56px)',
              scrollBehavior: 'smooth'
            }}
          >
            {renderSlides()}
          </div>
          
          {/* Finish button - shown only on the last slide */}
          {isOnLastSlide && (
            <div className="fixed bottom-6 left-0 right-0 flex justify-center z-20 mb-4 md:mb-0">
              <button
                onClick={handleComplete}
                className="bg-green-600 hover:bg-green-700 text-white font-bold py-3 px-8 rounded-full shadow-lg transition-all duration-300 animate-pulse"
              >
                Finish Lesson
              </button>
            </div>
          )}
          
          {/* Optional course footer */}
          {course.author && (
            <footer className="bg-gray-900 py-2 px-4 border-t border-gray-800 text-xs text-gray-500 sticky bottom-0 z-10 md:block hidden">
              Created by {course.author} • {course.duration || 'Unknown duration'}
            </footer>
          )}
        </div>
      </AskAIWrapper>
    </TextSelectionProvider>
  );
};

// Export with memoization
export default React.memo(CoursePlayer, (prevProps, nextProps) => {
  // Since CoursePlayer doesn't take props, always return true
  // This prevents unnecessary re-renders from parent component changes
  return true;
}); 