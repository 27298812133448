// Initialize Supabase client
import { createClient } from '@supabase/supabase-js';

const supabaseUrl = 'https://lxmwikrzgtidseauoeam.supabase.co';
const supabaseKey = process.env.REACT_APP_SUPABASE_KEY || 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx4bXdpa3J6Z3RpZHNlYXVvZWFtIiwicm9sZSI6ImFub24iLCJpYXQiOjE3NDI0NTUzNjQsImV4cCI6MjA1ODAzMTM2NH0.s4U9VdWPikNDRXIqs29I5Ja7rccjltotovw3C90H0WE';

console.log('Initializing Supabase client with URL:', supabaseUrl);
console.log('API Key length:', supabaseKey?.length || 0);

// Check if key looks valid
if (!supabaseKey || supabaseKey.length < 20) {
  console.error('Supabase API key appears to be invalid. Check your environment variables.');
}

// Set more conservative timeouts but with higher values for slow connections
const supabaseOptions = {
  auth: {
    autoRefreshToken: true,
    persistSession: true,
  },
  global: {
    fetch: (...args) => {
      const [url, options = {}] = args;
      options.headers = options.headers || {};
      // Set a higher timeout for requests
      options.timeout = 20000; // 20 second timeout
      return fetch(url, options);
    }
  }
};

// Initialize Supabase client
const supabase = createClient(supabaseUrl, supabaseKey, supabaseOptions);

// Log successful initialization
console.log('Supabase client initialized');

// Set connection status to connected by default - skip the connection test entirely
let connectionStatus = 'connected';

export { supabase, connectionStatus };
export default supabase; 