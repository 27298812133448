import React from 'react';
import { ChevronUp, Globe, Book, HelpCircle, Award, MessageSquare } from 'lucide-react';

/**
 * Common UI components used across slide types
 */

// Shared slide container used by all slides
export const SlideContainer = React.forwardRef(({ children, className = '', style = {} }, ref) => {
  return (
    <div 
      ref={ref} 
      className={`w-full h-[calc(100vh-56px)] flex items-center justify-center relative ${className}`}
      style={style}
    >
      <div className="w-full max-w-3xl mx-auto px-6 md:px-8 flex items-center justify-center h-full">
        {children}
      </div>
    </div>
  );
});

// Mobile bottom navigation tabs
export const MobileNavigation = ({ activeTab = 'lessons', onCourseClick, onLessonClick, onAskAIClick }) => {
  return (
    <div className="md:hidden fixed bottom-0 left-0 right-0 bg-gray-900 border-t border-gray-800 z-50">
      <div className="flex items-center justify-around py-3">
        <button 
          className={`flex flex-col items-center px-4 py-2 ${activeTab === 'courses' ? 'text-blue-400' : 'text-gray-400'}`}
          onClick={onCourseClick}
        >
          <Globe size={20} />
          <span className="text-xs mt-1">Courses</span>
        </button>
        <button 
          className={`flex flex-col items-center px-4 py-2 ${activeTab === 'lessons' ? 'text-blue-400' : 'text-gray-400'}`}
          onClick={onLessonClick}
        >
          <Book size={20} />
          <span className="text-xs mt-1">Lessons</span>
        </button>
        <button 
          className={`flex flex-col items-center px-4 py-2 ${activeTab === 'askAI' ? 'text-blue-400' : 'text-gray-400'}`}
          onClick={onAskAIClick}
        >
          <MessageSquare size={20} />
          <span className="text-xs mt-1">Ask AI</span>
        </button>
      </div>
    </div>
  );
};

// Phase label for the top of slides
export const PhaseLabel = ({ phase }) => {
  return null; // Always return null to hide phase labels
};

// Navigation buttons shared by all slides
export const NavigationButtons = ({ 
  onNext, 
  onPrevious, 
  isFirst, 
  isLast,
  isCorrect = false,
  showContinue = true 
}) => {
  return null; // Always return null to hide navigation buttons
};

// Helper function to get the appropriate icon for each phase
export const getPhaseIcon = (phase) => {
  switch (phase?.toLowerCase()) {
    case "context":
      return <Globe size={16} className="mr-1" />;
    case "learn":
      return <Book size={16} className="mr-1" />;
    case "test":
      return <HelpCircle size={16} className="mr-1" />;
    case "recap":
      return <Award size={16} className="mr-1" />;
    default:
      return null;
  }
}; 