import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import { askQuestionAboutText } from '../../../services/aiService';
import PaywallModal from '../../../components/ui/PaywallModal';

// Create the context
export const TextSelectionContext = createContext();

/**
 * Provider component for text selection functionality
 * Manages the state for selected text, popup visibility, and AI responses
 */
export const TextSelectionProvider = ({ children }) => {
  // Selected text state
  const [selectedText, setSelectedText] = useState('');
  const [selectionPosition, setSelectionPosition] = useState({ x: 0, y: 0 });
  const [selectionRange, setSelectionRange] = useState(null);
  
  // UI state
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [question, setQuestion] = useState('');
  
  // Conversation state
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentSlideTitle, setCurrentSlideTitle] = useState('');

  // Network state
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [showPaywall, setShowPaywall] = useState(false);

  // Monitor network status
  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);
    
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);
    
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  /**
   * Check if device is mobile
   */
  const isMobile = useCallback(() => {
    const userAgent = window.navigator.userAgent;
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent) || window.innerWidth <= 768;
  }, []);

  /**
   * Handle text selection
   */
  const handleTextSelection = useCallback((e) => {
    // Don't process selection if it's within navigation buttons or if user is interacting with Ask AI components
    if (e.target.closest('.navigation-buttons') || 
        e.target.closest('.ask-ai-button') || 
        e.target.closest('.question-modal') ||
        e.target.closest('textarea') ||
        e.target.closest('input')) {
      return;
    }
    
    // Use a slightly longer timeout on mobile to ensure selection has settled
    const isMobileDevice = isMobile();
    const timeoutDelay = isMobileDevice ? 300 : 10;
    
    // Use a small timeout to ensure we don't interfere with the browser's selection behavior
    setTimeout(() => {
      try {
        const selection = window.getSelection();
        const selectedText = selection.toString().trim();
        
        // Only process if there's actual text selected (even a single character)
        if (selectedText && selectedText.length > 0 && selection.rangeCount > 0) {
          // Get the position of the selection
          const range = selection.getRangeAt(0);
          const rect = range.getBoundingClientRect();
          
          // Adjust position for mobile viewing
          let x = rect.left;
          let y = rect.top;
          
          // On mobile, position the button more centered
          if (isMobileDevice) {
            x = window.innerWidth / 2;
            y = Math.min(rect.bottom + 40, window.innerHeight - 100);
          }
          
          // Set state without touching the actual selection
          setSelectedText(selectedText);
          setSelectionPosition({
            x: x,
            y: y,
            width: rect.width,
            height: rect.height,
            bottom: rect.bottom
          });
          
          // Important: Don't do anything that might affect the selection
          setIsPopupVisible(true);
        } else {
          // No text selected
          hidePopup();
        }
      } catch (error) {
        console.error("Error handling text selection:", error);
        hidePopup();
      }
    }, timeoutDelay);
  }, [isMobile]);

  /**
   * Hide the selection popup
   */
  const hidePopup = () => {
    setIsPopupVisible(false);
  };

  /**
   * Open the question modal
   */
  const openQuestionModal = () => {
    // Store the current selection before opening modal
    const selection = window.getSelection();
    const currentRange = selection.rangeCount > 0 ? selection.getRangeAt(0) : null;
    const hasSelectedText = selectedText && selectedText.trim().length > 0;
    
    setIsModalOpen(true);
    setIsPopupVisible(false);
    
    // Reset conversation when opening a new modal
    setMessages([
      {
        type: 'system',
        content: hasSelectedText 
          ? `Selected text from "${currentSlideTitle}": ${selectedText}`
          : `Ask a question about "${currentSlideTitle}"`
      }
    ]);
    
    // Restore the selection after a brief delay
    if (currentRange) {
      setTimeout(() => {
        try {
          const selection = window.getSelection();
          selection.removeAllRanges();
          selection.addRange(currentRange);
        } catch (e) {
          console.error('Failed to restore selection:', e);
        }
      }, 10);
    }
  };

  /**
   * Close the question modal
   */
  const closeQuestionModal = () => {
    setIsModalOpen(false);
    setQuestion('');
    setMessages([]);
  };

  /**
   * Set the current slide title for context
   */
  const updateSlideContext = (title) => {
    setCurrentSlideTitle(title);
  };

  /**
   * Handle question input change
   */
  const handleQuestionChange = (e) => {
    setQuestion(e.target.value);
  };

  /**
   * Add a message to the conversation
   */
  const addMessage = (message) => {
    setMessages(prev => [...prev, message]);
  };

  /**
   * Submit question to AI
   */
  const submitQuestion = async (customQuestion = null) => {
    const questionToSubmit = customQuestion || question;
    if (!questionToSubmit.trim()) return;
    
    // Check if offline before making API call
    if (!isOnline) {
      addMessage({
        type: 'assistant',
        content: "You appear to be offline. Please check your internet connection and try again.",
        isNetworkError: true,
        onRetry: () => submitQuestion(questionToSubmit)
      });
      return;
    }
    
    // Add user question to messages
    const userMessage = {
      type: 'user',
      content: questionToSubmit
    };
    
    setMessages(prev => [...prev, userMessage]);
    setIsLoading(true);
    setQuestion(''); // Clear input field
    
    try {
      const hasSelectedText = selectedText && selectedText.trim().length > 0;
      
      const response = await askQuestionAboutText(
        hasSelectedText ? selectedText : '',
        userMessage.content,
        currentSlideTitle
      );
      
      // Add AI response to messages with appropriate error flags
      if (!response.isSuccess) {
        // Check if this is a premium feature restriction
        if (response.isPremiumFeature) {
          setShowPaywall(true);
          // Add message about premium feature
          const aiMessage = {
            type: 'assistant',
            content: response.answer || "This is a premium feature. Please upgrade to access AI assistance.",
            isPremiumFeature: true
          };
          setMessages(prev => [...prev, aiMessage]);
        } else {
          // Handle other error types
          const aiMessage = {
            type: 'assistant',
            content: response.answer || response.message || "Sorry, I couldn't process your question.",
            isNetworkError: response.isNetworkError,
            isTimeoutError: response.isTimeoutError,
            isMobileError: response.isMobileError,
            onRetry: () => submitQuestion(questionToSubmit)
          };
          setMessages(prev => [...prev, aiMessage]);
        }
      } else {
        // Success case
        const aiMessage = {
          type: 'assistant',
          content: response.answer
        };
        
        setMessages(prev => [...prev, aiMessage]);
      }
    } catch (error) {
      console.error('Error asking AI a question:', error);
      
      // Determine error type
      let errorMessage = {
        type: 'assistant',
        content: "Sorry, I couldn't process your question. Please try again.",
        onRetry: () => submitQuestion(questionToSubmit)
      };
      
      // Check for network errors
      if (!isOnline || error.message?.includes('network') || error.message?.includes('fetch')) {
        errorMessage.content = "Network error. Please check your connection and try again.";
        errorMessage.isNetworkError = true;
      }
      // Check for mobile-specific issues
      else if (isMobile() && (error.message?.includes('timeout') || error.message?.includes('aborted'))) {
        errorMessage.content = "The request timed out on your mobile device. Try asking a simpler question.";
        errorMessage.isTimeoutError = true;
      }
      
      setMessages(prev => [...prev, errorMessage]);
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Clear the conversation history
   */
  const clearConversation = () => {
    setMessages([
      {
        type: 'system',
        content: selectedText 
          ? `Selected text from "${currentSlideTitle}": ${selectedText}`
          : `Ask a question about "${currentSlideTitle}"`
      }
    ]);
  };

  /**
   * Effect to add and remove mouseup and touchend event listeners for text selection
   */
  useEffect(() => {
    // Use mouseup for selection detection on desktop
    const handleMouseUp = (e) => {
      handleTextSelection(e);
    };
    
    // Use touchend for selection detection on mobile
    const handleTouchEnd = (e) => {
      handleTextSelection(e);
    };
    
    document.addEventListener('mouseup', handleMouseUp);
    document.addEventListener('touchend', handleTouchEnd);
    
    // Click outside handler to close popup
    const handleClickOutside = (e) => {
      // Don't close if clicking on the selection popup or question modal
      const isSelectionPopup = e.target.closest('.selection-popup');
      const isQuestionModal = e.target.closest('.question-modal');
      
      if (!isSelectionPopup && !isQuestionModal && isPopupVisible) {
        hidePopup();
      }
    };
    
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);
    
    return () => {
      document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('touchend', handleTouchEnd);
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [handleTextSelection, isPopupVisible, hidePopup]);

  /**
   * Close the paywall modal
   */
  const closePaywall = () => {
    setShowPaywall(false);
  };

  // The value to be provided to consumers
  const contextValue = {
    selectedText,
    selectionPosition,
    isPopupVisible,
    isModalOpen,
    question,
    messages,
    isLoading,
    currentSlideTitle,
    hidePopup,
    openQuestionModal,
    closeQuestionModal,
    updateSlideContext,
    handleQuestionChange,
    submitQuestion,
    clearConversation,
    addMessage,
    showPaywall,
    closePaywall
  };

  return (
    <TextSelectionContext.Provider value={contextValue}>
      {children}
      {showPaywall && <PaywallModal isOpen={showPaywall} onClose={closePaywall} feature="AI Assistant" />}
    </TextSelectionContext.Provider>
  );
};

/**
 * Custom hook to use the text selection context
 */
export const useTextSelection = () => {
  const context = useContext(TextSelectionContext);
  
  if (!context) {
    throw new Error('useTextSelection must be used within a TextSelectionProvider');
  }
  
  return context;
};