import React, { useState, useEffect } from 'react';

/**
 * VideoComponent - Displays and controls video content
 * 
 * This component handles the rendering of video content with controls.
 * It supports both direct video files and embedded videos (YouTube, Vimeo).
 */
const VideoComponent = ({ data }) => {
  // Extract props from data object
  const videoUrl = data?.videoUrl || data?.url;
  const videoFile = data?.videoFile || data?.file;
  const autoplay = data?.autoplay || false; 
  const showControls = data?.showControls !== false;
  
  const [videoSource, setVideoSource] = useState('');
  
  useEffect(() => {
    // Determine video source
    if (videoUrl) {
      // Handle YouTube URLs
      if (videoUrl.includes('youtube.com') || videoUrl.includes('youtu.be')) {
        const youtubeId = extractYoutubeId(videoUrl);
        if (youtubeId) {
          setVideoSource(`https://www.youtube.com/embed/${youtubeId}?autoplay=${autoplay ? 1 : 0}&controls=${showControls ? 1 : 0}`);
          return;
        }
      }
      
      // Handle Vimeo URLs
      if (videoUrl.includes('vimeo.com')) {
        const vimeoId = extractVimeoId(videoUrl);
        if (vimeoId) {
          setVideoSource(`https://player.vimeo.com/video/${vimeoId}?autoplay=${autoplay ? 1 : 0}&controls=${showControls ? 1 : 0}`);
          return;
        }
      }
      
      // Direct video URL
      setVideoSource(videoUrl);
    } else if (videoFile) {
      setVideoSource(videoFile);
    }
  }, [videoUrl, videoFile, autoplay, showControls]);
  
  // Extract YouTube video ID from URL
  const extractYoutubeId = (url) => {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = url.match(regExp);
    return (match && match[7].length === 11) ? match[7] : null;
  };
  
  // Extract Vimeo video ID from URL
  const extractVimeoId = (url) => {
    const regExp = /vimeo\.com\/([0-9]+)/;
    const match = url.match(regExp);
    return match ? match[1] : null;
  };
  
  if (!videoSource) {
    return <div className="text-gray-500">No video source available</div>;
  }
  
  // Render embedded iframe for YouTube/Vimeo, or video element for direct files
  if (videoSource.includes('youtube.com/embed') || videoSource.includes('player.vimeo.com')) {
    return (
      <div className="video-component my-4 w-full max-w-2xl mx-auto">
        {/* Responsive container with 16:9 aspect ratio */}
        <div className="relative pb-[56.25%] h-0 overflow-hidden rounded-lg bg-gray-900 shadow-lg">
          <iframe 
            src={videoSource}
            title="Video Player"
            className="absolute top-0 left-0 w-full h-full border-0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          />
        </div>
      </div>
    );
  } else {
    return (
      <div className="video-component my-4 w-full max-w-2xl mx-auto">
        <div className="relative overflow-hidden rounded-lg bg-gray-900 shadow-lg">
          <video 
            src={videoSource}
            className="w-full rounded-md"
            controls={showControls}
            autoPlay={autoplay}
            preload="metadata"
          />
        </div>
      </div>
    );
  }
};

export default VideoComponent; 