import React from 'react';
import CustomMarkdown from '../../ui/CustomMarkdown';

/**
 * TextComponent - Renders text content with markdown support
 * 
 * This component takes markdown or plain text and renders it with
 * syntax highlighting, formatting, and other markdown features.
 */
const TextComponent = ({ data }) => {
  // Handle different ways content might be provided
  let content;
  
  if (typeof data === 'string') {
    // Direct string content
    content = data;
  } else if (data?.content) {
    // Content in data.content (new format)
    content = data.content;
  } else if (data?.text) {
    // Content in data.text (alternative format)
    content = data.text;
  } else {
    // No content found
    return null;
  }

  return (
    <div className="text-component mb-6">
      <CustomMarkdown content={content} />
    </div>
  );
};

export default TextComponent; 