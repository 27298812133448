import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { SLIDE_TYPES } from '../../data/courseSchema';
import SlideRenderer from '../slides/SlideRenderer';
import { evaluateCode } from '../../../../services/aiService';

/**
 * LessonContainer - Main container for interactive lessons
 * Handles slide navigation, state management, and AI interactions
 */
const LessonContainer = ({ course, lesson }) => {
  // State for slide navigation
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [slides, setSlides] = useState([]);
  
  // State for user inputs and feedback
  const [userTestInputs, setUserTestInputs] = useState('');
  const [testFeedback, setTestFeedback] = useState(null);
  const [userCodeInputs, setUserCodeInputs] = useState('');
  const [codingFeedback, setCodingFeedback] = useState(null);
  
  // State for follow-up questions and exercises
  const [followUpQuestions, setFollowUpQuestions] = useState([]);
  const [followUpAnswers, setFollowUpAnswers] = useState([]);
  const [followUpFeedback, setFollowUpFeedback] = useState([]);
  
  // State for coding follow-up exercises
  const [followUpExercises, setFollowUpExercises] = useState([]);
  const [followUpCodeAnswers, setFollowUpCodeAnswers] = useState([]);
  const [followUpCodeFeedback, setFollowUpCodeFeedback] = useState([]);
  
  // State for active question/exercise index
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(0);
  const [activeExerciseIndex, setActiveExerciseIndex] = useState(0);
  
  // State for processing status
  const [isProcessing, setIsProcessing] = useState(false);
  
  // Refs for textarea and code editor
  const textareaRef = useRef(null);
  const codeEditorRef = useRef(null);
  
  // Load slides when course and lesson change
  useEffect(() => {
    if (course && lesson) {
      const lessonData = course.lessons.find(l => l.title === lesson);
      if (lessonData && lessonData.slides) {
        setSlides(lessonData.slides);
        // Reset state when lesson changes
        resetState();
      }
    }
  }, [course, lesson]);
  
  // Reset all state when changing lessons
  const resetState = () => {
    setCurrentSlideIndex(0);
    setUserTestInputs('');
    setTestFeedback(null);
    setUserCodeInputs('');
    setCodingFeedback(null);
    setFollowUpQuestions([]);
    setFollowUpAnswers([]);
    setFollowUpFeedback([]);
    setFollowUpExercises([]);
    setFollowUpCodeAnswers([]);
    setFollowUpCodeFeedback([]);
    setActiveQuestionIndex(0);
    setActiveExerciseIndex(0);
  };
  
  // Get current slide
  const currentSlide = slides[currentSlideIndex] || {};
  
  // Navigation handlers
  const handleNext = () => {
    if (currentSlideIndex < slides.length - 1) {
      setCurrentSlideIndex(currentSlideIndex + 1);
      // Reset state for the specific slide type
      if (currentSlide.type === SLIDE_TYPES.USER_INPUT) {
        setUserTestInputs('');
        setTestFeedback(null);
        setFollowUpQuestions([]);
        setFollowUpAnswers([]);
        setFollowUpFeedback([]);
        setActiveQuestionIndex(0);
      } else if (currentSlide.type === SLIDE_TYPES.CODING) {
        setUserCodeInputs('');
        setCodingFeedback(null);
        setFollowUpExercises([]);
        setFollowUpCodeAnswers([]);
        setFollowUpCodeFeedback([]);
        setActiveExerciseIndex(0);
      }
    }
  };
  
  const handlePrevious = () => {
    if (currentSlideIndex > 0) {
      setCurrentSlideIndex(currentSlideIndex - 1);
    }
  };
  
  // Test slide handlers
  const handleTestInputChange = (value) => {
    setUserTestInputs(value);
  };
  
  const handleTestSubmit = async () => {
    if (!userTestInputs.trim()) return;
    
    setIsProcessing(true);
    
    try {
      // Simulate AI evaluation (replace with actual API call)
      await new Promise(resolve => setTimeout(resolve, 1500));
      
      // Sample feedback (replace with actual AI response)
      const aiResponse = {
        isCorrect: Math.random() > 0.5, // Randomly determine if correct
        feedback: `Your answer is ${Math.random() > 0.5 ? 'correct' : 'partially correct'}. ${Math.random() > 0.7 ? 'Consider adding more details about the concept.' : 'Good job explaining the key points.'}`,
        isFollowUpNeeded: Math.random() > 0.7, // Randomly determine if follow-up is needed
        expectedAnswer: "This is the expected answer that would be considered correct. It includes the key concepts and clear explanations."
      };
      
      setTestFeedback(aiResponse);
    } catch (error) {
      console.error('Error evaluating test answer:', error);
      setTestFeedback({
        isCorrect: false,
        feedback: 'There was an error evaluating your answer. Please try again.',
        isFollowUpNeeded: false,
        expectedAnswer: "Unable to provide an expected answer due to evaluation error."
      });
    } finally {
      setIsProcessing(false);
    }
  };
  
  // Coding slide handlers
  const handleCodeInputChange = (value) => {
    setUserCodeInputs(value);
  };
  
  const handleCodeSubmit = async () => {
    if (!userCodeInputs.trim()) return;
    
    setIsProcessing(true);
    
    try {
      // Get the current slide for evaluation context
      const slide = slides[currentSlideIndex];
      
      // Use AI service to evaluate the code
      const result = await evaluateCode(userCodeInputs, slide);
      
      // Set the feedback
      setCodingFeedback({
        isCorrect: result.isCorrect,
        feedback: result.feedback,
        expectedCode: result.expectedCode
      });
    } catch (error) {
      console.error('Error evaluating code:', error);
      setCodingFeedback({
        isCorrect: false,
        feedback: 'There was an error evaluating your code. Please try again.',
        expectedCode: ''
      });
    } finally {
      setIsProcessing(false);
    }
  };
  
  // Follow-up question handlers
  const handleFollowUpInputChange = (index, value) => {
    const newAnswers = [...followUpAnswers];
    newAnswers[index] = value;
    setFollowUpAnswers(newAnswers);
  };
  
  const handleFollowUpSubmit = async (index, requestAdditional = false) => {
    // If requesting additional follow-up, generate a new question
    if (requestAdditional) {
      setIsProcessing(true);
      
      try {
        // Simulate AI generating a follow-up question (replace with actual API call)
        await new Promise(resolve => setTimeout(resolve, 1500));
        
        // Generate the content for the new follow-up question
        const followUpQuestionContent = `Can you explain more about ${Math.random() > 0.5 ? 'the concept' : 'your approach'}?`;
        
        // Format the question with a label
        const newQuestion = `Follow-up question ${followUpQuestions.length + 1}: ${followUpQuestionContent}`;
        
        // In the real implementation, the AI would return a response with this structure
        const aiResponse = {
          followUpQuestion: followUpQuestionContent, // Store the actual question content here
          isFollowUpNeeded: true
        };
        
        // Update the questions array
        const newQuestions = [...followUpQuestions, newQuestion];
        setFollowUpQuestions(newQuestions);
        
        // Add an empty answer slot
        const newAnswers = [...followUpAnswers, ''];
        setFollowUpAnswers(newAnswers);
        
        console.log('LessonContainer: Adding additional follow-up question', {
          questionCount: newQuestions.length,
          newQuestion,
          aiResponse
        });
        
        // Calculate the index for the new follow-up question slide
        // Each follow-up has 2 slides (question + feedback)
        const newSlideIndex = 2 + ((newQuestions.length - 1) * 2);
        
        // Set active question index to the new follow-up
        handleNavigateQuestion(newSlideIndex);
      } catch (error) {
        console.error('Error generating follow-up question:', error);
      } finally {
        setIsProcessing(false);
      }
      
      return;
    }
    
    // Regular follow-up submission
    const answer = followUpAnswers[index];
    if (!answer || !answer.trim()) return;
    
    setIsProcessing(true);
    
    try {
      // Simulate AI evaluation (replace with actual API call)
      await new Promise(resolve => setTimeout(resolve, 1500));
      
      // Sample feedback (replace with actual AI response)
      const aiResponse = {
        isCorrect: Math.random() > 0.5, // Randomly determine if correct
        feedback: `Your follow-up answer is ${Math.random() > 0.5 ? 'correct' : 'partially correct'}. ${Math.random() > 0.7 ? 'Consider adding more details about the concept.' : 'Good job explaining the key points.'}`,
        isFollowUpNeeded: Math.random() > 0.7, // Randomly determine if another follow-up is needed
        expectedAnswer: "This is what we were looking for in a complete answer. It covers all the necessary points clearly and concisely."
      };
      
      // Update feedback state
      const newFeedback = [...followUpFeedback];
      newFeedback[index] = aiResponse;
      setFollowUpFeedback(newFeedback);
    } catch (error) {
      console.error('Error evaluating follow-up answer:', error);
      const newFeedback = [...followUpFeedback];
      newFeedback[index] = {
        isCorrect: false,
        feedback: 'There was an error evaluating your answer. Please try again.',
        isFollowUpNeeded: false,
        expectedAnswer: "Unable to provide an expected answer due to evaluation error."
      };
      setFollowUpFeedback(newFeedback);
    } finally {
      setIsProcessing(false);
    }
  };
  
  // Handle navigation between questions/exercises
  const handleNavigateQuestion = (index) => {
    console.log(`LessonContainer: Setting activeQuestionIndex to ${index}`);
    setActiveQuestionIndex(index);
  };
  
  const handleNavigateExercise = (index) => {
    setActiveExerciseIndex(index);
  };
  
  // Function to handle requesting the first follow-up question
  const handleRequestFollowUp = async () => {
    setIsProcessing(true);
    
    try {
      // Simulate AI generating a follow-up question (replace with actual API call)
      await new Promise(resolve => setTimeout(resolve, 1500));
      
      // Generate first follow-up question with the content
      const followUpQuestionContent = `Can you elaborate on ${Math.random() > 0.5 ? 'your reasoning' : 'the key concepts'}?`;
      
      // Format the question with a label
      const newQuestion = `Follow-up question 1: ${followUpQuestionContent}`;
      
      console.log('LessonContainer: Adding first follow-up question');
      
      // In the real implementation, the AI would return a response with this structure
      const aiResponse = {
        followUpQuestion: followUpQuestionContent, // Store the actual question content here
        isFollowUpNeeded: true
      };
      
      // Set the follow-up question
      setFollowUpQuestions([newQuestion]);
      
      // Initialize empty answer
      setFollowUpAnswers(['']);
      
      // Clear any previous feedback for the follow-up
      setFollowUpFeedback([]);
      
      // The user will need to click "Continue to follow-up question" button
      // which will navigate to slide index 2 (the first follow-up question slide)
    } catch (error) {
      console.error('Error generating follow-up question:', error);
    } finally {
      setIsProcessing(false);
    }
  };
  
  // Render the appropriate slide based on type
  const renderSlide = () => {
    const slide = slides[currentSlideIndex];
    if (!slide) return null;
    
    const isFirst = currentSlideIndex === 0;
    const isLast = currentSlideIndex === slides.length - 1;
    
    return (
      <SlideRenderer
        slide={slide}
        onNext={handleNext}
        onPrevious={handlePrevious}
        isFirst={isFirst}
        isLast={isLast}
        onInputSubmit={handleTestSubmit}
        onCodeSubmit={handleCodeSubmit}
        onQuizAnswer={(result, quizSlide) => {
          // Handle quiz answers
          console.log('Quiz answered:', result);
        }}
        // Pass all relevant props for various components
        userInput={userTestInputs}
        feedback={testFeedback}
        followUpQuestions={followUpQuestions}
        followUpAnswers={followUpAnswers}
        followUpFeedback={followUpFeedback}
        activeQuestionIndex={activeQuestionIndex}
        onInputChange={handleTestInputChange}
        onFollowUpInputChange={handleFollowUpInputChange}
        onFollowUpSubmit={handleFollowUpSubmit}
        onNavigateQuestion={handleNavigateQuestion}
        onRequestFollowUp={handleRequestFollowUp}
        textareaRef={textareaRef}
        isProcessing={isProcessing}
        userCodeInputs={userCodeInputs}
        codingFeedback={codingFeedback}
        followUpExercises={slide.followUpExercises || []}
        followUpCodeAnswers={followUpCodeAnswers}
        followUpCodeFeedback={followUpCodeFeedback}
        activeExerciseIndex={activeExerciseIndex}
        onCodeInputChange={handleCodeInputChange}
        onNavigateExercise={handleNavigateExercise}
        codeEditorRef={codeEditorRef}
      />
    );
  };
  
  return (
    <div className="w-full max-w-6xl mx-auto py-8 px-4">
      {renderSlide()}
    </div>
  );
};

export default LessonContainer; 