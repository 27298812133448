import { LANGUAGES } from '../context/LanguageContext';

// Translations for the CourseSelector component
export const translations = {
  [LANGUAGES.EN]: {
    // 英語部分は元のままです
    // Header
    appTitle: 'Umi',
    searchPlaceholder: 'Search courses...',
    languageSelector: 'Language',
    
    // Hero section
    heroTitle: 'Elite Education for Everyone, <span>No Gatekeepers</span>',
    heroDescription: 'Learn by building real-world projects with our problem-first, hands-on approach that makes skills immediately applicable.',
    startLearningButton: 'Start Learning',
    
    // Feature cards
    problemFirstLearning: 'Problem-First Learning',
    handsOnInteractivity: 'Hands-On Interactivity',
    aiPoweredLearning: 'AI-Powered Learning',
    worldClassCurriculum: 'World-Class Curriculum',
    
    // Course section
    featuredCourses: 'Featured Courses',
    createCourse: 'Create Course',
    allSubjects: 'All',
    viewCourse: 'View Course',
    
    // Common subject names
    programming: 'Programming',
    webDevelopment: 'Web Development',
    artificialIntelligence: 'Artificial Intelligence',
    dataScience: 'Data Science',
    mathematics: 'Mathematics',
    physics: 'Physics',
    chemistry: 'Chemistry',
    biology: 'Biology',
    economics: 'Economics',
    business: 'Business',
    marketing: 'Marketing',
    design: 'Design',
    psychology: 'Psychology',
    
    // Course card
    author: 'Author',
    verifiedCreator: 'Verified Creator',
    lessonCount: '{count} Lessons',
    continueLearning: 'Continue Learning',
    startCourse: 'Start Course',
    completionRate: 'Completion Rate',
    
    // Lesson section
    lessons: 'Lessons',
    backToCourses: 'Back to Courses',
    backToCourse: 'Back to Course',
    nextLesson: 'Next Lesson',
    previousLesson: 'Previous Lesson',
    completeLesson: 'Complete Lesson',
    lessonCompleted: 'Lesson Completed!',
    
    // Lesson content labels
    conceptsLabel: 'Concepts',
    testsLabel: 'Tests',
    exercisesLabel: 'Exercises',
    interactiveLabel: 'Interactive',
    theoryLabel: 'Theory',
    durationMinutes: '{minutes} min',
    
    // Dashboard
    welcomeBack: 'Welcome back, {name}!',
    continueWhereLeft: 'Continue learning from where you left off.',
    myLessons: 'My Lessons',
    viewAllCourses: 'View all courses',
    inProgress: 'In Progress',
    completed: 'Completed',
    loadingLessons: 'Loading your lessons...',
    noLessonsInProgress: 'No lessons in progress',
    noLessonsCompleted: 'No completed lessons yet',
    startExploring: 'Start exploring courses',
    dashboard: 'Dashboard',
    myCourses: 'My Courses',
    discover: 'Discover',
    saved: 'Saved',
    settings: 'Settings',
    admin: 'Admin',
    
    // Discover section
    showMore: 'Show More',
    showLess: 'Show Less',
    topics: 'Topics',
    allTopics: 'All Topics',
    previouslyLoaded: 'Previously loaded courses',
    noCoursesFound: 'No courses found matching your criteria',
    
    // Profile settings
    profileSettings: 'Profile Settings',
    premium: 'Premium',
    displayName: 'Display Name',
    email: 'Email',
    changeEmail: 'Change Email',
    updateEmail: 'Update Email',
    cancel: 'Cancel',
    emailConfirmation: 'You will receive a confirmation email to verify your new address.',
    applicationPreferences: 'Application Preferences',
    language: 'Language',
    saveChanges: 'Save Changes',
    saving: 'Saving...',
    settingsSaved: 'Your settings have been updated successfully.',
    security: 'Security',
    manageAccount: 'Manage your account security options',
    resetPassword: 'Reset Password',
    logout: 'Logout',
    signOut: 'Sign out from your account',
    
    // Admin related
    adminTools: 'Admin Tools',
    adminToolsDescription: 'Manage courses and platform content',
    manageCourses: 'Manage Courses',
    resetPasswordButton: 'Reset Password',
    accountSettings: 'Account Settings',
    changePassword: 'Change Password',
    
    // Footer
    mission: 'Our mission is to provide elite education for everyone. No gatekeepers, just hands-on learning.',
    learn: 'Learn',
    allCourses: 'All Courses',
    about: 'About',
    ourMission: 'Our Mission',
    ourTeam: 'Our Team',
    careers: 'Careers',
    blog: 'Blog',
    contactUs: 'Contact Us',
    support: 'Support',
    faq: 'FAQ',
    helpCenter: 'Help Center',
    privacyPolicy: 'Privacy Policy',
    termsOfService: 'Terms of Service',
    accessibility: 'Accessibility',
    copyright: '© 2023 Umi. All rights reserved.',
    terms: 'Terms',
    privacy: 'Privacy',
    cookies: 'Cookies',
    
    // Review feature
    review: 'Review',
    reviewIntro: 'Personalized Review Session',
    reviewIntroContent: 'This review session is based on topics you had difficulty with. Strengthening these areas will improve your overall knowledge.',
    reviewDescription: 'A custom review session based on your past challenges.',
    startReview: 'Start Review',
    areasForImprovement: 'Areas for Improvement',
    recentMistakes: 'Recent Mistakes',
    searchMistakes: 'Search mistakes...',
    loadingReview: 'Loading your review data...',
    noMistakesToReview: 'No mistakes to review yet.',
    completeCourses: 'Complete more courses to see your progress and areas for improvement.',
    reviewTopic: 'Topic Review',
    topicReviewIntro: 'Let\'s review concepts from this topic that need reinforcement.',
    reviewQuiz: 'Review Quiz',
    reviewExercise: 'Review Exercise',
    reviewInstructions: 'Be sure to cover all the key points and show your understanding.',
    reviewComplete: 'Review Complete',
    reviewCompleteContent: 'Great work completing this review. Regular practice of challenging concepts will strengthen your knowledge and skills.',
    personalizedReview: 'Personalized Review Session',
    completedLessons: 'Completed Lessons',
    averageScore: 'Average Score',
    mistakesToReview: 'Mistakes to Review',
    knowledgeImprovement: 'Knowledge Growth',
    mistakes: 'mistakes',
    correctAnswer: 'Correct! Good job.',
    incorrectAnswer: 'Incorrect. Review the explanation carefully.',
    goodExplanation: 'Good explanation! You covered the key points.',
    incompleteExplanation: 'Your explanation is incomplete. Review the expected answer.',
    exitReview: 'Exit Review',
    previous: 'Previous',
    next: 'Next',
    introductionSlide: 'Introduction Slide',
    answerQuestion: 'Answer this question',
    correctAnswers: 'Mastered',
    incorrectAnswers: 'Review Required',
    accuracy: 'Accuracy',
    returningToDashboard: 'Returning to dashboard...',
    returnNow: 'Return Now',
    
    // AskAI review items
    askAIQuestion: 'AskAI Question',
    context: 'Context',
    aiResponse: 'AI Response',
    viewFull: 'View Full',
    reviewItems: 'Items to Review',
    searchItems: 'Search items...',
    noItemsToReview: 'No items to review yet.',
    markReviewed: 'Mark Reviewed',
    markUnderstood: 'Mark as Understood',
    reviewAskAI: 'Questions You\'ve Asked AI',
    askAIReviewIntro: 'Let\'s review questions you\'ve asked our AI assistant. Understanding these concepts will strengthen your knowledge foundation.',
    yourQuestion: 'Your Question',
    aiAnswer: 'AI Answer',
    comprehensionCheck: 'Comprehension Check',
    summarizeAnswer: 'Summarize the key points of the AI\'s answer in your own words.',
    comprehensionHint: 'A good summary should include the main concept and at least 2-3 key points from the explanation.',
    totalReviews: 'Total Reviews',
    items: 'items',
    codingExercise: 'Coding Exercise',
    writtenExercise: 'Written Exercise',
    quizQuestion: 'Quiz Question',
    reviewCoding: 'Review Coding Exercise',
    
    // Review Modal UI
    practiceQuestion: 'Practice Question',
    fromLesson: 'From lesson',
    viewLesson: 'View Lesson',
    typeYourAnswer: 'Type your answer here',
    startTyping: 'Start typing...',
    aiEvaluating: 'AI is evaluating...',
    answerSubmitted: 'Answer Submitted',
    submitAnswer: 'Submit Answer',
    correct: 'Correct!',
    incorrect: 'Incorrect!',
    yourAnswer: 'Your Answer',
    feedback: 'Feedback',
    expectedAnswer: 'Expected Answer',
    viewQuestion: 'View Question',
    understood: 'Understood!',
    continue: 'Continue',
    attempts: 'Attempts',
    reviewRequired: 'Review Required',
    noMasteredItems: 'You haven\'t mastered any items yet.',
    practiceChallenges: 'Practice more challenges to build your mastery.',
  },
  [LANGUAGES.JA]: {
    // Header
    appTitle: 'umi.ai',
    searchPlaceholder: 'キーワードで検索',
    languageSelector: '言語切替',
    
    // Hero section
    heroTitle: '<span>誰でも学べる</span>本格的なスキルアップ講座',
    heroDescription: '実際の課題に取り組みながら学ぶハンズオン方式で、すぐに役立つスキルを身につけられます。',
    startLearningButton: '無料で始める',
    
    // Feature cards
    problemFirstLearning: '課題解決型学習',
    handsOnInteractivity: '実践的な演習',
    aiPoweredLearning: 'AI活用学習',
    worldClassCurriculum: '充実のカリキュラム',
    
    // Course section
    featuredCourses: '人気の講座',
    createCourse: '講座を作成',
    allSubjects: '全て',
    viewCourse: '詳細',
    
    // Common subject names
    programming: 'プログラミング',
    webDevelopment: 'Web開発',
    artificialIntelligence: 'AI・人工知能',
    dataScience: 'データ分析',
    mathematics: '数学',
    physics: '物理',
    chemistry: '化学',
    biology: '生物',
    economics: '経済',
    business: 'ビジネス',
    marketing: 'マーケティング',
    design: 'デザイン',
    psychology: '心理学',
    
    // Course card
    author: '講師',
    verifiedCreator: '認定講師',
    lessonCount: '全{count}回',
    continueLearning: '続きから',
    startCourse: 'スタート',
    completionRate: '進捗状況',
    
    // Lesson section
    lessons: 'カリキュラム',
    backToCourses: '講座一覧へ',
    backToCourse: '講座ページへ',
    nextLesson: '次へ',
    previousLesson: '前へ',
    completeLesson: '完了',
    lessonCompleted: '学習完了！',
    
    // Lesson content labels
    conceptsLabel: '基礎知識',
    testsLabel: '確認テスト',
    exercisesLabel: '練習問題',
    interactiveLabel: '実践演習',
    theoryLabel: '解説',
    durationMinutes: '約{minutes}分',
    
    // Dashboard
    welcomeBack: 'おかえりなさい、{name}さん！',
    continueWhereLeft: '前回の続きから学習を再開しましょう。',
    myLessons: 'マイレッスン',
    viewAllCourses: '全ての講座を見る',
    inProgress: '学習中',
    completed: '完了',
    loadingLessons: 'レッスンを読み込んでいます...',
    noLessonsInProgress: '学習中のレッスンはありません',
    noLessonsCompleted: '完了したレッスンはまだありません',
    startExploring: '講座を探してみましょう',
    dashboard: 'ダッシュボード',
    myCourses: 'マイ講座',
    discover: '講座を探す',
    saved: '保存済み',
    settings: '設定',
    admin: '管理',
    
    // Discover section
    showMore: 'もっと見る',
    showLess: '閉じる',
    topics: 'トピック',
    allTopics: '全てのトピック',
    previouslyLoaded: '読み込み済みの講座',
    noCoursesFound: '条件に一致する講座が見つかりません',
    
    // Profile settings
    profileSettings: 'プロフィール設定',
    premium: 'プレミアム',
    displayName: '表示名',
    email: 'メールアドレス',
    changeEmail: '変更する',
    updateEmail: '更新する',
    cancel: 'キャンセル',
    emailConfirmation: '新しいメールアドレスの確認のため、確認メールが送信されます。',
    applicationPreferences: 'アプリ設定',
    language: '言語',
    saveChanges: '変更を保存',
    saving: '保存中...',
    settingsSaved: '設定が正常に更新されました。',
    security: 'セキュリティ',
    manageAccount: 'アカウントのセキュリティ設定を管理',
    resetPassword: 'パスワードを再設定',
    logout: 'ログアウト',
    signOut: 'アカウントからサインアウト',
    
    // Admin related
    adminTools: '管理ツール',
    adminToolsDescription: 'コースとプラットフォームコンテンツを管理',
    manageCourses: 'コース管理',
    resetPasswordButton: 'パスワードをリセット',
    accountSettings: 'アカウント設定',
    changePassword: 'パスワードの変更',
    
    // Footer
    mission: '私たちは、質の高い学びの場をあらゆる人に提供します。難しい入学試験はなく、実践を通じて本当に役立つスキルを身につけられます。',
    learn: '学習する',
    allCourses: '講座一覧',
    about: 'サイトについて',
    ourMission: '私たちの想い',
    ourTeam: 'スタッフ紹介',
    careers: '採用情報',
    blog: 'ブログ',
    contactUs: 'お問い合わせ',
    support: 'サポート',
    faq: 'よくある質問',
    helpCenter: 'ヘルプ',
    privacyPolicy: 'プライバシーポリシー',
    termsOfService: '利用規約',
    accessibility: 'アクセシビリティ',
    copyright: '© 2023 実践で学ぶ All rights reserved.',
    terms: '利用規約',
    privacy: 'プライバシー',
    cookies: 'Cookie',
    
    // Review feature
    review: '復習',
    reviewIntro: 'パーソナライズ復習セッション',
    reviewIntroContent: 'この復習セッションは、あなたが苦手とした分野に基づいています。これらの分野を強化することで、全体的な知識が向上します。',
    reviewDescription: '過去の間違いに基づいたカスタム復習セッション。',
    startReview: '復習を始める',
    areasForImprovement: '改善が必要な分野',
    recentMistakes: '最近の間違い',
    searchMistakes: '間違いを検索...',
    loadingReview: '復習データを読み込み中...',
    noMistakesToReview: '復習する間違いはまだありません。',
    completeCourses: 'より多くの講座を完了して、進捗状況と改善が必要な分野を確認しましょう。',
    reviewTopic: 'トピック復習',
    topicReviewIntro: 'この分野で強化が必要な概念を復習しましょう。',
    reviewQuiz: '復習クイズ',
    reviewExercise: '復習エクササイズ',
    reviewInstructions: '全ての重要なポイントをカバーし、理解を示すようにしてください。',
    reviewComplete: '復習完了',
    reviewCompleteContent: '復習お疲れ様でした。難しい概念を定期的に練習することで、知識とスキルが強化されます。',
    personalizedReview: 'パーソナライズされた復習セッション',
    completedLessons: '完了したレッスン',
    averageScore: '平均スコア',
    mistakesToReview: '復習すべき間違い',
    knowledgeImprovement: '知識の成長',
    mistakes: '件の間違い',
    correctAnswer: '正解です！よくできました。',
    incorrectAnswer: '不正解です。説明をよく見直してください。',
    goodExplanation: '良い説明です！重要なポイントをカバーしています。',
    incompleteExplanation: '説明が不完全です。期待される回答を確認してください。',
    exitReview: '復習を終了',
    previous: '前へ',
    next: '次へ',
    introductionSlide: '導入スライド',
    answerQuestion: 'この質問に答える',
    correctAnswers: '習得済み',
    incorrectAnswers: '復習が必要',
    accuracy: '正確さ',
    returningToDashboard: 'ダッシュボードに戻ります...',
    returnNow: '今すぐ戻る',
    
    // AskAI review items
    askAIQuestion: 'AIに質問',
    context: 'コンテキスト',
    aiResponse: 'AIの回答',
    viewFull: '全文を見る',
    reviewItems: '復習項目',
    searchItems: '項目を検索...',
    noItemsToReview: '復習する項目はまだありません。',
    markReviewed: '復習済みとしてマーク',
    markUnderstood: '理解済みとしてマーク',
    reviewAskAI: 'AIに尋ねた質問',
    askAIReviewIntro: 'AIアシスタントに尋ねた質問を復習しましょう。これらの概念を理解することで、知識の基盤が強化されます。',
    yourQuestion: 'あなたの質問',
    aiAnswer: 'AIの回答',
    comprehensionCheck: '理解度チェック',
    summarizeAnswer: 'AIの回答の要点を自分の言葉でまとめてください。',
    comprehensionHint: '良いまとめには、主要な概念と少なくとも2〜3つの重要なポイントが含まれている必要があります。',
    totalReviews: '総復習回数',
    items: '項目',
    codingExercise: 'コーディング演習',
    writtenExercise: '記述演習',
    quizQuestion: 'クイズ問題',
    reviewCoding: 'コーディング演習の復習',
    
    // Review Modal UI
    practiceQuestion: '練習問題',
    fromLesson: 'このレッスンから',
    viewLesson: 'レッスンを見る',
    typeYourAnswer: 'ここに回答を入力',
    startTyping: '回答を入力...',
    aiEvaluating: 'AIが評価中...',
    answerSubmitted: '回答が提出されました',
    submitAnswer: '回答を送信',
    correct: '正解です！',
    incorrect: '不正解です。',
    yourAnswer: 'あなたの回答',
    feedback: 'フィードバック',
    expectedAnswer: '期待される回答',
    viewQuestion: '問題を見る',
    understood: '理解しました！',
    continue: '続ける',
    attempts: '試行回数',
    reviewRequired: '復習が必要',
    noMasteredItems: 'まだマスターした項目はありません。',
    practiceChallenges: 'より多くの練習課題を解いて、マスターを強化しましょう。',
  },
  [LANGUAGES.MY]: {
    // Header
    appTitle: 'umi.ai',
    searchPlaceholder: 'သင်တန်းများကို ရှာဖွေရန်...',
    languageSelector: 'ဘာသာစကား',
    
    // Hero section
    heroTitle: 'အရည်အသွေးမြင့် ပညာရေး <span>လူတိုင်းအတွက်</span>',
    heroDescription: 'လက်တွေ့စီမံကိန်းများကို တည်ဆောက်ခြင်းဖြင့် သင်ယူပြီး ကျွမ်းကျင်မှုများကို ချက်ချင်းအသုံးချနိုင်စေပါသည်။',
    startLearningButton: 'သင်ယူရန် စတင်ပါ',
    
    // Feature cards
    problemFirstLearning: 'ပြဿနာဦးစားပေး သင်ယူခြင်း',
    handsOnInteractivity: 'လက်တွေ့လုပ်ဆောင်နိုင်ခြင်း',
    aiPoweredLearning: 'AI ဖြင့် သင်ယူခြင်း',
    worldClassCurriculum: 'အဆင့်မြင့် သင်ရိုးညွှန်းတမ်း',
    
    // Course section
    featuredCourses: 'ထူးခြားသော သင်တန်းများ',
    createCourse: 'သင်တန်း ဖန်တီးရန်',
    allSubjects: 'အားလုံး',
    viewCourse: 'သင်တန်းကို ကြည့်ရန်',
    
    // Common subject names
    programming: 'ပရိုဂရမ်မင်း',
    webDevelopment: 'ဝဘ်ဆိုက် ဖွံ့ဖြိုးရေး',
    artificialIntelligence: 'ဥာဏ်ရည်တု',
    dataScience: 'ဒေတာ သိပ္ပံ',
    mathematics: 'သင်္ချာ',
    physics: 'ရူပဗေဒ',
    chemistry: 'ဓာတုဗေဒ',
    biology: 'ဇီဝဗေဒ',
    economics: 'စီးပွားရေး',
    business: 'စီးပွားရေးလုပ်ငန်း',
    marketing: 'စျေးကွက်ရှာဖွေရေး',
    design: 'ဒီဇိုင်း',
    psychology: 'စိတ်ပညာ',
    
    // Course card
    author: 'စာရေးသူ',
    verifiedCreator: 'အတည်ပြုထားသော ဖန်တီးသူ',
    lessonCount: 'သင်ခန်းစာ {count} ခု',
    continueLearning: 'ဆက်လက် သင်ယူရန်',
    startCourse: 'စတင်ရန်',
    completionRate: 'ပြီးမြောက်မှုနှုန်း',
    
    // Lesson section
    lessons: 'သင်ခန်းစာများ',
    backToCourses: 'သင်တန်းများသို့ ပြန်သွားရန်',
    backToCourse: 'သင်တန်းသို့ ပြန်သွားရန်',
    nextLesson: 'နောက်သင်ခန်းစာ',
    previousLesson: 'ယခင်သင်ခန်းစာ',
    completeLesson: 'သင်ခန်းစာ ပြီးဆုံးပါပြီ',
    lessonCompleted: 'သင်ခန်းစာ ပြီးဆုံးပါပြီ!',
    
    // Lesson content labels
    conceptsLabel: 'သဘောတရားများ',
    testsLabel: 'စမ်းသပ်မှုများ',
    exercisesLabel: 'လေ့ကျင့်ခန်းများ',
    interactiveLabel: 'အပြန်အလှန်',
    theoryLabel: 'သီအိုရီ',
    durationMinutes: '{minutes} မိနစ်',
    
    // Dashboard
    welcomeBack: 'ကြိုဆိုပါတယ်၊ {name}!',
    continueWhereLeft: 'ရပ်တန့်ခဲ့သောနေရာမှ ဆက်လက်သင်ယူပါ။',
    myLessons: 'ကျွန်ုပ်၏ သင်ခန်းစာများ',
    viewAllCourses: 'သင်တန်းအားလုံးကို ကြည့်ရန်',
    inProgress: 'လေ့လာဆဲ',
    completed: 'ပြီးဆုံးသွားပြီ',
    loadingLessons: 'သင်ခန်းစာများကို ဖွင့်နေသည်...',
    noLessonsInProgress: 'လေ့လာဆဲ သင်ခန်းစာမရှိပါ',
    noLessonsCompleted: 'ပြီးဆုံးသွားသော သင်ခန်းစာ မရှိသေးပါ',
    startExploring: 'သင်တန်းများကို စတင်ရှာဖွေပါ',
    dashboard: 'ဒက်ရှ်ဘုတ်',
    myCourses: 'ကျွန်ုပ်၏ သင်တန်းများ',
    discover: 'ရှာဖွေရန်',
    saved: 'သိမ်းဆည်းထားသည်',
    settings: 'ဆက်တင်များ',
    admin: 'အက်ဒမင်',
    
    // Discover section
    showMore: 'ပိုမိုကြည့်ရှုရန်',
    showLess: 'လျှော့ကြည့်ရန်',
    topics: 'အကြောင်းအရာများ',
    allTopics: 'အကြောင်းအရာအားလုံး',
    previouslyLoaded: 'ယခင်ဖွင့်ခဲ့သည့် သင်တန်းများ',
    noCoursesFound: 'သတ်မှတ်ချက်နှင့်ကိုက်ညီသော သင်တန်းမရှိပါ',
    
    // Profile settings
    profileSettings: 'ပရိုဖိုင် ဆက်တင်များ',
    premium: 'ပရီမီယံ',
    displayName: 'ဖော်ပြမည့် အမည်',
    email: 'အီးမေးလ်',
    changeEmail: 'အီးမေးလ် ပြောင်းရန်',
    updateEmail: 'အီးမေးလ် အပ်ဒိတ်လုပ်ရန်',
    cancel: 'ပယ်ဖျက်ရန်',
    emailConfirmation: 'သင့်အီးမေးလ်လိပ်စာအသစ်ကို အတည်ပြုရန် အီးမေးလ်တစ်စောင် ပို့ပေးပါမည်။',
    applicationPreferences: 'အပ်ပလီကေးရှင်း ဆက်တင်များ',
    language: 'ဘာသာစကား',
    saveChanges: 'ပြောင်းလဲမှုများကို သိမ်းဆည်းရန်',
    saving: 'သိမ်းဆည်းနေသည်...',
    settingsSaved: 'သင့်ဆက်တင်များကို အောင်မြင်စွာ အပ်ဒိတ်လုပ်ပြီးပါပြီ။',
    security: 'လုံခြုံရေး',
    manageAccount: 'သင့်အကောင့်လုံခြုံရေး ရွေးချယ်မှုများကို စီမံခန့်ခွဲပါ',
    resetPassword: 'စကားဝှက် ပြန်လည်သတ်မှတ်ရန်',
    logout: 'ထွက်ရန်',
    signOut: 'သင့်အကောင့်မှ ထွက်ရန်',
    
    // Admin related
    adminTools: 'အက်ဒမင် ကိရိယာများ',
    adminToolsDescription: 'သင်တန်းများနှင့် ပလက်ဖောင်းအကြောင်းအရာများကို စီမံခန့်ခွဲရန်',
    manageCourses: 'သင်တန်းများကို စီမံခန့်ခွဲရန်',
    resetPasswordButton: 'စကားဝှက် ပြန်လည်သတ်မှတ်ရန်',
    accountSettings: 'အကောင့် ဆက်တင်များ',
    changePassword: 'စကားဝှက် ပြောင်းရန်',
    
    // Footer
    mission: 'ကျွန်ုပ်တို့၏ရည်မှန်းချက်မှာ လူတိုင်းအတွက် အရည်အသွေးမြင့် ပညာရေးကို ပေးရန်ဖြစ်သည်။ တံခါးနှောင့်ပိတ်ခြင်းမရှိဘဲ၊ လက်တွေ့သင်ယူမှုများဖြင့် သင်ကြားပေးပါသည်။',
    learn: 'သင်ယူရန်',
    allCourses: 'သင်တန်းအားလုံး',
    about: 'အကြောင်း',
    ourMission: 'ကျွန်ုပ်တို့၏ ရည်မှန်းချက်',
    ourTeam: 'ကျွန်ုပ်တို့၏ အဖွဲ့',
    careers: 'အလုပ်အကိုင်များ',
    blog: 'ဘလော့ဂ်',
    contactUs: 'ဆက်သွယ်ရန်',
    support: 'အကူအညီ',
    faq: 'မေးလေ့ရှိသောမေးခွန်းများ',
    helpCenter: 'အကူအညီစင်တာ',
    privacyPolicy: 'ကိုယ်ရေးကိုယ်တာ မူဝါဒ',
    termsOfService: 'ဝန်ဆောင်မှုစည်းကမ်းချက်များ',
    accessibility: 'လက်လှမ်းမီမှု',
    copyright: '© 2023 Umi. မူပိုင်ခွင့်အားလုံး ထိန်းသိမ်းပြီး။',
    terms: 'စည်းမျဉ်းများ',
    privacy: 'ကိုယ်ရေးကိုယ်တာ',
    cookies: 'ကွတ်ကီးများ',
    
    // Review feature
    review: 'ပြန်လည်သုံးသပ်ခြင်း',
    reviewIntro: 'ပုဂ္ဂိုလ်ရေးသင်ခန်းစာ',
    reviewIntroContent: 'ဤသင်ခန်းစာသည် သင်အခက်အခဲရှိခဲ့သော အကြောင်းအရာများကို အခြေခံထားပါသည်။ ဤနယ်ပယ်များကို အားဖြည့်ခြင်းဖြင့် သင့်ဗဟုသုတကို တိုးတက်စေပါမည်။',
    reviewDescription: 'သင့်ယခင်အမှားများကို အခြေခံသော သင်ခန်းစာ',
    startReview: 'ပြန်လည်လေ့ကျင့်ရန်',
    areasForImprovement: 'တိုးတက်ရန်နယ်ပယ်များ',
    recentMistakes: 'မကြာသေးမီအမှားများ',
    searchMistakes: 'အမှားများကို ရှာဖွေရန်...',
    loadingReview: 'သင့်ပြန်လည်လေ့ကျင့်မှုအချက်အလက်များကို ဖွင့်နေသည်...',
    noMistakesToReview: 'ပြန်လည်လေ့ကျင့်ရန် အမှားများမရှိသေးပါ။',
    completeCourses: 'သင့်တိုးတက်မှုနှင့် တိုးတက်ရန်နယ်ပယ်များကို မြင်နိုင်ရန် သင်တန်းများပြီးဆုံးအောင် လုပ်ဆောင်ပါ။',
    reviewTopic: 'အကြောင်းအရာပြန်လည်လေ့ကျင့်ခြင်း',
    topicReviewIntro: 'ဤအကြောင်းအရာမှ အားဖြည့်ရန်လိုအပ်သော သဘောတရားများကို ပြန်လည်လေ့ကျင့်ကြည့်ကြပါစို့။',
    reviewQuiz: 'ပြန်လည်လေ့ကျင့်ရန် မေးခွန်း',
    reviewExercise: 'ပြန်လည်လေ့ကျင့်ရန် လေ့ကျင့်ခန်း',
    reviewInstructions: 'အဓိကအချက်အားလုံးကို သေချာဖော်ပြပြီး သင့်နားလည်မှုကို ပြသပါ။',
    reviewComplete: 'ပြန်လည်လေ့ကျင့်မှု ပြီးဆုံးပါပြီ',
    reviewCompleteContent: 'ဤပြန်လည်လေ့ကျင့်မှုကို ပြီးစီးအောင် လုပ်ဆောင်နိုင်သည့်အတွက် ကောင်းပါသည်။ ခက်ခဲသော သဘောတရားများကို ပုံမှန်လေ့ကျင့်ခြင်းဖြင့် သင့်ဗဟုသုတနှင့် ကျွမ်းကျင်မှုများကို ပိုမိုအားကောင်းစေပါမည်။',
    personalizedReview: 'ပုဂ္ဂိုလ်ရေးပြန်လည်လေ့ကျင့်မှု အစီအစဉ်',
    completedLessons: 'ပြီးဆုံးသွားသော သင်ခန်းစာများ',
    averageScore: 'ပျမ်းမျှအမှတ်',
    mistakesToReview: 'ပြန်လည်လေ့ကျင့်ရန် အမှားများ',
    knowledgeImprovement: 'ဗဟုသုတ တိုးတက်မှု',
    mistakes: 'အမှားများ',
    correctAnswer: 'မှန်ကန်ပါသည်! အလုပ်ကောင်းပါသည်။',
    incorrectAnswer: 'မှားယွင်းပါသည်။ ရှင်းလင်းချက်ကို သေချာဖတ်ရှုပါ။',
    goodExplanation: 'ရှင်းလင်းချက်ကောင်းပါသည်! အဓိကအချက်များကို ထည့်သွင်းဖော်ပြနိုင်ပါသည်။',
    incompleteExplanation: 'သင့်ရှင်းလင်းချက်မှာ မပြည့်စုံသေးပါ။ မျှော်လင့်ထားသော အဖြေကို ပြန်လည်ကြည့်ရှုပါ။',
    exitReview: 'ပြန်လည်လေ့ကျင့်မှုမှ ထွက်ရန်',
    previous: 'ယခင်',
    next: 'နောက်',
    introductionSlide: 'မိတ်ဆက်စလိုက်',
    answerQuestion: 'ဤမေးခွန်းကို ဖြေဆိုပါ',
    correctAnswers: 'ကျွမ်းကျင်ပြီး',
    incorrectAnswers: 'ပြန်လည်လေ့ကျင့်ရန် လိုအပ်သည်',
    accuracy: 'တိကျမှု',
    returningToDashboard: 'ဒက်ရှ်ဘုတ်သို့ ပြန်သွားနေသည်...',
    returnNow: 'ယခုပြန်သွားရန်',
    
    // AskAI review items
    askAIQuestion: 'AI ကို မေးခွန်း',
    context: 'အခြေအနေ',
    aiResponse: 'AI ၏ တုံ့ပြန်မှု',
    viewFull: 'အပြည့်အစုံကြည့်ရန်',
    reviewItems: 'ပြန်လည်လေ့ကျင့်ရန် အချက်များ',
    searchItems: 'အချက်များကို ရှာဖွေရန်...',
    noItemsToReview: 'ပြန်လည်လေ့ကျင့်ရန် အချက်များ မရှိသေးပါ။',
    markReviewed: 'ပြန်လည်လေ့ကျင့်ပြီးဟု မှတ်သားရန်',
    markUnderstood: 'နားလည်ပြီးဟု မှတ်သားရန်',
    reviewAskAI: 'သင် AI ကို မေးခဲ့သော မေးခွန်းများ',
    askAIReviewIntro: 'AI အကူအညီပေးသူကို သင်မေးခဲ့သော မေးခွန်းများကို ပြန်လည်လေ့ကျင့်ကြည့်ကြပါစို့။ ဤသဘောတရားများကို နားလည်ခြင်းက သင့်ဗဟုသုတအခြေခံကို ပိုမိုခိုင်မာစေပါမည်။',
    yourQuestion: 'သင့်မေးခွန်း',
    aiAnswer: 'AI ၏ အဖြေ',
    comprehensionCheck: 'နားလည်မှု စစ်ဆေးခြင်း',
    summarizeAnswer: 'AI ၏ အဖြေမှ အဓိကအချက်များကို သင့်ကိုယ်ပိုင်စကားလုံးများဖြင့် အကျဉ်းချုပ်ပါ။',
    comprehensionHint: 'အကျဉ်းချုပ်ကောင်းတစ်ခုတွင် အဓိကသဘောတရားနှင့် ရှင်းလင်းချက်မှ အဓိကအချက် အနည်းဆုံး 2-3 ချက် ပါဝင်သင့်ပါသည်။',
    totalReviews: 'ပြန်လည်လေ့ကျင့်မှု စုစုပေါင်း',
    items: 'အချက်များ',
    codingExercise: 'ကုဒ်ရေးသားခြင်း လေ့ကျင့်ခန်း',
    writtenExercise: 'ရေးသားခြင်း လေ့ကျင့်ခန်း',
    quizQuestion: 'မေးခွန်း',
    reviewCoding: 'ကုဒ်ရေးသားခြင်း လေ့ကျင့်ခန်းကို ပြန်လည်လေ့ကျင့်ရန်',
    
    // Review Modal UI
    practiceQuestion: 'လေ့ကျင့်ခန်း မေးခွန်း',
    fromLesson: 'သင်ခန်းစာမှ',
    viewLesson: 'သင်ခန်းစာကို ကြည့်ရန်',
    typeYourAnswer: 'သင့်အဖြေကို ဤနေရာတွင် ရိုက်ထည့်ပါ',
    startTyping: 'စတင်ရိုက်ထည့်ပါ...',
    aiEvaluating: 'AI က အကဲဖြတ်နေသည်...',
    answerSubmitted: 'အဖြေ တင်သွင်းပြီးပါပြီ',
    submitAnswer: 'အဖြေ တင်သွင်းရန်',
    correct: 'မှန်ကန်ပါသည်!',
    incorrect: 'မှားယွင်းပါသည်!',
    yourAnswer: 'သင့်အဖြေ',
    feedback: 'အကြံပြုချက်',
    expectedAnswer: 'မျှော်လင့်ထားသော အဖြေ',
    viewQuestion: 'မေးခွန်းကို ကြည့်ရန်',
    understood: 'နားလည်ပါပြီ!',
    continue: 'ဆက်လက်ရန်',
    attempts: 'ကြိုးစားခြင်းအကြိမ်ရေ',
    reviewRequired: 'ပြန်လည်လေ့ကျင့်ရန် လိုအပ်သည်',
    noMasteredItems: 'ကျွမ်းကျင်မှုရရှိသေးသော အချက်များ မရှိသေးပါ။',
    practiceChallenges: 'ကျွမ်းကျင်မှုတည်ဆောက်ရန် လေ့ကျင့်ခန်းများကို ပို၍လုပ်ဆောင်ပါ။',
  }
};

// Helper hook to use translations
export const getTranslation = (language, key, params = {}) => {
  // Check if params is actually a string (default value) rather than a params object
  if (typeof params === 'string') {
    // If params is a string, it's being used as a default value
    const defaultValue = params;
    // Default to English if translation not found
    if (!translations[language] || !translations[language][key]) {
      return defaultValue;
    }
    return translations[language][key];
  }
  
  // Regular case with params as an object
  if (!translations[language] || !translations[language][key]) {
    return formatTranslation(translations[LANGUAGES.EN][key] || key, params);
  }
  return formatTranslation(translations[language][key], params);
};

// Helper to format translations with parameters
const formatTranslation = (text, params) => {
  if (!text || !params || Object.keys(params).length === 0) {
    return text;
  }
  
  return Object.entries(params).reduce((result, [key, value]) => {
    return result.replace(new RegExp(`{${key}}`, 'g'), value);
  }, text);
};