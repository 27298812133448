import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams, Navigate } from 'react-router-dom';
import { useAuth } from '../../../auth/AuthContext';
import { getUserProgress } from '../../../services/userProgress';
import { createSlug } from '../utils/urlUtils';
import CoursePlayer from './CoursePlayer';
import { default as LessonSelector } from '../core/lesson/LessonSelector';
import CourseCreationSelector from './CourseCreationSelector';
import { getAllCourses } from '../../../services/courseService';
import { 
  ArrowRight, CheckCircle, GraduationCap, Search, 
  Star, Clock, Check, Filter, X, BookOpen, Plus,
  Rocket, Code, Brain, Globe, Heart, Mail, Twitter, Github, Linkedin
} from 'lucide-react';
import AskAIWrapper from '../ui/AskAI';
import { useLanguage, LANGUAGES, LanguageProvider } from '../context/LanguageContext';
import TranslatedText from '../ui/TranslatedText';
import { getTranslation } from '../utils/translations';
import AuthButton from '../../../auth/AuthButton';

/**
 * Language Selector Component
 * 
 * A dropdown component for selecting the application language
 */
const LanguageSelector = () => {
  const { language, changeLanguage } = useLanguage();
  const [isOpen, setIsOpen] = useState(false);
  
  return (
    <div className="relative">
      <button 
        className="flex items-center gap-2 text-white"
        onClick={() => setIsOpen(!isOpen)}
      >
        <Globe className="h-5 w-5 text-blue-400" />
        <span className="hidden md:inline text-sm font-medium">
          <TranslatedText id="languageSelector" />
        </span>
      </button>
      {isOpen && (
        <>
          <div 
            className="fixed inset-0 z-40" 
            onClick={() => setIsOpen(false)}
          />
          <div className="absolute right-0 top-full mt-2 py-2 bg-[#262b38] shadow-lg rounded-lg border border-[#2f3545] min-w-[120px] z-50">
            <button 
              className={`w-full text-left px-4 py-2 text-sm hover:bg-[#2f3545] transition-colors ${language === LANGUAGES.EN ? 'text-blue-400' : 'text-white'}`}
              onClick={() => {
                changeLanguage(LANGUAGES.EN);
                setIsOpen(false);
              }}
            >
              English
            </button>
            <button 
              className={`w-full text-left px-4 py-2 text-sm hover:bg-[#2f3545] transition-colors ${language === LANGUAGES.JA ? 'text-blue-400' : 'text-white'}`}
              onClick={() => {
                changeLanguage(LANGUAGES.JA);
                setIsOpen(false);
              }}
            >
              日本語
            </button>
            <button 
              className={`w-full text-left px-4 py-2 text-sm hover:bg-[#2f3545] transition-colors ${language === LANGUAGES.MY ? 'text-blue-400' : 'text-white'}`}
              onClick={() => {
                changeLanguage(LANGUAGES.MY);
                setIsOpen(false);
              }}
            >
              မြန်မာဘာသာ
            </button>
          </div>
        </>
      )}
    </div>
  );
};

/**
 * Course Selector Component
 * 
 * Provides a UI for browsing and selecting courses and their lessons.
 * This component renders a list of available courses and their contained lessons.
 * 
 * Props:
 * - onBack: Callback for when the user wants to go back
 */
const CourseSelector = ({ onBack }) => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [selectedLesson, setSelectedLesson] = useState(null);
  const [showCourseCreator, setShowCourseCreator] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState('All');
  const [subjects, setSubjects] = useState(['All']);
  const [searchQuery, setSearchQuery] = useState('');
  const [userProgress, setUserProgress] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [allCourses, setAllCourses] = useState([]);
  
  // Extract unique subjects and fetch courses from Supabase only
  useEffect(() => {
    const loadSubjectsAndProgress = async () => {
      setIsLoading(true);
      
      try {
        // Fetch courses from Supabase only
        const coursesFromSupabase = await getAllCourses();
        console.log('Courses from Supabase:', coursesFromSupabase.length);
        
        // Use only Supabase courses - don't merge with static courses
        setAllCourses(coursesFromSupabase);
        
        // Extract unique subjects
        const uniqueSubjects = ['All', ...new Set(coursesFromSupabase
          .map(course => course.subject || 'Other')
          .filter(Boolean))];
        setSubjects(uniqueSubjects);
        
        // Load user progress if logged in
        if (currentUser) {
          try {
            const progress = await getUserProgress(currentUser.uid);
            setUserProgress(progress?.courses || {});
          } catch (error) {
            console.error('Error fetching user progress:', error);
          }
        }
      } catch (error) {
        console.error('Error loading courses:', error);
      } finally {
        setIsLoading(false);
      }
    };
    
    loadSubjectsAndProgress();
  }, [currentUser]);
  
  // Helper function to get course progress
  const getCourseProgress = (courseId) => {
    if (!userProgress || !userProgress[courseId]) {
      return 0;
    }
    return userProgress[courseId].progress || 0;
  };
  
  // Helper function to check if a course is completed
  const isCourseCompleted = (courseId) => {
    if (!userProgress || !userProgress[courseId]) {
      return false;
    }
    return userProgress[courseId].progress === 100;
  };
  
  // Helper function to check if a course is in progress
  const isCourseInProgress = (courseId) => {
    if (!userProgress || !userProgress[courseId]) {
      return false;
    }
    return userProgress[courseId].progress > 0 && userProgress[courseId].progress < 100;
  };
  
  if (selectedLesson) {
    return (
      <CoursePlayer 
        course={selectedLesson}
        onComplete={() => setSelectedLesson(null)}
        onBack={() => setSelectedLesson(null)}
      />
    );
  }

  if (selectedCourse) {
    return (
      <LessonSelector
        course={selectedCourse}
        onBack={() => setSelectedCourse(null)}
        onSelectLesson={setSelectedLesson}
      />
    );
  }

  if (showCourseCreator) {
    return (
      <CourseCreationSelector 
        onBack={() => setShowCourseCreator(false)}
      />
    );
  }

  // Filter courses by selected subject and search query
  const filteredCourses = allCourses
    .filter(course => {
      // Filter by subject
      const matchesSubject = selectedSubject === 'All' || course.subject === selectedSubject;
      
      // Filter by search query
      const matchesSearch = !searchQuery || 
        course.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        (course.description && course.description.toLowerCase().includes(searchQuery.toLowerCase()));
      
      return matchesSubject && matchesSearch;
    });

  // The component to be rendered with AskAIWrapper
  const CourseSelectorContent = ({ openQuestionModal }) => {
    const { language } = useLanguage();
    
    return (
      <div className="min-h-screen bg-[#0a0c14] text-white">
        {/* Fixed Header */}
        <header className="fixed top-0 left-0 right-0 z-50 bg-gradient-to-b from-[#0a0c14] to-[#12141c] backdrop-blur-md shadow-xl border-b border-[#1f212a]">
          <div className="container mx-auto px-4 py-4 flex items-center justify-between">
            <div className="flex items-center">
              <img src="/logo1.png" alt="Umi Logo" className="h-10 w-10 mr-3" />
              <h1 className="text-xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-blue-300">
                Umi Learning
              </h1>
            </div>
            <div className="flex items-center gap-4">
              <LanguageSelector />
              <AuthButton />
            </div>
          </div>
        </header>

        {/* Main Content with padding-top to account for fixed header */}
        <main className="pt-20">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            {/* Hero Section */}
            <div className="hero-section py-16 md:py-20">
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 items-center">
                <div className="hero-content">
                  <h1 className="text-4xl md:text-5xl font-bold text-white mb-6 leading-tight">
                    <TranslatedText id="heroTitle" dangerouslySetInnerHTML={true} />
                  </h1>
                  <p className="text-xl text-gray-300 mb-8">
                    <TranslatedText id="heroDescription" />
                  </p>
                  <div>
                    <button 
                      onClick={() => navigate('/signup')}
                      className="px-6 py-3 bg-gradient-to-r from-blue-700 to-blue-600 text-white font-medium rounded-xl hover:from-blue-800 hover:to-blue-700 transition-all shadow-md flex items-center"
                    >
                      <TranslatedText id="startLearningButton" /> <ArrowRight className="ml-2 h-5 w-5" />
                    </button>
                  </div>
                </div>
                <div className="hero-features grid grid-cols-2 gap-4">
                  <div className="feature-card bg-[#12141c] p-4 rounded-xl border border-[#1f212a] hover:border-blue-500 transition-colors flex items-center gap-3">
                    <div className="feature-icon p-2 bg-blue-500 bg-opacity-20 rounded-lg inline-block">
                      <Rocket className="h-5 w-5 text-blue-400" />
                    </div>
                    <h3 className="text-sm font-medium text-white"><TranslatedText id="problemFirstLearning" /></h3>
                  </div>
                  <div className="feature-card bg-[#12141c] p-4 rounded-xl border border-[#1f212a] hover:border-blue-500 transition-colors flex items-center gap-3">
                    <div className="feature-icon p-2 bg-green-500 bg-opacity-20 rounded-lg inline-block">
                      <Code className="h-5 w-5 text-green-400" />
                    </div>
                    <h3 className="text-sm font-medium text-white"><TranslatedText id="handsOnInteractivity" /></h3>
                  </div>
                  <div className="feature-card bg-[#12141c] p-4 rounded-xl border border-[#1f212a] hover:border-blue-500 transition-colors flex items-center gap-3">
                    <div className="feature-icon p-2 bg-purple-500 bg-opacity-20 rounded-lg inline-block">
                      <Brain className="h-5 w-5 text-purple-400" />
                    </div>
                    <h3 className="text-sm font-medium text-white"><TranslatedText id="aiPoweredLearning" /></h3>
                  </div>
                  <div className="feature-card bg-[#12141c] p-4 rounded-xl border border-[#1f212a] hover:border-blue-500 transition-colors flex items-center gap-3">
                    <div className="feature-icon p-2 bg-yellow-500 bg-opacity-20 rounded-lg inline-block">
                      <Globe className="h-5 w-5 text-yellow-400" />
                    </div>
                    <h3 className="text-sm font-medium text-white"><TranslatedText id="worldClassCurriculum" /></h3>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-4 mb-8 mt-12">
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
                <h2 className="text-2xl font-bold text-white"><TranslatedText id="featuredCourses" /></h2>
                {currentUser && (
                  <button
                    onClick={() => navigate('/create-course')}
                    className="px-4 py-2 text-gray-300 bg-[#12141c] rounded-lg hover:bg-[#1f212a] transition-colors text-sm font-medium whitespace-nowrap flex items-center"
                  >
                    <Plus className="mr-2 h-4 w-4" /> <TranslatedText id="createCourse" />
                  </button>
                )}
              </div>
              
              {/* Search Bar - Moved here from the header */}
              <div className="relative w-full mb-4">
                <input 
                  type="text" 
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  placeholder={getTranslation(language, 'searchPlaceholder')}
                  className="w-full py-2 px-4 pl-10 bg-[#12141c] text-white rounded-lg border border-[#1f212a] focus:border-blue-500 focus:outline-none transition-colors"
                />
                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
              </div>
              
              {/* Subject Filter Buttons */}
              <div className="subject-filters flex flex-wrap gap-2 mb-6">
                {subjects.map((subject, index) => (
                  <button
                    key={index}
                    onClick={() => setSelectedSubject(subject)}
                    className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors ${
                      selectedSubject === subject
                        ? 'bg-gradient-to-r from-blue-700 to-blue-600 text-white shadow-md'
                        : 'bg-[#12141c] text-gray-300 hover:bg-[#1f212a]'
                    }`}
                  >
                    {subject === 'All' ? <TranslatedText id="allSubjects" /> : subject}
                  </button>
                ))}
              </div>
            </div>
            
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 pb-24 md:pb-32">
              {filteredCourses.map((course, index) => {
                const courseId = course.id || index.toString();
                const progress = getCourseProgress(courseId);
                const isCompleted = isCourseCompleted(courseId);
                const isInProgress = isCourseInProgress(courseId);
                
                return (
                  <div 
                    key={index}
                    className={`group bg-[#12141c] rounded-xl border ${isCompleted ? 'border-emerald-500' : isInProgress ? 'border-blue-500' : 'border-[#1f212a] hover:border-blue-500'} transition-all duration-200 cursor-pointer overflow-hidden shadow-lg hover:shadow-xl hover:transform hover:-translate-y-1`}
                    onClick={() => {
                      if (currentUser) {
                        navigate(`/dashboard/courses/${course.slug || createSlug(course.title, course.id)}`);
                      } else {
                        navigate('/login');
                      }
                    }}
                  >
                    <div className="p-6">
                      <div className="flex items-center gap-3 mb-3">
                        <h2 className="text-2xl font-bold text-white group-hover:text-blue-400 transition-colors">
                          {course.title}
                        </h2>
                        {currentUser && isCompleted && (
                          <span className="bg-emerald-500 bg-opacity-20 text-emerald-500 px-2 py-1 rounded-full text-xs font-medium flex items-center">
                            <CheckCircle className="h-3 w-3 mr-1" /> Completed
                          </span>
                        )}
                      </div>
                      {course.subject && (
                        <div className="text-sm text-blue-400 mb-3">
                          <TranslatedText id={course.subject.replace(/\s+/g, '').toLowerCase()} fallback={course.subject} />
                        </div>
                      )}
                      <p className="text-gray-400 mb-6 line-clamp-2">
                        {course.description}
                      </p>
                      
                      {currentUser && progress > 0 && (
                        <div className="mb-4">
                          <div className="flex items-center justify-between mb-1">
                            <span className="text-xs text-gray-400">Progress</span>
                            <span className="text-xs text-blue-400">{progress}%</span>
                          </div>
                          <div className="w-full h-1.5 bg-[#1a1f2d] rounded-full overflow-hidden">
                            <div 
                              className={`h-full rounded-full ${isCompleted ? 'bg-emerald-500' : 'bg-blue-500'}`} 
                              style={{ width: `${progress}%` }}
                            ></div>
                          </div>
                        </div>
                      )}
                      
                      <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-4 mb-4">
                        <div>
                          {course.author && (
                            <div className="flex items-center text-sm text-gray-400">
                              <GraduationCap className="mr-2 h-4 w-4 text-blue-400 shrink-0" />
                              <span className="truncate">
                                <TranslatedText id="author" className="text-gray-500 mr-1" />
                                {course.author}
                              </span>
                              {course.verified && (
                                <div className="relative ml-2 group/tooltip">
                                  {course.verifiedStatus === 'org' ? (
                                    <div className="h-5 w-5 bg-yellow-500 rounded-full flex items-center justify-center">
                                      <div className="h-4 w-4 bg-yellow-600 rounded-full flex items-center justify-center">
                                        <Check className="h-2 w-2 text-white" />
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="h-5 w-5 bg-blue-500 rounded-full flex items-center justify-center">
                                      <div className="h-4 w-4 bg-blue-600 rounded-full flex items-center justify-center">
                                        <Check className="h-2 w-2 text-white" />
                                      </div>
                                    </div>
                                  )}
                                  <div className="absolute bottom-full left-1/2 -translate-x-1/2 mb-2 px-2 py-1 bg-gray-900 text-xs text-white rounded opacity-0 group-hover/tooltip:opacity-100 whitespace-nowrap transition-opacity">
                                    <TranslatedText id={course.verifiedStatus === 'org' ? 'verifiedOrg' : 'verifiedCreator'} />
                                  </div>
                                </div>
                              )}
                            </div>
                          )}
                          {course.lessonCount && (
                            <div className="text-xs text-gray-400 mt-1">
                              <TranslatedText 
                                id="lessonCount" 
                                params={{ count: course.lessonCount }}
                              />
                            </div>
                          )}
                        </div>
                        
                        <div className="flex items-center text-yellow-500">
                          <Star className="h-4 w-4 fill-current" />
                          <Star className="h-4 w-4 fill-current" />
                          <Star className="h-4 w-4 fill-current" />
                          <Star className="h-4 w-4 fill-current" />
                          <Star className="h-4 w-4 fill-current" />
                        </div>
                      </div>
                      
                      <div className="mt-4 pt-4 border-t border-[#1f212a]">
                        <button 
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent triggering parent div's onClick
                            if (currentUser) {
                              navigate(`/dashboard/courses/${course.slug || createSlug(course.title, course.id)}`);
                            } else {
                              navigate('/login');
                            }
                          }}
                          className={`w-full flex items-center justify-center px-4 py-3 ${isCompleted ? 'bg-gradient-to-r from-emerald-600 to-emerald-500 hover:from-emerald-700 hover:to-emerald-600' : isInProgress ? 'bg-gradient-to-r from-blue-700 to-blue-600 hover:from-blue-800 hover:to-blue-700' : 'bg-gradient-to-r from-blue-700 to-blue-600 hover:from-blue-800 hover:to-blue-700'} text-white rounded-lg transition-colors text-sm font-medium shadow-md`}>
                          {isCompleted ? (
                            <><TranslatedText id="reviseCourse" /> <ArrowRight className="ml-2 h-4 w-4" /></>
                          ) : isInProgress ? (
                            <><TranslatedText id="continueCourse" /> <ArrowRight className="ml-2 h-4 w-4" /></>
                          ) : (
                            <><TranslatedText id="viewCourse" /> <ArrowRight className="ml-2 h-4 w-4" /></>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </main>

        {/* Footer */}
        <footer className="bg-[#0a0c14] border-t border-[#1f212a]">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
            <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
              <div className="space-y-6">
                <div className="flex items-center text-xl font-bold text-white">
                  <img src="/logo1.png" alt="Umi Logo" className="h-6 w-6 mr-2" />
                  <TranslatedText id="appTitle" />
                </div>
                <p className="text-gray-400 text-sm">
                  <TranslatedText id="mission" />
                </p>
                <div className="flex space-x-4">
                  <a href="#" className="text-gray-400 hover:text-blue-400 transition-colors">
                    <Twitter className="h-5 w-5" />
                  </a>
                  <a href="#" className="text-gray-400 hover:text-blue-400 transition-colors">
                    <Github className="h-5 w-5" />
                  </a>
                  <a href="#" className="text-gray-400 hover:text-blue-400 transition-colors">
                    <Linkedin className="h-5 w-5" />
                  </a>
                </div>
              </div>
              
              <div>
                <h3 className="text-white font-semibold mb-4"><TranslatedText id="learn" /></h3>
                <ul className="space-y-2">
                  <li><a href="#" className="text-gray-400 hover:text-blue-400 transition-colors text-sm"><TranslatedText id="allCourses" /></a></li>
                  <li><a href="#" className="text-gray-400 hover:text-blue-400 transition-colors text-sm"><TranslatedText id="programming" /></a></li>
                  <li><a href="#" className="text-gray-400 hover:text-blue-400 transition-colors text-sm"><TranslatedText id="webDevelopment" /></a></li>
                  <li><a href="#" className="text-gray-400 hover:text-blue-400 transition-colors text-sm"><TranslatedText id="artificialIntelligence" /></a></li>
                  <li><a href="#" className="text-gray-400 hover:text-blue-400 transition-colors text-sm"><TranslatedText id="dataScience" /></a></li>
                </ul>
              </div>
              
              <div>
                <h3 className="text-white font-semibold mb-4"><TranslatedText id="about" /></h3>
                <ul className="space-y-2">
                  <li><a href="#" className="text-gray-400 hover:text-blue-400 transition-colors text-sm"><TranslatedText id="ourMission" /></a></li>
                  <li><a href="#" className="text-gray-400 hover:text-blue-400 transition-colors text-sm"><TranslatedText id="ourTeam" /></a></li>
                  <li><a href="#" className="text-gray-400 hover:text-blue-400 transition-colors text-sm"><TranslatedText id="careers" /></a></li>
                  <li><a href="#" className="text-gray-400 hover:text-blue-400 transition-colors text-sm"><TranslatedText id="blog" /></a></li>
                  <li><a href="#" className="text-gray-400 hover:text-blue-400 transition-colors text-sm"><TranslatedText id="contactUs" /></a></li>
                </ul>
              </div>
              
              <div>
                <h3 className="text-white font-semibold mb-4"><TranslatedText id="support" /></h3>
                <ul className="space-y-2">
                  <li><a href="#" className="text-gray-400 hover:text-blue-400 transition-colors text-sm"><TranslatedText id="faq" /></a></li>
                  <li><a href="#" className="text-gray-400 hover:text-blue-400 transition-colors text-sm"><TranslatedText id="helpCenter" /></a></li>
                  <li><a href="#" className="text-gray-400 hover:text-blue-400 transition-colors text-sm"><TranslatedText id="privacyPolicy" /></a></li>
                  <li><a href="#" className="text-gray-400 hover:text-blue-400 transition-colors text-sm"><TranslatedText id="termsOfService" /></a></li>
                  <li><a href="#" className="text-gray-400 hover:text-blue-400 transition-colors text-sm"><TranslatedText id="accessibility" /></a></li>
                </ul>
              </div>
            </div>
            
            <div className="border-t border-[#1f212a] mt-10 pt-6 flex flex-col md:flex-row justify-between items-center text-gray-400 text-sm">
              <div><TranslatedText id="copyright" /></div>
              <div className="flex space-x-4 mt-4 md:mt-0">
                <a href="#" className="hover:text-blue-400 transition-colors"><TranslatedText id="terms" /></a>
                <a href="#" className="hover:text-blue-400 transition-colors"><TranslatedText id="privacy" /></a>
                <a href="#" className="hover:text-blue-400 transition-colors"><TranslatedText id="cookies" /></a>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  };

  return (
    <LanguageProvider>
      <AskAIWrapper>
        {(props) => <CourseSelectorContent {...props} />}
      </AskAIWrapper>
    </LanguageProvider>
  );
};

export default CourseSelector;