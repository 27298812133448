import React, { useState } from 'react';
import { Youtube, Globe, FileText, File, Music, Plus, X, Check, Upload } from 'lucide-react';
import { colors, components } from './designSystem';

const IMPORT_TYPES = [
  { id: 'youtube', label: 'YouTube', icon: Youtube, color: 'text-red-500', description: 'Import from YouTube videos' },
  { id: 'website', label: 'Website', icon: Globe, color: 'text-blue-400', description: 'Import from web articles' },
  { id: 'pdf', label: 'PDF', icon: FileText, color: 'text-orange-400', description: 'Import from PDF documents' },
  { id: 'text', label: 'Text', icon: File, color: 'text-gray-400', description: 'Import from text files' },
  { id: 'audio', label: 'Audio', icon: Music, color: 'text-green-400', description: 'Import from audio files' }
];

const CourseImporter = () => {
  const [activeType, setActiveType] = useState('youtube');
  const [courseTitle, setCourseTitle] = useState('');
  const [courseDescription, setCourseDescription] = useState('');
  const [currentUrl, setCurrentUrl] = useState('');
  const [currentFile, setCurrentFile] = useState(null);
  const [contentItems, setContentItems] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleAddContent = () => {
    const newItem = {
      id: Date.now(),
      type: activeType,
      title: '',
      source: activeType === 'youtube' || activeType === 'website' ? currentUrl : currentFile?.name || '',
    };

    setContentItems([...contentItems, newItem]);
    setCurrentUrl('');
    setCurrentFile(null);
  };

  const handleRemoveContent = (id) => {
    setContentItems(contentItems.filter(item => item.id !== id));
  };

  const handleUpdateContentTitle = (id, title) => {
    setContentItems(contentItems.map(item => 
      item.id === id ? { ...item, title } : item
    ));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!courseTitle.trim()) {
      setError('Course title is required');
      return;
    }
    
    if (contentItems.length === 0) {
      setError('At least one content item is required');
      return;
    }

    setError('');
    setIsProcessing(true);

    try {
      // Simulated processing
      await new Promise(resolve => setTimeout(resolve, 2000));
      setSuccess('Course created successfully!');
      setIsProcessing(false);
      setCourseTitle('');
      setCourseDescription('');
      setContentItems([]);
    } catch (err) {
      setError('Failed to create course. Please try again.');
      setIsProcessing(false);
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-8">
      {error && (
        <div className="mb-6 p-4 bg-red-500/10 backdrop-blur-sm border border-red-500/20 rounded-2xl text-red-500 flex items-center gap-2">
          <X size={20} />
          {error}
        </div>
      )}

      {success && (
        <div className="mb-6 p-4 bg-green-500/10 backdrop-blur-sm border border-green-500/20 rounded-2xl text-green-500 flex items-center gap-2">
          <Check size={20} />
          {success}
        </div>
      )}

      <form onSubmit={handleSubmit} className="space-y-8">
        {/* Course Details */}
        <div className="bg-[#262b38]/50 backdrop-blur-xl rounded-2xl border border-gray-800/50 p-8 space-y-6">
          <h2 className="text-xl font-medium text-white/90">Course Details</h2>
          
          <div className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-white/70 mb-2">
                Course Title<span className="text-blue-400">*</span>
              </label>
              <input
                type="text"
                value={courseTitle}
                onChange={(e) => setCourseTitle(e.target.value)}
                placeholder="Enter course title"
                className={`${components.input.base} ${components.input.sizes.lg}`}
                required
              />
            </div>
            
            <div>
              <label className="block text-sm font-medium text-white/70 mb-2">
                Course Description
              </label>
              <textarea
                value={courseDescription}
                onChange={(e) => setCourseDescription(e.target.value)}
                placeholder="Enter course description"
                rows={3}
                className={`${components.input.base} ${components.input.sizes.lg} resize-none`}
              />
            </div>
          </div>
        </div>

        {/* Import Content */}
        <div className="bg-[#262b38]/50 backdrop-blur-xl rounded-2xl border border-gray-800/50 p-8 space-y-6">
          <h2 className="text-xl font-medium text-white/90">Import Content</h2>

          {/* Import Type Selection */}
          <div className="flex gap-2 overflow-x-auto pb-2 -mx-2 px-2">
            {IMPORT_TYPES.map(type => (
              <button
                key={type.id}
                type="button"
                onClick={() => setActiveType(type.id)}
                className={`flex items-center gap-2 px-4 py-3 rounded-xl transition-all ${
                  activeType === type.id
                    ? 'bg-blue-500/20 text-blue-400 border border-blue-500/30'
                    : 'hover:bg-[#1a1f2d] text-white/70 border border-transparent'
                }`}
              >
                <type.icon className={`h-5 w-5 ${activeType === type.id ? type.color : ''}`} />
                <span className="whitespace-nowrap">{type.label}</span>
              </button>
            ))}
          </div>

          {/* Import Form */}
          <div className="space-y-4">
            {(activeType === 'youtube' || activeType === 'website') ? (
              <div>
                <label className="block text-sm font-medium text-white/70 mb-2">
                  {activeType === 'youtube' ? 'YouTube Video URL' : 'Website URL'}
                </label>
                <div className="flex gap-2">
                  <input
                    type="url"
                    value={currentUrl}
                    onChange={(e) => setCurrentUrl(e.target.value)}
                    placeholder={activeType === 'youtube' ? 'https://www.youtube.com/watch?v=...' : 'https://example.com/article'}
                    className={`${components.input.base} ${components.input.sizes.lg}`}
                  />
                  <button
                    type="button"
                    onClick={handleAddContent}
                    disabled={!currentUrl}
                    className={`${components.button.base} ${components.button.variants.primary} ${components.button.sizes.md}`}
                  >
                    <Plus className="h-5 w-5" />
                    Add
                  </button>
                </div>
              </div>
            ) : (
              <div>
                <label className="block text-sm font-medium text-white/70 mb-2">
                  Upload {activeType === 'pdf' ? 'PDF' : activeType === 'text' ? 'Text File' : 'Audio File'}
                </label>
                <div className="flex gap-2">
                  <label className="flex-1 cursor-pointer">
                    <div className="border-2 border-dashed border-gray-700 hover:border-blue-500/50 rounded-xl p-8 text-center transition-all">
                      <Upload className="h-8 w-8 mx-auto mb-2 text-gray-400" />
                      <p className="text-gray-400">
                        {currentFile ? currentFile.name : `Click to upload ${activeType === 'pdf' ? 'PDF' : activeType === 'text' ? 'text file' : 'audio file'}`}
                      </p>
                    </div>
                    <input
                      type="file"
                      className="hidden"
                      accept={activeType === 'pdf' ? '.pdf' : activeType === 'text' ? '.txt,.md,.doc,.docx' : '.mp3,.wav,.ogg,.m4a'}
                      onChange={(e) => setCurrentFile(e.target.files[0])}
                    />
                  </label>
                  <button
                    type="button"
                    onClick={handleAddContent}
                    disabled={!currentFile}
                    className="px-4 py-2 bg-blue-500 text-white rounded-xl hover:bg-blue-600 transition-colors disabled:opacity-50 disabled:cursor-not-allowed flex items-center gap-2"
                  >
                    <Plus className="h-5 w-5" />
                    Add
                  </button>
                </div>
              </div>
            )}
          </div>

          {/* Added Content List */}
          {contentItems.length > 0 && (
            <div className="space-y-4">
              <h3 className="text-lg font-medium text-white/90">Added Content</h3>
              <div className="space-y-3">
                {contentItems.map((item) => {
                  const TypeIcon = IMPORT_TYPES.find(t => t.id === item.type)?.icon;
                  const typeColor = IMPORT_TYPES.find(t => t.id === item.type)?.color;
                  
                  return (
                    <div key={item.id} className="flex items-center bg-[#1a1f2d] border border-gray-800 rounded-xl p-4 group">
                      <div className="mr-3">
                        <TypeIcon className={`h-5 w-5 ${typeColor}`} />
                      </div>
                      <div className="flex-1 min-w-0">
                        <input
                          type="text"
                          value={item.title}
                          onChange={(e) => handleUpdateContentTitle(item.id, e.target.value)}
                          placeholder={`Enter title for this ${item.type}`}
                          className={`w-full bg-transparent border-none focus:ring-0 outline-none text-white placeholder-gray-500`}
                        />
                        <p className="text-xs text-gray-500 truncate">{item.source}</p>
                      </div>
                      <button
                        type="button"
                        onClick={() => handleRemoveContent(item.id)}
                        className="ml-2 p-2 text-gray-400 hover:text-red-500 transition-colors rounded-lg opacity-0 group-hover:opacity-100"
                      >
                        <X className="h-5 w-5" />
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>

        {/* Submit Button */}
        <div className="flex justify-end">
          <button
            type="submit"
            disabled={isProcessing}
            className={`${components.button.base} ${components.button.variants.primary} ${components.button.sizes.lg}`}
          >
            {isProcessing ? (
              <>
                <div className="w-5 h-5 border-2 border-white/20 border-t-white rounded-full animate-spin" />
                Processing...
              </>
            ) : (
              <>
                <Check className="h-5 w-5" />
                Create Course
              </>
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default CourseImporter; 