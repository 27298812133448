import { supabase } from '../auth/supabase';
import { 
  invalidateCourseCaches, 
  CACHE_KEYS 
} from './cachingService';

// Constants
const TABLES = {
  COURSES: 'courses',
  LESSONS: 'lessons',
  USERS: 'users'
};

/**
 * Get all courses with optional filtering
 * @param {Object} options - Filter options
 * @param {string} options.difficulty - Filter by difficulty level
 * @param {Array} options.topics - Filter by topics
 * @param {number} options.limitCount - Limit the number of results
 * @returns {Promise<Array>} - Array of course objects
 */
export const getAllCourses = async (options = {}) => {
  try {
    console.log('getAllCourses called with options:', options);
    // Check cache first
    const cacheKey = CACHE_KEYS.ALL_COURSES_CACHE;
    const cachedCourses = localStorage.getItem(cacheKey);
    
    if (cachedCourses) {
      try {
        const parsedData = JSON.parse(cachedCourses);
        const cacheTimestamp = parsedData._cachedAt || 0;
        const cacheAge = Date.now() - cacheTimestamp;
        
        // Use cache if it's less than 5 minutes old
        if (cacheAge < 5 * 60 * 1000) {
          console.log('Using cached courses data');
          
          // Apply in-memory filtering if needed
          let filteredCourses = parsedData.courses;
          
          if (options.difficulty) {
            filteredCourses = filteredCourses.filter(course => 
              course.difficulty === options.difficulty
            );
          }
          
          if (options.topics && options.topics.length > 0) {
            filteredCourses = filteredCourses.filter(course => 
              course.topics && course.topics.some(topic => options.topics.includes(topic))
            );
          }
          
          // Apply limit if needed
          if (options.limitCount) {
            filteredCourses = filteredCourses.slice(0, options.limitCount);
          }
          
          return filteredCourses;
        } else {
          console.log('Cache expired, fetching fresh courses data');
        }
      } catch (e) {
        console.error('Error parsing cached courses:', e);
      }
    } else {
      console.log('No courses cache found');
    }
    
    // Create a promise that resolves after a timeout - this ensures we don't wait too long
    const timeoutPromise = new Promise((_, reject) => {
      setTimeout(() => reject(new Error('Fetch timeout')), 3000); // Reduced from 2500ms
    });
    
    // For browser compatibility, create a controller to abort the fetch
    const controller = new AbortController();
    setTimeout(() => controller.abort(), 3000);
    
    console.log('Fetching courses from Supabase...');
    const startTime = Date.now();
    
    // Cache miss or expired, fetch from Supabase
    let query = supabase
      .from(TABLES.COURSES)
      .select(`
        *,
        subjects!subject_id (
          id,
          subject,
          slug,
          description,
          thumbnail
        ),
        course_topics(topic_id),
        topics:course_topics(topics:topic_id(id, name))
      `);
    
    // Apply filters if provided
    if (options.difficulty) {
      query = query.eq('difficulty', options.difficulty);
    }
    
    if (options.topics && options.topics.length > 0) {
      // For Supabase we'll filter in memory if topics are provided
      // since it handles arrays differently than traditional NoSQL databases
    }
    
    // Apply ordering - by default, sort by creation date (newest first)
    query = query.order('created_at', { ascending: false });
    
    // Apply limit if provided
    if (options.limitCount) {
      query = query.limit(options.limitCount);
    }
    
    // Race between the fetch and timeout
    const fetchPromise = query.abortSignal(controller.signal);
    
    let courses = [];
    let error = null;
    
    try {
      const result = await Promise.race([
        fetchPromise,
        timeoutPromise
      ]);
      
      courses = result.data || [];
      error = result.error;
      
      // Debug - log raw subjects data from first few courses
      if (courses && courses.length > 0) {
        console.log('Raw courses data from Supabase (first 3):', 
          courses.slice(0, 3).map(course => ({
            id: course.id,
            title: course.title,
            subject_id: course.subject_id,
            subjects: course.subjects
          }))
        );
      }
      
      const endTime = Date.now();
      console.log(`Courses fetch completed in ${endTime - startTime}ms`);
    } catch (fetchError) {
      // If we hit the timeout or any other error, log it and use fallback data
      console.error('Courses fetch error:', fetchError);
      error = fetchError;
      
      // If we have cached data, use it as fallback
      if (cachedCourses) {
        try {
          console.log('Using cached courses as fallback due to fetch error');
          const parsedData = JSON.parse(cachedCourses);
          courses = parsedData.courses || [];
        } catch (e) {
          console.error('Error parsing cached courses fallback:', e);
          courses = [];
        }
      } else {
        // No cache, return empty array and log error for debugging
        console.error('No cache available for fallback, returning empty courses array');
        courses = [];
      }
    }
    
    if (error && (!courses || courses.length === 0)) {
      console.error('Error fetching courses and no fallback available:', error);
      return [];
    }
    
    // Filter by topics if needed (in memory)
    let filteredCourses = courses;
    if (options.topics && options.topics.length > 0 && courses.length > 0) {
      filteredCourses = courses.filter(course => 
        course.topics && course.topics.some(topic => options.topics.includes(topic))
      );
    }
    
    const formattedCourses = filteredCourses.map(course => {
      // Extract subject name from the joined subjects table
      let subjectName = null;
      
      // Check if we have a valid subject relation
      if (course.subjects) {
        // The subjects table uses "subject" as the name field, not "name"
        subjectName = course.subjects.subject;
      }
      
      // Extract topics from the joined topics table
      let topicsArray = [];
      if (course.topics) {
        // Check if we have the new structure with course_topics
        if (Array.isArray(course.topics)) {
          // Extract the topic names
          topicsArray = course.topics
            .filter(topicObj => topicObj && topicObj.topics && topicObj.topics.name)
            .map(topicObj => topicObj.topics.name);
        }
      }
      
      return {
        id: course.id,
        ...course,
        // Set subject name for display - the course.subject property will be used for filtering
        subject: subjectName || 'Other',
        // Replace the original topics array with our processed one if we have new data
        topics: topicsArray.length > 0 ? topicsArray : course.topics || []
      };
    });
    
    // Cache the full courses dataset
    try {
      console.log(`Caching ${courses.length} courses`);
      localStorage.setItem(cacheKey, JSON.stringify({
        courses: courses.map(course => ({ id: course.id, ...course })),
        _cachedAt: Date.now()
      }));
    } catch (cacheError) {
      console.error('Error caching courses:', cacheError);
    }
    
    return formattedCourses;
  } catch (error) {
    console.error('Critical error in getAllCourses:', error);
    
    // Return cached data in case of error
    const cachedCourses = localStorage.getItem(CACHE_KEYS.ALL_COURSES_CACHE);
    if (cachedCourses) {
      try {
        console.log('Using cached courses due to critical error');
        const parsedData = JSON.parse(cachedCourses);
        return parsedData.courses || [];
      } catch (e) {
        // If all else fails, return empty array and log the error
        console.error('Failed to parse cached courses after critical error:', e);
        return [];
      }
    }
    
    console.error('No cached courses available after critical error');
    return [];
  }
};

/**
 * Get a single course by ID
 * @param {string} courseId - The course ID
 * @returns {Promise<Object>} - Course object
 */
export const getCourseById = async (courseId) => {
  try {
    // Check cache first
    const cacheKey = CACHE_KEYS.COURSE_DETAILS(courseId);
    const cachedCourse = localStorage.getItem(cacheKey);
    
    if (cachedCourse) {
      try {
        const parsedData = JSON.parse(cachedCourse);
        const cacheTimestamp = parsedData._cachedAt || 0;
        const cacheAge = Date.now() - cacheTimestamp;
        
        // Use cache if it's less than 5 minutes old
        if (cacheAge < 5 * 60 * 1000) {
          console.log(`Using cached course data for ${courseId}`);
          return parsedData.course;
        } else {
          console.log('Cache expired, fetching fresh course data');
        }
      } catch (e) {
        console.error('Error parsing cached course:', e);
      }
    }
    
    // Cache miss or expired, fetch from Supabase
    const { data: course, error } = await supabase
      .from(TABLES.COURSES)
      .select(`
        *,
        subjects!subject_id (
          id,
          subject,
          slug,
          description,
          thumbnail
        ),
        course_topics(topic_id),
        topics:course_topics(topics:topic_id(id, name))
      `)
      .eq('id', courseId)
      .single();
    
    if (error) {
      throw new Error(`Course with ID ${courseId} not found: ${error.message}`);
    }
    
    // Process subject name
    let subjectName = null;
    if (course.subjects) {
      subjectName = course.subjects.subject;
    }
    
    // Process topics
    let topicsArray = [];
    if (course.topics) {
      if (Array.isArray(course.topics)) {
        topicsArray = course.topics
          .filter(topicObj => topicObj && topicObj.topics && topicObj.topics.name)
          .map(topicObj => topicObj.topics.name);
      }
    }
    
    const formattedCourse = {
      id: course.id,
      ...course,
      subject: subjectName || 'Other',
      topics: topicsArray.length > 0 ? topicsArray : course.topics || []
    };
    
    // Cache the course
    try {
      localStorage.setItem(cacheKey, JSON.stringify({
        course: formattedCourse,
        _cachedAt: Date.now()
      }));
    } catch (cacheError) {
      console.error('Error caching course:', cacheError);
    }
    
    return formattedCourse;
  } catch (error) {
    console.error(`Error fetching course with ID ${courseId}:`, error);
    throw error;
  }
};

/**
 * Get a single course by slug
 * @param {string} slug - The course slug
 * @returns {Promise<Object>} - Course object
 */
export const getCourseBySlug = async (slug) => {
  try {
    // Try exact match first
    const { data: courses, error } = await supabase
      .from(TABLES.COURSES)
      .select('*')
      .eq('slug', slug);
    
    if (error) {
      throw new Error(`Error looking up course with slug ${slug}: ${error.message}`);
    }
    
    // If exact match found, return the course
    if (courses && courses.length > 0) {
      return {
        id: courses[0].id,
        ...courses[0]
      };
    }
    
    // If no exact match, try more flexible match
    const { data: allCourses, error: allCoursesError } = await supabase
      .from(TABLES.COURSES)
      .select('*');
    
    if (allCoursesError) {
      throw new Error(`Error fetching all courses: ${allCoursesError.message}`);
    }
    
    // Try to find a course that contains the slug or where the slug contains part of the course slug
    const matchingCourse = allCourses.find(course => 
      (course.slug && course.slug.includes(slug)) || 
      (slug && slug.includes(course.slug)) ||
      (course.title && course.title.toLowerCase().includes(slug.toLowerCase()))
    );
    
    if (matchingCourse) {
      return {
        id: matchingCourse.id,
        ...matchingCourse
      };
    }
    
    throw new Error(`No course found with slug matching ${slug}`);
  } catch (error) {
    console.error(`Error fetching course with slug ${slug}:`, error);
    throw error;
  }
};

/**
 * Create a new course
 * @param {Object} courseData - Course data object
 * @param {string} courseId - Optional custom ID for the course
 * @returns {Promise<string>} - New course ID
 */
export const createCourse = async (courseData, courseId = null) => {
  try {
    const now = new Date().toISOString();
    
    // Prepare data with timestamps
    const newCourse = {
      ...courseData,
      created_at: now,
      updated_at: now
    };
    
    // If ID is provided, include it in the data
    if (courseId) {
      newCourse.id = courseId;
    }
    
    const { data, error } = await supabase
      .from(TABLES.COURSES)
      .insert([newCourse])
      .select();
    
    if (error) {
      console.error('Error creating course:', error);
      throw error;
    }
    
    // Invalidate all course-related caches after creating a new course
    invalidateCourseCaches();
    
    return data[0].id;
  } catch (error) {
    console.error('Error creating course:', error);
    throw error;
  }
};

/**
 * Update an existing course
 * @param {string} courseId - The course ID
 * @param {Object} courseData - Updated course data
 * @returns {Promise<void>}
 */
export const updateCourse = async (courseId, courseData) => {
  try {
    // Check if course exists
    const { data: existingCourse, error: checkError } = await supabase
      .from(TABLES.COURSES)
      .select('id')
      .eq('id', courseId)
      .single();
    
    if (checkError) {
      throw new Error(`Course with ID ${courseId} not found: ${checkError.message}`);
    }
    
    // Update with timestamp
    const { error: updateError } = await supabase
      .from(TABLES.COURSES)
      .update({
        ...courseData,
        updated_at: new Date().toISOString()
      })
      .eq('id', courseId);
    
    if (updateError) {
      throw updateError;
    }
    
    // Invalidate course caches after update
    invalidateCourseCaches();
  } catch (error) {
    console.error(`Error updating course with ID ${courseId}:`, error);
    throw error;
  }
};

/**
 * Delete a course and its lessons
 * @param {string} courseId - The course ID
 * @returns {Promise<void>}
 */
export const deleteCourse = async (courseId) => {
  try {
    // First, check if course exists
    const { data: existingCourse, error: checkError } = await supabase
      .from(TABLES.COURSES)
      .select('id')
      .eq('id', courseId)
      .single();
    
    if (checkError) {
      throw new Error(`Course with ID ${courseId} not found: ${checkError.message}`);
    }
    
    // Delete all lessons for this course
    const { error: lessonsDeleteError } = await supabase
      .from(TABLES.LESSONS)
      .delete()
      .eq('course_id', courseId);
    
    if (lessonsDeleteError) {
      console.error(`Error deleting lessons for course ${courseId}:`, lessonsDeleteError);
      throw lessonsDeleteError;
    }
    
    // Finally, delete the course
    const { error: courseDeleteError } = await supabase
      .from(TABLES.COURSES)
      .delete()
      .eq('id', courseId);
    
    if (courseDeleteError) {
      throw courseDeleteError;
    }
    
    // Invalidate all course-related caches after deletion
    invalidateCourseCaches();
  } catch (error) {
    console.error(`Error deleting course with ID ${courseId}:`, error);
    throw error;
  }
};

/**
 * Get popular courses based on user enrollments
 * @param {number} count - Number of courses to retrieve
 * @returns {Promise<Array>} - Array of popular course objects
 */
export const getPopularCourses = async (count = 5) => {
  try {
    // Get all courses
    const courses = await getAllCourses();
    
    // In a real application, you would track enrollments in a separate table
    // For this simplified version, we'll just return courses sorted by creation date
    return courses.slice(0, count);
  } catch (error) {
    console.error('Error fetching popular courses:', error);
    throw error;
  }
};

/**
 * Get recommended courses for a user
 * @param {string} userId - User ID
 * @param {number} count - Number of courses to retrieve
 * @returns {Promise<Array>} - Array of recommended course objects
 */
export const getRecommendedCourses = async (userId, count = 3) => {
  try {
    // Get user data to check interests/history
    const { data: userData, error: userError } = await supabase
      .from(TABLES.USERS)
      .select('*')
      .eq('id', userId)
      .single();
    
    if (userError) {
      throw new Error(`User with ID ${userId} not found: ${userError.message}`);
    }
    
    // Get courses the user hasn't completed or started
    const completedCourseIds = userData.courses 
      ? Object.keys(userData.courses).filter(id => userData.courses[id].progress === 100)
      : [];
    
    const inProgressCourseIds = userData.courses
      ? Object.keys(userData.courses).filter(id => userData.courses[id].progress > 0 && userData.courses[id].progress < 100)
      : [];
    
    // Get all courses
    const { data: allCourses, error: coursesError } = await supabase
      .from(TABLES.COURSES)
      .select('*');
    
    if (coursesError) {
      throw coursesError;
    }
    
    // Filter out courses the user has completed
    const availableCourses = allCourses.filter(course => 
      !completedCourseIds.includes(course.id)
    );
    
    // Prioritize courses similar to what the user is already learning
    let recommendedCourses = [];
    
    if (inProgressCourseIds.length > 0) {
      // Find current topics the user is learning
      const currentCourses = allCourses.filter(course => 
        inProgressCourseIds.includes(course.id)
      );
      
      const currentTopics = [];
      currentCourses.forEach(course => {
        if (course.topics) {
          currentTopics.push(...course.topics);
        }
      });
      
      // Find courses with similar topics
      if (currentTopics.length > 0) {
        recommendedCourses = availableCourses
          .filter(course => 
            !inProgressCourseIds.includes(course.id) && 
            course.topics && 
            course.topics.some(topic => currentTopics.includes(topic))
          )
          .sort((a, b) => {
            // Sort by number of matching topics
            const aMatchCount = a.topics ? a.topics.filter(topic => currentTopics.includes(topic)).length : 0;
            const bMatchCount = b.topics ? b.topics.filter(topic => currentTopics.includes(topic)).length : 0;
            return bMatchCount - aMatchCount;
          });
      }
    }
    
    // If not enough recommendations, add other available courses
    if (recommendedCourses.length < count) {
      const otherCourses = availableCourses
        .filter(course => !recommendedCourses.some(rc => rc.id === course.id))
        .slice(0, count - recommendedCourses.length);
      
      recommendedCourses = [...recommendedCourses, ...otherCourses];
    }
    
    return recommendedCourses.slice(0, count);
  } catch (error) {
    console.error('Error fetching recommended courses:', error);
    throw error;
  }
};

/**
 * Assign a subject to a course
 * @param {string} courseId - The course ID
 * @param {string} subjectId - The subject ID
 * @returns {Promise<boolean>} - Success status
 */
export const assignSubjectToCourse = async (courseId, subjectId) => {
  try {
    // Update the course with the new subject_id
    const { error } = await supabase
      .from(TABLES.COURSES)
      .update({
        subject_id: subjectId,
        updated_at: new Date().toISOString()
      })
      .eq('id', courseId);
    
    if (error) {
      console.error('Error assigning subject to course:', error);
      return false;
    }
    
    // Invalidate related caches
    invalidateCourseCaches();
    
    return true;
  } catch (error) {
    console.error('Error in assignSubjectToCourse:', error);
    return false;
  }
};

/**
 * Assign topics to a course
 * @param {string} courseId - The course ID
 * @param {Array<string>} topicIds - Array of topic IDs
 * @returns {Promise<boolean>} - Success status
 */
export const assignTopicsToCourse = async (courseId, topicIds) => {
  try {
    if (!topicIds || !topicIds.length) return false;
    
    // First, remove any existing topic associations
    const { error: deleteError } = await supabase
      .from('course_topics')
      .delete()
      .eq('course_id', courseId);
    
    if (deleteError) {
      console.error('Error removing existing course topics:', deleteError);
      return false;
    }
    
    // Create the new topic associations
    const topicAssociations = topicIds.map(topicId => ({
      course_id: courseId,
      topic_id: topicId
    }));
    
    const { error: insertError } = await supabase
      .from('course_topics')
      .insert(topicAssociations);
    
    if (insertError) {
      console.error('Error assigning topics to course:', insertError);
      return false;
    }
    
    // Invalidate related caches
    invalidateCourseCaches();
    
    return true;
  } catch (error) {
    console.error('Error in assignTopicsToCourse:', error);
    return false;
  }
};