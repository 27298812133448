import React from 'react';
import { useLanguage } from '../context/LanguageContext';
import { getTranslation } from '../utils/translations';

/**
 * TranslatedText Component
 * 
 * A utility component that displays text according to the current language setting.
 * 
 * Props:
 * - id: The translation key to look up
 * - dangerouslySetInnerHTML: Whether to use dangerouslySetInnerHTML (for HTML content)
 * - className: Optional CSS class name
 * - params: Object containing parameters for dynamic text
 * - fallback: Text to use if translation is not found
 * - ...rest: Any other props to pass to the component
 */
const TranslatedText = ({ 
  id, 
  dangerouslySetInnerHTML = false, 
  className = '', 
  params = {},
  fallback = '',
  ...rest 
}) => {
  const { language } = useLanguage();
  const translatedText = getTranslation(language, id, params) || fallback || id;
  
  if (dangerouslySetInnerHTML) {
    return (
      <span 
        className={className}
        dangerouslySetInnerHTML={{ __html: translatedText }}
        {...rest}
      />
    );
  }
  
  return (
    <span className={className} {...rest}>
      {translatedText}
    </span>
  );
};

export default TranslatedText; 