import React, { useState } from 'react';
import { Upload, Edit, ArrowLeft } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import CourseImporter from './CourseImporter';
import CourseCreator from './CourseCreator';
import { components } from './designSystem';

const CourseCreationSelector = () => {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState(null);

  const options = [
    {
      id: 'import',
      title: 'Import Content',
      description: 'Convert content from YouTube, websites, PDFs, and more into interactive courses',
      icon: Upload,
      component: CourseImporter
    },
    {
      id: 'manual',
      title: 'Create Manually',
      description: 'Build your course from scratch with a Notion-like editor',
      icon: Edit,
      component: CourseCreator
    }
  ];

  // Handle back navigation
  const handleBack = () => {
    navigate('/courses');
  };

  if (selectedOption) {
    const Component = options.find(opt => opt.id === selectedOption)?.component;
    return (
      <div className="min-h-screen bg-[#1a1f2d] text-white">
        <div className="max-w-6xl mx-auto">
          <div className="flex items-center gap-4 p-6 border-b border-gray-800">
            <button
              onClick={() => setSelectedOption(null)}
              className={`${components.button.base} ${components.button.variants.secondary} ${components.button.sizes.sm}`}
            >
              <ArrowLeft size={24} />
            </button>
            <h1 className="text-2xl font-bold text-white">
              {options.find(opt => opt.id === selectedOption)?.title}
            </h1>
          </div>
          <Component onBack={() => setSelectedOption(null)} />
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-[#1a1f2d] text-white">
      <div className="max-w-6xl mx-auto">
        <div className="flex items-center gap-4 p-6 border-b border-gray-800">
          <button
            onClick={handleBack}
            className={`${components.button.base} ${components.button.variants.secondary} ${components.button.sizes.sm}`}
          >
            <ArrowLeft size={24} />
          </button>
          <h1 className="text-2xl font-bold text-white">Create New Course</h1>
        </div>
        
        <div className="p-8">
          <div className="grid md:grid-cols-2 gap-6">
            {options.map((option) => (
              <button
                key={option.id}
                onClick={() => setSelectedOption(option.id)}
                className={`${components.card.base} ${components.card.variants.hover} p-8 text-left group relative overflow-hidden`}
              >
                <div className="absolute inset-0 bg-gradient-to-br from-blue-500/5 to-purple-500/5 opacity-0 group-hover:opacity-100 transition-opacity" />
                
                <div className="relative">
                  <div className="flex items-center gap-4 mb-4">
                    <div className="p-4 rounded-xl bg-[#1a1f2d] text-blue-500 group-hover:text-blue-400 transition-colors">
                      <option.icon size={28} />
                    </div>
                    <h2 className="text-xl font-semibold text-white group-hover:text-blue-400 transition-colors">
                      {option.title}
                    </h2>
                  </div>
                  <p className="text-gray-400 group-hover:text-gray-300 transition-colors">
                    {option.description}
                  </p>
                </div>
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseCreationSelector; 