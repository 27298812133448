import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../auth/AuthContext';
import { CourseSelector } from './interactivelessons';

const LandingPage = () => {
  const { currentUser } = useAuth();
  
  // If user is logged in, redirect to dashboard
  if (currentUser) {
    return <Navigate to="/dashboard" replace />;
  }
  
  // Otherwise show the course selector component
  return <CourseSelector />;
};

export default LandingPage; 