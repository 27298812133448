import React, { useState, useEffect } from 'react';
import { WifiOff } from 'react-feather';

/**
 * A small component that displays a notification when the user is offline.
 * This helps users understand why AI features might not be working.
 */
const NetworkStatus = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [showOfflineMessage, setShowOfflineMessage] = useState(false);
  
  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
      // Show a brief "back online" message
      setShowOfflineMessage(true);
      setTimeout(() => setShowOfflineMessage(false), 3000);
    };
    
    const handleOffline = () => {
      setIsOnline(false);
      setShowOfflineMessage(true);
    };
    
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);
    
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);
  
  // Only show when offline or briefly after coming back online
  if (!showOfflineMessage) return null;
  
  return (
    <div 
      className={`fixed bottom-20 left-1/2 transform -translate-x-1/2 z-50 px-4 py-2 rounded-lg shadow-lg flex items-center ${
        isOnline ? 'bg-green-800/90 text-green-100' : 'bg-red-800/90 text-red-100'
      }`}
      style={{ maxWidth: '90%', width: 'auto' }}
    >
      {!isOnline && <WifiOff size={16} className="mr-2" />}
      <span className="text-sm">
        {isOnline 
          ? "You're back online! AI features are now available."
          : "You're offline. AI features will not work until you reconnect."}
      </span>
    </div>
  );
};

export default NetworkStatus; 