import React, { createContext, useContext, useEffect, useState, useRef, useCallback } from "react";
import { supabase } from "./supabase";
import { initUserData, updateUserLoginTime, getUserProgress } from "../services/userProgress";

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userProfile, setUserProfile] = useState(null);
  const [enrolledCourses, setEnrolledCourses] = useState(null);
  const [isInitialized, setIsInitialized] = useState(false);
  const [isPremium, setIsPremium] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const lastProfileUpdateRef = useRef(Date.now());

  // Function to save cached auth data
  const saveCachedAuthData = (user, profile, enrolled) => {
    try {
      // Don't store sensitive auth information
      const userData = user ? {
        id: user.id,
        email: user.email,
        user_metadata: user.user_metadata
      } : null;
      
      localStorage.setItem('authCache', JSON.stringify({
        currentUser: userData,
        userProfile: profile,
        enrolledCourses: enrolled,
        timestamp: Date.now()
      }));
    } catch (error) {
      console.error('Error saving auth cache:', error);
    }
  };
  
  // Function to load cached auth data
  const loadCachedAuthData = () => {
    try {
      const cachedData = localStorage.getItem('authCache');
      if (cachedData) {
        const parsedData = JSON.parse(cachedData);
        const CACHE_DURATION = 30 * 60 * 1000; // 30 minutes
        
        if (parsedData && parsedData.timestamp && 
            (Date.now() - parsedData.timestamp) < CACHE_DURATION) {
          // Use cached data
          if (parsedData.currentUser) setCurrentUser(parsedData.currentUser);
          if (parsedData.userProfile) setUserProfile(parsedData.userProfile);
          if (parsedData.enrolledCourses) setEnrolledCourses(parsedData.enrolledCourses);
          
          lastProfileUpdateRef.current = parsedData.timestamp;
          return true;
        }
      }
      return false;
    } catch (error) {
      console.error('Error loading auth cache:', error);
      return false;
    }
  };

  // Function to process user data and extract enrolled courses information
  const processUserData = (userData) => {
    if (!userData || !userData.courses) return null;
    
    try {
      // Extract enrolled courses data
      const enrolled = {};
      Object.entries(userData.courses).forEach(([courseId, courseData]) => {
        // Check if courseData is valid
        if (courseData && typeof courseData === 'object') {
          // Either explicitly enrolled or has lesson progress
          const hasLessons = courseData.lessons && Object.keys(courseData.lessons).length > 0;
          if (courseData.enrolled || hasLessons) {
            enrolled[courseId] = {
              ...courseData,
              id: courseId
            };
          }
        }
      });
      
      return enrolled;
    } catch (error) {
      return null;
    }
  };

  async function signup(email, password, displayName) {
    console.log('Signing up with:', email, 'and displayName:', displayName);
    
    // Create auth user
    const { data, error } = await supabase.auth.signUp({
      email,
      password,
      options: {
        data: {
          displayName: displayName || email.split('@')[0]
        }
      }
    });
    
    if (error) throw error;
    
    if (data?.user) {
      // Initialize user data in Supabase
      const initResult = await initUserData(data.user.id);
      console.log('User data initialization result:', initResult);
    }
    
    return data;
  }

  async function login(email, password) {
    const { data, error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });
    
    if (error) throw error;
    
    if (data?.user) {
      // Update login time and streak
      const updateResult = await updateUserLoginTime(data.user.id);
      console.log('User login update result:', updateResult);
      
      // Fetch user profile
      const userProgressData = await getUserProgress(data.user.id);
      setUserProfile(userProgressData);
      
      // Process and set enrolled courses
      const enrolledData = processUserData(userProgressData);
      setEnrolledCourses(enrolledData);
      
      // Save to cache
      saveCachedAuthData(data.user, userProgressData, enrolledData);
      lastProfileUpdateRef.current = Date.now();
    }
    
    return data;
  }

  async function signInWithGoogle() {
    const { data, error } = await supabase.auth.signInWithOAuth({
      provider: 'google',
      options: {
        redirectTo: window.location.origin
      }
    });
    
    if (error) throw error;
    
    return data;
  }

  async function logout() {
    // Clear cache on logout
    localStorage.removeItem('authCache');
    localStorage.removeItem(`userProgress_${currentUser?.id}`);
    
    const { error } = await supabase.auth.signOut();
    if (error) throw error;
    setUserProfile(null);
    setEnrolledCourses(null);
    return true;
  }

  async function resetPassword(email) {
    const { error } = await supabase.auth.resetPasswordForEmail(email, {
      redirectTo: window.location.origin + '/reset-password',
    });
    
    if (error) throw error;
    return true;
  }

  async function updateUserProfile(user, data) {
    try {
      // First update auth metadata
      const { error: authError } = await supabase.auth.updateUser({
        data: {
          displayName: data.displayName || user.user_metadata?.displayName
        }
      });
      
      if (authError) throw authError;
      
      // Then update the profile in the users table
      const { error: dbError } = await supabase
        .from('users')
        .update({
          display_name: data.displayName || user.user_metadata?.displayName
        })
        .eq('id', user.id);
      
      if (dbError) throw dbError;
      
      // Refresh the user profile
      const updatedProfile = await getUserProgress(user.id);
      setUserProfile(updatedProfile);
      
      // Update enrolled courses data
      const enrolledData = processUserData(updatedProfile);
      setEnrolledCourses(enrolledData);
      
      // Update cache with new data
      saveCachedAuthData(user, updatedProfile, enrolledData);
      lastProfileUpdateRef.current = Date.now();
      
      return true;
    } catch (error) {
      console.error('Error updating user profile:', error);
      throw error;
    }
  }

  // Function to update the user progress in the local state first, then in the database
  // Use a useRef for the debounce timer
  const progressUpdateTimerRef = useRef(null);
  const pendingUpdatesRef = useRef({});

  const updateLocalUserProgress = useCallback((courseId, lessonId, progressData) => {
    try {
      // Only proceed if we have userProfile and valid inputs
      if (!userProfile || !courseId || typeof progressData !== 'object') {
        return null;
      }
      
      // Store this update in our pending updates
      if (!pendingUpdatesRef.current[courseId]) {
        pendingUpdatesRef.current[courseId] = {};
      }
      
      if (lessonId) {
        pendingUpdatesRef.current[courseId][lessonId] = progressData;
      }
      
      // Clear any existing timer
      if (progressUpdateTimerRef.current) {
        clearTimeout(progressUpdateTimerRef.current);
      }
      
      // Set a new timer to process all pending updates at once
      progressUpdateTimerRef.current = setTimeout(() => {
        // Clone the current profile to avoid direct state mutation
        const updatedProfile = {
          ...userProfile,
          courses: { ...(userProfile.courses || {}) }
        };
        
        // Process all pending updates
        Object.entries(pendingUpdatesRef.current).forEach(([cId, lessons]) => {
          // Ensure the course exists in the courses object
          if (!updatedProfile.courses[cId]) {
            updatedProfile.courses[cId] = {
              enrolled: true,
              lessons: {}
            };
          } else {
            // Create a copy of the existing course data
            updatedProfile.courses[cId] = {
              ...updatedProfile.courses[cId],
              enrolled: true
            };
          }
          
          // Ensure the lessons object exists for this course
          if (!updatedProfile.courses[cId].lessons) {
            updatedProfile.courses[cId].lessons = {};
          }
          
          // Update lesson data for each pending lesson
          Object.entries(lessons).forEach(([lId, pData]) => {
            updatedProfile.courses[cId].lessons[lId] = {
              ...(updatedProfile.courses[cId].lessons[lId] || {}),
              ...pData
            };
          });
        });
        
        // Reset pending updates
        pendingUpdatesRef.current = {};
        
        // Update the user profile state
        setUserProfile(updatedProfile);
        
        // Update the enrolled courses
        const enrolledData = processUserData(updatedProfile);
        if (enrolledData) {
          setEnrolledCourses(enrolledData);
        }
        
        // Update cache with new data
        saveCachedAuthData(currentUser, updatedProfile, enrolledData);
        lastProfileUpdateRef.current = Date.now();
      }, 500); // 500ms debounce
      
      // Return the existing userProfile since we haven't updated state yet
      return userProfile;
    } catch (error) {
      return null;
    }
  }, [userProfile, processUserData, currentUser]);

  useEffect(() => {
    // Set initial user on mount
    const getCurrentUser = async () => {
      try {
        // Try loading from cache first
        const cacheLoaded = loadCachedAuthData();
        if (cacheLoaded) {
          console.log('Loaded user data from cache');
          setLoading(false);
          setIsInitialized(true);
          
          // Optional: refresh data in background if cache is older than 5 minutes
          const REFRESH_THRESHOLD = 5 * 60 * 1000; // 5 minutes
          if (Date.now() - lastProfileUpdateRef.current > REFRESH_THRESHOLD) {
            // Refresh in background without blocking the UI
            refreshUserData();
          }
          return;
        }
        
        // Add a timeout protection to prevent infinite loading
        const timeoutId = setTimeout(() => {
          setCurrentUser(null);
          setUserProfile(null);
          setEnrolledCourses(null);
          setLoading(false);
         
        }, 10000); // 10 second timeout
        
        // Get current session
        const { data: { session }, error: sessionError } = await supabase.auth.getSession();
        
        if (sessionError) {
          setCurrentUser(null);
          setUserProfile(null);
          setEnrolledCourses(null);
          setLoading(false);
          setIsInitialized(true);
          clearTimeout(timeoutId);
          return;
        }
        
        if (session?.user && session.user.id) {
          // Ensure the user object has the uid property that components expect
          const enhancedUser = {
            ...session.user,
            uid: session.user.id, // Add uid field as an alias of id for compatibility
            displayName: session.user.user_metadata?.displayName ||
                        session.user.user_metadata?.full_name ||
                        session.user.user_metadata?.name || 
                        session.user.email?.split('@')[0]
          };
          
          // Set the enhanced user object and stop loading immediately to show the UI
          setCurrentUser(enhancedUser);
          
          // Set loading to false immediately to show the UI while we finish loading data
          setLoading(false);
          setIsInitialized(true);
          
          // Fetch user data in the background
          (async () => {
            try {
              // Ensure user record exists in the database
              await initUserData(session.user.id);
              
              // Get the full user profile
              const userProgressData = await getUserProgress(session.user.id);
              
              if (userProgressData) {
                setUserProfile(userProgressData);
                
                // Process and set enrolled courses
                const enrolledData = processUserData(userProgressData);
                setEnrolledCourses(enrolledData);
                
                // Save to cache
                saveCachedAuthData(enhancedUser, userProgressData, enrolledData);
                lastProfileUpdateRef.current = Date.now();
              }
            } catch (dataError) {
              // Silent error handling
              console.error('Error loading user data in background:', dataError);
            } finally {
              clearTimeout(timeoutId);
            }
          })();
        } else {
          setCurrentUser(null);
          setUserProfile(null);
          setEnrolledCourses(null);
          setLoading(false);
          setIsInitialized(true);
          clearTimeout(timeoutId);
        }
      } catch (error) {
        console.error('Error in getCurrentUser:', error);
        setCurrentUser(null);
        setUserProfile(null);
        setEnrolledCourses(null);
        setLoading(false);
        setIsInitialized(true);
      }
    };
    
    // Function to refresh user data in the background
    const refreshUserData = async () => {
      try {
        if (!currentUser) return;
        
        // Refresh user profile from database
        const userProgressData = await getUserProgress(currentUser.id);
        if (userProgressData) {
          setUserProfile(userProgressData);
          
          // Process and set enrolled courses
          const enrolledData = processUserData(userProgressData);
          setEnrolledCourses(enrolledData);
          
          // Update cache with fresh data
          saveCachedAuthData(currentUser, userProgressData, enrolledData);
          lastProfileUpdateRef.current = Date.now();
        }
      } catch (error) {
        console.error('Error refreshing user data:', error);
      }
    };
    
    getCurrentUser();
    
    // Listen for auth changes
    const { data: { subscription } } = supabase.auth.onAuthStateChange(
      async (authEvent, session) => {
        // Add auth state change timeout protection
        const authTimeoutId = setTimeout(() => {
          setLoading(false);
        }, 20000); // Increased from 10000 to 20000 (20 second timeout)
        
        try {
          console.log('Auth state change:', authEvent, session?.user?.id);
          
          if (session?.user && session.user.id) {
            // Ensure the user object has the uid property that components expect
            const enhancedUser = {
              ...session.user,
              uid: session.user.id, // Add uid field as an alias of id for compatibility
              displayName: session.user.user_metadata?.displayName ||
                          session.user.user_metadata?.full_name ||
                          session.user.user_metadata?.name || 
                          session.user.email?.split('@')[0]
            };
            
            // Set the enhanced user
            setCurrentUser(enhancedUser);
            
            if (authEvent === 'SIGNED_IN') {
              // Set loading to false immediately to show the UI
              setLoading(false);
              
              // For OAuth sign-ins, update login time and refresh user data
              if (session.user.app_metadata?.provider === 'google') {
                console.log('Google OAuth sign-in detected, initializing user data');
                
                // Add a small delay to ensure Supabase session is fully established
                setTimeout(async () => {
                  try {
                    // Try to update login time
                    const loginTimeResult = await updateUserLoginTime(session.user.id);
                    console.log('Login time update result for Google user:', loginTimeResult);
                    
                    // Initialize user data with retry
                    let initResult;
                    let initRetries = 0;
                    while (initRetries < 3) {
                      try {
                        console.log(`Attempt ${initRetries + 1} to initialize user data for Google user`);
                        initResult = await initUserData(session.user.id);
                        console.log('User data initialization result:', initResult);
                        break; // Success, exit loop
                      } catch (initError) {
                        console.error(`Initialization attempt ${initRetries + 1} failed:`, initError);
                        initRetries++;
                        if (initRetries < 3) {
                          // Wait before retrying
                          await new Promise(resolve => setTimeout(resolve, 1000));
                        }
                      }
                    }
                    
                    // Get the full user profile with retry
                    let userProgressData;
                    let progressRetries = 0;
                    while (progressRetries < 3) {
                      try {
                        console.log(`Attempt ${progressRetries + 1} to fetch user profile for Google user`);
                        userProgressData = await getUserProgress(session.user.id);
                        if (userProgressData) {
                          console.log('Successfully fetched user profile for Google user');
                          break; // Success, exit loop
                        }
                        progressRetries++;
                      } catch (progressError) {
                        console.error(`User profile fetch attempt ${progressRetries + 1} failed:`, progressError);
                        progressRetries++;
                        if (progressRetries < 3) {
                          // Wait before retrying
                          await new Promise(resolve => setTimeout(resolve, 1000));
                        }
                      }
                    }
                    
                    if (userProgressData) {
                      setUserProfile(userProgressData);
                      
                      // Process and set enrolled courses
                      const enrolledData = processUserData(userProgressData);
                      setEnrolledCourses(enrolledData);
                      
                      // Save to cache
                      saveCachedAuthData(enhancedUser, userProgressData, enrolledData);
                      lastProfileUpdateRef.current = Date.now();
                    } else {
                      // Create default user profile as fallback
                      console.log('Using default empty profile for Google user');
                      const defaultProfile = {
                        id: session.user.id,
                        courses: {},
                        streak: { current: 0 },
                        preferences: { dark_mode: true, language: 'en' }
                      };
                      setUserProfile(defaultProfile);
                      setEnrolledCourses({});
                      saveCachedAuthData(enhancedUser, defaultProfile, {});
                    }
                  } catch (error) {
                    console.error('Error in Google user initialization:', error);
                  } finally {
                    clearTimeout(authTimeoutId);
                  }
                }, 1000); // 1 second delay
              } else {
                // Non-OAuth login path (existing code)
                // Fetch user data in the background
                (async () => {
                  try {
                    await initUserData(session.user.id);
                    
                    // Get the full user profile
                    const userProgressData = await getUserProgress(session.user.id);
                    
                    if (userProgressData) {
                      setUserProfile(userProgressData);
                      
                      // Process and set enrolled courses
                      const enrolledData = processUserData(userProgressData);
                      setEnrolledCourses(enrolledData);
                      
                      // Save to cache
                      saveCachedAuthData(enhancedUser, userProgressData, enrolledData);
                      lastProfileUpdateRef.current = Date.now();
                    }
                  } catch (error) {
                    // Silent error handling
                    console.error('Error loading user data on sign in:', error);
                  } finally {
                    clearTimeout(authTimeoutId);
                  }
                })();
              }
            } else if (authEvent === 'SIGNED_OUT') {
              setCurrentUser(null);
              setUserProfile(null);
              setEnrolledCourses(null);
              setLoading(false);
              clearTimeout(authTimeoutId);
            }
          } else if (authEvent === 'SIGNED_OUT') {
            setCurrentUser(null);
            setUserProfile(null);
            setEnrolledCourses(null);
            setLoading(false);
            clearTimeout(authTimeoutId);
          }
        } catch (error) {
          console.error('Error in auth state change:', error);
          setLoading(false);
          clearTimeout(authTimeoutId);
        }
      }
    );
    
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  // Update auth state whenever currentUser or userProfile changes
  useEffect(() => {
    if (userProfile) {
      setIsPremium(userProfile.is_premium || false);
      setIsAdmin(userProfile.is_admin || false);
    } else {
      setIsPremium(false);
      setIsAdmin(false);
    }
  }, [userProfile]);

  // Add refreshUser function to refresh the user data when subscription status changes
  const refreshUser = useCallback(async () => {
    if (!currentUser) return;
    
    try {
      // Fetch user profile
      const userProgressData = await getUserProgress(currentUser.id);
      setUserProfile(userProgressData);
      
      // Process and set enrolled courses
      const enrolledData = processUserData(userProgressData);
      setEnrolledCourses(enrolledData);
      
      // Update premium status
      if (userProgressData && userProgressData.is_premium !== undefined) {
        setIsPremium(userProgressData.is_premium);
      }
      
      // Save to cache
      saveCachedAuthData(currentUser, userProgressData, enrolledData);
      lastProfileUpdateRef.current = Date.now();
      
      return userProgressData;
    } catch (error) {
      console.error('Error refreshing user data:', error);
      throw error;
    }
  }, [currentUser]);

  const value = {
    currentUser,
    userProfile,
    enrolledCourses,
    isPremium,
    isAdmin,
    loading,
    signup,
    login,
    signInWithGoogle,
    logout,
    resetPassword,
    updateUserProfile,
    updateUserProgress: updateLocalUserProgress,  // This is debounced
    isInitialized,
    refreshUser,  // Export the refreshUser function
  };

  if (!isInitialized) {
    return (
      <div className="min-h-screen bg-[#1a1e2b] flex items-center justify-center">
        <div className="text-center">
          <div className="w-16 h-16 border-4 border-blue-600 border-t-transparent rounded-full animate-spin mx-auto mb-4"></div>
          <p className="text-gray-400">Loading application...</p>
        </div>
      </div>
    );
  }

  return (
    <AuthContext.Provider value={value}>
      {!loading && isInitialized && children}
    </AuthContext.Provider>
  );
} 