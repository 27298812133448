/**
 * Centralized caching service for handling cached data consistently across the application
 */

// Cache durations in milliseconds
export const CACHE_DURATIONS = {
  AUTH: 30 * 60 * 1000, // 30 minutes
  USER_PROGRESS: 5 * 60 * 1000, // 5 minutes
  DASHBOARD: 10 * 60 * 1000, // 10 minutes
  COURSES: 15 * 60 * 1000, // 15 minutes
};

// Cache keys
export const CACHE_KEYS = {
  AUTH: 'authCache',
  USER_PROGRESS: (userId) => `userProgress_${userId}`,
  DASHBOARD_CONTENT: 'dashboardContentCache',
  COURSES_LIST: 'coursesListCache',
  COURSE_DETAILS: (courseId) => `courseDetails_${courseId}`,
  ENROLLED_COURSES: (userId) => `enrolledCourses_${userId}`,
  ALL_COURSES_CACHE: 'all_courses_cache',
  LESSONS_BY_COURSE: (courseId) => `lessons_by_course_${courseId}`,
  LESSON_DETAILS: (lessonId) => `lesson_details_${lessonId}`,
};

/**
 * Save data to cache with specified key and timestamp
 * @param {string} key - Cache key
 * @param {any} data - Data to cache
 * @param {Object} [options] - Additional options
 * @param {number} [options.duration] - Custom duration in ms
 */
export const saveToCache = (key, data, options = {}) => {
  try {
    const cacheData = {
      data,
      timestamp: Date.now(),
      ...options
    };
    
    localStorage.setItem(key, JSON.stringify(cacheData));
    return true;
  } catch (error) {
    console.error(`Error saving to cache (${key}):`, error);
    return false;
  }
};

/**
 * Load data from cache if available and not expired
 * @param {string} key - Cache key 
 * @param {number} [duration] - Optional custom duration in ms
 * @returns {Object|null} Cache data or null if expired/invalid
 */
export const loadFromCache = (key, duration) => {
  try {
    const cachedItem = localStorage.getItem(key);
    
    if (!cachedItem) return null;
    
    const parsedData = JSON.parse(cachedItem);
    const cacheDuration = duration || 
      (key === CACHE_KEYS.AUTH ? CACHE_DURATIONS.AUTH : 
      key === CACHE_KEYS.DASHBOARD_CONTENT ? CACHE_DURATIONS.DASHBOARD : 
      key.startsWith('userProgress_') ? CACHE_DURATIONS.USER_PROGRESS :
      key.startsWith('enrolledCourses_') ? CACHE_DURATIONS.COURSES :
      key === CACHE_KEYS.COURSES_LIST ? CACHE_DURATIONS.COURSES :
      key.startsWith('courseDetails_') ? CACHE_DURATIONS.COURSES :
      5 * 60 * 1000); // Default 5 min fallback
    
    // Check if cache is still valid
    if (parsedData && parsedData.timestamp && 
        (Date.now() - parsedData.timestamp) < cacheDuration) {
      return parsedData;
    }
    
    return null;
  } catch (error) {
    console.error(`Error loading from cache (${key}):`, error);
    return null;
  }
};

/**
 * Clear specific cache by key
 * @param {string} key - Cache key to clear
 */
export const clearCache = (key) => {
  try {
    localStorage.removeItem(key);
    return true;
  } catch (error) {
    console.error(`Error clearing cache (${key}):`, error);
    return false;
  }
};

/**
 * Clear all caches related to a user
 * @param {string} userId - User ID
 */
export const clearUserCaches = (userId) => {
  try {
    // Clear all caches related to this user
    clearCache(CACHE_KEYS.AUTH);
    clearCache(CACHE_KEYS.USER_PROGRESS(userId));
    clearCache(CACHE_KEYS.ENROLLED_COURSES(userId));
    clearCache(CACHE_KEYS.DASHBOARD_CONTENT);
    
    return true;
  } catch (error) {
    console.error(`Error clearing user caches for ${userId}:`, error);
    return false;
  }
};

/**
 * Check if cache needs refresh (older than specified threshold)
 * @param {string} key - Cache key
 * @param {number} thresholdMs - Threshold in milliseconds 
 * @returns {boolean} True if cache should be refreshed
 */
export const shouldRefreshCache = (key, thresholdMs = 5 * 60 * 1000) => {
  try {
    const cachedItem = localStorage.getItem(key);
    if (!cachedItem) return true;
    
    const parsedData = JSON.parse(cachedItem);
    return !parsedData || !parsedData.timestamp || 
      (Date.now() - parsedData.timestamp) > thresholdMs;
  } catch (error) {
    console.error(`Error checking cache refresh (${key}):`, error);
    return true; // Refresh on error
  }
};

/**
 * Invalidate all course-related caches
 * Called when courses are added, updated, or deleted
 * @returns {boolean} Success status
 */
export const invalidateCourseCaches = () => {
  try {
    // Clear the main courses cache
    clearCache(CACHE_KEYS.COURSES_LIST);
    clearCache(CACHE_KEYS.ALL_COURSES_CACHE);
    clearCache(CACHE_KEYS.DASHBOARD_CONTENT);
    
    // Find and clear all course detail caches
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key && key.startsWith('courseDetails_')) {
        clearCache(key);
      }
      if (key && key.startsWith('enrolledCourses_')) {
        clearCache(key);
      }
    }
    
    console.log('All course caches invalidated');
    return true;
  } catch (error) {
    console.error('Error invalidating course caches:', error);
    return false;
  }
};

/**
 * Invalidate lesson caches for a specific course
 * Called when lessons are added, updated, or deleted
 * @param {string} courseId - Course ID to invalidate lessons for
 * @returns {boolean} Success status
 */
export const invalidateLessonCachesByCourse = (courseId) => {
  try {
    if (!courseId) {
      console.error('No courseId provided for lesson cache invalidation');
      return false;
    }
    
    // Clear the lessons by course cache
    clearCache(CACHE_KEYS.LESSONS_BY_COURSE(courseId));
    
    // Also clear the course details since lesson count might have changed
    clearCache(CACHE_KEYS.COURSE_DETAILS(courseId));
    
    // Clear all lesson detail caches for this course
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key && key.startsWith('lesson_details_') && key.includes(courseId)) {
        clearCache(key);
      }
    }
    
    console.log(`Lesson caches invalidated for course: ${courseId}`);
    return true;
  } catch (error) {
    console.error(`Error invalidating lesson caches for course ${courseId}:`, error);
    return false;
  }
};

/**
 * Invalidate a specific lesson cache
 * Called when a specific lesson is updated
 * @param {string} lessonId - Lesson ID to invalidate
 * @param {string} courseId - Course ID (optional) 
 * @returns {boolean} Success status
 */
export const invalidateLessonCache = (lessonId, courseId = null) => {
  try {
    if (!lessonId) {
      console.error('No lessonId provided for cache invalidation');
      return false;
    }
    
    // Clear the specific lesson detail cache
    clearCache(CACHE_KEYS.LESSON_DETAILS(lessonId));
    
    // If courseId is provided, also clear course-level lesson caches
    if (courseId) {
      clearCache(CACHE_KEYS.LESSONS_BY_COURSE(courseId));
      clearCache(CACHE_KEYS.COURSE_DETAILS(courseId));
    }
    
    console.log(`Lesson cache invalidated: ${lessonId}`);
    return true;
  } catch (error) {
    console.error(`Error invalidating lesson cache ${lessonId}:`, error);
    return false;
  }
}; 